import React from 'react';
import Game from "./Game";
import {connect} from "react-redux";
import {Button, Col, Row} from "reactstrap";
import i18n from "../../../i18n";
import Settings from "../Settings";
import {getCheckURI, getGameURI, getGameURI2, initCells} from "../../Games/Common/FloorX";

class PaperOnline extends Game {
    constructor(props) {
        super(props);

        const {ticket} = this.props.match.params;

        this.state = {};

        const {api, cache} = this.props;
        cache.db.getPaper(ticket, api)
            .then(gameInfo => {
                this.setState({
                    game: this.prepareGameInfo(gameInfo, api)
                });
            });
    }

    prepareGameInfo = (gameInfo, api) => {
        const {ticket, settings_id, subgame_code, public_code: publicCode, prizes} = gameInfo;
        const superGame = Settings.getSuperGame(settings_id);
        const type = parseInt(subgame_code);
        const prepareCells = initCells(prizes, gameInfo.price_mult);

        return {
            prizes: prizes
            , ticket: ticket
            , publicCode: publicCode
            , validFrom: new Date(gameInfo.create)
            , validTo: new Date(gameInfo.expire)
            , cells: prepareCells.rows
            , useMultiplicator: prepareCells.useMultiplicator
            , type: type
            , subtype: superGame.subtype
            , settings_id: settings_id
            , cost: gameInfo.price / 100
            , zones: prizes[0].column
        }
    };

    quadColor = () => 'black';

    makeButtons = () => {
        const {game, eog} = this.state;
        const {settings_id, type, cost, subtype, zones} = game;
        let rows = [];

        if (eog) {
            rows.push(
                <Row key={1} className={'py-1'}>
                    <Col key={2} xs={12}>
                        <Button color="primary" className={'btn-block'}
                                href={getCheckURI(game.secretCode + '_' + game.serverSteps, game.publicCode)}
                                target='_blank'>
                            {i18n.t('common.check_game')}
                        </Button>
                    </Col>
                </Row>);
        } else {
            rows.push(
                <Row key={1} className={'py-1'}>
                    <Col key={3} xs={12}>
                        <Button color="primary" className={'btn-block'} onClick={() => this.onBtnTakePrize(true)}>
                            {i18n.t('common.play', {
                                title: Settings.getGameFullTitle(settings_id, type),
                                price: cost
                            })}
                        </Button>
                    </Col>
                </Row>);
            rows.push(
                <Row key={2} className={'py-1'}>
                    <Col key={4} xs={12}>
                        <Button color="secondary" className={'btn-block'} href={getGameURI(game) + '&color=false'}>
                            {i18n.t('common.interactive')}
                        </Button>
                    </Col>
                </Row>);
            if (subtype === 'football' && zones === 4) {
                rows.push(
                    <Row key={3} className={'py-1'}>
                        <Col key={4} xs={12}>
                            <Button color="success" className={'btn-block'} href={getGameURI2(game)}>
                                Web online
                            </Button>
                        </Col>
                    </Row>);
            }
        }
        return rows;
    }
}

const mapStateToProps = (state) => {
    return {
        api: state.api,
        cache: state.cache,
    }
};

export default connect(mapStateToProps)(PaperOnline)