import React from "react";
import {connect} from "react-redux";
import StatsGamePlayer from "./Stats/StatsGamePlayer";
import SimpleContainer from "../../Pages/SimpleContainer";

const BoardId = '071c2083-3f8f-42fe-a61b-ec6207d0990d';

const StatsPage = (props) => {
    return (
        <SimpleContainer>
            <StatsGamePlayer BoardId={BoardId}/>
        </SimpleContainer>);
};

const mapStateToProps = (state) => {
    return {
        api: state.api,
        cache: state.cache,
    }
};

export default connect(mapStateToProps)(StatsPage)