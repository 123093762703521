import React from "react";
import {connect} from "react-redux";
import SimpleContainer from "../../Pages/SimpleContainer";
import Rating from "./Rating";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const RatingsPage = (props) => {
    const {t} = useTranslation();

    return (
        <SimpleContainer BackGroundColor={'white'}>
            <Rating Rated={true} Count={10}/>
            <Link to={'/aft'}>{t('common.back_to_game_select')}</Link>
        </SimpleContainer>);
};

const mapStateToProps = (state) => {
    return {
        api: state.api,
        cache: state.cache,
    }
};

export default connect(mapStateToProps)(RatingsPage)