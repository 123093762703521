import React, {useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import SimpleContainer from "../../Pages/SimpleContainer";
import {Link, Redirect} from "react-router-dom";
import {
    Alert,
    Button,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal, ModalBody, ModalFooter,
    ModalHeader,
    Nav,
    Navbar,
    NavbarBrand,
    Row,
    UncontrolledDropdown
} from "reactstrap";
import i18n, {dangerHtml, i18nCurrentLanguage, i18ntDangerHtml} from "../../../i18n";
import {getTitle} from "./FindThis";
import {getBalance, preProcessWord, prizeString, processWallet} from "../../../components/Common/GameControl/Utils";
import Rating from "./Rating";
import * as queryString from "query-string";
import {useTranslation} from "react-i18next";
import {StatsGameAdjacent} from "./Stats/StatsGameAdjacent";
import VersionControl from "../Common/VersionControl";

const BoardId = '071c2083-3f8f-42fe-a61b-ec6207d0990d';
const timeRefreshVersionCheck = 20 * 60; // каждые 20 минут
const select_lang = [
    {
        title: 'En'
        , code: 'en'
        , flag: 'flag-icon-gb'
        , disable: false
        , active: true
    }
    , {
        title: 'De'
        , code: 'de'
        , flag: 'flag-icon-de'
        , disable: false
        , active: true
    }
    , {
        title: 'Fr'
        , code: 'fr'
        , flag: 'flag-icon-fr'
        , disable: false
        , active: false
    }
    , {
        title: 'It'
        , code: 'it'
        , flag: 'flag-icon-it'
        , disable: false
        , active: false
    }
    , {
        title: 'Ru'
        , code: 'ru'
        , flag: 'flag-icon-ru'
        , disable: false
        , active: true
    }
];

export const AprilGame = (props) => {
    const {t} = useTranslation();
    const api = useSelector(state => state.api);
    const cache = useSelector(state => state.cache);
    const [isPromoInfoShow, setPromoInfoShow] = useState(true);
    const [xLimit, setXLimit] = useState(0);
    const [auth, setAuth] = useState(false);
    const [words, setWords] = useState({});
    const [state, setState] = useState({});
    const [wallet, setWallet] = useState([{}]);
    // eslint-disable-next-line no-unused-vars
    const [gameInProgress, setGameInProgress] = useState([{}]);
    const [isShortRule, setShortRule] = useState(true);

    const loadSettings = useCallback((board_id) => {
        const updateBoardState = async (boardInfo) => {
            let words = {};

            setGameInProgress(boardInfo.gip);

            // проверка кеша настроек
            const cs = await cache.db.getSettings(boardInfo.settings_id);
            if (cs === undefined || cs.version < boardInfo.settings_version) {
                let sq = [];
                sq.push({id: boardInfo.settings_id});
                await cache.db.updateSettings(sq, api);
            }

            let xl = 0;
            const settings = await cache.db.getSettings(boardInfo.settings_id, api, boardInfo.settings_version);
            if (settings.xlimit !== undefined) {
                xl = settings.xlimit;
            }

            let ww;

            for (const g of settings.games) {
                ww = g.wallet;

                const item = words[g.description];
                if (item === undefined) {
                    words = {
                        ...words,
                        [g.description]: {
                            ...g,
                            settings_id: boardInfo.settings_id,
                            partners: [settings.partner]
                        }
                    };
                } else {
                    item.partners.push(settings.partner);
                }
            }

            const w = processWallet(ww, boardInfo.wallet);
            setWallet(w);

            setXLimit(xl)
            setWords(words);
        };

        api.auth.requestTokenApiJson({
            request: '/api/c/ft/check_board',
            query: {
                board_id
            }
        }).then((response) => {
            updateBoardState(response)
                .then(r => {
                });
        });
    }, [api, cache.db]);

    // монтирование
    useEffect(() => {
        const promoRaw = sessionStorage.getItem("promo");
        if (promoRaw !== null) {
            setPromoInfoShow(promoRaw === 'true');
        }

        let parsed = queryString.parse(props.location.search);
        const {rfc} = parsed;

        if (api.auth.isUserNull() || api.auth.getReferenceToken() === undefined || api.auth.isRefreshTokenExpired()) {
            api.auth.registerUser(rfc)
                .then(
                    () => {
                        setAuth(true);
                        loadSettings(BoardId);
                    }
                );
        } else {
            setAuth(true);
            loadSettings(BoardId);
        }
    }, [props, api.auth, loadSettings]);

    const showWallet = (wallet) => {
        return (wallet && wallet.length > 0) ? getBalance(wallet, true, 't-blue') : '&nbsp;';
    };

    const title = <span key={'t1'} dangerouslySetInnerHTML={i18ntDangerHtml('app.title2')}/>;
    const currentLanguage = i18nCurrentLanguage();
    const {ftg} = state;

    if (ftg !== undefined) {
        return <Redirect to={`/ftg/${ftg}`}/>;
    }

    const togglePromoInfoShow = () => {
        if (isPromoInfoShow) {
            sessionStorage.setItem("promo", "false");
        }
        setPromoInfoShow(prevState => !prevState);
    };

    const makePromoInfoDialog = () => {
        return (
            <Modal isOpen={auth && isPromoInfoShow} toggle={togglePromoInfoShow} className={'t-blue'}>
                <ModalHeader toggle={togglePromoInfoShow}>
                    <span dangerouslySetInnerHTML={i18ntDangerHtml('ft.promo_title')}/>
                </ModalHeader>
                <ModalBody>
                    <div dangerouslySetInnerHTML={i18ntDangerHtml('ft.promo_body', {
                        nickname: api.auth.getNickname()
                        , count: xLimit
                        , balance: showWallet(wallet)
                    })}/>
                </ModalBody>
                <ModalFooter>
                    <Button key={'tp'} color="primary" onClick={togglePromoInfoShow}>{i18n.t('common.ok')}</Button>
                </ModalFooter>
            </Modal>);
    };

    const onBtnTicket = async (e, item) => {
        const response = await api.auth.requestTokenApiJson({
            request: '/api/c/ft/start',
            json: {
                settings: item.settings_id
                , game_descriptor: item.game_descriptor
//              ,  ticket_ref: id
            }
        });

        api.auth.saveServerTimestamp(response.server_time);

        if (response.public_code !== undefined) {
            await cache.db.setPaper(response.ticket, response.expire, response);
        }
        setState({ftg: response.ticket});
    };

    const gameList = () => {
        return (auth
            ? <Row noGutters={true}>
                <Col>
                    {Object
                        .keys(words)
                        .map(key => {
                                const item = words[key];
                                return (
                                    <Row key={key}>
                                        <Col className={'px-0 d-flex flex-column align-items-start border'}>
                                            <Button color={'link'} onClick={(e) => onBtnTicket(e, item)}
                                                    dangerouslySetInnerHTML={i18ntDangerHtml('ft.word_select', {
                                                        title: getTitle(item.adjacent !== undefined ? parseInt(item.adjacent) : undefined, false),
                                                        word: preProcessWord(item.description, true),
                                                        game_descriptor: item.game_descriptor,
                                                        letters: item.description.length,
                                                        max: item.width * item.height
                                                    })}/>
                                            <div dangerouslySetInnerHTML={i18ntDangerHtml('ft.prizes', {
                                                prizes: item.prizes.map(p => prizeString(p, false, true)).join(', ')
                                            })}/>
                                        </Col>
                                    </Row>);
                            }
                        )}
                </Col>
            </Row>
            : <></>);
    };

    const makeInfoBlock = () => {
        return (
            <Row noGutters={true}>
                <Col>
                    <Alert className={'my-1'} color="success" fade={true}>
                        <div className={'text-left'} dangerouslySetInnerHTML={i18ntDangerHtml(isShortRule ? 'ft.MainRuleShort' : 'ft.MainRule')}/>
                        <div className={'text-right t-blue cursor-pointer'}
                             onClick={() => setShortRule(prevState => !prevState)}>{i18n.t(isShortRule ? 'common.expand' : 'common.collapse')}</div>
                    </Alert>
                </Col>
                {makePromoInfoDialog()}
            </Row>
        );
    };

    return (
        <SimpleContainer BackGroundColor={'white'}>
            <Row>
                <Col>
                    <Navbar color="light" light expand="md">
                        <NavbarBrand className="mr-auto">
                            <h4 className={'my-0'}>
                                {title}
                            </h4>
                        </NavbarBrand>
                        <Nav navbar>
                            <i className={`fa fa-arrow-circle-o-up t-blue text-info-2`} aria-hidden="true" onClick={togglePromoInfoShow}/>
                        </Nav>
                        <Nav className="ml-auto" navbar>
                            {/*{navLogout}*/}
                            <UncontrolledDropdown nav inNavbar>
                                {select_lang.filter(item => item.code === currentLanguage && item.active).map(item =>
                                    <DropdownToggle key={item.code} nav caret>
                                        <span className={'flag-icon ' + item.flag}/> {item.title}
                                    </DropdownToggle>
                                )}
                                <DropdownMenu right>
                                    {select_lang.filter(item => item.code !== currentLanguage && item.active).map(item =>
                                        <DropdownItem key={item.code} disabled={item.disable}
                                                      onClick={() => i18n.changeLanguage(item.code, () => {
                                                          setState(s => ({...s, newLang: item.code}))
                                                      })}>
                                            <span className={'flag-icon ' + item.flag}/> {item.title}
                                        </DropdownItem>
                                    )}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                    </Navbar>
                </Col>
            </Row>
            <Row>
                <Col>
                    <VersionControl Timer={timeRefreshVersionCheck} AutoReload={false}/>
                </Col>
            </Row>
            <Row>
                <Col className={'d-flex justify-content-center align-items-center position-relative px-1 t-blue'}>
                    <h5 className={'position-absolute'} style={{left: '1rem '}}>
                        <span dangerouslySetInnerHTML={i18ntDangerHtml('ft.test-tour')}/>
                    </h5>
                    <h5 className={'m-0 py-1'}>
                        <span className={'t-blue'}>{i18n.t('modes.ft.title')}</span>
                        <img style={{height: '1.5rem'}} className={'mt-n1 px-1'} src={'/img/logo.svg'} alt='logo'/>
                        <span className={'t-red'}>{i18n.t('modes.ft.title_suffix')}</span>
                    </h5>
                    <h5 className={'position-absolute'} style={{right: '1rem '}}>
                        <div dangerouslySetInnerHTML={dangerHtml(showWallet(wallet))}/>
                    </h5>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Rating top={3} lang={state.newLang}/>
                </Col>
            </Row>
            <Row>
                <Col className={'text-left'}>
                    <Link to={'/aft/unrated'}>{t('common.unrated')}</Link>
                </Col>
                <Col className={'text-right'}>
                    <Link to={'/aft/ratings'}>{t('common.ratings')}</Link>
                </Col>
            </Row>
            {makeInfoBlock()}
            {gameList()}
            <Row>
                <Col className={'m-1 shadow border rounded'}>
                    <StatsGameAdjacent BoardId={BoardId} Type={'pie'} TitleTag={'common.stats_adj_title'}/>
                </Col>
            </Row>
        </SimpleContainer>);
};
