import React, {useCallback, useEffect, useState} from "react";
import * as queryString from "query-string";
import {useSelector} from "react-redux";
import {Redirect} from "react-router-dom";
import {reduceQuery} from "../../../modules/api/CoreApi";
import {Alert, Col, Row} from "reactstrap";
import i18n, {i18ntDangerHtml} from "../../../i18n";
import {getTitle} from "./FindThis";
import {preProcessWord, prizeString} from "../../../components/Common/GameControl/Utils";
import {TouchPanel} from "../../../components";

export const DashboardFindThis = (props) => {
    const api = useSelector(state => state.api);
    const cache = useSelector(state => state.cache);
    // eslint-disable-next-line no-unused-vars
    const [gameInProgress, setGameInProgress] = useState([{}]);
    const [words, setWords] = useState({});
    const [auth, setAuth] = useState(false);
    const [isShortRule, setShortRule] = useState(true);
    // eslint-disable-next-line no-unused-vars
    const [xLimit, setXLimit] = useState(0);
    const [redirect, setRedirect] = useState();

    const onReloadBoardInfo = useCallback(async (board_id, settings_id, setting_version) => {
        const updateBoardState = async (boardInfo) => {
            let words = {};

            setGameInProgress(boardInfo.gip);

            // проверка кеша настроек
            const cs = await cache.db.getSettings(boardInfo.settings_id);
            if (cs === undefined || cs.version < boardInfo.settings_version) {
                let sq = [];
                sq.push({id: boardInfo.settings_id});
                await cache.db.updateSettings(sq, api);
            }

            let xl = 0;
            const settings = await cache.db.getSettings(boardInfo.settings_id, api, boardInfo.settings_version);
            if (settings.xlimit !== undefined) {
                xl = settings.xlimit;
            }

            for (const g of settings.games) {
                const item = words[g.description];
                if (item === undefined) {
                    words = {
                        ...words,
                        [g.description]: {
                            ...g,
                            settings_id: boardInfo.settings_id,
                            partners: [settings.partner]
                        }
                    };
                } else {
                    item.partners.push(settings.partner);
                }
            }

            setXLimit(xl)
            setWords(words);
        };

        const response = await api.auth.requestTokenApiJson({
            request: '/api/c/ft/check_board',
            query: {
                board_id
            }
        });
        await updateBoardState(response);
    }, [api, cache.db]);

    // монтирование
    useEffect(() => {
        let parsed = queryString.parse(props.location.search);
        const {board_id, settings_id, setting_version = 0} = parsed;

        setAuth(api.auth.isUserAuthenticated);
        onReloadBoardInfo(board_id, settings_id, setting_version);
    }, [props, api.auth, onReloadBoardInfo]);

    if (redirect !== undefined) {
        return <Redirect push={true} to={reduceQuery(redirect.url, redirect.query)}/>;
    }

    const onBtnTicket = async (e, item) => {
        const response = await api.auth.requestTokenApiJson({
            request: '/api/c/ft/start',
            json: {
                settings: item.settings_id
                , game_descriptor: item.game_descriptor
            }
        });

        api.auth.saveServerTimestamp(response.server_time);

        if (response.public_code !== undefined) {
            await cache.db.setPaper(response.ticket, response.expire, response);
        }
        setRedirect({'url': `/ftg/${response.ticket}`});
    };

    const gameList = () => {
        return (auth
            ? <Row noGutters={true}>
                <Col>
                    {Object
                        .keys(words)
                        .map(key => {
                                const item = words[key];
                                return (
                                    <Row key={key} noGutters={true}>
                                        <Col className={'px-0 d-flex flex-column align-items-start m-1'}>
                                            <TouchPanel className={'w-100 px-1'} Compact={true} onClick={(e) => onBtnTicket(e, item)}>
                                                <div className={'text-info-1 text-info'} dangerouslySetInnerHTML={i18ntDangerHtml('ft.word_select', {
                                                    title: getTitle(item.adjacent !== undefined ? parseInt(item.adjacent) : undefined, false),
                                                    word: preProcessWord(item.description, true),
                                                    game_descriptor: item.game_descriptor,
                                                    letters: item.description.length,
                                                    max: item.width * item.height
                                                })}/>
                                                <div dangerouslySetInnerHTML={i18ntDangerHtml('ft.prizes', {
                                                    prizes: item.prizes && item.prizes.map(p => prizeString(p, false, true)).join(', ')
                                                })}/>
                                            </TouchPanel>
                                        </Col>
                                    </Row>);
                            }
                        )}
                </Col>
            </Row>
            : <></>);
    };

    const makeInfoBlock = () => {
        return (
            <Row noGutters={true}>
                <Col>
                    <Alert className={'my-1'} color="success" fade={true}>
                        <div className={'text-left'} dangerouslySetInnerHTML={i18ntDangerHtml(isShortRule ? 'ft.MainRuleShort' : 'ft.MainRule')}/>
                        <div className={'text-right t-blue cursor-pointer'}
                             onClick={() => setShortRule(prevState => !prevState)}>{i18n.t(isShortRule ? 'common.expand' : 'common.collapse')}</div>
                    </Alert>
                </Col>
            </Row>
        );
    };

    return (
        <>
            <Row> <Col> </Col> </Row>
            {makeInfoBlock()}
            {gameList()}
        </>
    );
};
