import React, {Component} from "react";
import {connect} from "react-redux";
import {
    Row,
    Button,
    DropdownToggle,
    Input,
    UncontrolledButtonDropdown,
    DropdownMenu, DropdownItem,
    Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledDropdown,
    FormGroup
} from 'reactstrap';
import {Redirect} from "react-router-dom";

import Editor from 'react-medium-editor';


import i18n, {dangerHtml} from "../../i18n";
import Moment from "moment";
import MediumButton from "medium-button";

const dateFormat = 'YYYY-MM-DD';
//const dateTimeFormat = '"YYYY-MM-DD HH:mm"';
const timeFormat = 'HH:mm';

const select_lang = [
    {
        title: 'En'
        , code: 'en'
        , flag: 'flag-icon-gb'
        , disable: false
    }
    , {
        title: 'De'
        , code: 'de'
        , flag: 'flag-icon-de'
        , disable: false
    }
    , {
        title: 'Fr'
        , code: 'fr'
        , flag: 'flag-icon-fr'
        , disable: false
    }
    , {
        title: 'It'
        , code: 'it'
        , flag: 'flag-icon-it'
        , disable: false
    }
    , {
        title: 'Ru'
        , code: 'ru'
        , flag: 'flag-icon-ru'
        , disable: false
    }
];
const tag = "m";

class NewsItem extends Component {
    constructor(props) {
        super(props);

        const {id, lang, draft} = this.props.match.params;
        console.log(draft);
        this.state = {
            id,
            lang,
            draft,
            modal: false,
            query: {
                tag
            },
            translate: {[lang]: {}}, // пустой дефолтный объект
            isOnBack: false,
            newsSettingsLang: localStorage.getItem("newsSettingsLang"),
            isHtml: false
        };

        if (id !== undefined) {
            this.getNewsItem(id);
        }
    }

    getNewsItem = async (id) => {
        const {api} = this.props;

        const response = await api.auth.requestApiJson({
            request: '/api/c/news/item',
            query: {id}
        });

        const {lang} = this.state;
        const {newsSettingsLang = localStorage.getItem("newsSettingsLang")} = this.state;
        const translate = {[lang]: {}};
        let lang2 = newsSettingsLang;
        let langs = [];
        response.translate.forEach(item => {

            const {lang: itemLang, ...next} = item;
            langs.push(itemLang);
            translate[itemLang] = next;
            translate[itemLang]["isChanged"] = false;
        });

        if (lang2 === null || lang2 === lang || translate[lang2] === undefined) {
            for (let i = 0; i < langs.length; i++) {
                if (langs[i] !== lang) {
                    lang2 = langs[i];
                    break;
                } else {
                    lang2 = undefined;

                }
            }
        }
        console.log("translate[lang].draft = ", translate[lang].draft, translate)

        this.setState({
            id,
            lang2,
            showPlaceholder: translate[lang].body === '',
            translate,
            draft: translate[lang].draft
        })
    };

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const {lang, translate} = this.state;
        if (name === 'time') {
            const date = Moment(translate[lang].date_of_news).format(dateFormat);
            translate[lang].date_of_news = new Date(date + 'T' + value + ':00.000+0200');
        } else if (name === 'date') {
            const time = Moment(translate[lang].date_of_news).format(timeFormat);
            translate[lang].date_of_news = new Date(value + 'T' + time + ':00.000+0200');
        } else {
            translate[lang][name] = value;
        }
        translate[lang]["isChanged"] = true;
        //const translateDateTime = new Date(date+'T'+time+':00.000+0100');

        this.setState({
            translate
        });
    };

    onSaveItem = async (t, lang) => {
        const {api} = this.props;
        const {id} = this.state;
        return await api.auth.requestApiJson({
            request: '/api/c/news/item',
            method: "PUT",
            query: {id, lang: lang, tag},
            json: {
                date_of_news: t.date_of_news,
                title: t.title,
                draft: true,
                body: t.body
            }
        });
    };

    onSave = async () => {
        const {translate} = this.state;
        let isOnBack = true;
        Object.keys(translate).forEach(k => {
            console.log("key = ", k, translate);
            if (translate[k].isChanged) {
                if ((translate[k].title !== undefined && translate[k].title.length !== 0) &&
                    translate[k].date_of_news !== undefined) {
                    this.onSaveItem(translate[k], k);
                } else {
                    isOnBack = false;

                }
            }
        });
        return this.setState({
            isOnBack
        });
    };

    onPublishNews = async () => {
        const {api} = this.props;
        const {id, lang, draft} = this.state;

        const response = await api.auth.requestApiJson({
            method: 'PUT',
            request: '/api/c/news/item',
            query: {id, lang},
            json: {
                draft: !draft
            }
        });
        this.setState({
            draft: !draft
        });
        console.log("onPublishNews response = ", response, " draft = ", draft);

    };

    toggle = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    };

    toggleSave = () => {
        this.setState(prevState => ({
            modalSave: !prevState.modalSave
        }));
    };

    onDeleteNews = async () => {
        const {id, lang} = this.state;
        const {api} = this.props;
        console.log(lang);
        const response = await api.auth.requestApiJson({
            method: 'DELETE',
            request: '/api/c/news/item',
            query: {id, lang}
        });
        console.log("response Delete = ", response);
        this.onBack();
    };

    onBack = () => {
        this.setState({
            isOnBack: true
        });
    };

    addTranslate = (newLang) => {
        const {lang, id, translate} = this.state;
        this.setState({
            lang2: lang,
            lang: newLang,
            translate: {...translate, ...{[newLang]: {id, date_of_news: new Date(), showPlaceholder: true}}}
        })
    };

    onHandleChange = (text, medium) => {
        const {lang, translate} = this.state;
        translate[lang].body = text;
        translate[lang]["isChanged"] = true;
        this.setState({
            showPlaceholder: false,
            translate
        });
    };


    onHtmlText = () => {
        const {isHtml} = this.state;
        this.setState({
            isHtml: !isHtml
        })
    };

    render() {
        const {lang, lang2, translate, isOnBack, draft, newsSettingsLang, isHtml = false, showPlaceholder = false} = this.state;
        if (isOnBack) {
            return (<Redirect to={'/news/list'}/>);
        }
        const {title = '', body = '', date_of_news} = translate[lang];

        let title2 = '', body2 = '', date_of_news2 = '';
        const isLang2 = lang2 !== undefined && lang2 !== null;
        if (isLang2) {
            title2 = translate[lang2].title;
            body2 = translate[lang2].body;
            date_of_news2 = translate[lang2].date_of_news;
        }
        let isSaveAble = title !== "" && body !== "" && date_of_news !== undefined;
        return (
            <Row>
                <div className={"col card mb-3 px-0"}>
                    <div className="card-body">
                        {/**
                         * Title
                         */}
                        <UncontrolledDropdown className={"d-flex bd-highlight "}>

                            {select_lang.filter(item => item.code === lang).map(item =>
                                <DropdownToggle className={"p-1 py-2 "} key={item.code} nav caret>
                                    <span className={'p-1  flag-icon ' + item.flag}
                                          title="Язык перевода"/> {item.title}
                                </DropdownToggle>
                            )}
                            <DropdownMenu>
                                {select_lang.filter(item => translate[item.code] !== undefined).map(item =>
                                    <DropdownItem key={item.code}
                                                  onClick={() => i18n.changeLanguage(item.code, () => {
                                                      localStorage.setItem("newlang", item.code);
                                                      this.setState({
                                                          lang2: lang,
                                                          lang: item.code
                                                      })
                                                  })}>
                                        <span className={'mr-1 flag-icon ' + item.flag}/>
                                        {item.title}
                                        {translate[item.code].isChanged ?
                                            <span> - Были изменения</span> : <></>}
                                    </DropdownItem>
                                )}
                            </DropdownMenu>
                        </UncontrolledDropdown>

                        <h5 className={"t-black"}>Заголовок:</h5>
                        <div className="input-group mb-3">

                            <Input type="text" className="form-control" placeholder="Введите текст заголовка"
                                   name="title"
                                   value={title} onChange={this.handleChange}>
                            </Input>
                        </div>
                        <h5 className={"t-black"}>Дата время публикации:</h5>
                        <FormGroup className={"d-flex bd-highlight "}>
                            <Input className={"py-1 px-2  mr-2 w-50"}
                                   type="date"
                                   key={date_of_news + "date"}
                                   name="date"
                                   id="date_of_news"
                                   placeholder="date placeholder"
                                   value={Moment(date_of_news).format(dateFormat)} onChange={this.handleChange}

                            />
                            <Input
                                className={"py-1 px-2  w-50"}
                                type="time"
                                key={date_of_news + "time"}
                                name="time"
                                id="time"
                                placeholder="time placeholder"

                                value={Moment(date_of_news).format(timeFormat)} onChange={this.handleChange}
                            />
                        </FormGroup>
                        {/**
                         * Body
                         */}
                        <h5 className={"t-black"}>Содержимое новости:</h5>
                        {isHtml ?
                            <div className="edit-me border py-1 px-2 mr-2">{body}
                            </div> :
                            <Editor
                                text={body}
                                options={{
                                    toolbar: {
                                        buttons: [
                                            'bold',
                                            'italic',
                                            'underline',
                                            'colorB',
                                            'colorR',
                                            'anchor',
                                            'smileYellow',
                                            'smileGreen',
                                            'html',
                                        ]
                                    },
                                    extensions: {
                                        // compact
                                        'colorB': new MediumButton({
                                            label: '<i class="fa fa-font t-blue"/>',
                                            start: "<span class='blue'>",
                                            end: '</span>'
                                        }), 'colorR': new MediumButton({
                                            label: '<i class="fa fa-font  t-red"/>',
                                            start: "<span class='t-red'>",
                                            end: '</span>'
                                        }), 'smileYellow': new MediumButton({
                                            label: '<i class="fa fa-smile-o  t-yellow"/>',
                                            start: " <img src=\"images/smile_yellow.png\" width=\"14px\" alt=\"rs\" />",
                                            end: '</>'
                                        }), 'smileGreen': new MediumButton({
                                            label: '<i class="fa fa-smile-o  t-green"/>',
                                            start: " <img src=\"images/smile_green.png\" width=\"14px\" alt=\"rs\" />",
                                            end: '</>'
                                        }),
                                    },
                                    placeholder: showPlaceholder
                                }}
                                className="edit-me border  py-1 px-2 mr-2"
                                onChange={this.onHandleChange}

                            />}

                        <div className={"d-flex bd-highlight my-2"}>

                            <UncontrolledButtonDropdown>
                                <DropdownToggle className={" p-2 bd-highlight mr-1 my-1"} caret>
                                    Добавить новость
                                </DropdownToggle>
                                <DropdownMenu>
                                    {select_lang.filter(item => translate[item.code] === undefined).map(item =>
                                        <DropdownItem key={item.code}
                                                      onClick={() => this.addTranslate(item.code)}>
                                            <span className={'mr-1 flag-icon ' + item.flag}/>{item.title}</DropdownItem>
                                    )}
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>

                            <Button className={"p-2 bd-highlight mr-1 my-1"}
                                    onClick={() => this.onPublishNews()}>{draft ? "Publish" : "Draft"}</Button>
                            <Button className="fa fa-trash p-2 bd-highlight mr-1 my-1" aria-hidden="true"
                                    onClick={this.toggle}/>
                            <Button className="p-2 bd-highlight mr-1 my-1" aria-hidden="true"
                                    onClick={() => this.onHtmlText()}>{"HTML"} &harr;{"Text"}</Button>
                            <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                                <ModalHeader toggle={this.toggle}>Удаление Новости</ModalHeader>
                                <ModalBody>
                                    Вы действительно хотите удалить новость?
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="primary"
                                            onClick={() => this.onDeleteNews()}>Удалить</Button>{' '}
                                    <Button color="secondary" onClick={this.toggle}>Отмена</Button>
                                </ModalFooter>
                            </Modal>

                            <Modal isOpen={this.state.modalSave} toggle={this.toggleSave}
                                   className={this.props.className}>
                                <ModalHeader toggle={this.toggleSave}>Сохранение новости</ModalHeader>
                                <ModalBody>
                                    Необходимо заполнить все поля.
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="secondary" onClick={this.toggleSave}>Отмена</Button>
                                </ModalFooter>
                            </Modal>
                            <Button className={"p-2 bd-highlight my-1 ml-auto mr-1"}
                                    color={translate[lang].isChanged ? "primary" : 'secondary'}
                                    onClick={isSaveAble ? (() => this.onSave()) : (this.toggleSave)}>Сохранить</Button>
                            <Button className={"p-2 bd-highlight my-1"}
                                    onClick={() => this.onBack()}>Отмена</Button></div>
                    </div>
                </div>
                {isLang2 ? (<div className={"col card mb-3 px-0"}>
                    <div className="card-body">
                        {/**
                         * Title
                         */}
                        <UncontrolledDropdown className={"d-flex bd-highlight "}>
                            {select_lang.filter(item => item.code === newsSettingsLang && lang2 !== newsSettingsLang).map(item => (
                                    <span className={'p-1 flag-icon ' + item.flag} key={"flag"} alt='flag'
                                          title="Приоритетный язык перевода"/>
                                )
                            )}
                            {select_lang.filter(item => item.code === lang2).map(item =>
                                <DropdownToggle className={"p-1 py-2"} key={item.code} nav caret>
                                    <span className={'p-1 flag-icon ' + item.flag}
                                          title="Язык перевода"/> {item.title}
                                </DropdownToggle>
                            )}
                            <DropdownMenu>
                                {select_lang.map(item =>
                                    <DropdownItem key={item.code} disabled={translate[item.code] === undefined}
                                                  onClick={() => i18n.changeLanguage(item.code, () => {
                                                      localStorage.setItem("newsSettingsLang", item.code);
                                                      this.setState({
                                                          newsSettingsLang: item.code
                                                      })
                                                  })}>
                                        <span className={'mr-1 flag-icon ' + item.flag}/>
                                        {item.title}
                                        {item.code === newsSettingsLang ?
                                            <span className={'ml-1 fa fa-check '}/> : <></>}
                                    </DropdownItem>
                                )}
                            </DropdownMenu>
                        </UncontrolledDropdown>

                        <h5 className={"t-black"}>Заголовок:</h5>
                        <div className="input-group mb-3">
                            <div className="form-control">{title2}</div>
                        </div>
                        <h5 className={"t-black"}>Дата время публикации:</h5>
                        <FormGroup className={"d-flex bd-highlight"}>
                            <Input className={"py-1 px-2  mr-2 w-50"}
                                   type="text"
                                   key={date_of_news + "date2"}
                                   name="date2"
                                   id="date_of_news2"
                                   defaultValue={Moment(date_of_news2).format(dateFormat)}
                                   disable={"true"}
                            />
                            <Input
                                className={"py-1 px-2  w-50"}
                                type="text"
                                key={date_of_news + "time2"}
                                name="time2"
                                id="time2"
                                defaultValue={Moment(date_of_news2).format(timeFormat)}
                                disable={"true"}
                            />
                        </FormGroup>

                        {/**
                         * Body
                         */}
                        <h5 className={"t-black"}>Содержимое новости:</h5>
                        {isHtml ?
                            <div className="edit-me border py-1 px-2 mr-2">{body2}
                            </div> :
                            <div className=" edit-me border p-3">
                                <div dangerouslySetInnerHTML={dangerHtml(body2)}/>
                            </div>}
                    </div>
                </div>) : <></>}
            </Row>
        )
    }
}

const
    mapStateToProps = (state) => {
        return {
            api: state.api,
            cache: state.cache,
        }
    };
export default connect(mapStateToProps)(NewsItem)
