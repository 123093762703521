import React from 'react';
import {Col, Container, Row} from "reactstrap";

const D2 = () => {
    const info_cn = 'px-1 my-2';
    const col_cn = `d-flex flex-column justify-content-start align-items-center px-1`;
    const panel_cn = `sig-panel m-1 shadow w-100 d-flex flex-column justify-content-start align-items-center`;

    return (
        <Container>
            <Row>
                <Col xs={12} className={info_cn}>
                    <div className={`t-blue bg-white shadow w-100 d-flex flex-column justify-content-start align-items-center`}>
                        <span className={'main w-100 px-2'}>Игры ......</span>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={6} className={col_cn}>
                    <div className={panel_cn}>
                        <span className={'main w-100'}>Что это</span>
                    </div>
                </Col>
                <Col xs={6} className={col_cn}>
                    <div className={panel_cn}>
                        <span className={'main w-100'}>Новости</span>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={6} className={col_cn}>
                    <div className={panel_cn}>
                        <span className={'main w-100'}>Футбол</span>
                        <span className={'index-bottom w-100 pr-1 pb-1'}>е-билет</span>
                    </div>
                </Col>
                <Col xs={6} className={col_cn}>
                    <div className={panel_cn}>
                        <span className={'main w-100'}>Пенальти</span>
                        <span className={'index-bottom w-100 pr-1 pb-1'}>е-билет</span>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={6} className={col_cn}>
                    <div className={panel_cn}>
                        <span className={'main w-100'}>Найти</span>
                        <span className={'index-bottom w-100 pr-1 pb-1'}>е-билет</span>
                    </div>
                </Col>
                <Col xs={6} className={col_cn}>
                    <div className={panel_cn}>
                        <span className={'main w-100'}>Найти++</span>
                        <span className={'index-bottom w-100 pr-1 pb-1'}>турнир</span>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={6} className={col_cn}>
                    <div className={panel_cn}>
                        <span className={'main w-100'}>OneZero</span>
                        <span className={'index-bottom w-100 pr-1 pb-1'}>^^^</span>
                    </div>
                </Col>
                <Col xs={6} className={col_cn}>
                    <div className={panel_cn}>
                        <span className={'main w-100'}>OneOne</span>
                        <span className={'index-bottom w-100 pr-1 pb-1'}>^^^</span>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={6} className={col_cn}>
                    <div className={panel_cn}>
                        <div className={'main w-100'}>
                            <span>Мои</span>
                            <img src={'/img/small_red_smile.png'} alt={''} style={{height: '.9rem'}}/>
                            <img src={'/img/small_yellow_smile.png'} alt={''} style={{height: '.9rem'}}/>
                            <img src={'/img/small_green_smile.png'} alt={''} style={{height: '.9rem'}}/>
                        </div>
                    </div>
                </Col>
                <Col xs={6} className={col_cn}>
                    <div className={panel_cn}>
                        <span className={'main w-100'}>Рефералы</span>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default D2;