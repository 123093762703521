import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Config} from "../../../config";
import {i18ntDangerHtml} from "../../../i18n";
import {Alert} from "reactstrap";

const {VERSION, SIG_KEY} = Config;

const VersionControl = (props) => {
    const [newVersion, updateNewVersion] = useState({
        available: false
        , force: false
    });

    // монтирование
    useEffect(() => {
        const {api} = props;

        const check = (api) => {
            api.auth.requestApiJson({
                request: '/api/c/version',
                query: {version: VERSION, app_id: SIG_KEY}
            }).then(response => {
                const version = {
                    available: false
                    , force: false
                };

                if (!response.active) {
                    version.available = true;
                    version.force = true;
                } else if (!response.unknown) {
                    const av = response.available.filter(item => item.version === VERSION);

                    version.available = av.length === 0 || !av[0].stable;
                    version.force = av.length === 0;
                }

                updateNewVersion(version);
            });
        };

        check(api);

        const intervalId = window.setInterval(() => {
            check(api);
        }, props.Timer * 1000);
        return () => clearInterval(intervalId);
    }, [props]);

    return (
        <Alert isOpen={newVersion.available} color={newVersion.force ? 'danger' : 'warning'} fade={true}>
            <span dangerouslySetInnerHTML={i18ntDangerHtml('common.new_version', {
                uri: '#'
                , click: 'window.location.reload()'
            })}/>
        </Alert>
    );

};

const mapStateToProps = (state) => {
    return {
        api: state.api,
    }
};

export default connect(mapStateToProps)(VersionControl)
