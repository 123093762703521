export const AdvertisingList = {
    "common": {
        "ad": [
            {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/telecast_360p.mp4"
                , bLandscape: true
            }
            , {
                sType: "gif"
                , sBody: null
                , sURL: "http://khorost.net/pa/common/222nb.gif"
                , bLandscape: false
            }
            , {
                sType: "png"
                , sBody: null
                , sURL: "https://khorost.net/unnamed.png"
                , bLandscape: true
            }
            , {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/vp360.mp4"
                , bLandscape: true
            }
            , {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/vp.webm"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "http://khorost.net/pa/common/222telecast_360p.mp4"
                , bLandscape: true
            }]
    },
    "yandex": {
        "ad": [
            {
                sType: "text",
                sBody: "<div class='text-left t-black'>В играх <span class='t-blue'>Найти</span> от <span class='t-red'>Яндекс</span> баллами могут быть:<br/>" +
                    "- бесплатные километры в <span class='t-red'>Яндекс</span>.<span class='t-blue'>Такси</span><br/>" +
                    "- % скидки в <span class='t-red'>Яндекс</span>.<span class='t-blue'>Еда</span><br/>" +
                    "- бесплатные минуты в <span class='t-red'>Яндекс</span>.<span class='t-blue'>Драйв</span>.</div>",
                sURL: "",
                bLandscape: true
            }, {
                sType: "gif"
                , sBody: null
                , sURL: "https://khorost.net/img.gif"
                , bLandscape: false
            }, {
                sType: "gif"
                , sBody: null
                , sURL: "http://khorost.net/pa/common/222nb.gif"
                , bLandscape: false
            }
            , {
                sType: "png"
                , sBody: null
                , sURL: "https://khorost.net/unnamed.png"
                , bLandscape: true
            }
            , {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/telecast_360p.mp4"
                , bLandscape: true
            }
            , {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/vp360.mp4"
                , bLandscape: true
            }
            , {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/vp.webm"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "http://khorost.net/pa/common/222telecast_360p.mp4"
                , bLandscape: true
            }]
    },
    "migros_1": {
        "ad": [
            {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/migros/cum_01_01.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/migros/cum_01_02.mp4"
                , bLandscape: true
            },
            {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/migros/cum_01_03.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/migros/cum_01_04.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/migros/cum_01_05.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/migros/cum_01_06.mp4"
                , bLandscape: true
            }
        ]
    },
    "migros_2": {
        "ad": [
            {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/migros/cum_02_01.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/migros/cum_02_02.mp4"
                , bLandscape: true
            },
            {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/migros/cum_02_03.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/migros/cum_02_04.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/migros/cum_02_05.mp4"
                , bLandscape: true
            }
        ]
    },
    "migros_3": {
        "ad": [
            {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/migros/cum_03_01.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/migros/cum_03_02.mp4"
                , bLandscape: true
            },
            {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/migros/cum_03_03.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/migros/cum_03_04.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/migros/cum_03_05.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/migros/cum_03_06.mp4"
                , bLandscape: true
            }
        ]
    },
    'manor': {
        ad: [
            {
                sType: "text",
                sBody: "<span class='t-red'>Manor</span> ist immer in Ihrer Nähe, <span class='t-red'>60</span> Standorte in der ganzen Schweiz.",
                sURL: "",
                bLandscape: true
            }, {
                sType: "text",
                sBody: "<span class='t-red'>Manor</span> liefert gratis innerhalb von <span class='t-red'>2</span> bis <span class='t-red'>3</span> Tagen, ins Warenhaus, oder direkt nach Hause.",
                sURL: "",
                bLandscape: true
            }, {
                sType: "text",
                sBody: "<span class='t-red'>Manor</span> nimmt Ihre Einkäufe innerhalb von <span class='t-red'>30</span> Tagen gratis zurück.",
                sURL: "",
                bLandscape: true
            }, {
                sType: "text",
                sBody: "<span class='t-red'>Manor</span> hört Ihnen immer zu und spricht Ihre Sprache.",
                sURL: "https://khorost.net/pa/migros/007.jpg",
                bLandscape: true
            }, {
                sType: "text",
                sBody: "Neu im <span class='t-red'>Manor</span>! Versuchen Sie jeden Monat Ihr Glück und gewinnen Sie Manor Geschenkkarten im Wert von je CHF <span class='t-red'>200.–</span>!",
                sURL: "https://khorost.net/pa/migros/007.jpg",
                bLandscape: true
            }, {
                sType: "text",
                sBody: "Zusätzlich zu den vielen Vorteilen, die Ihnen die <span class='t-red'>Manor</span> Karte bietet,<br/>haben Sie die Möglichkeit, jeden Monat CHF <span class='t-red'>200.–</span> in Form von Geschenkkarten zu gewinnen.",
                sURL: "https://khorost.net/pa/migros/007.jpg",
                bLandscape: true
            },
        ]
    },
    '4video+img': {
        ad: [
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/coop/01.jpg",

                bLandscape: false
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/coop/01.mp4",
                bLandscape: true
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/coop/02.jpg",
                bLandscape: false
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/coop/02.mp4",
                bLandscape: true
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/coop/03.jpg",
                bLandscape: false
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/coop/03.mp4",
                bLandscape: true
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/coop/04.jpg",
                bLandscape: false
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/coop/04.mp4",
                bLandscape: true
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/coop/05.jpg",
                bLandscape: false
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/coop/06.jpg",
                bLandscape: false
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/coop/07.jpg",
                bLandscape: false
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/coop/08.jpg",
                bLandscape: false
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/coop/09.jpg",
                bLandscape: false
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/coop/10.jpg",
                bLandscape: false
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/coop/11.jpg",

                bLandscape: false
            }
        ]
    },
    '12videos': {
        ad: [
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/coop/c01.mp4",

                bLandscape: true
            }, {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/coop/c02.mp4",
                bLandscape: true
            }, {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/coop/c03.mp4",
                bLandscape: true
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/coop/c04.mp4",
                bLandscape: true
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/coop/c05.mp4",
                bLandscape: true
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/coop/c06.mp4",
                bLandscape: true
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/coop/c07.mp4",
                bLandscape: true
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/coop/c08.mp4",
                bLandscape: true
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/coop/c09.mp4",
                bLandscape: true
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/coop/c10.mp4",
                bLandscape: true
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/coop/c12.mp4",
                bLandscape: true
            }
        ]
    },
    'CoopMix': {
        ad: [
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/coop/01.jpg",
                bLandscape: false
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/coop/c10.mp4",
                bLandscape: true
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/coop/01.mp4",
                bLandscape: true
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/coop/02.jpg",
                bLandscape: false
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/coop/02.mp4",
                bLandscape: true
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/coop/c12.mp4",
                bLandscape: true
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/coop/03.jpg",

                bLandscape: false
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/coop/03.mp4",
                bLandscape: true
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/coop/c09.mp4",
                bLandscape: true
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/coop/04.jpg",
                bLandscape: false
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/coop/04.mp4",
                bLandscape: true
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/coop/05.jpg",

                bLandscape: false
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/coop/c08.mp4",

                bLandscape: true
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/coop/06.jpg",

                bLandscape: false
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/coop/c07.mp4",

                bLandscape: true
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/coop/07.jpg",
                bLandscape: false
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/coop/c06.mp4",
                bLandscape: true
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/coop/08.jpg",
                bLandscape: false
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/coop/c05.mp4",
                bLandscape: true
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/coop/09.jpg",
                bLandscape: false
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/coop/c04.mp4",
                bLandscape: true
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/coop/10.jpg",
                bLandscape: false
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/coop/c03.mp4",
                bLandscape: true
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/coop/11.jpg",
                bLandscape: false
            }
        ]
    },
    'bierlieb': {
        ad: [
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/bierliebe/007.jpg",

                bLandscape: false
            }, {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/bierliebe/111.webm",
                bLandscape: false
            }, {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/bierliebe/002.jpg",
                bLandscape: false
            }, {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/bierliebe/222.webm",
                bLandscape: false
            }, {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/bierliebe/003.jpg",
                bLandscape: false
            }, {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/bierliebe/333.webm",
                bLandscape: false
            }, {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/bierliebe/004.jpg",

                bLandscape: false
            }, {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/bierliebe/444.webm",

                bLandscape: false
            }, {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/bierliebe/005.jpg",

                bLandscape: false
            }, {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/bierliebe/555.webm",

                bLandscape: false
            }, {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/bierliebe/006.jpg",

                bLandscape: false
            }, {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/bierliebe/001.jpg",

                bLandscape: false
            }
        ]
    },
    'aviasales': {
        ad: [
            {
                sType: "text",
                sBody: "<span class='t-red'>Aviasales</span> — крупнейший поисковик билетов в России. Вы задаёте направление — мы находим наиболее удобные рейсы и показываем их для сравнения.",
                sURL: "",

                bLandscape: true
            }, {
                sType: "text",
                sBody: "<span class='t-red'>Aviasales</span>: Мы не продаём билеты. Мы помогаем вам найти лучшие предложения. Бесплатно.",
                sURL: "",

                bLandscape: true
            }, {
                sType: "text",
                sBody: "Цена, которую вы видите на <span class='t-red'>Aviasales</span> — финальная. Мы убрали все скрытые услуги и галочки. Всё честно, как на нудистских пляжах Франции.",
                sURL: "",

                bLandscape: true
            }, {
                sType: "text",
                sBody: "Поиск <span class='t-red'>Aviasales</span> продуман до мелочей  — здесь есть всё, что вы хотели узнать о предстоящем полёте, багаже, пересадках и перевозчике.",
                sURL: "https://khorost.net/pa/migros/007.jpg",

                bLandscape: true
            }, {
                sType: "text",
                sBody: "Приложение <span class='t-red'>Aviasales</span> в каждом 6 телефоне страны!<br/>Можно купить билет в отпуск, пока стоишь в душной пробке или планировать поездку вместе с друзьями за кружкой с кофе или чем-то более веселым.",
                sURL: "https://khorost.net/pa/migros/007.jpg",

                bLandscape: true
            }
        ]
    },
    'opencard': {
        ad: [
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/open/10.jpg",

                bLandscape: false
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/open/01-1.mp4",

                bLandscape: true
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/open/20.jpg",

                bLandscape: false
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/open/01-2.mp4",

                bLandscape: true
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/open/21.jpg",

                bLandscape: false
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/open/01-3.mp4",

                bLandscape: true
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/open/30.jpg",

                bLandscape: false
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/open/01-4.mp4",

                bLandscape: true
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/open/40.jpg",

                bLandscape: false
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/open/02.mp4",

                bLandscape: true
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/open/03.mp4",

                bLandscape: true
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/open/41.jpg",

                bLandscape: false
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/open/50.jpg",

                bLandscape: false
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/open/04.mp4",

                bLandscape: true
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/open/51.jpg",

                bLandscape: false
            },
        ]
    },
    'dodo': {
        ad: [
            {
                sType: "text",
                sBody: "<span class='t-red'>Паста Четыре сыра</span><br/>Паста, моцарелла, сыр блю чиз, смесь сыров чеддер и пармезан, сливочный соус<br/><span class='t-red'>265₽</span>",
                sURL: "",

                bLandscape: true
            }, {
                sType: "text",
                sBody: "<span class='t-red'>Паста Цыпленок и грибы</span><br/>Паста, цыпленок, шампиньоны, соус ранч, моцарелла, итальянские травы<br/><span class='t-red'>265₽</span>",
                sURL: "",

                bLandscape: true
            }, {
                sType: "text",
                sBody: "<span class='t-red'>Паста Болоньезе</span><br/>Паста, мясной соус болоньезе, моцарелла, итальянские травы<br/><span class='t-red'>265₽</span>",
                sURL: "",

                bLandscape: true
            }, {
                sType: "text",
                sBody: "<span class='t-red'>Рулетики с сыром</span><br/>Сытные рулетики с моцареллой и сырным соусом, 16 шт.<br/><span class='t-red'>245₽</span>",
                sURL: "https://khorost.net/pa/migros/007.jpg",

                bLandscape: true
            }, {
                sType: "text",
                sBody: "<span class='t-red'>Крылья барбекю</span><br/>Запеченные куриные крылья в маринаде из соуса барбекю, 10 шт<br/><span class='t-red'>350₽</span>",
                sURL: "https://khorost.net/pa/migros/007.jpg",

                bLandscape: true
            }
        ]
    },
    'otto': {
        uno: false,
        time: 7000,
        ad: [
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/otto/a1.mp4",

                bLandscape: true
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/otto/01.jpg",

                bLandscape: false
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/otto/a2.mp4",

                bLandscape: true
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/otto/02.jpg",

                bLandscape: false
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/otto/a3.mp4",

                bLandscape: true
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/otto/03.jpg",
                bLandscape: false
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/otto/a4.mp4",

                bLandscape: true
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/otto/a5.mp4",

                bLandscape: true
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/otto/a6.mp4",

                bLandscape: true
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/otto/a7.mp4",

                bLandscape: true
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/otto/a8.mp4",

                bLandscape: true
            }
        ]
    },
    'QW': {
        ad: [
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/QW/qa-1.mp4",

                bLandscape: true
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/QW/qa-2.mp4",

                bLandscape: true
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/QW/qa-3.mp4",

                bLandscape: true
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/QW/qa-4.mp4",

                bLandscape: true
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/QW/q1.mp4",

                bLandscape: true
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/QW/qa-5.mp4",

                bLandscape: true
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/QW/qa-6.mp4",

                bLandscape: true
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/QW/qa-7.mp4",

                bLandscape: true
            }
        ]
    },
    'bking': {
        ad: [
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/bking/b01-1.mp4",

                bLandscape: true
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/bking/p001.jpg",

                bLandscape: true
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/bking/b01-2.mp4",

                bLandscape: true
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/bking/p002.jpg",

                bLandscape: true
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/bking/b02.mp4",

                bLandscape: true
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/bking/p003.jpg",

                bLandscape: true
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/bking/b03-1.mp4",

                bLandscape: true
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/bking/p004.jpg",

                bLandscape: true
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/bking/b03-2.mp4",

                bLandscape: true
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/bking/p005.jpg",

                bLandscape: true
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/bking/b03-3.mp4",

                bLandscape: true
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/bking/p006.jpg",

                bLandscape: true
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/bking/b03-4.mp4",

                bLandscape: true
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/bking/p007.jpg",

                bLandscape: true
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/bking/b03-5.mp4",

                bLandscape: true
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/bking/p008.jpg",

                bLandscape: true
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/bking/p009.jpg",

                bLandscape: true
            }
        ]
    },
    'kaegi': {
        ad: [
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/kaegi/k-011.mp4",

                bLandscape: true
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/kaegi/k-021.mp4",

                bLandscape: true
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/kaegi/k-022.mp4",

                bLandscape: true
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/kaegi/k-031.mp4",

                bLandscape: true
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/kaegi/k-032.mp4",

                bLandscape: true
            }
        ]
    },
    'b3000': {
        ad: [
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/b3000/b3-02.mp4",

                bLandscape: true
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/b3000/b3-001.jpg",

                bLandscape: false
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/b3000/b3-03.mp4",

                bLandscape: true
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/b3000/b3-002.jpg",

                bLandscape: true
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/b3000/b3-01.mp4",

                bLandscape: true
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/b3000/b3-003.jpg",
                bLandscape: true
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/b3000/b3-04.mp4",
                bLandscape: true
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/b3000/b3-004.jpg",
                bLandscape: true
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/b3000/b3-05.mp4",

                bLandscape: true
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/b3000/b3-005.jpg",

                bLandscape: true
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/b3000/b3-06.mp4",

                bLandscape: true
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/b3000/b3-006.jpg",

                bLandscape: true
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/b3000/b3-07.mp4",

                bLandscape: true
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/b3000/b3-08.mp4",

                bLandscape: true
            }
        ]
    },
    'vkv': {
        ad: [
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/vkv/vv-1.mp4",
                bLandscape: true
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/vkv/vv-01.jpg",
                bLandscape: false
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/vkv/vv-2.mp4",
                bLandscape: true
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/vkv/vv-02.jpg",
                bLandscape: false
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/vkv/vv-3.mp4",
                bLandscape: true
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/vkv/vv-03.jpg",
                bLandscape: false
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/vkv/vv-4.mp4",
                bLandscape: true
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/vkv/vv-04.jpg",
                bLandscape: false
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/vkv/vv-5.mp4",
                bLandscape: true
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/vkv/vv-05.jpg",
                bLandscape: false
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/vkv/vv-6.mp4",
                bLandscape: true
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/vkv/vv-06.jpg",
                bLandscape: false
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/vkv/vv-7.mp4",
                bLandscape: true
            },
            {
                sType: "png",
                sBody: "",
                sURL: "https://khorost.net/pa/vkv/vv-07.jpg",
                bLandscape: false
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/vkv/vv-8.mp4",
                bLandscape: true
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/vkv/vv-9.mp4",
                bLandscape: true
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/vkv/vv-10.mp4",
                bLandscape: true
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/vkv/vv-11.mp4",
                bLandscape: true
            }
        ]
    },
    'home': {
        uno: false,
        ad: [
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/home/w1.mp4",
                bLandscape: true
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/home/w2a.mp4",
                bLandscape: true
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/home/w2b.mp4",
                bLandscape: true
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/home/w2c.mp4",
                bLandscape: true
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/home/w2d.mp4",
                bLandscape: true
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/home/w2e.mp4",
                bLandscape: true
            },
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/home/w2f.mp4",
                bLandscape: true
            }
        ]
    },
    'qww': {
        uno: true,
        ad: [
            {
                sType: "video",
                sBody: "",
                sURL: "https://khorost.net/pa/qww/qww0.mp4",

                bLandscape: true
            }
        ]
    }
    , 'home2': {
        uno: true,
        ad: [{
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/home/w2a.mp4",
            bLandscape: true
        }]
    }
    , 'sm0': {
        uno: true,
        ad: [{
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/sm/sm_a_01.mp4",
            bLandscape: true
        }]
    }
    , 'sm1': {
        uno: false,
        ad: [{
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/sm/sm_a_02.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/sm/sm_a_03.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/sm/sm_a_04.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/sm/sm_a_06.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/sm/sm_a_07.mp4",
            bLandscape: true
        }]
    }
    , 'sm2': {
        uno: false,
        ad: [{
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/sm/sm_a_05_001.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/sm/sm_a_05_002.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/sm/sm_a_05_003.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/sm/sm_a_05_004.mp4",
            bLandscape: true
        }]
    }
    , 'ss_s': {
        uno: false,
        ad: [{
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/ss/s_01.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/ss/s_02.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/ss/s_03.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/ss/s_04.mp4",
            bLandscape: true
        }]
    }, 'ss_n': {
        uno: false,
        ad: [{
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/ss/n_01.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/ss/n_02.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/ss/n_03.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/ss/n_04.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/ss/n_05.mp4",
            bLandscape: true
        }]
    }, 'ss_b': {
        uno: false,
        ad: [{
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/ss/b_01.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/ss/b_02.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/ss/b_03.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/ss/b_04.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/ss/b_05.mp4",
            bLandscape: true
        }]
    }, 'baden1': {
        uno: false,
        ad: [{
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/bd/bdg_1_00.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/bd/bdg_1_01.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/bd/bdg_1_02.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/bd/bdg_1_03.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/bd/bdg_1_04.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/bd/bdg_1_05.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/bd/bdg_1_06.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/bd/bdg_1_07.mp4",
            bLandscape: true
        }]
    }, 'baden2': {
        uno: false,
        ad: [{
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/bd/bdg_2_00.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/bd/bdg_2_01.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/bd/bdg_2_02.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/bd/bdg_2_03.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/bd/bdg_2_04.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/bd/bdg_2_05.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/bd/bdg_2_06.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/bd/bdg_2_07.mp4",
            bLandscape: true
        }]
    }, 'ab__1': {
        uno: false,
        ad: [{
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/ab/ab_01_01.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/ab/ab_01_02.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/ab/ab_01_03.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/ab/ab_01_04.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/ab/ab_01_05.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/ab/ab_01_06.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/ab/ab_01_07.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/ab/ab_01_08.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/ab/ab_01_09.mp4",
            bLandscape: true
        }]
    }, 'ab__2': {
        uno: false,
        ad: [{
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/ab/ab_02_01.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/ab/ab_02_02.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/ab/ab_02_03.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/ab/ab_02_04.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/ab/ab_02_05.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/ab/ab_02_06.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/ab/ab_02_07.mp4",
            bLandscape: true
        }]
    }, 'ab__3': {
        uno: false,
        ad: [{
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/ab/ab_03_01.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/ab/ab_03_02.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/ab/ab_03_03.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/ab/ab_03_04.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/ab/ab_03_05.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/ab/ab_03_06.mp4",
            bLandscape: true
        }]
    }, 'vtb__1': {
        uno: false,
        ad: [{
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/vtb/v_01_01.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/vtb/v_01_02.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/vtb/v_01_03.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/vtb/v_01_04.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/vtb/v_01_05.mp4",
            bLandscape: true
        }]
    }, 'vtb__2': {
        uno: false,
        ad: [{
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/vtb/v_02_01.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/vtb/v_02_02.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/vtb/v_02_03.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/vtb/v_02_04.mp4",
            bLandscape: true
        }]
    }, 'vtb__3': {
        uno: false,
        ad: [{
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/vtb/v_03_01.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/vtb/v_03_02.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/vtb/v_03_03.mp4",
            bLandscape: true
        }]
    }, 'swa__1': {
        uno: false,
        ad: [{
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/sw/swa_01_01.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/sw/swa_01_02.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/sw/swa_01_03.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/sw/swa_01_04.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/sw/swa_01_05.mp4",
            bLandscape: true
        }]
    }, 'swa__2': {
        uno: false,
        ad: [{
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/sw/swa_02_01.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/sw/swa_02_02.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/sw/swa_02_03.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/sw/swa_02_04.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/sw/swa_02_05.mp4",
            bLandscape: true
        }]
    }, 'swa__3': {
        uno: false,
        ad: [{
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/sw/swa_03_01.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/sw/swa_03_02.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/sw/swa_03_03.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/sw/swa_03_04.mp4",
            bLandscape: true
        }, {
            sType: "video",
            sBody: "",
            sURL: "https://khorost.net/pa/sw/swa_03_05.mp4",
            bLandscape: true
        }]
    },
    "tk__1": {
        "ad": [
            {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/tk/t_01_01.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/tk/t_01_02.mp4"
                , bLandscape: true
            },
            {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/tk/t_01_03.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/tk/t_01_04.mp4"
                , bLandscape: true
            }
        ]
    },
    "tk__2": {
        "ad": [
            {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/tk/t_02_01.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/tk/t_02_02.mp4"
                , bLandscape: true
            },
            {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/tk/t_02_03.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/tk/t_02_04.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/tk/t_02_05.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/tk/t_02_06.mp4"
                , bLandscape: true
            }
        ]
    },
    "tk__3": {
        "ad": [
            {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/tk/t_03_01.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/tk/t_03_02.mp4"
                , bLandscape: true
            },
            {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/tk/t_03_03.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/tk/t_03_04.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/tk/t_03_05.mp4"
                , bLandscape: true
            }
        ]
    },
    "vb__1": {
        "ad": [
            {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/vonbet/vb_01_01.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/vonbet/vb_01_02.mp4"
                , bLandscape: true
            },
            {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/vonbet/vb_01_03.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/vonbet/vb_01_04.mp4"
                , bLandscape: true
            }
        ]
    },
    "vb__2": {
        "ad": [
            {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/vonbet/vb_02_01.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/vonbet/vb_02_02.mp4"
                , bLandscape: true
            },
            {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/vonbet/vb_02_03.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/vonbet/vb_02_04.mp4"
                , bLandscape: true
            }
        ]
    },
    "vb__3": {
        "ad": [
            {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/vonbet/vb_03_01.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/vonbet/vb_03_02.mp4"
                , bLandscape: true
            },
            {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/vonbet/vb_03_03.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/vonbet/vb_03_04.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/vonbet/vb_03_05.mp4"
                , bLandscape: true
            }
        ]
    },
    "ls__1": {
        "ad": [
            {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/ligas/ls_01_01.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/ligas/ls_01_02.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/ligas/ls_01_03.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/ligas/ls_01_04.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/ligas/ls_01_05.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/ligas/ls_01_06.mp4"
                , bLandscape: true
            }
        ]
    }, "ls__2": {
        "ad": [
            {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/ligas/ls_02_01.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/ligas/ls_02_02.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/ligas/ls_02_03.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/ligas/ls_02_04.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/ligas/ls_02_05.mp4"
                , bLandscape: true
            }
        ]
    }, "ls__3": {
        "ad": [
            {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/ligas/ls_03_01.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/ligas/ls_03_02.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/ligas/ls_03_03.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/ligas/ls_03_04.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/ligas/ls_03_05.mp4"
                , bLandscape: true
            }
        ]
    }, "dt__1": {
        "ad": [
            {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/dt/dt_01_01.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/dt/dt_01_02.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/dt/dt_01_03.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/dt/dt_01_04.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/dt/dt_01_05.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/dt/dt_01_06.mp4"
                , bLandscape: true
            }
        ]
    }, "dt__2": {
        "ad": [
            {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/dt/dt_02_01.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/dt/dt_02_02.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/dt/dt_02_03.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/dt/dt_02_04.mp4"
                , bLandscape: true
            }
        ]
    }, "sb__1": {
        "ad": [
            {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/sber/sb__01_01.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/sber/sb__01_02.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/sber/sb__01_03.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/sber/sb__01_04.mp4"
                , bLandscape: true
            }
        ]
    }, "sb__2": {
        "ad": [
            {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/sber/sb__02_01.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/sber/sb__02_02.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/sber/sb__02_03.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/sber/sb__02_04.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/sber/sb__02_05.mp4"
                , bLandscape: true
            }
        ]
    }, "sb__3": {
        "ad": [
            {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/sber/sb__03_01.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/sber/sb__03_02.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/sber/sb__03_03.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/sber/sb__03_04.mp4"
                , bLandscape: true
            }, {
                sType: "video"
                , sBody: null
                , sURL: "https://khorost.net/pa/sber/sb__03_05.mp4"
                , bLandscape: true
            }
        ]
    }
};
