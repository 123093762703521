import React from 'react';
import {useSelector} from "react-redux";
import {i18ntDangerHtml} from "../i18n";

export const Footer = () => {
    const config = useSelector(state => state.config);
    const {REACT_APP_VERSION_BRANCH = '', REACT_APP_VERSION_HASH = '', REACT_APP_VERSION_TIMESTAMP = ''} = process.env;

    if (config.MobileDetect.phone()) {
        return (
            <footer className="app-footer border-top-blue">
                <span className="ml-auto" dangerouslySetInnerHTML={i18ntDangerHtml('app.copyright')}/>
            </footer>
        );
    } else {
        return (
            <footer className="app-footer">
                <span><a href="https://cryptochainlab.com">CryptoChainLab</a> &copy; 2017-2020</span>
                <span>&nbsp;v{REACT_APP_VERSION_HASH + '/' + REACT_APP_VERSION_BRANCH + '@' + REACT_APP_VERSION_TIMESTAMP}</span>
                <span className="ml-auto">Powered by <a href="https://khorost.net">Khorost</a></span>
            </footer>
        );
    }
};
