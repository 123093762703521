import {createStore, applyMiddleware} from 'redux'
import rootReducer from '../reducers'
import {createLogger} from 'redux-logger'
import thunk from 'redux-thunk'

export default function configureStore(initialState) {
    let middleware = [];
    if (process.env.NODE_ENV === 'development') {
        const logger = createLogger({
            predicate: (getState, action) => action.type !== 'UPDATE_WALLET' && action.type !== 'CHANGE_CONFIG',
            collapsed: true
        });
        middleware = [...middleware, thunk, logger];
    } else {
        middleware = [...middleware, thunk];
    }

    const store = createStore(
        rootReducer,
        initialState,
        applyMiddleware(...middleware));

    if (module.hot) {
        module.hot.accept('../reducers', () => {
            const nextRootReducer = require('../reducers');
            store.replaceReducer(nextRootReducer)
        })
    }
    return store
}
