import React from "react";
import {Col, Row} from "reactstrap";
import {getColumnDescription, getRowDescription} from "./GameOneView";

export const Board = (props) => {
    const game = props.game;
    const settings = props.settings;
    const gameInfo = settings.games[props.gameTypeIndex];
    const {isGameStarted} = game.statistics;

    const colClassname0 = `frx-col-frx${gameInfo.width + 1}-1 p-0 `;
    const colClassname = `${colClassname0} text-monospace onex-cell`;
    const innerClassname = `frx-font-size-${gameInfo.width + 1} onex-cell-inner d-flex justify-content-center align-items-center`;

    let rows = [];

    const onClick = (c, r) => {
        props.onSelected && props.onSelected(getPosition(c, r));
    };

    const getPosition = (c, r) => (c - 1) + (r - 1) * gameInfo.width;

    const getCellInfo = (c, r) => {
        const position = getPosition(c, r);
        const field = game.cells[position];

        if (field === undefined) {
            return {
                className: 'onex-number',
                description: <span>&nbsp;</span>
            };
        }

        if (position === props.selectedPosition) {
            if (!isGameStarted || field.count === 0) {
                return {
                    className: 'onex-number-empty-selected',
                    description: <span>?</span>
                };
            } else {
                return {
                    className: 'onex-number-onex-zero-selected',
                    description: <span>{field.count}</span>
                };
            }
        }

        if (isGameStarted) {
            if (field.count === 0) {
                return {
                    className: 'onex-number',
                    description: <span>&nbsp;</span>
                };
            } else {
                return {
                    className: 'onex-number-zero-fail',
                    description: <span>{field.count}</span>
                };
            }
        } else {
            return {
                className: 'onex-number'
                , description: <img className={'onex-icon'} src={'/img/onex/smile-regular_yellow.png'} alt={''}/>
            }
        }
    };

    for (let r = gameInfo.height; r >= 0; r--) {
        let cols = [];
        for (let c = 0; c <= gameInfo.width; c++) {
            const key = `${r}x${c}`;
            if (c === 0 && r === 0) {
                cols.push(
                    <Col key={key} className={`${colClassname0}`}>
                        <div className={innerClassname}>
                            <img className={'onex-logo'} src={'/img/logo.png'} alt={''}/>
                        </div>
                    </Col>);
            } else if (c === 0) {
                cols.push(
                    <Col key={key} className={`${colClassname} onex-axis`}>
                        <div className={innerClassname}>
                            {getRowDescription(settings, r - 1, gameInfo.width)}
                        </div>
                    </Col>);
            } else if (r === 0) {
                cols.push(
                    <Col key={key} className={`${colClassname} onex-axis`}>
                        <div className={innerClassname}>
                            {getColumnDescription(settings, c - 1, gameInfo.height)}
                        </div>
                    </Col>);
            } else {
                const info = getCellInfo(c, r);
                cols.push(
                    <Col key={key} className={`${colClassname} ${info.className}`} onClick={() => onClick(c, r)}>
                        <div className={innerClassname}>
                            {info.description}
                        </div>
                    </Col>);
            }
        }
        rows.push(<Row key={r} noGutters={true}>{cols}</Row>);
    }

    return (
        <>{rows}</>
    );
};

