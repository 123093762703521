import React, {useEffect} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import {Header} from "../components";
import {Footer} from "../components";
import Sidebar from "../components/Sidebar";
import requireAuthentication from "../views/Pages/AuthenticatedComponent";
import {Dashboard} from "../components";
import D2 from "../components/Mobile/Dashboard/D2";

export const Mobile = (props) => {
    // монтирование
    useEffect(() => {
        document.body.classList.remove('breadcrumb-fixed');
    }, [props]);

    return (
        <div className="app-mobile">
            <Header/>
            <div className="app-mobile-body border-top-blue">
                <Sidebar {...props}/>
                <main className="main">
                    <Switch>
                        <Route path="/m/dashboard" component={requireAuthentication(Dashboard)}/>
                        <Route path="/m/dashboard2" component={D2}/>

                        <Redirect from="/m" to="/dashboard2"/>
                    </Switch>
                </main>
            </div>
            <Footer/>
        </div>
    );
};
