import React, {lazy, Suspense} from "react";
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import Login from "../views/Pages/Login";
import Logout from "../views/Pages/Logout";
import Page404 from "../views/Pages/Page404";
import GameOneView from "../views/OneX/GameOneView";
import PaperFootballDashboard from "../views/Football/Dashboard";
import {Full, Main, Mobile} from "./";

import * as configActions from '../actions/ConfigAction'

import FindThisInteractive from "../views/Games/FindThis/FindThisInteractive";
import FindThisPaper from "../views/Games/FindThis/FindThisPaper";
import Dashboard from "../views/Games/FindThis/Dashboard";
import AdExample from "../views/Games/FindThis/AdExample";
import requireToken from "../views/Pages/TokenComponent";
import Test from "../views/Test/Test";
import {InteractiveFloorX} from "../views/Games/Xmt/";
import PaperFloorX from "../views/Football/Paper";
import PaperInteractive from "../views/Football/Paper/PaperInteractive";
import PaperOnline from "../views/Football/Paper/PaperOnline";
import requireAuthentication from "../views/Pages/AuthenticatedComponent";
import {withTracker} from "../withTracker";
import ReactGA from 'react-ga';
import Football from "../views/Games/Football/Football";
import {Duel, DuelDashboard} from "../views/Games/Football";
import {AprilGame, FindThisPayLanding} from "../views/Games/FindThis";
import {ReferralPage} from "../views/Games/FindThis/ReferralPage";
import RatingsPage from "../views/Games/FindThis/RatingsPage";
import StatsPage from "../views/Games/FindThis/StatsPage";
import UnratedPage from "../views/Games/FindThis/UnratedPage";
import {FastGame} from "../views/Games/Common";
import {library} from '@fortawesome/fontawesome-svg-core'
import {faQrcode, faBars, faSearch, faWindowClose, faFileAlt, faQuestionCircle} from "@fortawesome/pro-light-svg-icons";
import {useTranslation} from "react-i18next";

const GameBoard = lazy(() => import('../views/Games/Common/Board'));
const PenaltyApp = lazy(() => import('../_penalty/App'));

library.add(faQrcode, faBars, faSearch, faWindowClose, faFileAlt, faQuestionCircle);

const wrapPromise = (promise) => {
    let status = "pending";
    let result;
    let suspender = promise.then(
        r => {
            status = "success";
            result = r;
        },
        e => {
            status = "error";
            result = e;
        }
    );
    return {
        read() {
            if (status === "pending") {
                throw suspender;
            } else if (status === "error") {
                throw result;
            } else if (status === "success") {
                return result;
            }
        }
    };
};

const configFetch = wrapPromise(
    fetch('/config.json')
        .then(response => response.json())
);

const SigApp = (props) => {
    const {t} = useTranslation();
    const {configActions} = props;
    const config = configFetch.read();
    const googleAnalytics = config && config['google-analytics'];

    if (googleAnalytics !== undefined) {
        ReactGA.initialize(googleAnalytics.id, googleAnalytics.options);
    }

    configActions.changeConfig(config);

    return (
        <Router>
            <Suspense fallback={<div>{t('common.loading')}</div>}>
                <Switch>
                    <Route exact path="/login" name="Login Page" component={Login}/>
                    <Route exact path="/logout" name="Logout Page" component={Logout}/>
                    <Route exact path="/404" name="Page 404" component={Page404}/>

                    <Route exact path="/onex/:mode" name="OneX" component={requireAuthentication(GameOneView)}/>

                    <Route exact path="/" component={Main}/>

                    {/*Paper online*/}
                    <Route exact path="/fp" component={withTracker(PaperFootballDashboard)}/>
                    <Route exact path="/fpg/:ticket" component={withTracker(PaperFloorX, 'fpg')}/>
                    <Route exact path="/fpga/:ticket" component={withTracker(PaperOnline, 'fpga')}/>
                    <Route path="/fig" component={withTracker(InteractiveFloorX)}/>
                    <Route path="/fiz" component={withTracker(PaperInteractive)}/>
                    <Route path="/g/fb" component={withTracker(Football)} name={'Football'}/>
                    <Route exact path="/fpdg/:ticket" component={withTracker(Duel, 'fpdg')}/>
                    <Route exact path="/fpdd/" component={withTracker(DuelDashboard, 'fpdd')}/>

                    <Route exact path="/aft/referral" component={withTracker(ReferralPage, 'aft-referral')}/>
                    <Route exact path="/aft/ratings" component={withTracker(RatingsPage, 'aft-ratings')}/>
                    <Route exact path="/aft/unrated" component={withTracker(UnratedPage, 'aft-unrated')}/>
                    <Route exact path="/aft/stats" component={withTracker(StatsPage, 'aft-stats')}/>
                    <Route exact path="/aft/" component={withTracker(AprilGame, 'aft')}/>
                    <Route exact path="/ftd/" component={withTracker(requireToken(Dashboard))}/>
                    <Route exact path="/ft/:vendor" component={withTracker(Dashboard)}/>
                    <Route exact path="/ftg/:ticket" component={withTracker(FindThisPaper, 'ftg')}/>
                    <Route exact path="/fti/:ticket?" component={withTracker(FindThisInteractive, 'fti')}/>
                    <Route exact path="/ftp/:ticket?" component={withTracker(FindThisPayLanding, 'ftp')}/>

                    <Route path="/g/f/:type/:code/:id/:cost?" component={withTracker(FastGame)}/>
                    <Route path="/g/a/frx-d" component={withTracker(PenaltyApp, '/g/a/frx-d')}/>

                    <Route path="/b/:board_id/:game_id?" component={withTracker(GameBoard, '/b')}/>

                    <Route exact path="/adexample" name="AdExample" component={AdExample}/>
                    <Route exact path="/test" component={Test}/>

                    <Route path="/m" component={Mobile}/>
                    <Route path="/" component={Full}/>
                </Switch>
            </Suspense>
        </Router>
    );
};

const App = (props) => {
    return (
        <Suspense fallback="config loading...">
            <SigApp configActions={props.configActions}/>
        </Suspense>);
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
    configActions: bindActionCreators(configActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(App)
