import React, {useCallback, useEffect, useState} from 'react';
import {
    NavbarToggler,
    NavbarBrand,
} from 'reactstrap';
import UserInfo from "./UserInfo/UserInfo";
import {useDispatch, useSelector} from "react-redux";
import {i18nCurrentLanguage, i18ntDangerHtml} from "../i18n";
import moment from "moment-timezone";
import Smile from "./Common/GameControl/Smile";
import {updateWallet, checkServerState} from '../actions/AppActions'
import * as queryString from "query-string";

export const Header = (props) => {
    const dispatch = useDispatch();
    const api = useSelector(state => state.api);
    const app = useSelector(state => state.app);
    const cache = useSelector(state => state.cache);
    const config = useSelector(state => state.config);
    const [serverTime, setServerTime] = useState();

    const refreshWallet = useCallback(async () => {
        const wallet = await cache.db.loadWallet();
        dispatch(
            updateWallet(wallet)
        );
    }, [cache.db, dispatch]);

    const refreshCheckServerState = useCallback(() => {
        dispatch(
            checkServerState(i18nCurrentLanguage(), api, cache)
        );
    }, [api, cache, dispatch]);

    // монтирование
    useEffect(() => {
        let parsed = queryString.parse(props.location.search);
        const {rfc} = parsed;

        if (config.MobileDetect.phone()) {
            document.body.classList.remove('header-fixed');
            document.body.classList.add('header-mobile-fixed');
        }

        const afterOk = () => {
            refreshWallet();
            refreshCheckServerState();

            const intervalId = window.setInterval(() => {
                setServerTime(api.auth.adopt2ServerTime(new Date()));
            }, 1000);
            return () => clearInterval(intervalId);
        };

        if (api.auth.isUserNull() || api.auth.getReferenceToken() === undefined || api.auth.isRefreshTokenExpired()) {
            api.auth.registerUser(rfc).then(afterOk);
        } else {
            return afterOk();
        }
    }, [props.location.search, api.auth, config.MobileDetect, refreshCheckServerState, refreshWallet]);

    const mobileSidebarToggle = (e) => {
        e.preventDefault();
        document.body.classList.toggle('sidebar-mobile-show');
    };

    const sidebarToggle = (e) => {
        e.preventDefault();
        document.body.classList.toggle('sidebar-hidden');
    };

    /*    const sidebarMinimize = (e) => {
            e.preventDefault();
            document.body.classList.toggle('sidebar-minimized');
        };
    */

    const asideToggle = (e) => {
        e.preventDefault();
        document.body.classList.toggle('aside-menu-hidden');
    };

    //  const {api} = props;

    const userInfo = api.auth.isUserAuthenticated() ? <span>You: {api.auth.getNickname()}</span> : <></>;

    if (config.MobileDetect.phone()) {
        const now = moment(serverTime).tz('CET');
        return (
            <header className="app-header navbar">
                <div className="header-title w-100">
                    <NavbarToggler onClick={mobileSidebarToggle}>
                        <span className="navbar-toggler-icon"/>
                    </NavbarToggler>
                    <NavbarBrand className={''} href="#">
                        <span dangerouslySetInnerHTML={i18ntDangerHtml('app.title')}/>
                    </NavbarBrand>
                </div>
                <div className="header-info-basic d-flex align-items-center border-bottom-gray w-100">
                    <div className={'text-right flex-fill t-blue pr-1'}>
                        {now.format('DD-MM-YYYY')}
                    </div>
                    <img style={{height: '1.7rem'}} src={'/img/logo.svg'} alt='logo'/>
                    <div className={'text-left flex-fill t-blue pl-1'}>
                        {now.format('HH:mm:ss')} CET
                    </div>
                </div>
                <div className="header-info-basic d-flex w-100">
                    {userInfo}
                    <div className={'ml-auto d-flex'}>
                        <Smile className={'pr-1'} wallet={app.wallet} type={'r'}/>
                        <Smile className={'pr-1'} wallet={app.wallet} type={'y'}/>
                        <Smile className={'pr-1'} wallet={app.wallet} type={'g'}/>
                    </div>
                </div>
            </header>
        );
    } else {
        return (
            <header className="app-header navbar">
                <NavbarToggler className="d-lg-none" onClick={mobileSidebarToggle}>
                    <span className="navbar-toggler-icon"/>
                </NavbarToggler>
                <NavbarBrand href="#"/>
                <NavbarToggler className="d-md-down-none mr-auto" onClick={sidebarToggle}>
                    <span className="navbar-toggler-icon"/>
                </NavbarToggler>
                <UserInfo/>
                <NavbarToggler className="d-md-down-none" onClick={asideToggle}>
                    <span className="navbar-toggler-icon"/>
                </NavbarToggler>
            </header>
        );
    }
}
