import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import SimpleContainer from "../../Pages/SimpleContainer";
import {Alert, Col, Row} from "reactstrap";
import {Config} from "../../../config";
import {getLocationOrigin} from "../../../components/Common/GameControl/Utils";
import QRCode from "qrcode.react";
import i18n, {i18ntDangerHtml} from "../../../i18n";
import {Link} from "react-router-dom";

const getReferralURI = (code) => {
    const origin = Config.COMMON_URL !== '' ? Config.COMMON_URL : getLocationOrigin();
    return `${origin}/fp?rfc=${code}`;
};

export const ReferralPage = () => {
    const api = useSelector(state => state.api);
    const [code, setCode] = useState('');

    // монтирование
    useEffect(() => {
        api.auth.requestTokenApiJson({
            request: '/api/c/referral'
        }).then((response) => {
            setCode(response.code);
        });
    }, [api.auth]);

    return (
        <SimpleContainer BackGroundColor={'white'}>
            <Row>
                <Col>
                    <Alert className={'my-1 rounded'} color="success" fade={false}>
                        <div dangerouslySetInnerHTML={i18ntDangerHtml('common.referral_info', {
                                code
                                , uri: getReferralURI(code)
                                , nickname: api.auth.getNickname()
                            }
                        )}/>
                    </Alert>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Alert className={'my-1 rounded'} color="success" fade={false}>
                        <div dangerouslySetInnerHTML={i18ntDangerHtml('common.referral_info_qr')}/>
                    </Alert>
                </Col>
            </Row>
            <Row>
                <Col className={'my-2'}>
                    <QRCode width={'140'} height={'140'} renderAs='svg' level='M' value={getReferralURI(code)}/>
                </Col>
            </Row>
            <Row>
                <Col className={'my-2'}>
                    <Link to={'/fp'}>{i18n.t('common.back_to_game_select')}</Link>
                </Col>
            </Row>
        </SimpleContainer>
    );
}

