import React, {Component} from "react";
import {connect} from "react-redux";
import classnames from 'classnames';
import {Link, Redirect} from "react-router-dom";
import {Alert, Button, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import SimpleContainer from "../../Pages/SimpleContainer";
import i18n, {i18ntDangerHtml} from "../../../i18n";
import {preProcessWord, prizeString} from "../../../components/Common/GameControl/Utils";
import {checkVersionAuto} from "../../../components/Common/GameControl/Service";
import {getTitle} from "./FindThis";

class Dashboard extends Component {
    constructor(props) {
        super(props);

        const check = sessionStorage.getItem("token");
        const accessToken = check !== null ? JSON.parse(check) : null;

        this.state = {
            accessToken
        };
    }

    toggle = tab => {
        const {activeTab} = this.state;
        if (activeTab !== tab) {
            this.setState({activeTab: tab});
        }
    };

    render() {
        const {vendor} = this.props.match.params;
        const {ftg, accessToken, words = [], partners = {}, activeTab = '2'} = this.state;

        if (ftg !== undefined) {
            return <Redirect to={`/ftg/${ftg}`}/>;
        } else if (vendor === undefined) {
            const acl = accessToken.payload.acl.filter(a => a.subtype === 'ft');
            if (acl === undefined || acl.length === 0) {
                return (<Redirect to={`/fp`}/>);
            }

            const ids = acl[0].ids;
            if (ids.length === 1 && ids[0] !== '*') {
                return (<Redirect to={`/ft/${ids[0]}`}/>);
            }

            return (
                <SimpleContainer BackGroundColor={'#ceebe4'} Footer0={footer0}>
                    {this.makeHeader()}
                    {this.makeInfoBlock()}
                    <Row>
                        <Col>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink className={classnames({active: activeTab === '1'})} onClick={() => this.toggle('1')}>
                                        Words
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({active: activeTab === '2'})} onClick={() => this.toggle('2')}>
                                        Groups
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab} className={'mb-1 '}>
                                <TabPane tabId="1">
                                    {Object.keys(words).sort((l, r) => {
                                        const {order: lv = 100} = words[l], {order: rv = 100} = words[r];
                                        return lv - rv;
                                    }).map((key) => {
                                            const item = words[key];
                                            return (
                                                <Row key={key} className={'border'}>
                                                    <Col className={'px-0 d-flex flex-column align-items-start'}>
                                                        <Button key={'idx'} color={'link'} onClick={(e) => this.onBtnTicket(e, item)}
                                                                dangerouslySetInnerHTML={i18ntDangerHtml('ft.word_select', {
                                                                    title: getTitle(item.adjacent !== undefined ? parseInt(item.adjacent) : undefined, false),
                                                                    word: preProcessWord(key, true),
                                                                    game_descriptor: item.game_descriptor,
                                                                    letters: key.length,
                                                                    max: item.width * item.height
                                                                })}/>
                                                        <div dangerouslySetInnerHTML={i18ntDangerHtml('ft.prizes', {
                                                            prizes: item.prizes && item.prizes.map(p => prizeString(p, false, true)).join(', ')
                                                        })}/>
                                                    </Col>
                                                    <Col xs={'auto'} className={'px-2 d-flex flex-column align-items-end'}>
                                                        {item.partners.map((p, idx) =>
                                                            <Link key={idx} to={`/ft/${p}`}>{p}</Link>
                                                        )}
                                                    </Col>
                                                </Row>
                                            );
                                        }
                                    )}
                                </TabPane>
                                <TabPane tabId="2">
                                    <Row key={'th-2'} className={'bg-secondary'}>
                                        <Col>
                                            Description
                                        </Col>
                                        <Col>
                                            Words Count
                                        </Col>
                                    </Row>
                                    {Object.keys(partners).sort((l, r) => {
                                        return l.localeCompare(r);
                                    }).map((key) =>
                                        <Row key={key} className={'bg-light py-2 border'}>
                                            <Col className={'text-left'}>
                                                <Link to={`/ft/${key}`}>{key}</Link>
                                            </Col>
                                            <Col>
                                                {partners[key].length}
                                            </Col>
                                        </Row>)}
                                </TabPane>
                            </TabContent>
                        </Col>
                    </Row>
                </SimpleContainer>
            );
        }

        let sv = partners[vendor];
        if (sv === undefined) {
            return (<>.</>);
        }

        return (
            <SimpleContainer BackGroundColor={'#ceebe4'} Footer0={footer0}>
                {this.makeHeader()}
                {this.makeInfoBlock()}
                {this.makeUserInfo()}
                {sv.map((item, idx) => {
                        let append_info = '';
                        if (item.win !== undefined) {
                            append_info += ` Won: ${item.win}`;
                        }
                        if (item.prz !== undefined) {
                            append_info += ` Prz: ${item.prz}`;
                        }
                        if (item.max_won !== undefined) {
                            append_info += ` MaxWon: ${item.max_won}`;
                        }
                        return (
                            <Row key={idx}>
                                <Col className={'px-0 d-flex flex-column align-items-start border'}>
                                    <Button key={'idx'} color={'link'} onClick={(e) => this.onBtnTicket(e, item)}
                                            dangerouslySetInnerHTML={i18ntDangerHtml('ft.word_select', {
                                                title: getTitle(item.adjacent !== undefined ? parseInt(item.adjacent) : undefined, false),
                                                word: preProcessWord(item.description, true),
                                                game_descriptor: item.game_descriptor,
                                                letters: item.description.length,
                                                max: item.width * item.height
                                            })}/>
                                    <div dangerouslySetInnerHTML={i18ntDangerHtml('ft.prizes', {
                                        prizes: item.prizes.map(p => prizeString(p, false, true)).join(', ')
                                        , append_info
                                    })}/>
                                </Col>
                            </Row>)
                    }
                )}
            </SimpleContainer>
        )
    }

    makeUserInfo = () => {
        const {users = []} = this.state;
        const [userInfoLast] = users;

        if (userInfoLast === undefined) {
            return <></>;
        }

        return (<Row key={'ui'} className={'my-2'}>
            <Col xs={'auto'} className={'d-flex justify-content-center align-items-center'}>
                Current UserId = <span className={'t-red'}>{userInfoLast.id}</span>
            </Col>
            <Col xs={'auto'} className={'d-flex justify-content-center align-items-end ml-auto'}>
                <Button color={`primary`} onClick={() => this.onBtnTakeUser()}>New UserId</Button>
            </Col>
        </Row>);
    };

    onBtnTakeUser = async () => {
        const {accessToken} = this.state;
        const {api} = this.props;

        if (accessToken === undefined) {
            return;
        }

        await api.auth.requestApiJson({
            request: '/api/c/ft/take',
            json: {
                token: accessToken.tag
            }
        });

        const users_resp = await api.auth.requestApiJson({
            request: '/api/c/ft/users',
            json: {
                token: accessToken.tag
            }
        });

        this.setState({users: users_resp.users});
    };

    onBtnTicket = async (e, item) => {
        const {api, cache} = this.props;
        const {description, game_descriptor} = item;
        const {accessToken = {}, users = []} = this.state;
        const [userInfoLast] = users;

        let id;
        if (userInfoLast === undefined) {
            const user_response = await api.auth.requestApiJson({
                request: '/api/c/ft/take',
                json: {
                    settings_id: item.settings_id,
                    word: description,
                    game_descriptor,
                    token: accessToken.tag
                }
            });
            id = user_response.ticket;
        } else {
            id = userInfoLast.id;
        }

        const response = await api.auth.requestApiJson({
            request: '/api/c/ft/start',
            json: {
                ticket_ref: id
                , settings: item.settings_id
                , game_descriptor
            }
        });

        api.auth.saveServerTimestamp(response.server_time);

        if (response.public_code !== undefined) {
            await cache.db.setPaper(response.ticket, response.expire, response);
        }
        this.setState({ftg: response.ticket});
    };

    makeHeader = () =>
        <Row className={'info-white'}>
            <Col className={'d-flex justify-content-start align-items-center px-1'}>
                <h5 className={'m-0 py-1'}>
                    <span className={'t-blue'}>{i18n.t('modes.ft.title_full')}</span>
                </h5>
            </Col>
            <Col className={'d-flex justify-content-end align-items-center px-1'}>
                <h5 className={'m-0 py-1'}>
                    <span className={'t-blue ml-1'}>{i18n.t('common.title_admin_panel')}</span>
                </h5>
            </Col>
        </Row>;

    makeInfoBlock = () => {
        return (
            <Row noGutters={true}>
                <Col>
                    <Alert className={'my-1'} color="success" fade={false}>
                        <div className={'text-left'} dangerouslySetInnerHTML={i18ntDangerHtml('ft.MainRule')}/>
                    </Alert>
                </Col>
            </Row>
        );
    };

    updateWordsList = async (partnersList) => {
        const {api, cache} = this.props;
        let words = [], partners = {};

        // проверка кеша настроек
        let sq = [];
        for (const check of partnersList) {
            const settings = await cache.db.getSettings(check.id);
            if (settings === undefined || settings.version < check.version) {
                sq.push({id: check.id});
            }
        }
        if (sq.length > 0) {
            await cache.db.updateSettings(sq, api);
        }

        for (const partner of partnersList) {
            const settings = await cache.db.getSettings(partner.id, api, partner.version);

            partners[settings.partner] = [
                ...settings.games.map(g => {
                    return {...g, settings_id: partner.id}
                }),
            ];

            for (const g of settings.games) {
                const item = words[g.description];
                if (item === undefined) {
                    words = {
                        ...words,
                        [g.description]: {
                            ...g,
                            settings_id: partner.id,
                            partners: [settings.partner]
                        }
                    };
                } else {
                    item.partners.push(settings.partner);
                }
            }
        }

        this.setState({words, partners});
    };

    componentDidMount = async () => {
        const {accessToken} = this.state;
        const {api} = this.props;

        checkVersionAuto(api);

        const pg = await api.auth.requestApiJson({
            request: '/api/c/ft/list',
            json: {
                token: accessToken.tag
            }
        });

        this.updateWordsList(pg);

        const users_resp = await api.auth.requestApiJson({
            request: '/api/c/ft/users',
            json: {
                token: accessToken.tag
            }
        });

        this.setState({users: users_resp.users});
    };
}

const footer0 =
    <Row>
        <Col className={'my-2'}>
            <Link to={'/fp'}>{i18n.t('common.back_to_select_subtype')}</Link>
        </Col>
    </Row>;

const mapStateToProps = (state) => {
    return {
        api: state.api,
        cache: state.cache,
    }
};

export default connect(mapStateToProps)(Dashboard)
