import React, {useState, useRef, useEffect} from 'react'
import {useSpring, animated/*, interpolate*/} from 'react-spring'
import Container from "reactstrap/es/Container";
import {Col, Row} from "reactstrap";
//import {useGesture} from "react-with-gesture";

export default function Test() {
    /*
        const props = useSpring({
            from: {left: '0%', top: '0%', width: '0%', height: '0%', background: 'lightgreen'},
            to: async next => {
                while (1) {
                    await next({left: '0%', top: '0%', width: '100%', height: '100%', background: 'lightblue'})
                    await next({height: '50%', background: 'lightgreen'})
                    await next({width: '50%', left: '50%', background: 'lightgoldenrodyellow'})
                    await next({top: '0%', height: '100%', background: 'lightpink'})
                    await next({top: '50%', height: '50%', background: 'lightsalmon'})
                    await next({width: '100%', left: '0%', background: 'lightcoral'})
                    await next({width: '50%', background: 'lightseagreen'})
                    await next({top: '0%', height: '100%', background: 'lightskyblue'})
                    await next({width: '100%', background: 'lightslategrey'})
                }
            },
        })

        return <animated.div className="script-box"
                             style={{...props, willChange: 'width, height, left, top', position: 'relative'}}>12<br/>3</animated.div>
    */

    const parent = useRef(null);
    const src = useRef(null);
    const dst = useRef(null);

//    const [bind, {/*xy,*/ delta, down}] = useGesture();
//    console.log(delta, down);

    const [target, setTarget] = useState([0, 0]);
    const [source, setSource] = useState([0, 0]);

    useEffect(() => {
        const pr = parent.current.getBoundingClientRect();
        const sr = src.current.getBoundingClientRect();
        const dr = dst.current.getBoundingClientRect();
        console.log("*", pr, sr, dr);
        setSource([sr.x - pr.x + sr.width / 2, sr.y - pr.y]);
        setTarget([dr.x - pr.x + dr.width / 2, dr.y - pr.y]);
    }, []);

    const [on, toggle] = useState(false)
    const props2 = useSpring({number: 10, from: {number: 0}});

    console.log(source, target);

    const {xy/*, x, c*/} = useSpring({
        from: {xy: source, c: 0},
        x: on ? 1 : 0,
        c: on ? 1 : 0,
        xy: on ? target : source,
//        reset: source[0] > 0
//            delay: 800,
    });

    /*
        const {x, size} = useSpring({
            x: down ? /!*delta[0]*!/xy[0] : 0,
            size: down ? 2.1 : 1,
            immediate: name => down && name === 'x'
        });
    */
    const props = useSpring({
        opacity: 1, from: {opacity: 0}, onRest: () => {
            console.log("opa")
        }, config: {duration: 10000}
    });

    return (
        <div>
            {/*  <animated.h1
                style={{
                    transform: x.interpolate({
                        range: [0, .25, .5, .75, 1],
                        output: [0, 500, 200, 800, 500]
                    }).interpolate(x => `translateX(${x}px)`),

                    color: c.interpolate({
                        range: [0, .5, 1],
                        output: ['red', 'blue', 'green']
                    }).interpolate(c => c)
                }}>
                {!on ? "I'm here" : "Now don't know where I'm going"}</animated.h1>*/}
            <Container className={'text-center'}>
                <animated.div style={props}>I will fade in</animated.div>
                <div ref={parent} className={'xmt-cell'}>
                    <animated.span>{props2.number}</animated.span>
                    <Row>
                        <Col xs={2} className={'bg-green'}>2</Col>
                        <Col xs={7} className={'bg-yellow'}>8</Col>
                        <Col xs={3} className={'bg-green'}>2</Col>
                    </Row>
                    <Row>
                        <Col xs={2} className={'bg-yellow'}>
                            <div ref={src}>-2-</div>
                        </Col>
                        <Col xs={7} className={'bg-green'}>8</Col>
                        <Col xs={3} className={'bg-yellow'}>
                            <div>2</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={2} className={'bg-green'}>2</Col>
                        <Col xs={7} className={'bg-yellow'}>8</Col>
                        <Col xs={3} className={'bg-green'}>
                            <div ref={dst}>*2*</div>
                        </Col>
                    </Row>
                    <animated.div
                        className={'xmt-animated-c'}
                        style={{
//                                transform: interpolate([x, size], (_x, _s) => `translate3d(${_x}px,0,0) scale(${_s})`)
                            transform: xy.interpolate((x, y) => `translate(${x}px, ${y}px)`)
                            /*
                                                            transform: x.interpolate({
                                                                range: [0, .25, .5, .75, 1],
                                                                output: [0, 25, 10, 40, 25]
                                                            }).interpolate(x => `translateX(${x}px)`),
                            */

                            /*

                                                color: c.interpolate({
                                                    range: [0, .5, 1],
                                                    output: ['red', 'blue', 'green']
                                                }).interpolate(c => c)
                            */
                        }}>
                        <img className={'xmt-animated-m'} src={'/img/tree_ball_yellow.png'} alt={''}/>
                    </animated.div>
                </div>
            </Container>
            <button onClick={() => toggle(!on)}>Change</button>
        </div>
    )
}

