import React, {useEffect, useRef, useState} from "react";
import {Helmet} from 'react-helmet';
import {connect} from "react-redux";
import SimpleContainer from "../../Pages/SimpleContainer";
import {Button, Col, Row} from "reactstrap";
import i18n, {dangerHtml, i18ntDangerHtml} from "../../../i18n";
import * as queryString from "query-string";
import {fillCellsFromResponse, getCheckURI, prepareGameInfo} from "../Common/FloorX";
import {dataDiff, getCharByIndex, parseRoundValue, useInterval} from "../../../components/Common/GameControl/Utils";
import Settings from "../../Football/Settings";
import {Footer0} from "../Common/Utils";

const delay = 1000;

const AnimationDiv = React.forwardRef((props, ref) => (
    <div ref={ref}>
        {props.children}
    </div>
));

const Football = (props) => {
    const [state, setState] = useState({});
    const [game, updateGame] = useState({});
    const [isRunning, setIsRunning] = useState(false);
    const [serverTime, setServerTime] = useState(undefined);
    const gateA = useRef(null);
    const gateD = useRef(null);

    // монтирование
    useEffect(() => {
        let parsed = queryString.parse(props.location.search);
        const {game_id: code1, ticket: code2} = parsed;
        const code = code2 !== undefined ? code2 : code1;

        const {api, cache} = props;
        cache.db.getPaper(code, api)
            .then(async gameInfo => {
                const response = await api.auth.requestApiJson({
                    request: '/api/c/ticket/status',
                    query: {
                        ticket: gameInfo.ticket
                    }
                });

                const {result, status} = response;
                const newGame = prepareGameInfo(gameInfo);
                fillCellsFromResponse(newGame, result);

                updateGame(newGame);

                setState({
                    eog: status.game_over,
                    zonesActive: !status.game_over,
                    prize: result.prize / 100,
                    prizeNumber: result.prize_number
                });

                if (!status.game_over) {
                    setIsRunning(true);
                }
            });

    }, [props]);

    useInterval(() => {
        const {api} = props;
        const now = api.auth.adopt2ServerTime(new Date());
        const gameExpire = game.validTo < now;

        if (gameExpire) {
            setIsRunning(false);

            onBtnTakePrize();
        }
        setServerTime(now);
    }, isRunning ? delay : null);

    if (game.subtype === undefined) {
        return <SimpleContainer BackGroundColor={'white'} Footer0={Footer0}>{i18n.t('common.loading')}</SimpleContainer>;
    }

    const makeFooter = (subtype) => {
        const {ecoupon = true, prize = 0, prizeNumber = '', eog, gameExpire = false} = state;
        const {step = -1, stepsServer = []} = game;

        let rows = [];
        let cols = [];

        if (gameExpire) {
            cols.push(
                <Col key={3} xs={12}>
                    <Button color="danger" className={'btn-block rounded'} onClick={() => onBtnTakePrize()}>
                        {i18n.t('common.check_game0')}
                    </Button>
                </Col>
            );
        } else if (eog) {
            if (ecoupon && prize > 0) {
                /****
                 cols.push(
                 <Col key={2} xs={12}>
                 <Button color="success" className={'btn-block rounded text-white'} disabled={payed}
                 onClick={() => setModalPay(true)}
                 dangerouslySetInnerHTML={i18ntDangerHtml(payed ? 'common.prize_payed' : 'common.prize_notpayed', {
                                    prize: `<span class='t-red'>${prize}</span>b`,
                                    kick: prizeNumber
                                })}/>
                 <Modal isOpen={modalPay} toggle={() => setModalPay(!modalPay)} className={props.className}>
                 <ModalHeader
                 toggle={() => setModalPay(!modalPay)}>{i18n.t('common.paper_prize_title')}</ModalHeader>
                 <ModalBody>
                 <div>{i18n.t('common.paper_prize_body', {prize, kick: prizeNumber})}</div>
                 <div dangerouslySetInnerHTML={i18ntDangerHtml('common.paper_prize_body2')}/>
                 </ModalBody>
                 <ModalFooter>
                 <Button color="primary" onClick={onBtnPay}>{i18n.t('common.ok')}</Button>{' '}
                 </ModalFooter>
                 </Modal>
                 </Col>);
                 */
            }
        } else if (prize === 0 && prizeNumber === 0) {
            cols.push(
                <Col key={4} xs={12}>
                    <Button color="danger" className={'btn-block rounded'} disabled={step === -1}
                            onClick={() => onBtnPlay()}>
                        {i18n.t('common.play0', {step: step === -1 ? '' : getCharByIndex('A', step)})}
                    </Button>
                </Col>
            );
        } else {
            cols.push(
                <Col key={3} xs={6} className={'px-1'}>
                    <Button color="danger" className={'btn-block rounded'}
                            onClick={() => prize !== 0 ? onBtnTakePrize() : null/*openCheckDialog(!enabledCheckDialog)*/}>{i18n.t(prize !== 0 ? 'common.take_prize' : 'common.check_game0')}</Button>
                </Col>
            );
            cols.push(
                <Col key={4} xs={6} className={'px-1'}>
                    <Button color="danger" className={'btn-block rounded'} disabled={step === -1 || stepsServer.length > 0}
                            onClick={() => onBtnPlay()}>
                        {i18n.t('common.play0', {step: step === -1 || stepsServer.length > 0 ? '' : getCharByIndex('A', step)})}
                    </Button>
                </Col>
            );
        }

        if (cols.length > 0) {
            rows.push(<Row key={'buttons'} className={'my-2'}>{cols}</Row>);
        }

        if (eog) {
            rows.push(
                <Row key={'check'} className={cols.length > 0 ? 'mb-2' : 'my-2'}>
                    <Col>
                        <Button color="primary" className={'btn-block rounded'} target='_blank'
                                href={getCheckURI(`${game.secretCode}_${game.serverSteps}`, game.publicCode)}>
                            {i18n.t('common.check_game')}
                        </Button>
                    </Col>
                </Row>
            );

            const secretCode = `${game.secretCode}<span class='t-blue'>_</span>${getServerStepsFormat()}`;

            rows.push(
                <Row key={'sc'}>
                    <Col key={1} className={'px-0 wordBreakAll'}>
                        <span className={'t-blue'} dangerouslySetInnerHTML={i18ntDangerHtml('xmt.secret_code_title')}/>
                        <span className={'t-red px-1'} dangerouslySetInnerHTML={dangerHtml(secretCode)}/>
                    </Col>
                </Row>
            );
            rows.push(
                <Row key={'pc'}>
                    <Col key={1} className={'px-0 wordBreakAll'}>
                        <span className={'t-blue'} dangerouslySetInnerHTML={i18ntDangerHtml('xmt.open_code_title')}/>
                        <span className={'t-red px-1'}>{game.publicCode}</span>
                    </Col>
                </Row>
            );
        }

        return rows;
    };

    const getServerStepsFormat = () => {
        const {serverParts = [], prizes = []} = game;
        let result = '';

        for (let k = 0; k < prizes.length; k++) {
            result += `<span class='t-blue'>${k + 1}</span><span class='t-red'>${k < serverParts.length ? serverParts[k] : 'X'}</span>`;
        }

        return result;
    };

    const onBtnPlay = async () => {
        const {ticket, step, position} = game;
        const {api} = props;

        const response = await api.auth.requestApiJson({
            request: '/api/c/ticket/step',
            query: {
                ticket,
                step: getCharByIndex('A', step),
                pos: position
            }
        });

        if (response.error === undefined) {
            const result = response.result;

            api.auth.saveServerTimestamp(result.server_time);

            game.stepsServer = fillCellsFromResponse(game, result);

            if (response.status.game_over) {
                game.stepsServer = [];
                setIsRunning(false);
            } else {
                game.position = position + 1;
            }

            updateGame(game);

            setState({
                ...state,
                eog: response.status.game_over,
                zonesActive: !response.status.game_over,
                error: undefined,
                prize: result.prize / 100,
                prizeNumber: result.prize_number
            });
        } else {
            setState({
                ...state,
                error: response.error
            });
        }
    };

    const onBtnTakePrize = async () => {
        const {ticket} = game;
        const {api} = props;

        const response = await api.auth.requestApiJson({
            request: '/api/c/ticket/check',
            json: {
                ticket
            }
        });

        setIsRunning(false);

        const {error, result} = response;

        if (result !== undefined) {
            fillCellsFromResponse(game, result);

            game.stepsServer = [];

            setState({
                ...state,
                eog: true,
                zonesActive: false,
                ecoupon: true,
                error: undefined,
                gameExpire: undefined,
                finish: new Date(result.finish),
                prize: result.prize / 100,
                prizeNumber: result.prize_number
            });
        } else {
            setState({
                ...state
                , error: error
            });
        }
    };

    const getWinLost = (cells) => cells
        .reduce((v, item) => item.status === 'lost' ? {lost: v.lost + 1, win: v.win} : item.status === 'win' ? {
            lost: v.lost,
            win: v.win + 1
        } : v, {lost: 0, win: 0});

    const getInfoTagFootball = (stepsServer, step, prize) => {
        const {eog = false, prizeNumber} = state;
        const {reason = ''} = game;

        if (eog) {
            const result = getWinLost(game.cells);

            if (reason === 'win') {
                return {
                    infoHit: i18n.t(prizeNumber === game.prizes.length ? 'xmt.interactive_info_hit_take_max_prize' : 'xmt.interactive_info_hit_take_prize', {prize: prize}),
                    infoStatus: i18n.t(result.win === game.prizes.length ? 'xmt.interactive_info_hit_take_main_prize' : `xmt.interactive_info_status_take_prize_${superGame.restart ? 'plus' : 'zero'}`, {
                        fail: result.lost,
                        prize: prize
                    }),
                    classname: 'xmt-info-turn-none'
                };
            } else if (reason === 'lost' && prizeNumber !== game.prizes.length && superGame.restart) {
                return {
                    infoHit: i18n.t('xmt.interactive_info_hit_lost2'),
                    infoStatus: i18n.t(`xmt.interactive_info_status_take_prize_${superGame.restart ? 'plus' : 'zero'}`, {
                        fail: result.lost,
                        prize: 0
                    }),
                    classname: 'xmt-info-turn-failure2'
                };
            } else {
                return {
                    infoHit: i18n.t(reason === 'timeout' ? 'xmt.info_bottom_result.timeout2' : 'football.interactive_info_hit_lost'),
                    infoStatus: i18n.t(reason === 'timeout' ? `xmt.interactive_info_play2_timeout` : `xmt.interactive_info_status_take_prize_${superGame.restart ? 'plus' : 'zero'}`, {
                        fail: result.lost,
                        prize: 0
                    }),
                    classname: 'xmt-info-turn-failure'
                };
            }
        } else {
            if (stepsServer.length > 0) {
                if (stepsServer.filter(i => i === step).length > 0) {
                    return {
                        infoHit: i18n.t('xmt.status_hit'),
                        infoStatus: i18n.t(`xmt.interactive_info_not_goal`),
                        classname: 'xmt-info-turn-failure'
                    };
                } else {
                    return {
                        infoHit: i18n.t('xmt.status_not_hit', {prize: prize}),
                        infoStatus: i18n.t(`xmt.interactive_info_goal`),
                        classname: 'fb-info-turn-success'
                    };
                }
            } else {
                return {
                    infoHit: i18n.t(`football.${superGame.restart ? 'interactive_info_play2_plus' : 'interactive_info_play2_zero'}`),
                    infoStatus: i18n.t(`${subtype}.interactive_info_play`),
                    classname: 'fb-info-turn-none'
                };
            }
        }
    };

    const makeCellFootball = (pos, cell, activeRow, activeCell, winStatus, code) => {
        if (cell.player && cell.server) {
            return {
                classname: 'fb-cell-archive-hb'
                , content: <img className={'fb-ball'} src={'/img/g/fb/fh_hands_ball.png'} alt={''}/>
            };
        } else if (cell.player) {
            return {
                classname: 'fb-cell-archive-b'
                , content: <img className={'fb-ball'} src={`/img/g/fb/fh_b.png`} alt={''}/>
            };
        } else if (cell.server) {
            return {
                classname: 'fb-cell-archive-h'
                , content: <img className={'fb-ball'}
                                src={'/img/g/fb/fh_hands.png'}
                                alt={''}/>
            };
        } else {
            return {
                classname: 'fb-cell-archive'
                , content: <span className={activeRow ? (activeCell ? 't-red' : 't-yellow') : 'fb-t-green'}>{getCharByIndex('A', pos - 1)}</span>
            };
        }
    };

    const makeRows = () => {
        const {eog, prize = 0} = state;
        const {step = -1, position = 0, stepsServer = [], prizes, zones, cells, code} = game;
        let rows = [], rows_post = [], superRows = [];

        const base_classname = `d-flex justify-content-center align-items-center`;
        const infoTagFootball = getInfoTagFootball(stepsServer, step, prize);
        const cn = `${base_classname} frx-col-frx${zones}-1`;

        rows.push(
            <Row key={`r-h`} noGutters={true}>
                <Col key={'info-left'} xs={2} className={`${base_classname} fb-info-active`}>
                    N
                </Col>
                <Col key={'info-center'} xs={8} className={``}>
                    {eog
                        ? <></>
                        : <Row noGutters={true}>
                            <Col xs={6} className={`${base_classname} ${stepsServer.length === 0 && step === 0 ? 'fb-gate-cell-active' : 'fb-gate-cell'}`}
                                 onClick={() => onBtnClick(position + 1, 1)}>
                                <AnimationDiv ref={gateA}>A</AnimationDiv>
                            </Col>
                            <Col xs={6} className={`${base_classname} ${stepsServer.length === 0 && step === 3 ? 'fb-gate-cell-active' : 'fb-gate-cell'}`}
                                 onClick={() => onBtnClick(position + 1, 4)}>
                                <AnimationDiv ref={gateD}>D</AnimationDiv>
                            </Col>
                        </Row>
                    }
                </Col>
                <Col key={'info-right'} xs={2} className={`${base_classname} fb-info-active`}>
                    Приз
                </Col>
            </Row>);

        for (let idx = prizes.length; idx > 0; idx--) {
            let columns = prizes[idx - 1].column;
            let cols = [];

            const row = cells[idx - 1];
            const ps = parseRoundValue(row.prize / 100.0);

            const cn_info = position === (idx - 1) ? 'fb-info-active' : 'fb-info';

            let ball_img, block_img;
            if (!eog && row.status === "future" && position !== (idx - 1)) {
                ball_img = <img className={`${superGame.restart ? 'fb-ball-right' : 'fb-ball-left'}`} src={`/img/g/fb/fbb.png`} alt={''}/>;
                if (eog && !superGame.restart) {
                    block_img = <img className={`xmt-block`} src={'/img/blocked.png'} alt={''}/>;
                }
            }

            cols.push(
                <Col key={'info-left'} xs={2} className={`${base_classname} ${cn_info}`}>
                    {idx}
                    {ball_img}
                    {block_img}
                </Col>
            );

            let active_cols = [];
            if (eog) {
                for (let c = 1; c <= columns; c++) {
                    const cc = row.cols[c - 1];

                    const cell = makeCellFootball(c, cc, position === (idx - 1), (c - 1) === step && stepsServer.length === 0, row.status === 'win' || row.status === 'timeoutWin' || (eog && row.status === 'future' && (superGame.restart || game.reason !== 'lost')), code);
                    active_cols.push(<div key={c} className={`${cn} ${cell.classname}`}
                                          onClick={() => eog ? undefined : onBtnClick(idx, c)}>{cell.content}</div>)
                }
            } else if (idx === 1) {
                for (let c = 1; c <= columns; c++) {
                    active_cols.push(<div key={c}
                                          className={`${cn} ${(c - 1) === step && stepsServer.length === 0 ? 'fb-cell-selector-active' : 'fb-cell-selector'}`}
                                          onClick={() => onBtnClick(position + 1, c)}>{getCharByIndex('A', c - 1)}</div>)
                }
            }

            cols.push(
                <Col key={'info-center'} xs={8} className={`${idx === prizes.length ? 'paper-top-h2-white' : ''}`}>
                    <Row noGutters={true}>
                        {eog || idx !== prizes.length
                            ? active_cols :
                            <>
                                <Col xs={6} className={`${base_classname} ${stepsServer.length === 0 && step === 1 ? 'fb-gate-cell-active' : 'fb-gate-cell'}`}
                                     onClick={() => onBtnClick(position + 1, 2)}>B</Col>
                                <Col xs={6} className={`${base_classname} ${stepsServer.length === 0 && step === 2 ? 'fb-gate-cell-active' : 'fb-gate-cell'}`}
                                     onClick={() => onBtnClick(position + 1, 3)}>C</Col>
                            </>
                        }
                    </Row>
                </Col>);

            cols.push(
                <Col key={'info-right'} xs={2}
                     className={`${base_classname} ${cn_info} ${row.status === 'lost' || row.status === 'lostWin' || (eog && row.status === 'future' && game.reason === 'lost' && !superGame.restart) ? 'text-through' : ''}`}>
                    {(row.status === 'win' || row.status === 'lostWin' || row.status === 'timeoutWin') ? `[${ps}]` : ps}
                </Col>);

            if (eog) {
                rows.push(<Row key={`r-${idx}`} noGutters={true}>{cols}</Row>);
            } else if (idx === 1) {
                rows_post.push(<Row key={`r-${idx}`} noGutters={true}>{cols}</Row>);
            } else {
                rows.push(<Row key={`r-${idx}`} noGutters={true}>{cols}</Row>);
            }
        }

        const getGateInfo = (ballPos, activeInfo, keeperPos, result) => {
            const ballChar = getCharByIndex('a', ballPos);

            if (activeInfo && !result) {
                let line;
                if (ballPos >= 0) {
                    line = {
                        ...line
                        , line: {
                            classname: `fb-gate-line-${ballChar}`
                        }
                    };
                }

                return {
                    ...line
                    , ball: {
                        src: `/img/g/fb/fbb.png`
                        , classname: `fb-ball-point`
                    }
                    , keeper: {
                        src: `/img/g/fb/f4_r_.png`
                        , classname: 'fb-keeper-ready'
                    }
                };
            }

            if (ballPos !== -1) {
                const keeperChar = getCharByIndex('a', keeperPos);

                return activeInfo && keeperPos === ballPos
                    ? {
                        keeper: {
                            src: `/img/g/fb/f4_${ballChar}b.png`
                            , classname: `fb-keeper-${ballChar}`
                        }
                    }
                    : {
                        ball: {
                            src: `/img/g/fb/fbb.png`
                            , classname: `fb-ball-${ballChar}`
                        }
                        , keeper: {
                            src: `/img/g/fb/f4_${keeperChar}_.png`
                            , classname: `fb-keeper-${keeperChar}`
                        }
                    };
            }

            return {
                keeper: {
                    src: `/img/g/fb/f4_0_.png`
                    , classname: 'fb-keeper-ready'
                }
            };
        };

        const gateInfo = getGateInfo(step, !eog, stepsServer.length !== 0 ? stepsServer[0] : undefined, stepsServer.length !== 0);

        superRows.push(
            <Row key={'sr'}>
                <Col xs={12} className={'px-0'}>
                    <div style={{position: 'relative'}}>
                        <div className={`fb-field ${eog ? 'd-none' : ''}`}>
                            <svg baseProfile="full" viewBox="0 0 100 4" width="100%" xmlns="http://www.w3.org/2000/svg" className={'fb-gate-border-top'}>
                                <line x1="0" y1="1" x2="100" y2="1" stroke={'gray'} strokeWidth={3}/>
                            </svg>
                            <svg baseProfile="full" viewBox="0 0 8 100" xmlns="http://www.w3.org/2000/svg" className={'fb-gate-border-left'}>
                                <line x1="4" y1="0" x2="4" y2="100" stroke={'gray'} strokeWidth={8}/>
                            </svg>
                            <svg baseProfile="full" viewBox="0 0 8 100" xmlns="http://www.w3.org/2000/svg" className={'fb-gate-border-right'}>
                                <line x1="4" y1="0" x2="4" y2="100" stroke={'gray'} strokeWidth={8}/>
                            </svg>

                            <svg baseProfile="full" viewBox="0 0 100 3" width="100%" xmlns="http://www.w3.org/2000/svg" className={'fb-line-gate'}>
                                <line x1="0" y1="0" x2="100" y2="0" stroke={'white'} strokeWidth={3}/>
                            </svg>
                            <svg baseProfile="full" viewBox="0 0 100 3" width="100%" xmlns="http://www.w3.org/2000/svg" className={'fb-line-middle'}>
                                <line x1="0" y1="0" x2="100" y2="0" stroke={'white'} strokeWidth={3}/>
                            </svg>
                            <svg baseProfile="full" viewBox="0 0 10 10" height=".9rem" xmlns="http://www.w3.org/2000/svg" className={'fb-ball-point'}>
                                <circle cx="5" cy="5" r="5" fill={'white'}/>
                            </svg>
                            <img src={gateInfo.keeper.src} alt={''} className={gateInfo.keeper.classname}/>
                            {gateInfo.line !== undefined ? <svg baseProfile="full" xmlns="http://www.w3.org/2000/svg" className={gateInfo.line.classname}>
                                <line x1="0" y1="0" x2="100%" y2="100%" stroke={'white'} strokeWidth={3} strokeDasharray="8"/>
                            </svg> : <></>}
                            {gateInfo.ball !== undefined ? <img src={gateInfo.ball.src} alt={''} className={gateInfo.ball.classname}/> : <></>}
                        </div>
                        {rows}
                    </div>
                </Col>
            </Row>);

        superRows.push(
            <Row key={'sr-post'}>
                <Col xs={12} className={'px-0'}>
                    <div style={{position: 'relative'}}>
                        {rows_post}
                    </div>
                </Col>
            </Row>);

        superRows.push(
            <Row key={'info-hit'}>
                <Col xs={12} className={'p-0 paper-top-h2-white'}>
                    <div className={`px-2 py-1 ${infoTagFootball.classname}`}
                         dangerouslySetInnerHTML={dangerHtml(infoTagFootball.infoHit)}/>
                </Col>
            </Row>);

        superRows.push(
            <Row key={'info-status'}>
                <Col xs={12} className={'p-0 paper-top-h2-white'}>
                    <div className={'d-flex flex-column justify-content-center'}>
                        <div className={'px-2 py-1 fb-info-status'} dangerouslySetInnerHTML={dangerHtml(infoTagFootball.infoStatus)}/>
                    </div>
                </Col>
            </Row>
        );

        return superRows;
    };

    const onBtnClick = (row, col) => {
        const {stepsServer = [], position = 0} = game;
        let nextGame = {...game};

        if (stepsServer.length > 0) {
            nextGame.stepsServer = [];
            nextGame.step = -1;
        }

        if (row - 1 === position) {
            if (nextGame.step === col - 1) {
                nextGame.step = -1;
            } else {
                nextGame.step = col - 1;
            }
            updateGame(nextGame);
        }
    };

    const {eog} = state;
    const {subtype, settings_id, type} = game;
    const superGame = Settings.getSuperGame(settings_id);
    const tv = Math.floor(dataDiff(game.validTo, eog ? (game.finish !== undefined ? game.finish : game.validTo) : serverTime) / 1000);

    return (
        <SimpleContainer BackGroundColor={'white'} Footer0={Footer0}>
            <Helmet>
                <title>EasyFairGames - {Settings.getGameFullTitle(settings_id, type)}</title>
            </Helmet>
            <Row>
                <Col className={'paper-h1 t-gray'}>
                    <Row className={'my-1 mx-n2'}>
                        <Col className={'p-0 d-flex justify-content-start align-items-center'}>
                            <i className="fa fa-qrcode text-info-2 t-red pl-1" aria-hidden="true"
                               onClick={() => /*openQrDialog(!qrDialog)*/null}/>
                        </Col>
                        <Col className={'p-0 d-flex justify-content-center align-items-center'}>
                            {Settings.getGameFullTitle(settings_id, type)}
                        </Col>
                        <Col className={'p-0 d-flex justify-content-end align-items-center'}>
                            <span className={'text-info-1 t-red'}>{Math.max(tv, 0)}</span>
                            <span className={'text-info-1 t-blue'}>"</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col className={'fb-field-outer t-blue'}>
                            {makeRows()}
                        </Col>
                    </Row>
                    {makeFooter(subtype)}
                </Col>
            </Row>
        </SimpleContainer>
    );
};

const mapStateToProps = (state) => {
    return {
        api: state.api,
        cache: state.cache,
    }
};

export default connect(mapStateToProps)(Football)
