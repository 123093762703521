import {Config} from "../../../config";

const {VERSION, SIG_KEY} = Config;
const CHECK_TIMEOUT = 20 * 60 * 1000; // 20 минут

export const checkVersionAuto = (api) => {
    const now = new Date();
    let nc = localStorage.getItem("nextCheck");
    if (nc !== null && now.getTime() < parseInt(nc)) {
        return;
    }

    api.auth.requestApiJson({
        request: '/api/c/version',
        query: {version: VERSION, app_id: SIG_KEY}
    }).then(
        (response) => {
            localStorage.setItem("nextCheck", now.getTime() + CHECK_TIMEOUT);
            if (!response.active) {
                console.log("reload");
                window.location.reload();
            }
        }
    );
};
