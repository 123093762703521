import i18n from "../../i18n";

const fs = {
    "7d10489d-53e7-45c6-8944-01dd5decff51": {
        // zero
        "common": {
            "type": "frx",
            "games": [{
                "id": "f2",
                "code": "2",
                "price": [200],
                "rules": "integrity",
                "prizes": [{"prize": 200, "column": 2}, {"prize": 400, "column": 2}, {
                    "prize": 800,
                    "column": 2
                }, {"prize": 1600, "column": 2}, {"prize": 3200, "column": 2}, {
                    "prize": 6400,
                    "column": 2
                }, {"prize": 12800, "column": 2}, {"prize": 25600, "column": 2}, {
                    "prize": 51200,
                    "column": 2
                }, {"prize": 102400, "column": 2}, {"prize": 204800, "column": 2}, {"prize": 409600, "column": 2}],
                "default": true,
                "restart": false
            }, {
                "id": "f3",
                "code": "3",
                "price": [200],
                "rules": "integrity",
                "prizes": [{"prize": 150, "column": 3}, {"prize": 230, "column": 3}, {
                    "prize": 340,
                    "column": 3
                }, {"prize": 500, "column": 3}, {"prize": 750, "column": 3}, {"prize": 1100, "column": 3}, {
                    "prize": 1700,
                    "column": 3
                }, {"prize": 2600, "column": 3}, {"prize": 3900, "column": 3}, {"prize": 5800, "column": 3}, {
                    "prize": 8700,
                    "column": 3
                }, {"prize": 13000, "column": 3}],
                "restart": false
            }, {
                "id": "f4",
                "code": "4",
                "price": [200],
                "rules": "integrity",
                "prizes": [{"prize": 130, "column": 4}, {"prize": 180, "column": 4}, {
                    "prize": 240,
                    "column": 4
                }, {"prize": 320, "column": 4}, {"prize": 420, "column": 4}, {"prize": 560, "column": 4}, {
                    "prize": 750,
                    "column": 4
                }, {"prize": 1000, "column": 4}, {"prize": 1300, "column": 4}, {"prize": 1800, "column": 4}, {
                    "prize": 2400,
                    "column": 4
                }, {"prize": 3200, "column": 4}],
                "restart": false
            }, {
                "id": "f5",
                "code": "5",
                "price": [200],
                "rules": "integrity",
                "prizes": [{"prize": 120, "column": 5}, {"prize": 160, "column": 5}, {
                    "prize": 200,
                    "column": 5
                }, {"prize": 240, "column": 5}, {"prize": 310, "column": 5}, {"prize": 400, "column": 5}, {
                    "prize": 500,
                    "column": 5
                }, {"prize": 600, "column": 5}, {"prize": 750, "column": 5}, {"prize": 900, "column": 5}, {
                    "prize": 1200,
                    "column": 5
                }, {"prize": 1500, "column": 5}],
                "restart": false
            }, {
                "id": "f6",
                "code": "6",
                "price": [200],
                "rules": "integrity",
                "prizes": [{"prize": 110, "column": 6}, {"prize": 130, "column": 6}, {
                    "prize": 170,
                    "column": 6
                }, {"prize": 210, "column": 6}, {"prize": 250, "column": 6}, {"prize": 300, "column": 6}, {
                    "prize": 360,
                    "column": 6
                }, {"prize": 430, "column": 6}, {"prize": 500, "column": 6}, {"prize": 600, "column": 6}, {
                    "prize": 700,
                    "column": 6
                }, {"prize": 900, "column": 6}],
                "restart": false
            }],
            "price": [200],
            "ticket": true,
            "restart": false,
            "subtype": "football",
            "accounts": [{"order": 1, "prize": "y", "spent": "r", "priority": 3}, {
                "order": 3,
                "prize": "y",
                "spent": "g",
                "priority": 2
            }, {"order": 2, "prize": "y", "spent": "y", "priority": 1}],
            "description": {"tag": "gcl_ball_wrm", "code": "Fbl", "mask": "%TITLE%"}
        }, "version": 3
    }
    , "125e354b-8c13-4866-bb60-684db1dd3a0d": {
        // plus
        "common": {
            "type": "frx"
            , "games": [
                {
                    "id": "f2",
                    "code": "2",
                    "price": [600],
                    "rules": "integrity",
                    "prizes": [{"prize": 100, "column": 2}, {"prize": 200, "column": 2}, {
                        "prize": 400,
                        "column": 2
                    }, {"prize": 800, "column": 2}, {"prize": 1600, "column": 2}, {
                        "prize": 3200,
                        "column": 2
                    }, {"prize": 6400, "column": 2}, {"prize": 12800, "column": 2}, {
                        "prize": 25600,
                        "column": 2
                    }, {"prize": 51200, "column": 2}, {"prize": 102400, "column": 2}, {"prize": 204800, "column": 2}],
                    "default": true,
                    "restart": true
                }, {
                    "id": "f3",
                    "code": "3",
                    "price": [500],
                    "rules": "integrity",
                    "prizes": [{"prize": 100, "column": 3}, {"prize": 150, "column": 3}, {
                        "prize": 230,
                        "column": 3
                    }, {"prize": 340, "column": 3}, {"prize": 500, "column": 3}, {
                        "prize": 750,
                        "column": 3
                    }, {"prize": 1100, "column": 3}, {"prize": 1700, "column": 3}, {
                        "prize": 2600,
                        "column": 3
                    }, {"prize": 3900, "column": 3}, {"prize": 5800, "column": 3}, {"prize": 8700, "column": 3}],
                    "restart": true
                }, {
                    "id": "f4",
                    "code": "4",
                    "price": [400],
                    "rules": "integrity",
                    "prizes": [
                        {"prize": 100, "column": 4}
                        , {"prize": 130, "column": 4}
                        , {"prize": 180, "column": 4}
                        , {"prize": 240, "column": 4}
                        , {"prize": 320, "column": 4}
                        , {"prize": 420, "column": 4}
                        , {"prize": 560, "column": 4}
                        , {"prize": 750, "column": 4}
                        , {"prize": 1000, "column": 4}
                        , {"prize": 1300, "column": 4}
                        , {"prize": 1800, "column": 4}
                        , {"prize": 2400, "column": 4}],
                    "restart": true
                }, {
                    "id": "f5",
                    "code": "5",
                    "price": [300],
                    "rules": "integrity",
                    "prizes": [{"prize": 100, "column": 5}, {"prize": 120, "column": 5}, {
                        "prize": 160,
                        "column": 5
                    }, {"prize": 200, "column": 5}, {"prize": 240, "column": 5}, {"prize": 310, "column": 5}, {
                        "prize": 400,
                        "column": 5
                    }, {"prize": 500, "column": 5}, {"prize": 600, "column": 5}, {"prize": 750, "column": 5}, {
                        "prize": 900,
                        "column": 5
                    }, {"prize": 1200, "column": 5}],
                    "restart": true
                }, {
                    "id": "f6",
                    "code": "6",
                    "price": [200],
                    "rules": "integrity",
                    "prizes": [{"prize": 100, "column": 6}, {"prize": 110, "column": 6}, {
                        "prize": 130,
                        "column": 6
                    }, {"prize": 170, "column": 6}, {"prize": 210, "column": 6}, {"prize": 250, "column": 6}, {
                        "prize": 300,
                        "column": 6
                    }, {"prize": 360, "column": 6}, {"prize": 430, "column": 6}, {"prize": 500, "column": 6}, {
                        "prize": 600,
                        "column": 6
                    }, {"prize": 700, "column": 6}],
                    "restart": true
                }],
            "price": [600],
            "ticket": true,
            "restart": true,
            "subtype": "football",
            "accounts": [{"order": 1, "prize": "y", "spent": "r", "priority": 3}, {
                "order": 3,
                "prize": "y",
                "spent": "g",
                "priority": 2
            }, {"order": 2, "prize": "y", "spent": "y", "priority": 1}],
            "description": {"tag": "gcl_ball_wrm", "code": "Fbl", "mask": "%TITLE%+"}
        }, "version": 3
    }
    , "3e0e58f1-592c-4293-a31c-3ee60f241e6c": {
        // zero xms
        "common": {
            "type": "frx",
            "games": [
                {
                    "id": "f13.1",
                    "code": "1",
                    "price": [
                        100,
                        200,
                        300,
                        400,
                        500
                    ],
                    "rules": "integrity",
                    "prizes": [
                        {
                            "edge": 13,
                            "prize": 70,
                            "column": 13
                        },
                        {
                            "edge": 12,
                            "prize": 80,
                            "column": 12
                        },
                        {
                            "edge": 11,
                            "prize": 90,
                            "column": 11
                        },
                        {
                            "edge": 10,
                            "prize": 100,
                            "column": 10
                        },
                        {
                            "edge": 9,
                            "prize": 110,
                            "column": 9
                        },
                        {
                            "edge": 8,
                            "prize": 130,
                            "column": 8
                        },
                        {
                            "edge": 7,
                            "prize": 150,
                            "column": 7
                        },
                        {
                            "edge": 6,
                            "prize": 180,
                            "column": 6
                        },
                        {
                            "edge": 5,
                            "prize": 230,
                            "column": 5
                        },
                        {
                            "edge": 4,
                            "prize": 300,
                            "column": 4
                        },
                        {
                            "edge": 3,
                            "prize": 450,
                            "column": 3
                        },
                        {
                            "edge": 2,
                            "prize": 900,
                            "column": 2
                        }
                    ],
                    "default": true,
                    "rebuild": false
                },
                {
                    "id": "f13.2",
                    "code": "2",
                    "price": [
                        100,
                        200,
                        300,
                        400,
                        500
                    ],
                    "rules": "integrity",
                    "prizes": [
                        {
                            "edge": 13,
                            "prize": 80,
                            "column": 13,
                            "multiplicator": 2
                        },
                        {
                            "edge": 12,
                            "prize": 100,
                            "column": 12,
                            "multiplicator": 2
                        },
                        {
                            "edge": 11,
                            "prize": 120,
                            "column": 11,
                            "multiplicator": 2
                        },
                        {
                            "edge": 10,
                            "prize": 150,
                            "column": 10,
                            "multiplicator": 2
                        },
                        {
                            "edge": 9,
                            "prize": 200,
                            "column": 9,
                            "multiplicator": 2
                        },
                        {
                            "edge": 8,
                            "prize": 260,
                            "column": 8,
                            "multiplicator": 2
                        },
                        {
                            "edge": 7,
                            "prize": 370,
                            "column": 7,
                            "multiplicator": 2
                        },
                        {
                            "edge": 6,
                            "prize": 550,
                            "column": 6,
                            "multiplicator": 2
                        },
                        {
                            "edge": 5,
                            "prize": 900,
                            "column": 5,
                            "multiplicator": 2
                        },
                        {
                            "edge": 4,
                            "prize": 1800,
                            "column": 4,
                            "multiplicator": 2
                        },
                        {
                            "edge": 3,
                            "prize": 2700,
                            "column": 3,
                            "multiplicator": 1
                        },
                        {
                            "edge": 2,
                            "prize": 5500,
                            "column": 2,
                            "multiplicator": 1
                        }
                    ],
                    "default": false,
                    "rebuild": false
                },
                {
                    "id": "f13.3",
                    "code": "3",
                    "price": [
                        100,
                        200,
                        300,
                        400,
                        500
                    ],
                    "rules": "integrity",
                    "prizes": [
                        {
                            "edge": 13,
                            "prize": 90,
                            "column": 13,
                            "multiplicator": 3
                        },
                        {
                            "edge": 12,
                            "prize": 120,
                            "column": 12,
                            "multiplicator": 3
                        },
                        {
                            "edge": 11,
                            "prize": 170,
                            "column": 11,
                            "multiplicator": 3
                        },
                        {
                            "edge": 10,
                            "prize": 240,
                            "column": 10,
                            "multiplicator": 3
                        },
                        {
                            "edge": 9,
                            "prize": 360,
                            "column": 9,
                            "multiplicator": 3
                        },
                        {
                            "edge": 8,
                            "prize": 570,
                            "column": 8,
                            "multiplicator": 3
                        },
                        {
                            "edge": 7,
                            "prize": 1000,
                            "column": 7,
                            "multiplicator": 3
                        },
                        {
                            "edge": 6,
                            "prize": 2000,
                            "column": 6,
                            "multiplicator": 3
                        },
                        {
                            "edge": 5,
                            "prize": 3300,
                            "column": 5,
                            "multiplicator": 2
                        },
                        {
                            "edge": 4,
                            "prize": 6600,
                            "column": 4,
                            "multiplicator": 2
                        },
                        {
                            "edge": 3,
                            "prize": 10000,
                            "column": 3,
                            "multiplicator": 1
                        },
                        {
                            "edge": 2,
                            "prize": 20000,
                            "column": 2,
                            "multiplicator": 1
                        }
                    ],
                    "default": false,
                    "rebuild": false
                },
                {
                    "id": "f13.4",
                    "code": "4",
                    "price": [
                        100,
                        200,
                        300,
                        400,
                        500
                    ],
                    "rules": "integrity",
                    "prizes": [
                        {
                            "edge": 13,
                            "prize": 100,
                            "column": 13,
                            "multiplicator": 4
                        },
                        {
                            "edge": 12,
                            "prize": 150,
                            "column": 12,
                            "multiplicator": 4
                        },
                        {
                            "edge": 11,
                            "prize": 240,
                            "column": 11,
                            "multiplicator": 4
                        },
                        {
                            "edge": 10,
                            "prize": 400,
                            "column": 10,
                            "multiplicator": 4
                        },
                        {
                            "edge": 9,
                            "prize": 600,
                            "column": 9,
                            "multiplicator": 3
                        },
                        {
                            "edge": 8,
                            "prize": 1000,
                            "column": 8,
                            "multiplicator": 3
                        },
                        {
                            "edge": 7,
                            "prize": 1700,
                            "column": 7,
                            "multiplicator": 3
                        },
                        {
                            "edge": 6,
                            "prize": 3400,
                            "column": 6,
                            "multiplicator": 3
                        },
                        {
                            "edge": 5,
                            "prize": 5800,
                            "column": 5,
                            "multiplicator": 2
                        },
                        {
                            "edge": 4,
                            "prize": 11700,
                            "column": 4,
                            "multiplicator": 2
                        },
                        {
                            "edge": 3,
                            "prize": 17500,
                            "column": 3,
                            "multiplicator": 1
                        },
                        {
                            "edge": 2,
                            "prize": 35000,
                            "column": 2,
                            "multiplicator": 1
                        }
                    ],
                    "default": false,
                    "rebuild": false
                }
            ],
            "price": [
                100,
                200,
                300,
                400,
                500
            ],
            "ticket": true,
            "restart": false,
            "subtype": "xmt",
            "accounts": [
                {
                    "order": 1,
                    "prize": "y",
                    "spent": "r",
                    "priority": 3
                },
                {
                    "order": 2,
                    "prize": "y",
                    "spent": "y",
                    "priority": 1
                },
                {
                    "order": 3,
                    "prize": "y",
                    "spent": "g",
                    "priority": 2
                }
            ],
            "description": {
                "tag": "gcl_xmt",
                "code": "Xms",
                "mask": "%TITLE%"
            }
        },
        "version": 3
    }

    , "962c639e-c45e-4247-8072-1b98cce79963":
        {
            // plus xms
            "common": {
                "type": "frx",
                "games": [{
                    "id": "f13+1",
                    "code": "1",
                    "price": [100, 200, 300, 400, 500],
                    "rules": "integrity",
                    "prizes": [{"edge": 13, "prize": 40, "column": 13}, {
                        "edge": 12,
                        "prize": 50,
                        "column": 12
                    }, {"edge": 11, "prize": 60, "column": 11}, {"edge": 10, "prize": 70, "column": 10}, {
                        "edge": 9,
                        "prize": 80,
                        "column": 9
                    }, {"edge": 8, "prize": 90, "column": 8}, {"edge": 7, "prize": 100, "column": 7}, {
                        "edge": 6,
                        "prize": 120,
                        "column": 6
                    }, {"edge": 5, "prize": 150, "column": 5}, {"edge": 4, "prize": 200, "column": 4}, {
                        "edge": 3,
                        "prize": 300,
                        "column": 3
                    }, {"edge": 2, "prize": 600, "column": 2}],
                    "default": true,
                    "rebuild": false
                }, {
                    "id": "f13+2",
                    "code": "2",
                    "price": [100, 200, 300, 400, 500],
                    "rules": "integrity",
                    "prizes": [{"edge": 13, "prize": 50, "column": 13, "multiplicator": 2}, {
                        "edge": 12,
                        "prize": 60,
                        "column": 12,
                        "multiplicator": 2
                    }, {"edge": 11, "prize": 70, "column": 11, "multiplicator": 2}, {
                        "edge": 10,
                        "prize": 80,
                        "column": 10,
                        "multiplicator": 2
                    }, {"edge": 9, "prize": 100, "column": 9, "multiplicator": 2}, {
                        "edge": 8,
                        "prize": 130,
                        "column": 8,
                        "multiplicator": 2
                    }, {"edge": 7, "prize": 180, "column": 7, "multiplicator": 2}, {
                        "edge": 6,
                        "prize": 270,
                        "column": 6,
                        "multiplicator": 2
                    }, {"edge": 5, "prize": 450, "column": 5, "multiplicator": 2}, {
                        "edge": 4,
                        "prize": 900,
                        "column": 4,
                        "multiplicator": 2
                    }, {"edge": 3, "prize": 1350, "column": 3, "multiplicator": 1}, {
                        "edge": 2,
                        "prize": 2700,
                        "column": 2,
                        "multiplicator": 1
                    }],
                    "default": false,
                    "rebuild": false
                }, {
                    "id": "f13+3",
                    "code": "3",
                    "price": [100, 200, 300, 400, 500],
                    "rules": "integrity",
                    "prizes": [{"edge": 13, "prize": 60, "column": 13, "multiplicator": 3}, {
                        "edge": 12,
                        "prize": 70,
                        "column": 12,
                        "multiplicator": 3
                    }, {"edge": 11, "prize": 80, "column": 11, "multiplicator": 3}, {
                        "edge": 10,
                        "prize": 100,
                        "column": 10,
                        "multiplicator": 3
                    }, {"edge": 9, "prize": 140, "column": 9, "multiplicator": 3}, {
                        "edge": 8,
                        "prize": 220,
                        "column": 8,
                        "multiplicator": 3
                    }, {"edge": 7, "prize": 380, "column": 7, "multiplicator": 3}, {
                        "edge": 6,
                        "prize": 760,
                        "column": 6,
                        "multiplicator": 3
                    }, {"edge": 5, "prize": 1250, "column": 5, "multiplicator": 2}, {
                        "edge": 4,
                        "prize": 2500,
                        "column": 4,
                        "multiplicator": 2
                    }, {"edge": 3, "prize": 3800, "column": 3, "multiplicator": 1}, {
                        "edge": 2,
                        "prize": 7600,
                        "column": 2,
                        "multiplicator": 1
                    }],
                    "default": false,
                    "rebuild": false
                }, {
                    "id": "f13+4",
                    "code": "4",
                    "price": [100, 200, 300, 400, 500],
                    "rules": "integrity",
                    "prizes": [{"edge": 13, "prize": 60, "column": 13, "multiplicator": 4}, {
                        "edge": 12,
                        "prize": 70,
                        "column": 12,
                        "multiplicator": 4
                    }, {"edge": 11, "prize": 80, "column": 11, "multiplicator": 4}, {
                        "edge": 10,
                        "prize": 100,
                        "column": 10,
                        "multiplicator": 4
                    }, {"edge": 9, "prize": 140, "column": 9, "multiplicator": 3}, {
                        "edge": 8,
                        "prize": 220,
                        "column": 8,
                        "multiplicator": 3
                    }, {"edge": 7, "prize": 380, "column": 7, "multiplicator": 3}, {
                        "edge": 6,
                        "prize": 760,
                        "column": 6,
                        "multiplicator": 3
                    }, {"edge": 5, "prize": 1250, "column": 5, "multiplicator": 2}, {
                        "edge": 4,
                        "prize": 2500,
                        "column": 4,
                        "multiplicator": 2
                    }, {"edge": 3, "prize": 3800, "column": 3, "multiplicator": 1}, {
                        "edge": 2,
                        "prize": 7600,
                        "column": 2,
                        "multiplicator": 1
                    }],
                    "default": false,
                    "rebuild": false
                }],
                "price": [100, 200, 300, 400, 500],
                "ticket": true,
                "restart": true,
                "subtype": "xmt",
                "accounts": [{"order": 1, "prize": "y", "spent": "r", "priority": 3}, {
                    "order": 2,
                    "prize": "y",
                    "spent": "y",
                    "priority": 1
                }, {"order": 3, "prize": "y", "spent": "g", "priority": 2}],
                "description": {"tag": "gcl_xmt", "code": "Xms", "mask": "%TITLE%+"}
            }, "version": 3
        }
    , "dd73b83f-057f-46af-811c-88cb6e5da913": {
        "common": {
            "type": "frx-d",
            "games": [
                {
                    "id": "f2",
                    "code": "2",
                    "price": [
                        500,
                        1500,
                        2500,
                        5000
                    ],
                    "price_default": 5000,
                    "prize": {
                        "win": 1.95,
                        "draw": 0.95,
                        "rounding": "floor"
                    },
                    "rules": "blocks",
                    "blocks": [
                        {
                            "count": 5,
                            "column": 2
                        },
                        {
                            "count": 1,
                            "column": 2
                        },
                        {
                            "count": 1,
                            "column": 2
                        },
                        {
                            "count": 1,
                            "column": 2
                        },
                        {
                            "count": 1,
                            "column": 2
                        },
                        {
                            "count": 1,
                            "column": 2
                        }
                    ],
                    "default": true,
                    "restart": true
                },
                {
                    "id": "f3",
                    "code": "3",
                    "price": [
                        2500,
                        4000,
                        5000,
                        7500
                    ],
                    "price_default": 4000,
                    "prize": {
                        "win": 1.95,
                        "draw": 0.95,
                        "rounding": "floor"
                    },
                    "rules": "blocks",
                    "blocks": [
                        {
                            "count": 5,
                            "column": 3
                        },
                        {
                            "count": 1,
                            "column": 3
                        },
                        {
                            "count": 1,
                            "column": 3
                        },
                        {
                            "count": 1,
                            "column": 3
                        },
                        {
                            "count": 1,
                            "column": 3
                        },
                        {
                            "count": 1,
                            "column": 3
                        }
                    ],
                    "restart": true
                },
                {
                    "id": "f4",
                    "code": "4",
                    "price": [
                        1000,
                        2000,
                        3000
                    ],
                    "price_default": 3000,
                    "prize": {
                        "win": 1.9,
                        "draw": 0.9,
                        "rounding": "floor"
                    },
                    "rules": "blocks",
                    "blocks": [
                        {
                            "count": 5,
                            "column": 4
                        },
                        {
                            "count": 1,
                            "column": 4
                        },
                        {
                            "count": 1,
                            "column": 4
                        },
                        {
                            "count": 1,
                            "column": 4
                        },
                        {
                            "count": 1,
                            "column": 4
                        },
                        {
                            "count": 1,
                            "column": 4
                        }
                    ],
                    "restart": true
                },
                {
                    "id": "f5",
                    "code": "5",
                    "price": [
                        2000,
                        3000,
                        4500,
                        6000,
                        7500
                    ],
                    "price_default": 2000,
                    "prize": {
                        "win": 1.95,
                        "draw": 0.95,
                        "rounding": "floor"
                    },
                    "rules": "blocks",
                    "blocks": [
                        {
                            "count": 5,
                            "column": 5
                        },
                        {
                            "count": 1,
                            "column": 5
                        },
                        {
                            "count": 1,
                            "column": 5
                        },
                        {
                            "count": 1,
                            "column": 5
                        },
                        {
                            "count": 1,
                            "column": 5
                        },
                        {
                            "count": 1,
                            "column": 5
                        }
                    ],
                    "restart": true
                },
                {
                    "id": "f6",
                    "code": "6",
                    "price": [
                        1000,
                        2000,
                        3000,
                        4000,
                        5000
                    ],
                    "price_default": 1000,
                    "prize": {
                        "win": 1.95,
                        "draw": 0.95,
                        "rounding": "floor"
                    },
                    "rules": "blocks",
                    "blocks": [
                        {
                            "count": 5,
                            "column": 6
                        },
                        {
                            "count": 1,
                            "column": 6
                        },
                        {
                            "count": 1,
                            "column": 6
                        },
                        {
                            "count": 1,
                            "column": 6
                        },
                        {
                            "count": 1,
                            "column": 6
                        },
                        {
                            "count": 1,
                            "column": 6
                        }
                    ],
                    "restart": true
                }
            ],
            "prize": {
                "rate": 1.9,
                "rounding": "floor"
            },
            "ticket": true,
            "options": {
                "rate": 1.9,
                "rounding": "floor"
            },
            "restart": true,
            "subtype": "football-duel",
            "accounts": [
                {
                    "order": 1,
                    "prize": "g",
                    "spent": "r",
                    "priority": 3
                },
                {
                    "order": 2,
                    "prize": "y",
                    "spent": "y",
                    "priority": 1
                },
                {
                    "order": 3,
                    "prize": "y",
                    "spent": "g",
                    "priority": 2
                }
            ],
            "description": {
                "tag": "gcl_ball_pen",
                "code": "Pen",
                "mask": "%TITLE%"
            }
        },
        "version": 3
    }
};

export default class Settings {
    static getModeById(settings_id) {
        switch (settings_id) {
            case '7d10489d-53e7-45c6-8944-01dd5decff51':
                return 'fbl_zero';
            case '125e354b-8c13-4866-bb60-684db1dd3a0d':
                return 'fbl_plus';
            case '3e0e58f1-592c-4293-a31c-3ee60f241e6c':
                return 'xmt_zero';
            case '962c639e-c45e-4247-8072-1b98cce79963':
                return 'xmt_plus';
            case 'dd73b83f-057f-46af-811c-88cb6e5da913':
                return 'penalty';
            default:
                return '';
        }
    }

    static getGameFullTitle(settings_id, type) {
        return i18n.t('modes.' + Settings.getModeById(settings_id) + '.title') + '_' + type;
    }

    static getSubType(settings_id, type) {
        const games = fs[settings_id].common.games.filter(item => parseInt(item.code) === type);
        return games.length > 0 ? games[0].id : undefined;
    }

    static getGame(settings_id, type) {
        const games = fs[settings_id].common.games.filter(item => parseInt(item.code) === type);
        return games.length > 0 ? games[0] : undefined;
    }

    static getMaxPrize(settings_id, type, price) {
        const gameInfo = Settings.getGame(settings_id, type);
        const priceZero = gameInfo.price[0];
        const div = price * 100.0 / priceZero;
        return div * gameInfo.prizes[gameInfo.prizes.length - 1].prize / 100.0;
    }

    static getSuperGame(settings_id) {
        return fs[settings_id].common;
    }
}
