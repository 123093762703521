import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Redirect} from 'react-router-dom';

export default function requireAuthentication(SafeComponent) {
    class AuthenticatedComponent extends Component {
        render() {
            const {api, location} = this.props;
            return (
                <div>
                    {api.auth.isUserAuthenticated()
                        ? <SafeComponent {...this.props} />
                        : <Redirect to={{
                            pathname: '/login',
                            search: "?back=" + encodeURIComponent(location.pathname)
                        }}/>
                    }
                </div>
            )
        }
    }

    function mapStateToProps(state) {
        return {
            api: state.api
        }
    }

    return connect(mapStateToProps)(AuthenticatedComponent)
}