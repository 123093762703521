import React, {useCallback, useEffect} from 'react'
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import i18n from "../../../i18n";
import * as queryString from "query-string";
import {setBackURI} from "../../../actions/ConfigAction";

export const FastGame = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const api = useSelector(state => state.api);
    const cache = useSelector(state => state.cache);
    const config = useSelector(state => state.config);

    const createGame = useCallback(async (type, code, id, cost) => {

        const board = await api.auth.requestTokenApiJson({
            request: `/api/c/board/find/${type}/${code}`
        });

        if (board.board_id === undefined) {
            return; // не найдено
        }

        const settings = await cache.db.getSettings(board.settings_id, api, board.settings_version);
        if (type === 'ft') {
            for (const g of settings.games) {
                if (g.game_descriptor === id) {
                    const response = await api.auth.requestTokenApiJson({
                        request: '/api/c/ft/start',
                        json: {
                            settings: board.settings_id
                            , game_descriptor: id
                        }
                    });

                    api.auth.saveServerTimestamp(response.server_time);

                    if (response.public_code !== undefined) {
                        await cache.db.setPaper(response.ticket, response.expire, response);
                        history.push(`/ftg/${response.ticket}`);
                    }

                    return;
                }
            }
        } else if (type === 'frx-d') {
            for (const g of settings.games) {
                if (g.id === id) {
                    const response = await api.auth.requestApiJson({
                        request: '/api/c/fd/take',
                        query: {
                            settings_id: board.settings_id,
                            type: id,
                            price: g.price_default !== undefined ? g.price_default : g.price[0], // TODO
                            code: 'g'
                        }
                    });

                    api.auth.saveServerTimestamp(response.server_time);

                    await cache.db.setPaper(response.ticket, response.expire, response);
                    history.push(`/fpdg/${response.ticket}`);

                    return;
                }
            }
        } else if (type === 'frx') {
            for (const g of settings.games) {
                if (g.id === id) {
                    const response = await api.auth.requestApiJson({
                        request: '/api/c/ticket/take',
                        query: {
                            settings_id: board.settings_id,
                            type: id,
                            price: settings.subtype === 'xmt' ? 1000 : g.price[0],
                            code: 'g'
                        }
                    });

                    api.auth.saveServerTimestamp(response.server_time);
                    await cache.db.setPaper(response.ticket, response.expire, response);

                    history.push(`/fig/?game_id=${response.ticket}`);
                    return;
                }
            }
        }
    }, [api, cache.db, history]);

    // монтирование
    useEffect(() => {
        let parsed = queryString.parse(props.location.search);
        const {back} = parsed;

        if (back !== undefined && config.BackURI !== back) {
            dispatch(
                setBackURI(back)
            );
        } else {
            const fetchGame = async () => {
                const {type, code, id, cost} = props.match.params;

                if (api.auth.isUserNull() || api.auth.isRefreshTokenExpired()) {
                    await api.auth.registerUser();
                }
                await createGame(type, code, id, cost);
            }

            fetchGame().then(r => {
            });
        }
    }, [props, api.auth, createGame, config.BackURI, dispatch]);

    return <div>{i18n.t('common.loading')}</div>
}
