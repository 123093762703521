import MobileDetect from "mobile-detect";

const initialState = {
    MobileDetect: new MobileDetect(window.navigator.userAgent)
    , BackURI: undefined
};

export default function config(state = initialState, action) {
    switch (action.type) {
        case 'SET_BACK_URI':
            return {...state, BackURI: action.payload};
        case 'CHANGE_CONFIG':
            return {...state, ...action.payload};
        default:
            return state;
    }
}
