import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {dangerHtml, i18nCurrentLanguage} from "../i18n";
import {selectFolder} from "../actions/AppActions";
import {Col, Row} from "reactstrap";
import {useHistory} from "react-router-dom";
import {TouchPanel} from "./TouchPanel";

export const Dashboard = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const app = useSelector(state => state.app);
    const cache = useSelector(state => state.cache);
    const [panels, setPanels] = useState({
        currentFolderId: undefined
        , data: []
    });

    // монтирование
    useEffect(() => {
        let profile_id = localStorage.getItem('profile_id');
        if (app.currentFolderId === undefined) {
            cache.db
                .getPanelsRoot(profile_id, i18nCurrentLanguage())
                .then(folderId => {
                    if (folderId !== undefined && folderId !== null) {
                        dispatch(selectFolder(folderId));
                    }
                });
        } else if (panels.currentFolderId !== app.currentFolderId) {
            cache.db.loadPanels(profile_id, i18nCurrentLanguage(), app.currentFolderId)
                .then(res => res && setPanels({
                        currentFolderId: app.currentFolderId
                        , data: res.map(v => {
                            return {
                                ...v.item
                                , description: v.item.description.replace(/(\[\w+])/g, '')// TODO залепуха, переделать как правильно
                            };
                        }).sort((l, r) => l.priority - r.priority)
                    })
                );
        }
    }, [dispatch, cache.db, app.currentFolderId, panels.currentFolderId]);

    const onClickPanel = (item) => {
        if (item.code !== undefined) {
            history.push(`/b/${item.child_id}`);
            /*
                        setRedirect({
                            'url': `/g/a/${item.code}`
                            , 'query': {
                                'board_id': item.child_id
                                , 'settings_id': item.settings_id
                                , 'settings_version': item.settings_version
                            }
                        });
            */
        }
    };

    let rows = [];
    let cols = [];
    panels.data.forEach((item, idx) => {
        cols.push(
            <Col key={cols.length} className={'m-1'}>
                <TouchPanel onClick={() => onClickPanel(item)}>
                    <div className={'block-main'} dangerouslySetInnerHTML={dangerHtml(item.description)}/>
                </TouchPanel>
            </Col>
        );
        if (cols.length >= 2) {
            rows.push(<Row noGutters={true} key={rows.length}>{cols}</Row>);
            cols = [];
        }
    });

    if (cols.length > 0) {
        rows.push(<Row noGutters={true} key={rows.length}>{cols}</Row>);
    }

    return (
        <div className="animated fadeIn">
            {rows}
        </div>);
}
