import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Alert, Button, Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Helmet} from "react-helmet";
import SimpleContainer from "../../Pages/SimpleContainer";
import {getCharByIndex, getLocationOrigin, makeQuad, map2Tags, parseRoundValuePenalty} from "../../../components/Common/GameControl/Utils";
import i18n, {i18ntDangerHtml} from "../../../i18n";
import QRCode from "qrcode.react";
import Moment from "moment";
import {dateTimeFormat, getCheckURI} from "../Common/FloorX";
import {Config} from "../../../config";
import {Redirect} from "react-router-dom";
import {reduceQuery} from "../../../modules/api/CoreApi";
import {Footer0} from "../Common/Utils";

const iconBall = <i className="fa fa-futbol-o" aria-hidden="true"/>;

const fillCellsFromResponse = (game, result) => {
    const {first, second} = game;
    const charCodeAlpha = 'A'.charCodeAt(0);

    const processRow = (row, source, goal) => {
        let ng = true;
        if (source.first !== undefined) {
            const fi = source.first.charCodeAt(0) - charCodeAlpha;
            row.cells[fi].first = true;
        }
        if (source.second !== undefined) {
            const si = source.second.charCodeAt(0) - charCodeAlpha;
            row.cells[si].second = true;

            ng = row.cells[si].second === row.cells[si].first;
        }

        row.goal = source.status === "skip" || ng ? goal : (goal + 1);
        row.status = source.status;

        return row.goal;
    };

    if (result.first !== undefined) {
        let fg = 0, sg = 0, firstZones = '', secondZones = '';
        for (let p = 0; p < result.first.length; p++) {
            fg = processRow(first[p], result.first[p], fg);
            sg = processRow(second[p], result.second[p], sg);

            firstZones += result.first[p].second;
            secondZones += result.second[p].first;
        }

        game.score = {
            first: fg
            , second: sg
        };
        game.reason = result.reason;
        game.secretKey = result.secret_key;
        game.firstZones = firstZones;
        game.secondZones = secondZones;
    }
};

const prepareGameInfo = async (gameInfo, api, cache) => {
    const initRows = (blocks) => {
        let rows = [];

        for (let idx = 0; idx < blocks.length; idx++) {
            const block = blocks[idx];

            for (let ib = 0; ib < block.count; ib++) {
                let cells = [];
                for (let c = 1; c <= block.column; c++) {
                    cells.push({
                        first: false
                        , second: false
                    });
                }

                rows.push({
                    cells
                    , goal: 0
                    , status: 'future'
                    , group: idx
                });
            }
        }

        return rows;
    };

    const {ticket, settings_id, subgame_code, public_code: publicCode, code = 'y'} = gameInfo;
    const type = parseInt(subgame_code);
    const superGame = await cache.db.getSettings(settings_id, api);
    const gamesList = superGame && superGame.games.filter((item) => parseInt(item.code) === type);

    if (gamesList === undefined || gamesList.length === 0) {
        return {};
    }
    const settingsGame = gamesList[0];

    return {
        blocks: settingsGame.blocks
        , ticket: ticket
        , publicCode: publicCode
        , validFrom: new Date(gameInfo.create)
        , validTo: new Date(gameInfo.expire)
        , first: initRows(settingsGame.blocks)
        , second: initRows(settingsGame.blocks)
        , score: {
            first: 0
            , second: 0
        }
        , settingsPrize: settingsGame.prize
        , type: type
        , subtype: superGame.subtype
        , settings_id: settings_id
        , cost: gameInfo.price / 100
        , zones: settingsGame.blocks[0].column
        , position: 0
        , step: -1
        , price: gameInfo.price
        , code
        , settingsCommon: superGame
    }
};

const getGameURIForPenalty = (game, backURI) => {
    const origin = Config.COMMON_URL !== '' ? Config.COMMON_URL : getLocationOrigin();
    return `${origin}/b/e1kj35${backURI !== undefined ? '?back=' + encodeURIComponent(backURI) : ''}`; // TODO залепуха
};

export const Penalty = (props) => {
    const api = useSelector(state => state.api);
    const cache = useSelector(state => state.cache);
    const config = useSelector(state => state.config);

    const [state, setState] = useState({});
    const [game, updateGame] = useState({});
    const [isNewGameDialog, showNewGameDialog] = useState(false);
    const [redirect, setRedirect] = useState();

    // монтирование
    useEffect(() => {
        const startGame = async (code) => {
            const gameInfo = await cache.db.getPaper(code, api);
            const response = await api.auth.requestApiJson({
                request: '/api/c/fd/status',
                query: {
                    ticket: gameInfo.ticket
                }
            });

            const {result, status} = response;
            const preparedGame = await prepareGameInfo(gameInfo, api, cache);
            fillCellsFromResponse(preparedGame, result);

            updateGame(preparedGame);

            setState({
                eog: status.game_over
                , prize: result.prize / 100
            });
        };

        const {ticket: code} = props.match.params;
        startGame(code).then(v => {
        });
    }, [props, api, cache]);

    if (redirect !== undefined) {
        return <Redirect push={true} to={reduceQuery(redirect.url, redirect.query)}/>;
    }

    const {eog} = state;
    const {ticket, type, cost, blocks = [], zones, first, second, score = {}, settingsPrize = {}, reason} = game;

    const onBtnScan = async () => {
        let packFirst = [], packSecond = [];

        const packedRow = (row, firstUse) => {
            let pack = '';
            for (let c = 0; c < row.cells.length; c++) {
                const cell = row.cells[c];
                if ((firstUse && cell.first) || (!firstUse && cell.second)) {
                    pack += getCharByIndex('A', c);
                }
            }
            return pack;
        };

        for (let r = 0; r < first.length; r++) {
            packFirst.push(packedRow(first[r], true));
            packSecond.push(packedRow(second[r], false));
        }

        const response = await api.auth.requestApiJson({
            request: '/api/c/fd/check',
            json: {
                ticket,
                first: packFirst,
                second: packSecond
            }
        });

        const {error, result} = response;

        if (result !== undefined) {
            fillCellsFromResponse(game, result);

            updateGame({...game});

            setState(prevState => ({
                ...prevState,
                eog: true,
                zonesActive: false,
                error: undefined,
                gameExpire: undefined,
                finish: new Date(result.finish),
                prize: result.prize / 100,
                prizeNumber: result.prize_number
            }));
        } else {
            setState(prevState => ({
                ...prevState,
                error: error,
            }));
        }
    };

    const makeErrorText = (error) => {
        let result = i18n.t('error.title');
        if (error !== undefined) {
            const {status = '', first, second} = error;
            switch (status) {
                case 'no_move':
                    result += '\n' + i18n.t('error.penalty.no_move');
                    break;
                default:
                    if (first !== undefined) {
                        if (first.over !== undefined) {
                            result += '\n' + i18n.t('error.penalty.target.over_ball', {numbers: first.over.toString()});
                        }
                        if (first.missing !== undefined) {
                            result += '\n' + i18n.t('error.penalty.target.missing_ball', {numbers: first.missing.toString()});
                        }
                    }
                    if (second !== undefined) {
                        if (second.over !== undefined) {
                            result += '\n' + i18n.t('error.penalty.lock.over_ball', {numbers: second.over.toString()});
                        }
                        if (second.missing !== undefined) {
                            result += '\n' + i18n.t('error.penalty.lock.missing_ball', {numbers: second.missing.toString()});
                        }
                    }
            }
        }

        return result;
    };

    const onBtnNewGame = () => {
        showNewGameDialog(true);
    };

    const toggleStartSelect = () => {
        showNewGameDialog(prevState => !prevState);
    };

    const onBtnStartNewGame = async (id) => {
        setRedirect({'url': `/g/f/frx-d/p1/${id}`});
    };

    const makeNewGameSelectDialog = () => {
        const {settingsCommon} = game;

        return (
            <Modal isOpen={isNewGameDialog} toggle={toggleStartSelect}>
                <ModalHeader toggle={toggleStartSelect}>{i18n.t('common.new_game_title')}</ModalHeader>
                <ModalBody>
                    {config.BackURI !== undefined
                        ? <Button className={'btn rounded w-100 py-2 my-1 text-white'} color={'success'}
                                  href={config.BackURI}>{i18n.t('common.back_page')}</Button>
                        : <></>}
                    {settingsCommon.games && settingsCommon.games.map((g, key) => (
                        <div key={key} className={'my-2'}>
                            <Button color="primary" className={'btn-block rounded'} onClick={() => onBtnStartNewGame(g.id)}>
                                {i18n.t(`modes.penalty.title`)}_{g.code} @ {(g.price_default !== undefined ? g.price_default : g.price[0]) / 100}b
                            </Button>
                        </div>))
                    }
                </ModalBody>
            </Modal>);
    };

    const makeButtons = () => {
        let rows = [];

        if (eog) {
            rows.push(
                <Row key={1} className={'py-1'}>
                    <Col xs={12}>
                        <Button color="primary" className={'btn-block'} onClick={() => onBtnNewGame()}>
                            {i18n.t('common.new_game')}
                        </Button>
                    </Col>
                    {makeNewGameSelectDialog()}
                </Row>);
        }

        if (blocks.length > 0 && !eog) {
            rows.push(
                <Row key={1} className={'py-1'}>
                    <Col key={3} xs={12}>
                        <Button color="primary" className={'btn-block'} onClick={() => onBtnScan()}>
                            {i18n.t('common.play', {
                                title: getGameFullTitle(type),
                                price: cost
                            })}
                        </Button>
                    </Col>
                </Row>);
            rows.push(
                <Row key={102} className={'py-1'}>
                    <Col key={4}>
                        <Button color="secondary" className={'btn btn-lg btn-block rounded mt-1'} href={getGameURIForPenalty(game, config.BackURI)}>
                            {i18n.t('common.interactive')}
                        </Button>
                    </Col>
                </Row>);
        }

        rows.push(
            <Row key={2}>
                <Col>
                    <Alert color={'danger'} isOpen={state.error !== undefined}>
                        {map2Tags(makeErrorText(state.error), '\n', <br/>)}
                    </Alert>
                </Col>
            </Row>
        );

        return rows;
    };

    const onBtnClick = (firstUse, row, col) => {
        if (!eog) {
            if (firstUse) {
                const cc = first[row - 1].cells[col - 1];
                cc.first = !cc.first;
                updateGame({...game, first});
            } else {
                const cc = second[row - 1].cells[col - 1];
                cc.second = !cc.second;
                updateGame({...game, second});
            }
        }
    };

    const makeCell = (handBall, player, hide) => {
        if (hide) {
            return (<>&nbsp;</>);
        } else if (player) {
            return handBall ? <>O</> : iconBall;
        } else {
            return (<>&nbsp;</>);
        }
    };

    const makeRows = () => {
        const {reason} = game;
        const hide = reason === 'timeout';
        const cn_side = `px-0 fd-col-fd${zones} fd-cell`;
        const cn_central = `frx-col-frx10-1 fd-cell px-0`;
        let rows = [], ri = 0;

        for (let b = 0; b < blocks.length; b++) {
            const block = blocks[b];
            for (let bg = 0; bg < block.count; bg++, ri++) {
                const r = ri + 1;
                const f = first[first.length - r];
                const s = second[second.length - r];
                const handBallFirst = !eog || (f.status === 'skip' && reason !== 'timeout');
                const handBallSecond = !eog || (s.status === 'skip' && reason !== 'timeout');
                let firstCols = [], secondCols = [];

                for (let ci = 1; ci <= block.column; ci++) {
                    firstCols.push(
                        <Col className={`${cn_side} fd-active ${!hide && f.cells[ci - 1].second ? 'paper-diagonal-black' : ''}`} key={`f-${ci}`}
                             onClick={() => onBtnClick(true, first.length - r + 1, ci)}>
                            {makeCell(handBallFirst, f.cells[ci - 1].first, hide)}
                        </Col>);
                    secondCols.push(
                        <Col className={`${cn_side} fd-active ${!hide && s.cells[ci - 1].second ? 'paper-diagonal-black' : ''}`} key={`s-${ci}`}
                             onClick={() => onBtnClick(false, second.length - r + 1, ci)}>
                            {makeCell(handBallSecond, s.cells[ci - 1].first, hide)}
                        </Col>);
                }

                rows.push(
                    <Row key={ri}>
                        {firstCols}
                        <Col className={`${cn_central} fd-central ${f.status === 'goal' ? 'font-weight-bold' : 'font-weight-light'}`}
                             key={'pf'}>{!eog || f.status === 'skip' ? iconBall : f.goal}
                        </Col>
                        <Col className={`${cn_central} fd-central ${s.status === 'goal' ? 'font-weight-bold' : 'font-weight-light'}`}
                             key={'ps'}>{!eog || s.status === 'skip' ? iconBall : s.goal}
                        </Col>
                        {secondCols}
                    </Row>);
            }
        }

        let letterCols = [];
        for (let l = 0; l < zones; l++) {
            letterCols.push(
                <Col className={`${cn_side} fd-central`} key={l}>
                    {getCharByIndex('A', l)}
                </Col>);
        }

        rows.push(
            <Row key={'z'}>
                {letterCols}
                <Col className={`position-relative ${cn_central} fd-central`}><img src={`/img/g/fb/km_h.png`} height={'80%'} alt={''} style={{
                    bottom: '10%',
                    left: '50%',
                    transform: 'translate(-50%,0)',
                    position: 'absolute',
                    zIndex: 100
                }}/></Col>
                <Col className={`position-relative ${cn_central} fd-central`}><img src={`/img/g/fb/kp_h.png`} height={'80%'} alt={''} style={{
                    bottom: '10%',
                    left: '50%',
                    transform: 'translate(-50%,0)',
                    position: 'absolute',
                    zIndex: 100
                }}/></Col>
                {letterCols}
            </Row>);

        return rows;
    };

    const getCalculatePrize = (price, score) => parseRoundValuePenalty(price * score / 100)

    const getGamePrize = () => {
        if (score.first < score.second || (reason !== undefined && reason === 'timeout')) {
            return 0;
        } else {
            return getCalculatePrize(game.price, score.first === score.second ? settingsPrize.draw : settingsPrize.win);
        }
    };

    const makeFooter = () => {
        let rows = [];

        if (eog) {
            const {reason} = game;

            rows.push(
                <Row key={'f1'}>
                    <Col xs={12} key={'fl'} className={'d-flex flex-row p-1 paper-top-dashed-h1'}>
                        <div className={'h-100 d-flex justify-content-center align-items-center'}
                             onClick={() => window.open(getGameURIForPenalty(game), '_blank')}>
                            <QRCode width={'80'} height={'80'} renderAs='svg' level='L'
                                    value={getGameURIForPenalty(game)}
                            />
                        </div>
                        <div style={{wordBreak: 'break-all', textAlign: 'left'}}
                             className={'d-flex flex-column justify-content-center pl-1'}>
                            <div dangerouslySetInnerHTML={i18ntDangerHtml('common.paper_valid_to', {valid_to: Moment(game.validTo).format(dateTimeFormat)})}/>
                            <div dangerouslySetInnerHTML={i18ntDangerHtml('common.paper_game_id', {ticket_id: game.ticket})}/>
                            <div dangerouslySetInnerHTML={i18ntDangerHtml('common.paper_public_code', {public_code: game.publicCode})}/>
                        </div>
                    </Col>
                </Row>
            );

            if (reason !== 'timeout' && score.first >= score.second) {
                rows.push(
                    <Row key={'f2'}>
                        <Col xs={12} key={'fl'} className={'px-1'} style={{textAlign: 'left'}}>
                            <div className={'d-flex flex-column justify-content-center'}>
                                <div key={3} dangerouslySetInnerHTML={i18ntDangerHtml('penalty.paper_todo_prize', {
                                    prize: getGamePrize()
                                })}/>
                            </div>
                        </Col>
                    </Row>
                );
            }

            if (reason !== 'timeout') {
                const secretCode = `${game.secretKey}_${game.firstZones}_${game.secondZones}`;
                rows.push(
                    <Row key={'f3'}>
                        <Col xs={12} key={'fl'} className={'d-flex flex-row p-1 paper-top-dashed-h1'}>
                            <div style={{wordBreak: 'break-all', textAlign: 'left'}}
                                 className={'d-flex flex-column justify-content-center pr-1 w-100'}>
                                <div dangerouslySetInnerHTML={i18ntDangerHtml(`penalty.paper_secret_code`)}/>
                                <div dangerouslySetInnerHTML={i18ntDangerHtml('common.paper_secret_key', {secret_key: game.secretKey})}/>
                                <div dangerouslySetInnerHTML={i18ntDangerHtml(`penalty.paper_first_zones`, {zones: game.firstZones})}/>
                                <div dangerouslySetInnerHTML={i18ntDangerHtml(`penalty.paper_second_zones`, {zones: game.secondZones})}/>
                            </div>
                            <div className={'h-100 d-flex justify-content-center'}
                                 onClick={() => window.open(getCheckURI(secretCode, game.publicCode), '_blank')}>
                                <QRCode width={'80'} height={'80'} renderAs='svg' level='L'
                                        value={getCheckURI(secretCode, game.publicCode)}
                                />
                            </div>
                        </Col>
                    </Row>
                );

                rows.push(
                    <Row key={'f4'}>
                        <Col xs={12} key={'fl'} className={'px-1'} style={{textAlign: 'left'}}>
                            <div className={'d-flex flex-column justify-content-center'}>
                                <div dangerouslySetInnerHTML={i18ntDangerHtml('common.coupon_check')}/>
                            </div>
                        </Col>
                    </Row>
                );
                rows.push(
                    <Row key={'f5'}>
                        <Col xs={12} key={'fl'} className={'px-1 pb-1'} style={{textAlign: 'center'}}>
                            <div className={'d-flex flex-column justify-content-center'}>
                                <div dangerouslySetInnerHTML={i18ntDangerHtml('common.coupon_check_formula')}/>
                            </div>
                        </Col>
                    </Row>
                );
            }
        } else {
            rows.push(
                <Row key={'f1'}>
                    <Col xs={12} key={'fl'} className={'d-flex flex-row p-1 paper-top-dashed-h1'}>
                        <div className={'h-100 d-flex justify-content-center align-items-center'}
                             onClick={() => window.open(getGameURIForPenalty(game), '_blank')}>
                            <QRCode width={'80'} height={'80'} renderAs='svg' level='L'
                                    value={getGameURIForPenalty(game)}
                            />
                        </div>
                        <div style={{wordBreak: 'break-all', textAlign: 'left'}}
                             className={'d-flex flex-column justify-content-center pl-1'}>
                            <div dangerouslySetInnerHTML={i18ntDangerHtml('common.paper_valid_to', {valid_to: Moment(game.validTo).format(dateTimeFormat)})}/>
                            <div dangerouslySetInnerHTML={i18ntDangerHtml('common.paper_game_id', {ticket_id: game.ticket})}/>
                            <div dangerouslySetInnerHTML={i18ntDangerHtml('common.paper_public_code', {public_code: game.publicCode})}/>
                        </div>
                    </Col>
                </Row>
            );
            rows.push(
                <Row key={'f2'}>
                    <Col xs={12} key={'fl'} className={'px-1'} style={{textAlign: 'left'}}>
                        <div className={'d-flex flex-column justify-content-center'}>
                            <div key={3} dangerouslySetInnerHTML={i18ntDangerHtml('penalty.paper_todo')}/>
                        </div>
                    </Col>
                </Row>
            );
        }
        return rows;
    };

    const makeKeeperZones = () => {
        let rows = [];

        if (zones === 4) {
            rows.push(
                <Row key={1} noGutters={true}>
                    <Col xs={6} className={`fd-gate2 paper-bottom-dashed-h2`}>A</Col>
                    <Col xs={6} className={`fd-gate2 paper-bottom-dashed-h2 paper-left-dashed-h2`}>D</Col>
                </Row>
            );
            rows.push(
                <Row key={2} noGutters={true}>
                    <Col xs={6} className={`fd-gate2`}>B</Col>
                    <Col xs={6} className={`fd-gate2 paper-left-dashed-h2`}>C</Col>
                </Row>
            );
        } else if (zones === 2) {
            rows.push(
                <Row key={1} className={'h-100 align-middle'} noGutters={true}>
                    <Col xs={6} className={`fd-gate1 d-flex align-items-center justify-content-center `}>A</Col>
                    <Col xs={6} className={'fd-gate1 d-flex align-items-center justify-content-center paper-left-dashed-h2'}>B</Col>
                </Row>
            );
        } else if (zones === 3) {
            rows.push(
                <Row key={1} className={'h-100 align-middle'} noGutters={true}>
                    <Col xs={4} className={`fd-gate1 d-flex align-items-center justify-content-center `}>A</Col>
                    <Col xs={4} className={'fd-gate1 d-flex align-items-center justify-content-center paper-left-dashed-h2'}>B</Col>
                    <Col xs={4} className={'fd-gate1 d-flex align-items-center justify-content-center paper-left-dashed-h2'}>C</Col>
                </Row>
            );
        } else if (zones === 5) {
            rows.push(
                <Row key={1} className={'h-100 align-middle'} noGutters={true}>
                    <Col xs={4} className={`fd-gate1 d-flex flex-column align-items-center justify-content-center `}>
                        <div className={'w-100 fd-gate2 paper-bottom-dashed-h2'}>A</div>
                        <div>B</div>
                    </Col>
                    <Col xs={4} className={'fd-gate1 d-flex align-items-center justify-content-center paper-left-dashed-h2'}>C</Col>
                    <Col xs={4} className={'fd-gate1 d-flex flex-column align-items-center justify-content-center paper-left-dashed-h2'}>
                        <div className={'w-100 fd-gate2 paper-bottom-dashed-h2'}>E</div>
                        <div>D</div>
                    </Col>
                </Row>
            );
        } else if (zones === 6) {
            rows.push(
                <Row key={1} noGutters={true}>
                    <Col xs={4} className={`fd-gate2 paper-bottom-dashed-h2`}>A</Col>
                    <Col xs={4} className={`fd-gate2 paper-bottom-dashed-h2 paper-left-dashed-h2`}>D</Col>
                    <Col xs={4} className={`fd-gate2 paper-bottom-dashed-h2 paper-left-dashed-h2`}>F</Col>
                </Row>
            );
            rows.push(
                <Row key={2} noGutters={true}>
                    <Col xs={4} className={`fd-gate2`}>B</Col>
                    <Col xs={4} className={`fd-gate2 paper-left-dashed-h2`}>C</Col>
                    <Col xs={4} className={`fd-gate2 paper-left-dashed-h2`}>E</Col>
                </Row>
            );
        }

        return rows;
    };

    const getKeeperImage = (firstKeeper, success, draw) => {
        const fk = firstKeeper ? 'p' : 'm';
        const t = draw ? 'w' : (success ? 't' : 'f');

        return <img key={4}
                    src={`${eog ? `/img/g/fb/k${fk}_${t}.png` : `/img/g/fb/k${fk}_w.png`}`}
                    height={'85%'}
                    style={{
                        bottom: 0,
                        left: '50%',
                        transform: 'translate(-50%,0)',
                        position: 'absolute',
                        zIndex: 100
                    }} alt={''}/>
    };

    const makeHeader = (title, firstKeeper, success, draw) => {
        let rows = [];

        rows.push(
            <Row key={'t0'} noGutters={true}>
                <Col className={'fd-gate-top'}>
                    {title}
                </Col>
            </Row>);

        rows.push(
            <Row key={'t1'} noGutters={true}>
                <div className={'fd-gate-side paper-bottom-solid-h1'}>
                    &nbsp;
                </div>
                <div className={`position-relative fd-gate-inside paper-left-solid-h1 paper-top-solid-h1 paper-right-solid-h1`}>
                    {makeKeeperZones()}
                    {getKeeperImage(firstKeeper, success, draw)}
                </div>
                <div className={'fd-gate-side paper-bottom-solid-h1'}>
                    &nbsp;
                </div>
            </Row>);

        return rows;
    };

    const makeResult = () => {
        const {first: scoreFirst, second: scoreSecond} = score;
        let rows = [];

        rows.push(
            <div key={'t0'} className={`fd-result font-weight-bold`}>
                <span className={'pr-1'}>{scoreFirst}</span>:<span className={'pl-1'}>{scoreSecond}</span>
            </div>);
        rows.push(
            <div key={'t2'} className={``}>
                <span className={'pl-0 pr-1'}>{i18n.t('penalty.first')}</span><span className={'pl-1 pr-0'}>{i18n.t('penalty.second')}</span>
            </div>);

        return rows;
    };

    const makeTitleInfo = () => {
        const {reason, price} = game;

        if (eog) {
            if (reason === 'timeout') {
                return <span dangerouslySetInnerHTML={i18ntDangerHtml('penalty.title_status_timeout')}/>;
            }

            const {first: scoreFirst, second: scoreSecond} = score;
            const prize = getGamePrize();

            if (scoreFirst > scoreSecond) {
                return <span dangerouslySetInnerHTML={i18ntDangerHtml('penalty.title_status_win', {prize})}/>;
            } else if (scoreFirst === scoreSecond) {
                return <span dangerouslySetInnerHTML={i18ntDangerHtml('penalty.title_status_draw', {prize})}/>;
            } else {
                return <span dangerouslySetInnerHTML={i18ntDangerHtml('penalty.title_status_lost')}/>;
            }
        } else {
            return <span dangerouslySetInnerHTML={i18ntDangerHtml('penalty.title_info', {
                prize_win: getCalculatePrize(price, settingsPrize.win)
                , prize_draw: getCalculatePrize(price, settingsPrize.draw)
            })}/>;
        }
    };

    const getGameFullTitle = (type) => {
        return i18n.t('modes.penalty.title') + '_' + type;
    };

    return (
        <SimpleContainer BackGroundColor={'white'} Footer0={Footer0}>
            <Helmet>
                <title>EasyFairGames - {getGameFullTitle(type)}</title>
            </Helmet>
            <Row>
                <Col className={'paper-solid-h1 t-gray'}>
                    <Row className={'position-relative'}>
                        <Col className={'p-0 d-flex justify-content-center align-items-center font-weight-bold'}>
                            {i18n.t(`penalty.title_${eog ? 'coupon' : 'ticket'}`, {
                                "description": i18n.t('modes.penalty.title')
                                , "type": type
                            })}
                        </Col>
                        {eog ? <></> : makeQuad(1, 'black')}
                        {eog ? <></> : makeQuad(2, 'black')}
                    </Row>
                    <Row className={'text-center'}>
                        <Col className={'p-0 d-flex justify-content-center align-items-center font-weight-bold'}>
                            {makeTitleInfo()}
                        </Col>
                    </Row>
                    <Row className={'text-center'}>
                        <Col className={`px-0 frx-col-frx10-4 fb-field-outer paper-top-solid-h1 `}>
                            {makeHeader(i18n.t('penalty.gate_first'), true, score.second > score.first, score.second === score.first)}
                        </Col>
                        <Col
                            className={`px-0 d-flex flex-column frx-col-frx10-2 fb-field-outer paper-bottom-solid-h1 paper-right-solid-h1 paper-left-solid-h1 justify-content-center align-items-center`}>
                            {makeResult()}
                        </Col>
                        <Col className={`px-0 frx-col-frx10-4 fb-field-outer paper-top-solid-h1 `}>
                            {makeHeader(i18n.t('penalty.gate_second'), false, score.first > score.second, score.second === score.first)}
                        </Col>
                    </Row>
                    <Row>
                        <Col className={'fb-field-outer text-center'}>
                            {makeRows()}
                        </Col>
                    </Row>
                    <Row className={'position-relative'}>
                        <Col xs={'auto'} className={'fb-field-outer d-flex justify-content-center align-items-center px-0'}>
                            <span dangerouslySetInnerHTML={i18ntDangerHtml(`penalty.price`, {
                                price: game.price / 100
                            })}/>
                            {eog ? <>
                                <span className={'mx-auto'} dangerouslySetInnerHTML={i18ntDangerHtml(`penalty.played`, {
                                    played: Moment(game.finish).format(dateTimeFormat)
                                })}/>
                                <span dangerouslySetInnerHTML={i18ntDangerHtml(`penalty.prize`, {
                                    prize: getGamePrize()
                                })}/>
                            </> : <></>}
                        </Col>
                        {eog ? <></> : makeQuad(3, 'black')}
                    </Row>
                    {makeFooter()}
                </Col>
            </Row>
            {makeButtons()}
        </SimpleContainer>
    );
};
