import React, {Component} from "react";

import {
    Button,
    DropdownItem,
    DropdownMenu,
    DropdownToggle, Input,
    UncontrolledButtonDropdown,
} from "reactstrap";
import {connect} from "react-redux";
import {Link, Redirect} from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Moment from "moment";

const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
const dateFormat = 'YYYY-MM-DD';

const select_lang = [
    {
        title: 'En'
        , code: 'en'
        , flag: 'flag-icon-gb'
        , disable: false
    }
    , {
        title: 'De'
        , code: 'de'
        , flag: 'flag-icon-de'
        , disable: false
    }
    , {
        title: 'Fr'
        , code: 'fr'
        , flag: 'flag-icon-fr'
        , disable: false
    }
    , {
        title: 'It'
        , code: 'it'
        , flag: 'flag-icon-it'
        , disable: false
    }
    , {
        title: 'Ru'
        , code: 'ru'
        , flag: 'flag-icon-ru'
        , disable: false
    }
];

const news_status = [
    {
        draft: true,
        description: "Черновики"
    },
    {
        draft: false,
        description: "Опубликованные"
    },
    {
        draft: undefined,
        description: "Все"
    }
];

class ListNews extends Component {
    columns = [{
        dataField: 'index',
        text: '#'
    }, {
        dataField: 'edit', text: '',
        editable: false, align: "center",
        classes: "m-1 p-1",
        formatter: (cell, row) => {
            return (
                <div className={"d-flex flex-column"}>
                    {row.translate.map((item, idx) =>
                        <Button className={item.draft ? "fa fa-newspaper-o p-1 m-1" : "fa fa-pencil  p-1 m-1"}
                                key={"download_" + row.id + idx}
                                onClick={() => this.onPublishNews(row.id, item.lang, !item.draft)}
                                aria-hidden="true"
                                title={item.draft ? "Опубликовать" : "В черновик"}/>)}
                </div>

            );
        }
    }, {
        dataField: 'lang',
        text: 'Язык',
        editable: false,
        classes: "m-1 p-1",
        formatter: (cell, row) => {
            return (
                <div className={'d-flex flex-column'}>
                    {row.translate.map((item, idx) =>
                        <div className={item.draft ? "p-1 m-1 gray-italic" : "p-1 m-1"}
                             key={"lang" + idx}>{item.lang}
                        </div>)}
                </div>
            );
        }

    }, {
        dataField: 'date_news',
        text: 'Дата новости',
        editable: false,
        classes: "m-1 p-1",
        formatter: (cell, row) => {
            return (
                <div className={'d-flex flex-column'}>
                    {row.translate.map((item, idx) =>
                        <div className={item.draft ? "p-1 m-1 gray-italic" : "p-1 m-1"}
                             key={"date" + idx}>{Moment(item.date_of_news).format(dateFormat)}
                        </div>)}
                </div>
            );
        }

    }, {
        dataField: 'date_of_update',
        text: 'Дата редактирования',
        editable: false,
        classes: "m-1 p-1",
        formatter: (cell, row) => {
            return (
                <div className={'d-flex flex-column '}>
                    {row.translate.map((item, idx) =>
                        <div className={item.draft ? "p-1 m-1 gray-italic" : "p-1 m-1"}
                             key={"dateUpdate" + idx}>{Moment(item.date_of_update).format(dateTimeFormat)}
                        </div>)}
                </div>
            );
        }
    }, {
        dataField: 'title',
        text: 'Новость',
        editable: false,
        classes: "m-1 p-1     overflow: 'hidden',    textOverflow: 'ellipsis'",
        formatter: (cell, row) => {
            return (
                <div className={'d-flex flex-column'}>
                    {row.translate.map((item, idx) =>
                        <Link className={"p-1 m-1"} key={"link" + idx}
                              to={'/news/item/' + item.lang + "/" + row.id}> {item.title !== undefined ? item.title : ""}
                        </Link>)}
                </div>);
        }
    }];

    constructor(props) {
        super(props);

        this.lang = "ru";
        const tag = "m";

        this.state = {
            list: [],
            version: 1,
            query: {
                tag,
                draft: undefined,
                lang: undefined,
                query: ""
            }
        }
    }

    refreshList = async (page, sizePerPage) => {
        const from = (page - 1) * sizePerPage;
        const {query} = this.state;
        const {api} = this.props;
        const response = await api.auth.requestApiJson({
            request: '/api/c/news/list',
            query: {...query, from, count: sizePerPage}
        });

        this.setState({
            page, sizePerPage,
            totalSize: response.total_size,
            list: response.list !== undefined ? response.list.map((item, idx) => {
                item.index = from + idx + 1;
                return item;
            }) : [],
            version: response.version
        })
    };

    componentDidMount() {
        const {page = 1, sizePerPage = 10} = this.state;
console.log("componentDidMount");
        this.refreshList(page, sizePerPage);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {

        console.log("shouldComponentUpdate");
        return true;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log("componentDidUpdate");

    }

    addNews = (lang) => {
        this.setState({
            addNew: lang
        })
//        this.props.history.push('/news/item/' + lang);
    };

    onPublishNews = async (id, lang, draft) => {
        const {page = 1, sizePerPage = 10} = this.state;
        const {api} = this.props;

        await api.auth.requestApiJson({
            method: 'PUT',
            request: '/api/c/news/item',
            query: {id, lang},
            json: {
                draft
            }
        });
        this.refreshList(page, sizePerPage)
    };

    addFilter() {
        const {isFilter} = this.state;
        this.setState({
            isFilter: !isFilter
        })
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const {query} = this.state;
        query[name] = value;

        this.setState({
            query
        });
        event.preventDefault();
        const {page = 1, sizePerPage = 10} = this.state;
        if (event.target.value.length > 2) {
            this.refreshList(page, sizePerPage);
        }
    };
    handleSubmit = async (event) => {
        alert('A name was submitted: ' + event.target.value);


    };


    render() {
        const {addNew, page = 1, sizePerPage = 10, totalSize = 0, list = [], version = 0, isFilter = false, query} = this.state;
        if (addNew !== undefined) {
            console.log('/news/item/' + addNew + '/');
            return (<Redirect to={'/news/item/' + addNew}/>);
        }

        return (
            <div className="table-responsive-md">
                <div className={"card mb-3"}>
                    <div className="card-header bg-transparent">
                        <div className={"d-flex bd-highlight"}>
                            <h5 className="card-title p-2 bd-highlight mr-auto">{"Опубликованные новости"}</h5>

                            <UncontrolledButtonDropdown>
                                <DropdownToggle className={" p-2 bd-highlight mr-1 my-1"} caret>
                                    Добавить новость
                                </DropdownToggle>
                                <DropdownMenu>
                                    {select_lang.map(item =>
                                        <DropdownItem key={item.code}
                                                      onClick={() => this.addNews(item.code)}>
                                            <span className={'mr-1 flag-icon ' + item.flag}/>
                                            {item.title}
                                        </DropdownItem>
                                    )}
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>

                            <Button className={"fa fa-refresh p-2 bd-highlight mr-1 my-1"} aria-hidden="true" title="Обновить список"
                                    onClick={() => this.refreshList(page, sizePerPage)}/>
                            <Button className={"fa fa-filter h p-2 bd-highlight mr-1 my-1"} aria-hidden="true" title="Фильтр новостей"
                                    onClick={() => this.addFilter()}/>
                        </div>
                        {isFilter ?
                            <div className={"d-flex bd-highlight"}>
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle className={" p-2 bd-highlight mr-1 my-1"} caret>
                                        Язык новости
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {select_lang.map(item =>
                                            <DropdownItem key={item.code}
                                                          onClick={() => {
                                                              query.lang = item.code;
                                                              this.refreshList(page, sizePerPage)
                                                          }}>
                                                <span className={'mr-1 flag-icon ' + item.flag}/>
                                                {item.title}
                                                {item.code === query.lang ?
                                                    <span className={'ml-1 fa fa-check '}/> : <></>}
                                            </DropdownItem>
                                        )}
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle className={" p-2 bd-highlight mr-1 my-1"} caret>
                                        Статус новости
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {news_status.map((item, idx) =>
                                            <DropdownItem key={"status" + idx}
                                                          onClick={() => {
                                                              query.draft = item.draft;
                                                              this.refreshList(page, sizePerPage)
                                                          }}>
                                                {item.description}
                                                {item.draft === query.draft ?
                                                    <span className={'ml-1 fa fa-check '}/> : <></>}
                                            </DropdownItem>
                                        )}
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                                <Input
                                    className={" p-2 bd-highlight mr-1 my-1"}
                                    type="text"
                                    key={"query"}
                                    name="query"
                                    id="query"
                                    placeholder="Текст для поиска"

                                    value={this.state.query.query}
                                    onChange={this.handleChange}
                                />
                            </div>
                            : <></>}

                    </div>
                    <div>

                        <BootstrapTable
                            remote
                            striped
                            hover
                            keyField="id"
                            data={list}
                            columns={this.columns}
                            pagination={paginationFactory({page, sizePerPage, totalSize, showTotal: true})}
                            onTableChange={(type, {page, sizePerPage}) => this.refreshList(page, sizePerPage)}
                        />
                    </div>

                    <div className="card-footer bg-transparent ">{"Версия списка: " + version}</div>
                </div>
            </div>
        )
    }


}

const mapStateToProps = (state) => {
    return {
        api: state.api,
        cache: state.cache,
    }
};
export default connect(mapStateToProps)(ListNews)

/*const listNews = {
    list: [{
        id: "40b050b2-3296-4165-9cd4-6d9063337e84",
        translate: [
            {
                lang: "ru",
                date_of_news: "2019-1-20T22:00:00.296Z",
                date_of_update: "2019-1-21T08:23:17.304Z",
                title: "Разработка дуэльной игры Пенальти."
            }
        ]
    }],
    total_size: 1,
    version: 6
};*/
