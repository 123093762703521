import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, Row} from "reactstrap";

export default function requireToken(SafeComponent) {
    class TokenComponent extends Component {
        constructor(props) {
            super(props);

            const check = sessionStorage.getItem("token");
            const accessToken = check !== null ? JSON.parse(check) : null;

            this.state = {
                accessToken,
                tokenValid: accessToken !== null && accessToken.tag !== undefined,
            }
        }

        handleChange = (event) => {
            const target = event.target;
            const value = target.type === 'checkbox' ? target.checked : target.value;
            const name = target.name;

            this.setState({
                [name]: value
            });
        };

        handleSubmit = async (event) => {
            event.preventDefault();

            const {api} = this.props;
            const {tag} = this.state;

            const response = await api.auth.checkToken({token: tag});
            if (response.success) {
                sessionStorage.setItem('token', JSON.stringify({payload: response.payload, tag}));
                this.setState({tokenValid: true})
            }
        };

        render() {
            const {tag, tokenValid} = this.state;

            return (
                <>
                    {
                        tokenValid
                            ? <SafeComponent {...this.props} />
                            : <Container fluid={true}>
                                <Row className="justify-content-center">
                                    <Col md="8">
                                        <CardGroup className="mb-4">
                                            <Card className="p-4">
                                                <CardBody>
                                                    <h1>Promo Token</h1>
                                                    <p className="text-muted">Enter promo token</p>
                                                    <InputGroup className="mb-4">
                                                        <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                  <i className="icon-lock"/>
                                                </span>
                                                        </div>
                                                        <Input type="password" placeholder="Token" name="tag"
                                                               value={tag} onChange={this.handleChange}/>
                                                    </InputGroup>
                                                    <Row>
                                                        <Col xs="6">
                                                            <Button color="primary" className="px-4"
                                                                    onClick={this.handleSubmit}>Check</Button>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </CardGroup>
                                    </Col>
                                </Row>
                            </Container>
                    }
                </>
            )
        }
    }

    function mapStateToProps(state) {
        return {
            api: state.api
        }
    }

    return connect(mapStateToProps)(TokenComponent)
}