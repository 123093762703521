import {Col, Row} from "reactstrap";
import {Link} from "react-router-dom";
import i18n from "../../../i18n";
import React from "react";

export const Footer0 =
    <Row>
        <Col className={'my-2'}>
            {sessionStorage.getItem("token") !== null
                ? <Link to={'/fp'}>{i18n.t('common.back_to_select_subtype')}</Link>
                : <></>}
        </Col>
    </Row>