import React, {useEffect, useState} from "react";
import {connect, useSelector} from "react-redux";
import SimpleContainer from "../Pages/SimpleContainer";
import {Button, Col, Row} from "reactstrap";
import {Link} from "react-router-dom";
import i18n, {i18ntDangerHtml} from "../../i18n";
import Settings from "./Settings";
import {dateTimeFormat, getGameURI, initCells} from "../Games/Common/FloorX";
import QRCode from "qrcode.react";
import Moment from "moment";
import {parseRoundValue} from "../../components/Common/GameControl/Utils";
import {useTranslation} from "react-i18next";

const PaperFloorX = (props) => {
    const {t} = useTranslation();
    const config = useSelector(state => state.config);
    const [state, setState] = useState({});
    const [game, updateGame] = useState({});

    // монтирование
    useEffect(() => {
        const {ticket} = props.match.params;
        const {api, cache} = props;

        cache.db.getPaper(ticket, api)
            .then(gameInfo => {
                setState({});
                updateGame({...prepareGameInfo(gameInfo, api)});
            });
    }, [props]);

    const prepareGameInfo = (gameInfo, api) => {
        const {ticket, settings_id, subgame_code, public_code: publicCode, prizes} = gameInfo;
        const superGame = Settings.getSuperGame(settings_id);
        const type = parseInt(subgame_code);
        const prepareCells = initCells(prizes, gameInfo.price_mult);

        return {
            prizes: prizes
            , ticket: ticket
            , publicCode: publicCode
            , validFrom: new Date(gameInfo.create)
            , validTo: new Date(gameInfo.expire)
            , cells: prepareCells.rows
            , useMultiplicator: prepareCells.useMultiplicator
            , type: type
            , subtype: superGame.subtype
            , settings_id: settings_id
            , cost: gameInfo.price / 100
            , zones: prizes[0].column
        }
    };

    const makePaperTitle0 = (dashed, scan, gameOver) => {
        const paper = i18n.t(gameOver === undefined || !gameOver ? 'common.e_ticket' : 'common.e_coupon');
        return (
            <Row>
                <Col className={`${dashed ? 'paper-dashed-bottom' : ''} t-blue`}>
                    <span>{i18n.t(scan !== undefined ? (scan === 'scan' ? 'common.paper_title0_scan' : (gameOver ? 'common.paper_title0_photo' : 'common.paper_title0_common')) : 'common.paper_title0_common', {paper})}</span>
                </Col>
            </Row>
        );
    };

    if (game.settings_id === undefined) {
        return <SimpleContainer BackGroundColor={'white'} Footer0={footer0}>{t('common.loading')}</SimpleContainer>;
    }

    const superGame = Settings.getSuperGame(game.settings_id);

    return (
        <SimpleContainer BackGroundColor={'white'} Footer0={footer0}>
            {makePaperTitle0(true, false, state.eog)}
            <Row key={0} className={'info-white'}>
                <Col className={'px-1 d-flex justify-content-center align-items-center paper'}>
                    <h5 className={'m-0 py-1'}>
                        <span className={'t-blue'}>{i18n.t('common.title_paper0', {title: Settings.getGameFullTitle(game.settings_id, game.type)})}</span>
                    </h5>
                </Col>
            </Row>
            <Row>
                <Col xs={12} className={'px-1 paper'}
                     style={{textAlign: 'left'}}>
                        <span dangerouslySetInnerHTML={i18ntDangerHtml(superGame.restart ? 'xmt.qr_dialog_plus' : 'xmt.qr_dialog_zero', {
                            game_type: game.type
                            , timeout: (game.validTo.getTime() - game.validFrom.getTime()) / 1000
                            , max_prize: parseRoundValue(game.cells[game.prizes.length - 1].prize / 100.0)
                        })}/>
                </Col>
            </Row>
            <Row>
                <Col xs={12} key={'fl'} className={'paper-dashed-top d-flex flex-row p-1'}>
                    <div className={'h-100 d-flex justify-content-center align-items-center'}
                         onClick={() => window.open(getGameURI(game), '_self')}>
                        <QRCode width={'80'} height={'80'} renderAs='svg' level='L'
                                value={getGameURI(game)}
                        />
                    </div>
                    <div style={{wordBreak: 'break-all', textAlign: 'left'}}
                         className={'d-flex flex-column justify-content-center pl-1'}>
                        <div dangerouslySetInnerHTML={i18ntDangerHtml('common.paper_game_id', {ticket_id: game.ticket})}/>
                        <div dangerouslySetInnerHTML={i18ntDangerHtml('common.paper_public_code', {public_code: game.publicCode})}/>
                        <div dangerouslySetInnerHTML={i18ntDangerHtml('common.paper_valid_to', {valid_to: Moment(game.validTo).format(dateTimeFormat)})}/>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12} className={'paper-dashed-bottom px-1 '}
                     style={{textAlign: 'left'}}>
                    <span dangerouslySetInnerHTML={i18ntDangerHtml('xmt.paper_qr_info')}/>
                </Col>
            </Row>
            <Row className={'py-1'}>
                <Col key={4}>
                    <Button color="secondary" className={'btn-block rounded'} href={getGameURI(game, config.BackURI)}>
                        {i18n.t('common.interactive')}
                    </Button>
                </Col>
            </Row>
        </SimpleContainer>
    );
};

const footer0 =
    <Row>
        <Col className={'my-2'}>
            <Link to={'/fp'}>{i18n.t('common.back_to_select_subtype')}</Link>
        </Col>
    </Row>;

const mapStateToProps = (state) => {
    return {
        api: state.api,
        cache: state.cache,
        config: state.config
    }
};

export default connect(mapStateToProps)(PaperFloorX)