import React, {useCallback, useEffect, useState} from "react";
import {connect} from "react-redux";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Moment from "moment";
import {i18ntDangerHtml} from "../../../i18n";
import {dateTimeFormat} from "../Common/FloorX";
import {useTranslation} from "react-i18next";

const Rating = (props) => {
    const {t} = useTranslation();
    const [rows, setRows] = useState({
        items: []
        , page: 1
        , sizePerPage: props.Count
    });

    const onRefreshTop = useCallback((top) => {
        const {api} = props;
        if (api.auth.isUserAuthenticated()) {
            api.auth.requestTokenApiJson({
                request: '/api/c/ft/rating'
                , query: {
                    top
                }
            }).then((response) => {
                response && setRows({
                    items: response.items.map((item, x) => {
                        return {...item, idx: (x + 1), lang: props.lang}
                    })
                    , totalSize: response.total
                });
            });
        }
    }, [props]);

    const onRefreshList = useCallback(async (page, sizePerPage) => {
        const {api} = props;

        const from = (page - 1) * sizePerPage;
        const response = await api.auth.requestTokenApiJson({
            request: `/api/c/ft/rating`
            , query: {
                rated: props.Rated
                , from: from
                , count: sizePerPage
            }
        });

        response && setRows({
            items: response.items.map((item, x) => {
                return {...item, idx: from + (x + 1), lang: props.lang}
            })
            , totalSize: response.total
            , page
            , sizePerPage
        });
    }, [props]);

    // монтирование
    useEffect(() => {
        if (props.top !== undefined) {
            onRefreshTop(props.top);
        } else {
            onRefreshList(1, props.Count);
        }
    }, [props, onRefreshTop, onRefreshList]);

    const {api} = props;
    const columns = [{
        dataField: 'rank',
        text: '#',
        style: {
            width: '3rem'
        },
        formatter: (cell, row) => {
            return <span>{row.rank !== 0 ? row.rank : 'n/a'}</span>
        }
    }, {
        dataField: 'nickname',
        text: t('common.nickname')
        , style: {
            width: '8rem'
        }
        , formatter: (cell, row) => {
            return row.you
                ? <span dangerouslySetInnerHTML={i18ntDangerHtml('common.you', {nickname: row.nickname})}/>
                : row.cip !== 0
                    ? <span dangerouslySetInnerHTML={i18ntDangerHtml('common.cip', {nickname: row.nickname})}/>
                    : <span>{row.nickname}</span>;
        }
    }, {
        dataField: 'result',
        text: t('common.result')
    }, {
        dataField: 'dor',
        text: t('common.date_result'),
        style: {
            width: '8rem'
        },
        formatter: (cell, row) => {
            return <span>{Moment(row.dor).format(dateTimeFormat)}</span>
        }
    }, {
        dataField: 'count',
        text: t('common.series')
    }];

    return api.auth.isUserAuthenticated()
        ? <BootstrapTable
            bootstrap4
            condensed
            striped
            hover
            remote
            pagination={props.top !== undefined ? undefined : paginationFactory({
                page: rows.page,
                sizePerPage: rows.sizePerPage,
                totalSize: rows.totalSize,
                showTotal: false,
                hideSizePerPage: true,
                hidePageListOnlyOnePage: true
            })}
            onTableChange={(type, {page, sizePerPage}) => onRefreshList(page, sizePerPage)}
            keyField="idx"
            rowClasses={(row, rowIndex) => `ft-table-cell ${row.you ? 'you' : ''}`}
            data={rows.items}
            columns={columns}
        />
        : <></>;
};

const mapStateToProps = (state) => {
    return {
        api: state.api,
        cache: state.cache,
    }
};

export default connect(mapStateToProps)(Rating)
