import {connect} from "react-redux";
import FindThis, {getTagWordRule, getTitle} from "./FindThis";
import SimpleContainer from "../../Pages/SimpleContainer";
import React from "react";
import {Alert, Button, Col, Row} from "reactstrap";
import i18n, {dangerHtml, i18ntDangerHtml} from "../../../i18n";
import {makeQuad, preProcessLetter, prizeString} from "../../../components/Common/GameControl/Utils";
import Moment from "moment";
import QRCode from "qrcode.react";
import {dateTimeFormat, getCheckURI} from "../Common/FloorX";
import {Redirect} from "react-router-dom";
import {Footer0} from "../Common/Utils";

class FindThisPaper extends FindThis {
    state = {};

    componentDidMount() {
        const {ticket} = this.props.match.params;
        const {api, cache} = this.props;

        cache.db.getPaper(ticket, api, '/api/c/ft/load')
            .then(gameInfo => this.prepareGameInfo(gameInfo))
            .then(tg => this.setState({
                ...tg
            }));
    }

    onBtnNewGame = () => {
        this.setState({
            modalStartSelect: true
            , error: undefined
        });
    };

    makeButton = () => {
        let rows = [];
        const {config} = this.props;
        const {ticket, game} = this.state;

        if (game.gameOver) {
            rows.push(
                <Row key={'check_game'} className={'py-1'}>
                    <Col>
                        <Button color="primary" className={'btn-block rounded'}
                                href={getCheckURI(game.secretCode, game.publicCode)} target='_blank'>
                            {i18n.t('common.check_game')}
                        </Button>
                    </Col>
                </Row>);
            rows.push(
                <Row key={'new_game'} className={'py-1'}>
                    {this.makeStartSelectDialog()}
                    <Col>
                        <Button color="primary" className={'btn-block'} onClick={() => this.onBtnNewGame()}>
                            {i18n.t('common.new_game')}
                        </Button>
                    </Col>
                </Row>);
        } else {
            rows.push(
                <Row key={'turn'} className={'py-1'}>
                    <Col key={4}>
                        <Button onClick={() => this.onBtnScan()} color="primary"
                                className={'btn btn-lg btn-block rounded mt-1'}>
                            {i18n.t(game.scan === 'scan' ? 'ft.scan' : 'ft.promo', {
                                count: game.cells.filter(cell => cell.selected).length,
                                letters: ticket.description.length
                            })}
                        </Button>
                        {this.makeQrDialog()}
                    </Col>
                </Row>);
            rows.push(
                <Row key={2} className={'py-1'}>
                    <Col key={4}>
                        <Button color="secondary" className={'btn btn-lg btn-block rounded mt-1'} href={this.getGameURI(config.BackURI)}>
                            {i18n.t('common.interactive')}
                        </Button>
                    </Col>
                </Row>);
        }
        return rows;
    };

    getCellInfo = (gameOver, ticket, cells, pos) => {
        if (gameOver) {
            if (cells[pos].letter !== undefined && !cells[pos].open) {
                return {
                    letter: <div className={`paper-ft${ticket.height}-cell-letter`}>
                        {preProcessLetter(ticket.description[cells[pos].letter])}</div>,
                    index: <div className={`paper-ft${ticket.height}-cell-index paper-ft-cell-index`}>{pos + 1}</div>,
                    className: 'paper ones-open-after'
                }
            } else if (cells[pos].open) {
                if (cells[pos].letter === undefined) {
                    return {
                        letter: <div className={`paper-ft${ticket.height}-cell-letter`}>&nbsp;</div>,
                        index: <div className={`paper-ft${ticket.height}-cell-index paper-ft-cell-index`}>{pos + 1}</div>,
                        className: 'paper ones-lost'
                    }
                } else {
                    return {
                        letter: <div className={`paper-ft${ticket.height}-cell-letter`}>
                            {preProcessLetter(ticket.description[cells[pos].letter])}</div>,
                        index: <div className={`paper-ft${ticket.height}-cell-index paper-ft-cell-index`}>{pos + 1}</div>,
                        className: 'paper ones-open'
                    }
                }
            } else {
                return {
                    letter: <div className={`paper-ft${ticket.height}-cell-letter`}>&nbsp;</div>,
                    index: <div className={`paper-ft${ticket.height}-cell-index paper-ft-cell-index`}>{pos + 1}</div>,
                    className: 'paper ones-close'
                }
            }
        } else {
            return {
                letter: <div className={`paper-ft${ticket.height}-cell-letter`}>&nbsp;</div>,
                index: <div className={`paper-ft${ticket.height}-cell-index paper-ft-cell-index`}>{pos + 1}</div>,
                className: cells[pos].selected ? 'paper ones-selected' : 'paper ones-close'
            }
        }
    };

    onCellClick = (cells, pos) => {
        const {game = {}} = this.state;

        if (game.gameOver) {
            return;
        }

        game.cells[pos].selected = game.cells[pos].selected === undefined || !game.cells[pos].selected;
        this.setState({
            game
        });
    };

    onBtnScan = async () => {
        const {api} = this.props;
        const {ticket, game} = this.state;
        let {next} = this.state;
        let steps = [];

        for (let k = 0; k < game.cells.length; k++) {
            if (game.cells[k].selected) {
                steps.push(k);
            }
        }

        const response = await api.auth.requestApiJson({
            request: '/api/c/ft/check',
            json: {
                ticket: ticket.id,
                steps
            }
        });

        const {result, wallet: walletServer, next: ns} = response;

        if (result !== undefined) {
            next = await this.processNext(ns, walletServer);

            game.gameOver = true;

            this.updateFullGameInfo(game, result);

            this.setState({
                error: response.error,
                game,
                next
            });
        } else {
            this.setState({
                error: response.error
            });
        }
    };

    makeFooterPaper = () => {
        const {ticket, game} = this.state;
        let rows = [];

        const actionLeftQR = () => {
            if (game.gameOver && game.scan !== undefined && game.scan === 'scan') {
                return {
                    value: this.getGamePayURI()
                    , target: '_blank'
                }
            }

            return {
                value: this.getGameURI()
                , target: '_self'
            }
        };

        const action = actionLeftQR();

        rows.push(
            <Row key={'f1'}>
                <Col xs={12} key={'fl'} className={'paper-dashed-top d-flex flex-row p-1'}>
                    <div className={'h-100 d-flex align-items-start pt-1'}
                         onClick={() => window.open(action.value, action.target)}>
                        <QRCode width={'80'} height={'80'} renderAs='svg' level='L'
                                value={action.value}
                        />
                    </div>
                    <div style={{wordBreak: 'break-all', textAlign: 'left'}}
                         className={'d-flex flex-column justify-content-center pl-1'}>
                        <div
                            dangerouslySetInnerHTML={i18ntDangerHtml('common.paper_valid_to', {valid_to: Moment(game.validTo).format(dateTimeFormat)})}/>
                        <div dangerouslySetInnerHTML={i18ntDangerHtml('common.paper_game_id', {ticket_id: ticket.id})}/>
                        <div>
                            <b><span dangerouslySetInnerHTML={i18ntDangerHtml('ft.open_code_title')}/></b>
                            <span className={'px-1'}>{game.publicCode}</span>
                        </div>
                    </div>
                </Col>
            </Row>
        );
        rows.push(
            <Row key={'f2'}>
                <Col xs={12} key={'f1'} className={'px-1'}
                     style={{textAlign: 'left'}}>
                    <span dangerouslySetInnerHTML={dangerHtml(this.getFooterStatus())}/>
                </Col>
            </Row>
        );

        return rows;
    };

    makeMatrix = () => {
        let rows = [];
        const {ticket, game} = this.state;

        const colClassname = `frx-col-frx${ticket.width}-1 ft${ticket.height}-cell`;

        for (let r = 0; r < ticket.height; r++) {
            let cols = [];
            for (let c = 0; c < ticket.width; c++) {
                const pos = (r * ticket.width) + c;
                const cell = game === undefined ? {
                    letter: <>{pos + 1}</>,
                    index: undefined,
                    className: ''
                } : this.getCellInfo(game.gameOver, ticket, game.cells, pos);

                cols.push(
                    <div key={c}
                         className={cell.className + ' p-0 border border-dark text-monospace d-flex flex-column ' + colClassname}
                         onClick={() => this.onCellClick(game.cells, pos)}>
                        {cell.letter}{cell.index}
                    </div>
                );
            }
            rows.push(<Row key={r} className={'mx-1'}>{cols}</Row>);
        }

        return rows;
    };

    makeGameCostInfo = () => {
        const {game = {}} = this.state;
        const {scan, cost = {}} = game;

        if (scan === 'scan') {
            return (
                <span
                    dangerouslySetInnerHTML={i18ntDangerHtml('common.paper_cost_title', {
                        price: prizeString(cost, false, true)
                    })}/>
            );
        }
        return <>&nbsp;</>;
    };

    makePaperTitle0 = (dashed, scan, gameOver) => {
        const paper = i18n.t(gameOver === undefined || !gameOver ? 'common.e_ticket' : 'common.e_coupon');
        return (
            <Row>
                <Col className={`${dashed ? 'paper-dashed-bottom' : ''} t-blue`}>
                    <span>{i18n.t(scan !== undefined ? (scan === 'scan' ? 'common.paper_title0_scan' : (gameOver ? 'common.paper_title0_photo' : 'common.paper_title0_common')) : 'common.paper_title0_common', {paper})}</span>
                </Col>
            </Row>
        );
    };

    render() {
        const {config} = this.props;
        const {ticket, game} = this.state;

        if (ticket === undefined) {
            return <>.</>;
        }

        const {scan} = game;
        if (scan !== undefined && scan === "disable") {
            return <Redirect to={`/fti/${ticket.id}`}/>;
        }

        if (scan !== undefined) {
            return (
                <SimpleContainer BackGroundColor={'white'} Footer0={Footer0}>
                    {this.makePaperTitle0(false, scan, game.gameOver)}
                    <Row>
                        <Col className={'paper-h1 bg-white paper'}>
                            {/*======================  Header ======================*/}
                            <Row style={{position: 'relative'}}>
                                <Col className={'bg-white'}>
                                <span dangerouslySetInnerHTML={i18ntDangerHtml('ft.paper_title', {
                                    word: ticket.description,
                                    letters: ticket.description.length
                                })}/>
                                </Col>
                                {!game.gameOver ? makeQuad(1, 'black') : ''}
                                {!game.gameOver ? makeQuad(2, 'black') : ''}
                            </Row>
                            <Row>
                                <Col xs={12} key={'timer'}>
                                <span dangerouslySetInnerHTML={i18ntDangerHtml('ft.paper_title2', {
                                    prizes: this.getPaperPrizes(game.gameOver ? game.fail : 0,
                                        ticket.prizes,
                                        ticket.description.length)
                                })}/>
                                </Col>
                            </Row>
                            {/*====================== Rows ======================*/}
                            {this.makeMatrix()}
                            {/*====================== Footer ======================*/}
                            <Row style={{position: 'relative'}}>
                                <Col xs={'auto'} key={'pfl'} className={'ml-4 px-0 text-left flex-grow-1'}>
                                    {this.makeGameCostInfo()}
                                </Col>
                                <Col xs={'auto'} key={'pfr'} className={'mr-4 px-0 text-right'}>
                                <span
                                    dangerouslySetInnerHTML={i18ntDangerHtml(game.gameOver ? 'ft.paper_played_title' : 'ft.paper_issued_title', {
                                        date: Moment(game.gameOver ? game.finish : game.start).format(dateTimeFormat)
                                    })}/>
                                </Col>
                                {!game.gameOver ? makeQuad(3, 'black') : ''}
                            </Row>
                            {this.makeFooterPaper()}
                            {this.makeFooterGameOver()}
                        </Col>
                    </Row>
                    {this.makeButton()}
                    <Row>
                        <Col>
                            <Alert className={'my-2'} color={'danger'} isOpen={this.state.error !== undefined}>
                                <span dangerouslySetInnerHTML={dangerHtml(this.makeErrorText())}/>
                            </Alert>
                        </Col>
                    </Row>
                </SimpleContainer>
            );
        }

        return (
            <SimpleContainer BackGroundColor={'white'} Footer0={Footer0}>
                {this.makePaperTitle0(true, scan, game.gameOver)}
                <Row key={0} className={'info-white'}>
                    <Col className={'px-1 d-flex justify-content-center align-items-center paper'}>
                        <h5 className={'m-0 py-1'}>
                            <span className={'t-blue'}>{i18n.t('common.title_paper0', {
                                title: getTitle(ticket.adjacent, true)
                            })}</span>
                        </h5>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className={'px-1 paper'}
                         style={{textAlign: 'left'}}>
                        <span dangerouslySetInnerHTML={i18ntDangerHtml(getTagWordRule(ticket.word_rule, ticket.adjacent, false), {
                            word: ticket.description,
                            timeout: (game.validTo.getTime() - game.start.getTime()) / 1000,
                            letters: ticket.description.length,
                            max: ticket.width * ticket.height,
                            max_prize: ticket.prizes && prizeString(ticket.prizes[0], true, true)
                        })}/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} key={'fl'} className={'paper-dashed-top d-flex flex-row p-1'}>
                        {this.makeFooterTicket()}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className={'paper-dashed-bottom px-1'}
                         style={{textAlign: 'left'}}>
                        <span dangerouslySetInnerHTML={dangerHtml(this.getFooterStatus())}/>
                    </Col>
                </Row>
                <Row className={'py-1'}>
                    <Col key={4}>
                        <Button color="secondary" className={'btn-block rounded'} href={this.getGameURI(config.BackURI)}>
                            {i18n.t('common.interactive')}
                        </Button>
                    </Col>
                </Row>
            </SimpleContainer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        api: state.api,
        cache: state.cache,
        config: state.config,
    }
};

export default connect(mapStateToProps)(FindThisPaper)
