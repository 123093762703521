const initialState = {
    currentFolderId: undefined
    , wallet: {}
};

export default function app(state = initialState, action) {
    switch (action.type) {
        case 'UPDATE_WALLET':
            return {...state, wallet: action.payload};
        case 'CHANGE_FOLDER':
            return {...state, currentFolderId: action.payload};
        default:
            return state;
    }
}
