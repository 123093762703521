import React, {Component} from 'react';
import {Alert, Col, Row} from 'reactstrap';
import i18n, {dangerHtml, i18ntDangerHtml} from '../../../i18n';
import Settings from '../Settings'
import {
    getCharByIndex,
    makeQuad,
    map2Tags,
    parseRoundValue
} from '../../../components/Common/GameControl/Utils'
import MarqueeString from "../../../components/GameControl/MarqueeString";
import {
    fillCellsFromResponse,
    getCornerTagXmt,
    makeCellXmt2, makeErrorText, makeFooter,
    makeMultiplicator2, makeXmtTitle,
    prepareInfoBottomXmt
} from "../../Games/Common/FloorX";
import {Helmet} from "react-helmet";
import {Footer0} from "../../Games/Common/Utils";
import SimpleContainer from "../../Pages/SimpleContainer";

//import ReactiveQR from "reactive-qr";

class Game extends Component {
    componentWillUnmount() {
        this.stopTimer(this.timerId);
        this.timerId = undefined;
    }

    startTimer = (ticker, delta = 1000) => {
        return window.setInterval(ticker, delta);
    };

    stopTimer = (timerId) => {
        if (timerId !== undefined) {
            window.clearInterval(timerId);
        }
    };

    makeTitleInfo = () => {
        const {eog, prize, game} = this.state;

        if (eog) {
            const {reason} = game;
            if (reason === 'timeout') {
                return <span dangerouslySetInnerHTML={i18ntDangerHtml('football.title_info_timeout')}/>;
            } else {
                return <span dangerouslySetInnerHTML={i18ntDangerHtml('football.title_info_end', {prize})}/>;
            }
        } else {
            const {settings_id, type, cost} = game;
            return <span dangerouslySetInnerHTML={i18ntDangerHtml('football.title_info', {prize: Settings.getMaxPrize(settings_id, type, cost)})}/>;
        }
    };

    render() {
        const {zonesActive = false, game, eog, prize, prizeNumber, gameExpire = false} = this.state;

        if (game === undefined) {
            return (<>.</>)
        }

        const {subtype, settings_id, type} = game;
        const cnValue = zonesActive ? 't-red' : '';
        const cnTitle = zonesActive ? 't-blue' : '';
        const cnValuePrize = gameExpire ? cnValue + ' text-through' : cnValue;

        return (
            <SimpleContainer BackGroundColor={'white'} Footer0={Footer0}>
                <Helmet>
                    <title>EasyFairGames - {Settings.getGameFullTitle(settings_id, type)}</title>
                </Helmet>
                <Row>
                    <Col style={columnFullPaperStyle}>
                        {/*======================  Header ======================*/}
                        <Row className={`position-relative`}>
                            <Col xs={12} key={'timer'} className={`font-weight-bold`}>
                                {i18n.t(`penalty.title_${eog ? 'coupon' : 'ticket'}`, {
                                    "description": i18n.t('modes.' + Settings.getModeById(settings_id) + '.title')
                                    , "type": type
                                })}
                            </Col>
                            {!eog && !zonesActive ? makeQuad(1, this.quadColor()) : ''}
                            {!eog && !zonesActive ? makeQuad(2, this.quadColor()) : ''}
                        </Row>
                        <Row className={'text-center'}>
                            <Col className={'p-0 d-flex justify-content-center align-items-center font-weight-bold'}>
                                {this.makeTitleInfo()}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={2} className={`d-flex justify-content-center align-items-end paper-right-solid-h1 p-0`}>
                                <div className={`d-flex-column`}>
                                    <div className={'p-0 font-weight-bold'}>
                                        {i18n.t(subtype + '.step_title')}
                                    </div>
                                    <div>
                                        <span className={cnTitle}>#</span>
                                        <span className={cnValue}>{eog || zonesActive ? (prizeNumber === 0 ? '' : prizeNumber) : ''}</span>
                                    </div>
                                </div>
                            </Col>
                            {this.makeTitle(subtype)}
                            <Col xs={2} className={`d-flex justify-content-center align-items-end paper-left-solid-h1 p-0`}>
                                <div className={`d-flex-column`}>
                                    <div className={'p-0 font-weight-bold'}>{i18n.t('common.prize')}</div>
                                    <div>
                                        <span className={cnValuePrize}>{eog || zonesActive ? prize : ''}</span>
                                        <span className={cnTitle}>b</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        {/*====================== Rows ======================*/}
                        {this.makeRows(subtype)}
                        {/*====================== Footer ======================*/}
                        {this.makePreFooter(subtype)}
                        {makeFooter(subtype, this.state, game)}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {this.makeButtons()}
                        <Row>
                            <Col>
                                <Alert color={'danger'} isOpen={this.state.error !== undefined}>
                                    {map2Tags(makeErrorText(subtype, this.state.error), '\n', <br/>)}
                                </Alert>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Alert color={'info'} isOpen={!eog && !zonesActive} className={'p-1'}>
                                    <MarqueeString text={i18n.t(subtype + '.info_to_start')}/>
                                </Alert>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </SimpleContainer>
        )
    }

    makeTitle = (subtype) => {
        const {game, prize, prizeNumber, zonesActive} = this.state;

        switch (subtype) {
            case "football":
                return (
                    <Col xs={8} className={`d-flex-column px-0`}>
                        <div className={`paper-top-solid-h1`}>&nbsp;</div>
                        <div className={`d-flex`}>
                            <div className={`fd-gate-side paper-bottom-solid-h1 paper-right-solid-h1`}>&nbsp;</div>
                            <div className={`position-relative fd-gate-inside paper-top-solid-h1`}>
                                {this.makeKeeperZones()}
                                {this.getKeeperImage()}
                            </div>
                            <div className={`fd-gate-side paper-bottom-solid-h1 paper-left-solid-h1`}>&nbsp;</div>
                        </div>
                    </Col>);
            case "xmt":
                return makeXmtTitle(game, prize, prizeNumber, zonesActive);
            default:
                return [];
        }
    };

    getKeeperImage = () => {
        const {prize, zonesActive = false, game, eog} = this.state;
        const {type, stepsServer = []} = game;

        const t = (zonesActive && stepsServer.length > 0) || (eog && prize > 0) ? 'f' : 't';

        return <img key={4}
                    src={`${eog ? `/img/g/fb/kp_${t}.png` : `/img/g/fb/kp_w.png`}`}
                    height={this.calcKeeperSize(type)}
                    style={{
                        bottom: 0,
                        left: '50%',
                        transform: 'translate(-50%,0)',
                        position: 'absolute',
                        zIndex: 100
                    }} alt={''}/>
    };

    makeMultiplicator = (count, color) => {
        const shift = '1px', xx = 60, ww = 40;

        let qq = [];
        if (count > 0) {
            qq.push(this.drawRect(1, {bottom: shift, left: shift, x: 0, y: xx, width: ww}, color));
        }
        if (count > 1) {
            qq.push(this.drawRect(2, {bottom: shift, right: shift, x: xx, y: xx, width: ww}, color));
        }
        if (count > 2) {
            qq.push(this.drawRect(3, {top: shift, left: shift, x: 0, y: 0, width: ww}, color));
        }
        if (count > 3) {
            qq.push(this.drawRect(4, {top: shift, right: shift, x: xx, y: 0, width: ww}, color));
        }
        return qq;
    };

    drawRect = (key, geom, color) => {
        const {x, y, width, ...pos} = geom;
        return (<svg key={key} baseProfile="full" viewBox="0 0 100 100" style={{
            position: 'absolute',
            zIndex: 100,
            ...pos
        }} height="100%" xmlns="http://www.w3.org/2000/svg">
            <rect x={x + 2} y={y + 2} width={width - 3} height={width - 3} fill={'transparent'} stroke={color}
                  strokeWidth={4}/>
            <line x1={x + 1} y1={y + 1} x2={x + width - 2} y2={y + width - 2} stroke={color} strokeWidth={4}/>
            <line x1={x + 1} y1={y + width - 2} x2={x + width - 2} y2={y + 1} stroke={color} strokeWidth={4}/>
        </svg>);
    };

    makeRows = (subtype) => {
        switch (subtype) {
            case "football":
                return this.makeRowsFootball();
            case "xmt":
                const {color} = this.state;
                return color ? this.makeRowsXmt2() : this.makeRowsXmt();
            default:
                return [];
        }
    };

    makeRowsXmt2 = () => {
        const {zonesActive = false, eog, game, prize = 0} = this.state;
        const {step = -1, position = 0, stepsServer = [], prizes, zones, cells} = game;
        let rows = [], superRows = [];

        const tag_online = getCornerTagXmt(zonesActive, stepsServer, step, prize);
        const cn = 'frx-col-frx' + zones + '-1 xmt-cell ';
        const colorPrize = zonesActive ? 'red' : 'black';

        for (let idx = prizes.length; idx > 0; idx--) {
            let columns = prizes[idx - 1].column;
            let keyBase = idx * columns;
            let cols = [];

            const row = cells[idx - 1];
            const ps = parseRoundValue(row.prize / 100.0);

            let cn_info = '';
            if (position === (idx - 1)) {
                cn_info = 'xmt-info-active';
            }

            cols.push(<Col key={keyBase} xs={2} className={'xmt-info-left ' + cn_info}>{idx}</Col>);

            let active_cols = [];
            for (let c = 1; c <= columns; c++) {
                const cc = row.cols[c - 1];

                const cell = makeCellXmt2(c, cc, position === (idx - 1) && zonesActive, (c - 1) === step, row.status === 'win', 'y');
                active_cols.push(<div key={c} className={cn + cell.classname}
                                      onClick={() => this.onBtnClick(idx, c)}>{cell.content}</div>)
            }

            if (columns !== zones) {
                const cnh = "frx-col-frx" + zones + "-h" + columns;
                active_cols.push(<div key={columns + 1} className={cnh}>&nbsp;</div>);
                active_cols.unshift(<div key={0} className={cnh}>&nbsp;</div>);
            }

            cols.push(<Col key={keyBase + 1} xs={8}><Row>{active_cols}</Row></Col>);

            let rstyle = row.status === 'lost' || row.status === 'lostWin' ? {textDecoration: 'line-through'} : {};
            const mq = !eog && row.status === 'future' ? makeMultiplicator2(row.multiplicator > 1 ? row.multiplicator : 1, colorPrize) : [];
            const vps = (row.status === 'win' || row.status === 'lostWin') ? ("[" + ps + "]") : ps;

            cols.push(<Col key={keyBase + 2} xs={2} className={'xmt-info-right ' + cn_info}
                           style={{...rstyle, position: 'relative'}}>{vps}{mq}</Col>);

            if (idx === 1) {
                rows.push(<Row key={idx}
                               style={{position: 'relative'}}>{cols}{!eog && !zonesActive ? makeQuad(3, this.quadColor()) : ''}</Row>)
            } else {
                rows.push(<Row key={idx}>{cols}</Row>)
            }
        }

        superRows.push(
            <Row key={'sr'}>
                <Col xs={12} style={{position: 'relative'}}>
                    <div className={'xmt-field'}>
                        <img src={'/img/tree_white.png'} alt={''}/>
                    </div>
                    {rows}
                </Col>
            </Row>);

        superRows.push(
            <Row key={0}>
                <Col key={3} xs={12} className={'p-0 paper-top-h1'}
                     style={{...tag_online.style}}>
                    <div dangerouslySetInnerHTML={dangerHtml(prepareInfoBottomXmt(this.state, game, tag_online.info))}/>
                </Col>
            </Row>);

        return superRows;
    };

    makeRowsXmt = () => {
        const {zonesActive = false, eog, game, prize = 0} = this.state;
        const {step = -1, position = 0, stepsServer = [], prizes, zones, cells} = game;
        let rows = [];

        const tag_online = getCornerTagXmt(zonesActive, stepsServer, step, prize);
        const cn = "frx-col-frx" + zones + "-1";
        const colorPrize = zonesActive ? 'red' : 'black';
        const classPrize = zonesActive ? 'paper-diagonal-h2-t-red' : 'paper-diagonal-h2-black';

        for (let idx = prizes.length; idx > 0; idx--) {
            let columns = prizes[idx - 1].column;
            let keyBase = idx * columns;
            let cols = [];

            let styleSelect = {};
            let styleSelectIn = {};
            if (zonesActive && position === (idx - 1)) {
                styleSelect = {background: 'cyan'};
                styleSelectIn = {background: 'yellow'};
            }

            const row = cells[idx - 1];
            const ps = parseRoundValue(row.prize / 100.0);

            cols.push(<Col key={keyBase} xs={2} className={'paper-top-h1 paper-right-h1'}
                           style={{...styleSelect}}>{idx}</Col>);

            let active_cols = [];
            for (let c = 1; c <= columns; c++) {
                const cc = row.cols[c - 1];
                let baseStyle = c !== columns ? columnInnerStyle : columnInnerStyleLast;

                let cnl = cn;
                if (cc.server) {
                    cnl += ' ' + ((zonesActive && cc.player) ? 'paper-diagonal-h2-y-red xmt-player-cell' : classPrize);
                } else if (zonesActive && (cc.player || (position === (idx - 1) && (c - 1) === step && stepsServer.length === 0))) {
                    cnl += ' xmt-player-cell-b';
                }

                active_cols.push(<div key={c} className={cnl}
                                      style={{...baseStyle, ...styleSelectIn}}
                                      onClick={() => this.onBtnClick(idx, c)}>{this.makeCellXmt(c, !(eog || zonesActive), cc.player || (zonesActive && position === (idx - 1) && (c - 1) === step && stepsServer.length === 0), row.status === 'skip')}</div>)
            }

            if (columns !== zones) {
                const cnh = "frx-col-frx" + zones + "-h" + columns;
                active_cols.push(<div key={columns + 1} className={cnh + ' paper-left-h2'}>&nbsp;</div>);
                active_cols.unshift(<div key={0} className={cnh + ' paper-right-h2'}>&nbsp;</div>);
            }

            cols.push(<Col key={keyBase + 1} xs={8}><Row>{active_cols}</Row></Col>);

            let rstyle = row.status === 'lost' || row.status === 'lostWin' ? {textDecoration: 'line-through', ...columnOuterStyleRight} : columnOuterStyleRight;
            const mq = !eog && row.status === 'future' ? this.makeMultiplicator(row.multiplicator > 1 ? row.multiplicator : 1, colorPrize) : [];
            const vps = (row.status === 'win' || row.status === 'lostWin') ? ("[" + ps + "]") : ps;

            cols.push(<Col key={keyBase + 2} xs={2}
                           style={{...rstyle, ...styleSelect, position: 'relative'}}>{vps}{mq}</Col>);

            if (idx === 1) {
                rows.push(<Row key={idx}
                               style={{position: 'relative'}}>{cols}{!eog && !zonesActive ? makeQuad(3, this.quadColor()) : ''}</Row>)
            } else {
                rows.push(<Row key={idx}>{cols}</Row>)
            }
        }

        rows.push(
            <Row key={0}>
                <Col key={3} xs={12} className={'p-0 paper-top-h1 paper-dashed-bottom'} style={{...tag_online.style}}>
                    <div dangerouslySetInnerHTML={dangerHtml(prepareInfoBottomXmt(this.state, game, tag_online.info))}/>
                </Col>
            </Row>);

        return rows
    };

    makeRowsFootball = () => {
        const archivedRows = 0;// ограничетель на кол-во архивных строк
        const {zonesActive = false, eog, game, prize = 0} = this.state;
        const {reason, settings_id, step = -1, position = 0, stepsServer = [], prizes} = game;
        const superGame = Settings.getSuperGame(settings_id);
        let rows = [];
        let maxColumns = 0;

        const cells = game.cells;
        const div_xs = 12 / game.zones;

        for (let row_idx = prizes.length; row_idx > 0; row_idx--) {
            let idx;
            if (zonesActive) {
                if (position < (1 + archivedRows)) {
                    idx = row_idx;
                } else {
                    idx = row_idx + position - archivedRows;
                    if (idx > prizes.length) {
                        let active_cols = [];
                        for (let c = 1; c <= game.zones; c++) {
                            let baseStyle = c !== game.zones ? columnInnerStyle : columnInnerStyleLast;
                            active_cols.push(<Col key={c} xs={div_xs} style={baseStyle}>&nbsp;</Col>);
                        }

                        rows.push(<Row key={idx}>
                            <Col key={0} xs={2} style={columnOuterStyleLeft}>&nbsp;</Col>
                            <Col key={1} xs={8}><Row>{active_cols}</Row></Col>
                            <Col key={2} xs={2} style={columnOuterStyleRight}>&nbsp;</Col>
                        </Row>);

                        continue;
                    }
                }
            } else {
                idx = row_idx;
            }

            let columns = prizes[idx - 1].column;
            if (columns > maxColumns) {
                maxColumns = columns;
            }
            let keyBase = idx * columns;
            let cols = [];

            let styleSelect = {};
            let styleSelectIn = {};
            if (zonesActive && position === (idx - 1)) {
                styleSelect = {background: 'cyan'};
                styleSelectIn = {background: 'yellow'};
            }

            const row = cells[idx - 1];
            const ps = parseRoundValue(row.prize / 100.0);

            let ball_img;
            if ((!eog && row.status === "future" && !zonesActive) || (eog && reason === 'timeout')) {
                ball_img = <img className={`${superGame.restart ? 'fb-ball-right' : 'fb-ball-left'}`} src={`/img/g/fb/fbb.png`} alt={''}/>;
            }
            cols.push(<Col key={keyBase} xs={2} style={{...columnOuterStyleLeft, ...styleSelect}} className={''}>{idx}{ball_img}</Col>);

            let active_cols = [];
            for (let c = 1; c <= columns; c++) {
                const cc = row.cols[c - 1];
                let baseStyle = c !== columns ? columnInnerStyle : columnInnerStyleLast;
                if (cc.server) {
                    baseStyle = {...baseStyle, ...diagonalStyle};
                }

                active_cols.push(<Col key={c} xs={div_xs}
                                      style={{...baseStyle, ...styleSelectIn}}
                                      onClick={() => this.onBtnClick(idx, c)}>{this.makeCellFootball(zonesActive && position === (idx - 1) ? c : 0, !(eog || zonesActive), (eog && reason === 'timeout') ? false : (cc.player || (zonesActive && position === (idx - 1) && (c - 1) === step && stepsServer.length === 0)), row.status === 'skip')}</Col>)
            }

            cols.push(<Col key={keyBase + 1} xs={8}><Row>{active_cols}</Row></Col>);

            let rstyle = row.status === 'lost' || row.status === 'lostWin' ? {textDecoration: 'line-through', ...columnOuterStyleRight} : columnOuterStyleRight;
            cols.push(
                <Col key={keyBase + 2} xs={2} style={{...rstyle, ...styleSelect}}>
                    {(row.status === 'win' || row.status === 'lostWin') ? ("[" + ps + "]") : ps}
                </Col>);

            rows.push(<Row key={idx}>{cols}</Row>)
        }

        if (zonesActive) {
            const tag_online = this.getCornerTagFootball(stepsServer, step, prize);

            rows.push(
                <Row key={0}>
                    <Col key={3} xs={12} className={'p-0 paper-top-h1'}
                         style={{...tag_online.style}}>{tag_online.info}
                    </Col>
                </Row>);
        } else {
            let colsHeader = [];

            colsHeader.push(<Col key={0} xs={2} className={'p-0'}
                                 style={{...columnOuterStyleLeft}}>&nbsp;</Col>);

            let letter_cols = [];
            for (let c = 1; c <= maxColumns; c++) {
                let style = c !== maxColumns ? columnOuterStyleMiddle : columnOuterStyleMiddleLast;
                letter_cols.push(<Col key={c} xs={div_xs} style={style}>{getCharByIndex('A', c - 1)}</Col>)
            }

            colsHeader.push(<Col key={1} xs={8}><Row>{letter_cols}</Row></Col>);

            colsHeader.push(<Col key={3} xs={2} className={'p-0'}
                                 style={{...columnOuterStyleRight}}>&nbsp;</Col>);

            rows.push(<Row key={0} className={'paper-bottom-h1'}
                           style={{position: 'relative'}}>{colsHeader}{!eog && !zonesActive ? makeQuad(3, this.quadColor()) : ''}</Row>);
        }

        return rows
    };

    getCornerTagFootball = (stepsServer, step, prize) => {
        if (stepsServer.length > 0) {
            if (stepsServer.filter(i => i === step).length > 0) {
                return {
                    corner: i18n.t('football.short_not_goal'),
                    info: i18n.t('football.status_not_goal'),
                    style: {background: 'red', color: 'yellow'}
                };
            } else {
                return {
                    corner: i18n.t('football.short_goal'),
                    info: i18n.t('football.status_goal', {prize: prize}),
                    style: {background: '#1BC247', color: 'yellow'}
                };
            }
        } else {
            const {game} = this.state;
            const mode = Settings.getModeById(game.settings_id);
            return {
                corner: i18n.t('common.online'),
                info: i18n.t("modes." + mode + '.info')
            };
        }
    };

    quadColor = () => 'black';

    makeCellXmt = (pos, handBall, player, skip) => {
        const ballTag = <strong>O</strong>;

        if (player) {
            if (skip) {
                return (<span className={'text-through'}>O</span>);
            } else {
                return ballTag;
            }
        } else {
            return <>{getCharByIndex('A', pos - 1)}</>;
        }
    };

    makeCellFootball = (pos, handBall, player, skip) => {
        const ballTag = handBall ? 'O' : <i className="fa fa-futbol-o" aria-hidden="true"/>;

        if (player) {
            if (skip) {
                return (<>O</>);
            } else {
                return ballTag;
            }
        } else {
            if (pos > 0) {
                return <>{getCharByIndex('A', pos - 1)}</>;
            } else {
                return (<>&nbsp;</>);
            }
        }
    };

    onBtnClick(row, col) {
        const {zonesActive = false, eog, game} = this.state;
        const {stepsServer = [], position = 0} = game;

        if (!eog) {
            if (zonesActive) {
                if (stepsServer.length > 0) {
                    game.stepsServer = [];
                    game.step = -1;
                }
                if (row - 1 === position) {
                    if (game.step === col - 1) {
                        game.step = -1;
                    } else {
                        game.step = col - 1;
                    }
                    this.setState({game});
                }
            } else {
                let game = this.state.game;
                let cc = game.cells[row - 1].cols[col - 1];
                cc.player = !cc.player;

                this.setState({game});
            }
        }
    }

    onBtnTakePrize = async (fillSteps) => {
        const {game} = this.state;
        let steps;

        this.stopTimer(this.timerId);
        this.timerId = undefined;

        if (fillSteps) {
            steps = [];
            for (let r = game.cells.length; r > 0; r--) {
                const row = game.cells[r - 1];
                let step = '';
                for (let c = 0; c < row.cols.length; c++) {
                    if (row.cols[c].player) {
                        step += getCharByIndex('A', c);
                    }
                }
                if (step.length !== 0 || steps.length !== 0) {
                    steps.unshift(step);
                }
            }
        }

        const {ticket} = this.state.game;
        const {api} = this.props;

        const response = await api.auth.requestApiJson({
            request: '/api/c/ticket/check',
            json: {
                ticket,
                steps,
            }
        });

        const {error, result} = response;

        if (result !== undefined) {
            fillCellsFromResponse(game, result);

            game.stepsServer = [];

            this.setState({
                eog: true,
                zonesActive: false,
                ecoupon: !fillSteps,
                error: fillSteps ? error : undefined,
                gameExpire: undefined,
                finish: new Date(result.finish),
                prize: result.prize / 100,
                prizeNumber: result.prize_number,
                game
            });
        } else {
            this.setState({
                error: error,
            });
        }
    };

    toggle = async () => {
        const {modal = false, game} = this.state;
        if (!modal) {
            const {api} = this.props;

            const response = await api.auth.requestApiJson({
                request: '/api/c/ticket/pay',
                query: {
                    ticket: game.ticket
                }
            });

            const {status} = response;
            if (status !== undefined && status.status === 'pay') {
                game.payed = true;
            }
        }

        this.setState(prevState => ({
            modal: !prevState.modal,
            game
        }));
    };

    makeButtons = () => [];

    makePreFooter = (subtype) => [];

    calcKeeperSize = (type) => {
        if (type === 2) {
            return '90%';
        } else if (type === 3) {
            return '80%';
        } else {
            return '70%';
        }

    };

    keeperZoneLetter = (pos, stepsServer, ball) => stepsServer.length > 0 && ball === pos ?
        <i className="fa fa-futbol-o" aria-hidden="true"/> : getCharByIndex('A', pos);

    keepZoneStyle = (base, pos, stepsServer, ball, zonesActive) => {
        if (zonesActive) {
            if (stepsServer.length > 0) {
                if (stepsServer.filter(i => i === pos).length > 0) {
                    base = {...base, ...diagonalStyle};
                }
            } else if (pos === ball) {
                base = {...base, background: 'yellow'};
            }
        }
        return base;
    };

    makeKeeperZones = () => {
        const {zonesActive = false, game} = this.state;
        const {step = -1, stepsServer = [], type} = game;

        let rows = [];

        switch (type) {
            case 2:
                rows.push(
                    <Row key={1} className={'h-100 align-middle'} noGutters={true}>
                        <Col key={1} xs={6}
                             style={this.keepZoneStyle(columnKeeperStyle2Left, 0, stepsServer, step, zonesActive)}
                             className={'d-flex align-items-center justify-content-center fd-gate1'}>{this.keeperZoneLetter(0, stepsServer, zonesActive ? step : -1)}</Col>
                        <Col key={2} xs={6}
                             style={this.keepZoneStyle(columnKeeperStyle2Right, 1, stepsServer, step, zonesActive)}
                             className={'d-flex align-items-center justify-content-center fd-gate1'}>{this.keeperZoneLetter(1, stepsServer, zonesActive ? step : -1)}</Col>
                    </Row>
                );
                break;
            case 3:
                rows.push(
                    <Row key={1} className={'h-100 align-middle'} noGutters={true}>
                        <Col key={1} xs={4}
                             style={this.keepZoneStyle(columnKeeperStyle2Left, 0, stepsServer, step, zonesActive)}
                             className={'d-flex align-items-center justify-content-center'}>{this.keeperZoneLetter(0, stepsServer, zonesActive ? step : -1)}</Col>
                        <Col key={2} xs={4}
                             style={this.keepZoneStyle(columnKeeperStyle2Left, 1, stepsServer, step, zonesActive)}
                             className={'d-flex align-items-center justify-content-center'}>{this.keeperZoneLetter(1, stepsServer, zonesActive ? step : -1)}</Col>
                        <Col key={3} xs={4}
                             style={this.keepZoneStyle(columnKeeperStyle2Right, 2, stepsServer, step, zonesActive)}
                             className={'d-flex align-items-center justify-content-center'}>{this.keeperZoneLetter(2, stepsServer, zonesActive ? step : -1)}</Col>
                    </Row>
                );
                break;
            case 4:
                rows.push(
                    <Row key={1} noGutters={true}>
                        <Col key={1} xs={6} className={`fd-gate2`}
                             style={this.keepZoneStyle(columnKeeperStyleLeftTop, 0, stepsServer, step, zonesActive)}>{this.keeperZoneLetter(0, stepsServer, zonesActive ? step : -1)}</Col>
                        <Col key={4} xs={6} className={`fd-gate2`}
                             style={this.keepZoneStyle(columnKeeperStyleRightTop, 3, stepsServer, step, zonesActive)}>{this.keeperZoneLetter(3, stepsServer, zonesActive ? step : -1)}</Col>
                    </Row>
                );
                rows.push(
                    <Row key={2} noGutters={true}>
                        <Col key={2} xs={6} className={`fd-gate2`}
                             style={this.keepZoneStyle(columnKeeperStyleLeftBottom, 1, stepsServer, step, zonesActive)}>{this.keeperZoneLetter(1, stepsServer, zonesActive ? step : -1)}</Col>
                        <Col key={3} xs={6} className={`fd-gate2`}
                             style={this.keepZoneStyle(columnKeeperStyleRightBottom, 2, stepsServer, step, zonesActive)}>{this.keeperZoneLetter(2, stepsServer, zonesActive ? step : -1)}</Col>
                    </Row>
                );
                break;
            default:
                break;
        }
        return rows;
    };
}

// ==================== Style sets
const columnOuterStyleLeft = {
    borderTop: '2px solid black'
    , borderRight: '2px solid black'
};

const columnOuterStyleRight = {
    borderTop: '2px solid black'
    , borderLeft: '2px solid black'
};

const columnOuterStyleMiddle = {
    borderTop: '2px solid black'
    , borderRight: '2px solid black'
};

const columnOuterStyleMiddleLast = {
    borderTop: '2px solid black'
};

const columnInnerStyle = {
    borderTop: '1px solid gray'
    , borderRight: '1px solid gray'
};

const columnInnerStyleLast = {
    borderTop: '1px solid gray'
};

const columnFullPaperStyle = {
    border: '2px solid black'
};

const columnKeeperStyle2Left = {
    borderRight: '1px dashed gray'
};

const columnKeeperStyle2Right = {};

const columnKeeperStyleLeftTop = {
    borderRight: '1px dashed gray'
    , borderBottom: '1px dashed gray'
};

const columnKeeperStyleLeftBottom = {
    borderRight: '1px dashed gray'
};

const columnKeeperStyleRightTop = {
    borderBottom: '1px dashed gray'
};

const columnKeeperStyleRightBottom = {};

const diagonalStyle = {
    background: 'url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' version=\'1.1\' preserveAspectRatio=\'none\' viewBox=\'0 0 100 100\'><path d=\'M1 0 L0 1 L99 100 L100 99\' fill=\'black\' /><path d=\'M0 99 L99 0 L100 1 L1 100\' fill=\'black\' /></svg>")'
    , backgroundRepeat: 'no-repeat'
    , backgroundPosition: 'center center'
    , backgroundSize: '100% 100%, auto'
};

export default Game;
