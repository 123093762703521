import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from './locales/en.translation.json';
import translationRU from './locales/ru.translation.json';
import translationDE from './locales/de.translation.json';
import translationFR from './locales/fr.translation.json';
import translationIT from './locales/it.translation.json';
import onexRU from './locales/ru.onex.json';
import onexEN from './locales/en.onex.json';

// the translations
const resources = {
    en: {
        translation: translationEN,
        onex: onexEN
    }
    , ru: {
        translation: translationRU,
        onex: onexRU
    }
    , de: {
        translation: translationDE
    }
    , fr: {
        translation: translationFR
    }
    , it: {
        translation: translationIT
    }
};

i18n
// load translation using xhr -> see /public/locales
// learn more: https://github.com/i18next/i18next-xhr-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources,
        debug: false,
        fallbackLng: 'en',
        lowerCaseLng: true,
        load: 'languageOnly',
        /*lng: 'en',*/
        whitelist: ['en', 'ru', 'de', 'fr', 'it'],
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;

export const dangerHtml = (html) => {
    return {__html: html}
};

export const i18ntDangerHtml = (key, params) => {
    return {__html: i18n.t(key, params)};
};

export const i18nCurrentLanguage = () => i18n.language !== undefined ? i18n.language.split('-')[0] : undefined;
