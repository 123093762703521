import React, {Component} from "react";
import {Button} from 'reactstrap';
import classNames from 'classnames';

import {mapToCssModules, tagPropType} from "reactstrap/src/utils";
import * as PropTypes from "prop-types";

const propTypes = {
    tag: tagPropType,
    className: PropTypes.string,
    cssModule: PropTypes.object
};

const defaultProps = {
    isChangeable: true
};

export default class Selector extends Component {
    onChangeInternal = (diff) => {
        const {onChange} = this.props;
        if (onChange !== undefined) {
            onChange(diff);
        }
    };

    render() {
        const {
            className,
            cssModule,
            title,
            closeTitle,
            value,
            titleColor,
            valueSizeClass,
            onChange,
            isChangeable,
            ...attributes
        } = this.props;

        const classes = mapToCssModules(classNames(
            className,
        ), cssModule);

        let valueClass = valueSizeClass !== undefined ? valueSizeClass : "tmcWidth-1";
        let elements = [];
        if (isChangeable) {
            elements.push(<Button className={'py-0 px-1 mr-1'} key={"btn0"} onClick={() => this.onChangeInternal(-1)}>&lt;</Button>);
        }
        elements.push(<div className={`p-0 mx-0 ${valueClass}`} key={"btn1"}>{value}</div>);
        if (isChangeable) {
            elements.push(<Button className={'py-0 px-1 ml-1'} key={"btn2"} onClick={() => this.onChangeInternal(+1)}>&gt;</Button>);
        }

        return (
            <div {...attributes} className={`d-flex align-items-baseline ${classes}`}>
                {closeTitle ? <></> : <div className={`t-blue mr-1`}>{title}</div>}
                {elements}
            </div>
        )
    }
}

Selector.propTypes = propTypes;
Selector.defaultProps = defaultProps;
