import React, {Fragment, useEffect, useRef} from "react";
import {dangerHtml} from "../../../i18n";

export const map2Tags = (source, from, to) =>
    source
        .split(from)
        .map((item, key) => <Fragment key={key}>{item}{to}</Fragment>);

export const shift_index = (diff, index, arr) => {
    if (diff > 0) {
        index++;
        return index >= arr.length ? 0 : index;
    } else {
        return (index === 0 ? arr.length : index) - 1;
    }
};

export const parseRoundValue = (value) => value < 10 ? (Math.round(value * 10) / 10.0) : Math.round(value);

export const parseRoundValuePenalty = (value) => Math.floor(value);

export const formatTextMultiColor = (title, value) => <Fragment><span className={'t-red'}>{value}</span><span
    className={'t-blue'}>{title}</span></Fragment>;

export const checkAcl = (item, user) => {
    if (item === undefined) {
        return true;
    } else if (user === undefined) {
        return false;
    }

    for (let l = 0; l < item.length; l++) {
        for (let r = 0; r < user.length; r++) {
            if (item[l] === user[r]) {
                return true;
            }
        }
    }

    return false;
};

export const getCharByIndex = (first, index) => index !== undefined ? String.fromCharCode(first.charCodeAt(0) + index) : undefined;

export const getLocationOrigin = () => window.location.origin ? window.location.origin : window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');

export const isNumeric = (n) => !isNaN(parseFloat(n)) && isFinite(n);

export const makeQuad = (vp, color, height) => {
    let pos = {};
    if (vp === 1) {
        pos = {top: 0, left: 0, marginTop: '-2px', marginLeft: '-2px'};
    } else if (vp === 2) {
        pos = {top: 0, right: 0, marginTop: '-2px', marginRight: '-2px'};
    } else if (vp === 3) {
        pos = {bottom: 0, left: 0, marginBottom: '-1px', marginLeft: '-2px'};
    }

    return (<svg baseProfile="full" viewBox="0 0 100 100" style={{
        position: 'absolute',
        zIndex: 100,
        height,
        ...pos
    }} height="100%" xmlns="http://www.w3.org/2000/svg">
        <rect x="0" y="0" width="100" height="100" fill={color}/>
        <rect x="10" y="10" width="80" height="80" fill="white"/>
        <rect x="20" y="20" width="60" height="60" fill={color}/>
    </svg>);
};

export const preProcessWord = (word, color) => {
    let ws = '';
    for (let i = 0; i < word.length; ++i) {
        ws += preProcessLetterString(word[i], color);
    }
    return ws;
};

const HEARTS_LETTER = '♡';

export const preProcessLetter = (letter) => HEARTS_LETTER === letter ?
    <i className="fa fa-heart" aria-hidden="true"/> : letter;

export const preProcessLetterString = (letter, color) => (color && HEARTS_LETTER === letter) ? `<b>${letter}</b>` : letter;

export const randomString = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

export const dataDiff = (to, from) => {
    if (to === undefined || from === undefined) {
        return 0;
    }
    return to.getTime() - from.getTime();
};

export const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    // Remember the latest function.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }

        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
};

export const resultValue = (spent, income) => ({
    count: (income.count - spent.count)
    , code: spent.code
});

export const prizeValueType = (item, color, active) => {
    const {div = 1, precision = 0, count = 0, type = '', code = type, code_disable = ''} = item;
    const amount = (count / div).toFixed(precision);
    const a_value = item.count_hide ? `` : (color ? `<span class='${active ? 't-red' : 't-blue'}'>${amount}</span>` : `${amount}`);
    const a_type = (!active && item.shift)
        ? (color ? `<span class='t-blue'>${code_disable}</span>` : `${code_disable}`)
        : (color ? `<span class='${(item.count_hide && active) || item.mono ? 't-red' : 't-blue'}'>${code}</span>` : `${code}`);
    return item.type_prefix ? (a_type + a_value) : (a_value + a_type);
};

export const prizeString = (item, color, active, lost, suffix = '') => {
    const pvt = prizeValueType(item, color, active);
    return `<span class='${lost ? 'text-through' : ''}'>${pvt}${suffix}</span>`;
};

export const PrizeTag = ({className, item, color, active, lost}) => {
    return <span className={`${className} ${lost ? 'text-through' : ''}`}
                 dangerouslySetInnerHTML={dangerHtml(prizeValueType(item, color, active))}/>;
};

export const getBalance = (balance, ui_mode, color) => balance.map((item) => {
    const {count = 0, ui_hide = false/*,code = '', div = 1,  count_hide = false*/} = item;
    if (ui_hide && (ui_mode || count === 0)) {
        return ``;
    } else {
        return prizeString(item, true, true);
//        const val = count_hide && count === 1 ? `` : `<span class='t-red text-info-1'>${(count / div).toFixed(0)}</span>`;
//        return `${val}<span class='${color} text-info-1'>${code}</span>`;
    }
}).join(``);

export const processWallet = (walletSource, walletServer) => {
    let wallet = walletSource;
    wallet && wallet.forEach(wl => {
        if (walletServer !== undefined) {
            for (let s = 0; s < walletServer.length; ++s) {
                const ws = walletServer[s];
                if (wl.type === ws.type) {
                    wl.count = ws.count;
                    break;
                }
            }
        } else if (wl.count === undefined) {
            wl.count = 0;
        }
    });
    return wallet;
};
