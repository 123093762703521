import {Config} from '../../config';
import CoreApi from './CoreApi'

const {COMMON_AUTH_URL, SIG_KEY} = Config;

export class AuthApi extends CoreApi {
    constructor() {
        super();

        const token = localStorage.getItem('token');
        if (token !== null) {
            this.userInfo = JSON.parse(token);
        } else {
            this.userInfo = this.getNullUserInfo();
        }
    }

    getNickname = () => this.userInfo.nickname;

    getReferenceToken = () => this.userInfo.reference_token;

    isRefreshTokenExpired = () => {
        if (this.userInfo === undefined || this.userInfo.refresh_expire === undefined) {
            return true;
        }

        const now = new Date(), re = new Date(this.userInfo.refresh_expire);
        return re.getTime() <= now.getTime();
    };

    isAccessTokenExpired = () => {
        if (this.userInfo === undefined || this.userInfo.access_expire === undefined) {
            return true;
        }

        const now = new Date(), ae = new Date(this.userInfo.access_expire);
        return ae.getTime() <= now.getTime();
    };

    getAcl = () => {
        const {acl = []} = this.userInfo;
        return acl;
    };

    getNullUserInfo = () => ({nickname: '', acl: []});

    login = async (authParams) => {
        const response = await this.requestApi({
            api_url: COMMON_AUTH_URL,
            request: '/auth/login',
            query: {app_id: SIG_KEY, login: authParams.login},
            data: `password=${authParams.password}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });

        if (response.status === 200) {
            const userInfo = await response.json();
            this.userInfo = userInfo;
            localStorage.setItem('token', JSON.stringify(userInfo));
            return {auth: true, ...userInfo};
        } else {
            return {auth: false};
        }
    };

    logout = async () => {
        const response = await this.requestTokenApi({
            token: this.userInfo.refresh_token,
            api_url: COMMON_AUTH_URL,
            request: '/auth/logout'
        });

        const info = await response.json();

        localStorage.removeItem('token');
        this.userInfo = this.getNullUserInfo();

        return {auth: false, ...info};
    };

    requestTokenApiData = async (param, popup) => {
        return await this.requestTokenApi(param, popup)
            .then(response => response.json())
            .then(json => json.data);
    };

    requestTokenApiJson = async (param, popup) => {
        return await this.requestTokenApi(param, popup)
            .then(response => response.json());
    };

    requestTokenApi = async (params, popup, safe) => {
        const {access_token, refresh_token, token_type} = this.userInfo;
        const token = safe ? refresh_token : access_token;

        const response = await this.requestApi({
            ...params,
            headers: {
                Authorization: token_type + ' ' + token
            }
        });

        if (response.status === 200) {
            return response;
        } else if (safe) {
            // все плохо
            return response;
        } else if (response.status === 401) {
            const refresh_response = await this.requestTokenApi({
                api_url: COMMON_AUTH_URL,
                request: '/auth/refresh'
            }, popup, true);

            if (refresh_response.status === 200) {
                const userInfo = await refresh_response.json();
                const {auth = true} = userInfo;

                if (auth) {
                    this.userInfo = userInfo;
                    localStorage.setItem('token', JSON.stringify(userInfo));
                }

                return await this.requestTokenApi(params, popup, false);
            } else {
                localStorage.removeItem('token');
                this.userInfo = this.getNullUserInfo();
                if (popup !== undefined) {
                    return await popup();
                }
            }
        }
    };

    isUserAuthenticated = () => {
        const {acl = []} = this.userInfo;
        return acl.length > 0;
    };

    checkToken = async (param) => {
        return await this.requestApiJson({
            api_url: COMMON_AUTH_URL,
            request: '/auth/token',
            json: param
        });
    };

    isUserNull() {
        return !this.isUserAuthenticated();
    }

    registerUser = async (rfc) => {
        const response = await this.requestApi({
            api_url: COMMON_AUTH_URL,
            request: '/auth/register',
            query: {
                app_id: SIG_KEY
                , rfc
            }
        });

        if (response.status === 200) {
            const userInfo = await response.json();
            this.userInfo = userInfo;

            localStorage.setItem('token', JSON.stringify(userInfo));
            localStorage.setItem('hsdv', userInfo.hsdv);

            return {auth: true, ...userInfo};
        } else {
            return {auth: false};
        }
    }
}
