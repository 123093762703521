import React, {Component} from 'react';

class Compound extends Component {

    render() {
        return (
            <div>
                Compound
            </div>
        );
    }
}

export default Compound;