export const updateWallet = (wallet) => ({
    type: 'UPDATE_WALLET',
    payload: wallet
});

export const selectFolder = (folderId) => ({
    type: 'CHANGE_FOLDER',
    payload: folderId === undefined ? null : folderId
});

export const checkServerState = (lang, api, cache) => async (dispatch) => {
    if (api.auth.isUserAuthenticated()) {
        let profile_id = localStorage.getItem('profile_id');
        const profile_version = await cache.db.getProfileVersion(lang, profile_id);
        const response = await api.auth.requestTokenApiData({
            request: `/api/v2/check_server_state`
            , query: {
                lang,
                profile_version
            }
        });
        api.auth.saveServerTimestamp(response.timestamp);

        let wallet = {};
        response.coin && response.coin.forEach(item => wallet[item.type] = item.value);
        await cache.db.saveWallet(wallet);

        dispatch(
            updateWallet(wallet)
        );

        if (response.profiles !== undefined && response.profiles.length > 0) {
            const info = await cache.db.saveProfiles(lang, response.profiles);

            if (profile_id !== info.id) {
                profile_id = info.id;
                localStorage.setItem('profile_id', profile_id);
            }

            if (info.version !== profile_version) {
                const rr = await api.auth.requestTokenApiData({
                    request: '/api/v2/game_panels'
                    , query: {
                        lang,
                        profile_id
                    }
                });

                await cache.db.savePanels(profile_id, lang, rr.items);
                const folderId = await cache.db.getPanelsRoot(profile_id, lang);
                dispatch(
                    selectFolder(folderId)
                );
            }
        }
    }
};
