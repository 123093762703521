import Dexie from 'dexie'

class Cache {
    constructor() {
        this.db = new Dexie("sig2");

        this.db.version(1).stores({
            paperTicket: "&code, expire"
        });

        this.db.version(2).stores({
            paperTicket: "&code, expire",
            gameOneX: "boardId, gameId, gameInfo"
        });

        this.db.version(3).stores({
            paperTicket: "&code, expire",
            gameOneX2: "[boardId+gameId]",
            turnsOneX: "&[boardId+gameId+position], [boardId+gameId]",
            lastGameOneX: "&[boardId+mode], gameId",
            commonGames: "[boardId+gameId], [boardId+gameType]"
        });

        this.db.version(4).stores({
            paperTicket: "&code, expire",
            gameOneX2: "[boardId+gameId]",
            turnsOneX: "&[boardId+gameId+position], [boardId+gameId]",
            lastGameOneX: "&[boardId+mode], gameId",
            commonGames2: "&[boardId+gameType],[boardId+gameId] "
        });

        this.db.version(5).stores({
            wallet: "&type",
            profile: "&[id+order], id",
            panel_group: "&[profile_id+lang+parent_id+child_id],[profile_id+lang],[profile_id+lang+parent_id]",
            panel_item: "&[profile_id+lang+parent_id+child_id],[profile_id+lang+parent_id]"
        });

        this.db.version(6).stores({
            settings: "&id"
        });

        this.db.version(7).stores({
            app_versions: "&version, stable"
        });

        this.db.version(8).stores({
            app_versions: null
        });

        this.db.version(9).stores({
            panel_item: "&[profile_id+lang+parent_id+child_id],[profile_id+lang+parent_id],[profile_id+lang]"
            , profile: null
            , profile2: "&[id+lang+order], [id+lang], lang"
        });
    }

    saveSettings = async (settings) => {
        await this.db.open();
        await this.db.transaction('rw', this.db.settings, () => {
            this.db.settings.put({
                id: settings.id,
                data: settings
            });
        });
    };

    updateSettings = async (sq, api) => {
        const response = await api.auth.requestApiJson({
            request: '/api/c/settings',
            json: [
                ...sq
            ]
        });

        await this.db.open();
        await this.db.transaction('rw', this.db.settings, () => {
            response
                .forEach(item => {
                    this.db.settings.put({
                        id: item.id,
                        data: item
                    });
                });
        });
    };

    getSettings = async (id, api, version) => {
        await this.db.open();
        const settings = await this.db.settings
            .where('id')
            .equals(id)
            .first();

        if (settings !== undefined && (version === undefined || version === settings.data.version)) {
            return settings.data;
        }

        if (api !== undefined) {
            const response = await api.auth.requestApiJson({
                request: '/api/c/settings',
                query: {
                    id
                }
            });

            await this.saveSettings(response);
            return response;
        }

        return undefined;
    };

    saveWallet = async (wallet) => {
        await this.db.open();
        await this.db.transaction('rw', this.db.wallet, () => {
            this.db.wallet.clear();
            Object.keys(wallet).forEach(key => {
                this.db.wallet.put({
                    type: key,
                    value: wallet[key]
                });
            });
        });
    };

    loadWallet = async () => {
        const wa = await this.db.wallet.toCollection().toArray();
        let wallet = {};
        wa.forEach(item => wallet[item.type] = item.value);
        return wallet;
    };

    loadPanels = async (profile_id, lang, parent_id) => {
        if (profile_id === null || parent_id === undefined) {
            return undefined;
        }

        await this.db.open();
        return this.db.panel_item
            .where({profile_id, lang, parent_id})
            .toArray();
    };

    getPanelsRoot = async (profile_id, lang) => {
        if (profile_id === null) {
            return undefined;
        }
        await this.db.open();
        const first = await this.db.panel_group
            .where({profile_id, lang})
            .first();
        return first !== undefined ? first.item.child_id : undefined;
    };

    savePanels = async (profile_id, lang, panels) => {
        await this.db.open();
        await this.db.transaction('rw', this.db.panel_group, () => {
            this.db.panel_group
                .where({profile_id, lang})
                .delete()
                .then(a => console.log('delete panel_group ' + a));

            panels
                .filter(item => item.item_type === 'root' || item.item_type === 'folder')
                .forEach(item => {
                    const {parent_id = '', child_id = ''} = item;
                    this.db.panel_group.put({
                        profile_id, lang, parent_id, child_id, item
                    });
                });
        });
        await this.db.transaction('rw', this.db.panel_item, () => {
            this.db.panel_item
                .where({profile_id, lang})
                .delete()
                .then(a => console.log('delete panel_item ' + a));

            panels
                .filter(item => item.item_type === 'command' || item.item_type === 'game')
                .forEach(item => {
                    const {parent_id = '', child_id = ''} = item;
                    this.db.panel_item.put({
                        profile_id, lang, parent_id, child_id, item
                    });
                });
        });
    };

    getProfileVersion = async (lang, id) => {
        if (id === null) {
            return 0;
        }

        await this.db.open();
        const first = await this.db.profile2
            .where({id, lang})
            .first();
        return first !== undefined && first.version !== undefined ? first.version : 0;
    };

    saveProfiles = async (lang, profiles) => {
        let info = {};

        await this.db.open();
        await this.db.transaction('rw', this.db.profile2, () => {
            this.db.profile2
                .where({lang})
                .delete()
                .then(a => console.log('delete profile ' + a));

            let idx = 0;
            profiles.forEach(item => {
                if (item.default) {
                    info.id = item.profile_id;
                    info.version = item.panel_version;
                }

                this.db.profile2.put({
                    id: item.profile_id,
                    lang,
                    order: idx++,
                    version: item.panel_version,
                    profile: item
                });
            });
        });

        return info;
    };

    setPaper = async (code, expire, paper) => {
        await this.db.open();
        await this.db.transaction('rw', this.db.paperTicket, () => {
            this.db.paperTicket.put({
                code: code,
                expire: expire,
                body: paper
            });
        });
    };

    getPaper = async (code, api, url) => {
        await this.db.open();
        const paper = await this.db.paperTicket
            .where('code')
            .equals(code)
            .first();
        if (paper !== undefined) {
            return paper.body;
        }

        if (api !== undefined) {
            const response = await api.auth.requestApiJson({
                method: 'GET',
                request: url === undefined ? '/api/c/ticket/load' : url,
                query: {
                    code
                }
            });

            await this.setPaper(response.ticket, response.expire, response);
            return response;
        }

        return undefined;
    };

    setOneX = async (boardId, gameId, gameInfo) => {
        await this.db.open();
        await this.db.transaction('rw', this.db.gameOneX2, () => {
            console.log(" boardId, gameId, gameInfo = ", boardId, gameId, gameInfo);
            this.db.gameOneX2.put({
                boardId: boardId,
                gameId: gameId,
                gameInfo: gameInfo
            });
        });
    };
    getOneX = async (boardId, gameId) => {
        await this.db.open();
        const gameOneX = await this.db.gameOneX2
            .where('[boardId+gameId]')
            .equals([boardId, gameId])
            .first();
        if (gameOneX !== undefined) {
            return gameOneX.gameInfo;
        }

        return undefined;
    };
    setCommonGames = async (boardId, gameId, gameType, description, settingsId, subtypeId) => {
        await this.db.open();
        await this.db.transaction('rw', this.db.commonGames2, () => {
            this.db.commonGames2.put({
                boardId: boardId,
                gameId: gameId,
                gameType: gameType,
                description: description,
                settingsId: settingsId,
                subtypeId: subtypeId
            });
        });
    };

    getCommonGames = async (boardId, type) => {
        await this.db.open();
        return await this.db.commonGames2
            .where('[boardId+gameType]')
            .equals([boardId, type])
            .first();
    };

    setTurnsOneX = async (boardId, gameId, gameInfo) => {
        await this.db.open();
        await this.db.transaction('rw', this.db.turnsOneX, () => {
            gameInfo.forEach((item) => {
                this.db.turnsOneX.put({
                    boardId: boardId,
                    gameId: gameId,
                    position: item.position,
                    numbers: item
                });
            });
        });
    };

    getTurnsOneX = async (boardId, gameId) => {
        await this.db.open();
        return await this.db.turnsOneX
            .where('[boardId+gameId]')
            .equals([boardId, gameId])
            .toArray();
    };

    setLastGameIdOneX = async (boardId, mode, gameId, type) => {
        await this.db.open();
        await this.db.transaction('rw', this.db.lastGameOneX, () => {
            this.db.lastGameOneX.put({
                boardId: boardId,
                mode: mode,
                gameId: gameId,
                type: type
            });
        });
    };

    getLastGameOneX = async (boardId, mode) => {
        await this.db.open();
        const lastGameOneX = await this.db.lastGameOneX
            .where('[boardId+mode]')
            .equals([boardId, mode])
            .first();
        if (lastGameOneX !== undefined) {
            return lastGameOneX;
        }

        return undefined;
    };
}

const initialState = {
    db: new Cache()
};

export default function cache(state = initialState) {
    return state;
}
