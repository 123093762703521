import React, {Fragment, Component} from 'react';
import classNames from 'classnames';
import {mapToCssModules} from "reactstrap/src/utils";
import * as PropTypes from "prop-types";

const propTypes = {
    className: PropTypes.string,
    cssModule: PropTypes.object
};

export default class MarqueeString extends Component {
    render() {
        const {
            className,
            cssModule,
            text,
            dangerouslySetInnerHTML,
            ...attributes
        } = this.props;
        const classes = mapToCssModules(classNames(
            className,
        ), cssModule);

        return (
            <Fragment>
                {/*eslint-disable-next-line*/}
                <marquee {...attributes} scrollamount="2" className={classes} dangerouslySetInnerHTML={dangerouslySetInnerHTML}>{text}</marquee>
            </Fragment>
        )
    }
}

MarqueeString.propTypes = propTypes;
