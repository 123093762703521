import React, {Component} from 'react';
import {Col, Button, Row, ModalBody, ModalFooter, Modal, ModalHeader} from "reactstrap";
import i18n, {dangerHtml, i18ntDangerHtml} from "../../../i18n";
import Moment from "moment";
import {
    getLocationOrigin,
    preProcessWord,
    preProcessLetterString, prizeString, processWallet
} from "../../../components/Common/GameControl/Utils";
import QRCode from "qrcode.react";
import {Config} from "../../../config";
import {dateTimeFormat, getCheckURI} from "../Common/FloorX";
import ReactGA from "react-ga";

export const getTagWordRule = (word_rule, adjacent, onlineCoupon) =>
    word_rule !== undefined ? word_rule : onlineCoupon ? 'ft.Offer2Check-OnlineCoupon' : `ft.WordRule${adjacent === 1 ? 'PP' : adjacent === 2 ? 'P' : ''}`;

export const getTitle = (adjacent, full) => {
    const tag = adjacent === 1 ? 'modes.ft.title_pp' : adjacent === 2 ? 'modes.ft.title_p' : 'modes.ft.title';
    return i18n.t(tag + (full ? '_full' : ''));
};

class FindThis extends Component {
    state = {};

    makeErrorText = () => {
        const {ticket, error} = this.state;
        let result = i18n.t('error.title');
        if (error !== undefined) {
            const {status = '', diff = 0} = error;
            switch (status) {
                case 'interactive':
                    result += '<br/>' + i18n.t('error.interactive');
                    break;
                case 'archive':
                    result += '<br/>' + i18n.t('error.archive');
                    break;
                case 'timeout':
                    result += '<br/>' + i18n.t('error.timeout');
                    break;
                default:
                    result += '<br/>' + i18n.t('ft.error.turns', {
                        turns: ticket.description.length - diff,
                        letters: ticket.description.length
                    });
            }
        }

        return result;
    };

    makeFooterGameOver = () => {
        const {ticket, game} = this.state;
        let rows = [];

        if (game.gameOver) {
            const {ms} = game;
            const lock_zones = this.getCombinationText(ticket.description, ms, false, true, game.gameOver);

            rows.push(
                <Row key={'f3'}>
                    <Col xs={12} key={'fl'} className={'paper-dashed-top d-flex flex-row px-1 pt-1 mt-2 info-white'}>
                        <div style={{wordBreak: 'break-all', textAlign: 'left'}}
                             className={'d-flex flex-column justify-content-center pr-1 w-100'}>
                            <div dangerouslySetInnerHTML={i18ntDangerHtml('ft.secret_code')}/>
                            <div
                                dangerouslySetInnerHTML={i18ntDangerHtml('common.paper_secret_key', {secret_key: game.secretKey})}/>
                            <div dangerouslySetInnerHTML={i18ntDangerHtml('ft.lock_zones', {lock_zones})}/>
                            <div dangerouslySetInnerHTML={i18ntDangerHtml('common.paper_generate', {
                                date: Moment(game.start).format(dateTimeFormat)
                            })}/>
                        </div>
                        <div className={'h-100 d-flex justify-content-center'}
                             onClick={() => window.open(getCheckURI(game.secretCode, game.publicCode), '_blank')}>
                            <QRCode width={'80'} height={'80'} renderAs='svg' level='L'
                                    value={getCheckURI(game.secretCode, game.publicCode)}
                            />
                        </div>
                    </Col>
                </Row>
            );
            rows.push(
                <Row key={'f4'}>
                    <Col xs={12} key={'fl'} className={'px-1 info-white'} style={{textAlign: 'left'}}>
                        <div className={'d-flex flex-column justify-content-center'}>
                            <div dangerouslySetInnerHTML={i18ntDangerHtml('common.coupon_check')}/>
                        </div>
                    </Col>
                </Row>
            );
            rows.push(
                <Row key={'f5'}>
                    <Col xs={12} key={'fl'} className={'paper-dashed-bottom px-1 pb-1 info-white'}
                         style={{textAlign: 'center'}}>
                        <div className={'d-flex flex-column justify-content-center'}>
                            <div dangerouslySetInnerHTML={i18ntDangerHtml('common.coupon_check_formula')}/>
                        </div>
                    </Col>
                </Row>
            );
        }

        return rows;
    };

    getGamePayURI = () => {
        const {ticket, game = {}} = this.state;
        const {publicCode} = game;
        const origin = Config.COMMON_URL !== '' ? Config.COMMON_URL : getLocationOrigin();
        return origin + '/ftp?game_id=' + ticket.id + '&open_code=' + publicCode;
    }

    getGameURI = (BackURI) => {
        const {ticket, game = {}} = this.state;
        const {publicCode} = game;
        const origin = Config.COMMON_URL !== '' ? Config.COMMON_URL : getLocationOrigin();
        return `${origin}/fti?game_id=${ticket.id}&open_code=${publicCode}${BackURI !== undefined ? '&back=' + encodeURIComponent(BackURI) : ''}`;
    };

    getFooterStatus = () => {
        const {ticket, game} = this.state;
        if (game.gameOver) {
            const win = game.status === 'win';
            const prize = prizeString(win ? ticket.prizes[game.fail] : ticket.noPrize, true, true);

            return i18n.t(win ? 'ft.paper_status_win' : 'ft.paper_status_lost', {
                success: game.success,
                letters: ticket.description.length,
                prize
            });
        } else {
            return i18n.t(game.scan === undefined ? 'ft.paper_qr_info' : (game.scan === 'scan' ? 'ft.paper_qr_info_scan' : 'ft.paper_qr_info_promo'), {letters: ticket.description.length});
        }
    };

    prepareGameInfo = async (gameInfo, response) => {
        const {game = {}} = this.state;
        const {api, cache} = this.props;
        const {game_descriptor = '', word = '', ticket_ref, ticket, settings_id, settings_version, public_code: publicCode} = gameInfo;
        const settings = await cache.db.getSettings(settings_id, api, settings_version);
        const gameFromSettings = findGameFromSettings(settings, game_descriptor, word);

        if (gameFromSettings === undefined) {
            return {
                ticket: {
                    id: ticket
                }
            };
        }

        const {background = '#ceebe4', window, theme} = gameFromSettings;

        game.settings_id = settings_id;
        game.lockTooltipChange = false;
        game.xLimit = settings.xlimit;
        game.invite = settings.invite;
        game.window = window;
        game.theme = theme;
        game.background = background;
        game.cost = gameFromSettings.cost;
        game.start = new Date(gameInfo.create);
        game.validTo = new Date(gameInfo.expire);
        game.ms = [];
        game.cells = [];
        game.adjacent = gameFromSettings.adjacent !== undefined ? Array(gameFromSettings.height * gameFromSettings.width).fill(undefined) : [];
        game.gameOver = false;
        game.success = 0;
        game.fail = 0;
        game.publicCode = publicCode;
        game.scan = gameFromSettings.scan;
        game.secretCode = undefined;
        game.secretKey = undefined;
        game.finish = undefined;

        for (let k = 0; k < gameFromSettings.description.length; k++) {
            game.ms.push({
                open: false
                , index: -1
                , letter: undefined
            });
        }
        for (let r = 0; r < gameFromSettings.height; r++) {
            for (let c = 0; c < gameFromSettings.width; c++) {
                game.cells.push({open: false});
            }
        }

        const walletServer = response && response.wallet;
        const wallet = processWallet(gameFromSettings.wallet, walletServer);

        let all_right = true, next;
        if (response !== undefined) {
            const {status, result, adjacent} = response;

            next = await this.processNext(response.next, walletServer);

            if (status !== undefined) {
                all_right = !(status.status === "delayed" && gameFromSettings.word_rule_delay !== undefined);
                game.gameOver = status.status !== "inprogress" && status.status !== "delayed";
            }
            this.updateFullGameInfo(game, result, adjacent);
        }

        if (!game.gameOver) {
            if (this.startGame !== undefined) {
                this.startGame(game);
            }
        }

        return {
            ticket: {
                id: ticket
                , ref: ticket_ref
                , partner: settings.partner
                , description: word
                , gameDescriptor: gameFromSettings.game_descriptor
                , width: gameFromSettings.width
                , height: gameFromSettings.height
                , prizes: gameFromSettings.prizes
                , noPrize: gameFromSettings.no_prize
                , fast: gameFromSettings.fast
                , advertising: gameFromSettings.advertising
                , adjacent: gameFromSettings.adjacent !== undefined ? parseInt(gameFromSettings.adjacent) : undefined
                , adjacentHint: gameFromSettings.adjacent_hint // TODO deprecated?
                , word_rule: gameFromSettings.word_rule
                , word_rule_delay: gameFromSettings.word_rule_delay
            }
            , game
            , modalTicketCoupon: this.isEnableModalStart(game)
            , all_right
            , wallet
            , next
        };
    };

    isModeOnline = () => {
        const {game} = this.state;
        return game.xLimit !== undefined;
    };

    processNext = async (next, wallet) => {
        if (next === undefined || next.length === 0) {
            return next;
        }

        const {api, cache} = this.props;

        return await Promise.all(next.map(async (item) => {
            const settings = await cache.db.getSettings(item.settings, api, item.version);
            const gameFromSettings = findGameFromSettings(settings, item.game_descriptor);

            let bm = [];
            if (gameFromSettings.wallet !== undefined) {
                for (let accSettings of gameFromSettings.wallet) {
                    const {type, div, code, ui_hide = false, count_hide = false, count = 0} = accSettings;
                    const b = {type, div, code, ui_hide, count_hide, count};

                    if (wallet !== undefined) {
                        for (let accServer of wallet) {
                            if (accServer.type === accSettings.type) {
                                b.count = accServer.count;
                                break;
                            }
                        }
                    }

                    bm.push(b);
                }
            }

            return {
                ...item
                , adjacent: gameFromSettings.adjacent && parseInt(gameFromSettings.adjacent)
                , word: gameFromSettings.description
                , balance: bm
                , select_new_game: gameFromSettings.select_new_game
                , width: gameFromSettings.width
                , height: gameFromSettings.height
                , prizes: gameFromSettings.prizes
            };
        }));
    };

    onStartNext = (next, ticket_ref) => {
        if (next.length === 1) {
            const [n] = next;

            this.onStartDelayX({
                ticket_ref
                , settings: n.settings
                , game_descriptor: n.game_descriptor
            });
        } else {
            this.setState({
                modalStartSelect: true
            })
        }
    };

    async onStartDelayX(startInfo) {
        const {config = {}} = this.props;
        if (config['google-analytics'] !== undefined) {
            ReactGA.event({
                category: 'Game',
                action: 'start game'
            });
        }

        const {api, cache} = this.props;
        const response = await api.auth.requestTokenApiJson({
            request: '/api/c/ft/start',
            json: startInfo
        });

        await cache.db.setPaper(response.ticket, response.expire, response);
        const {wallet, adjacent} = response;
        const ng = await this.prepareGameInfo(response, {wallet, adjacent});

        let next;
        if (response.next !== undefined) {
            next = await this.processNext(response.next, wallet);
        }

        const {adv = {}} = this.state;
        adv.index = undefined;
        this.setState({
            ...ng
            , info: undefined
            , modalPay: false
            , modalStartSelect: false
            , redirectTicketId: response.ticket
            , next
            , adv
        });
    }

    makeStartSelectDialog = () => {
        const {config} = this.props;
        const {next = [], modalStartSelect = false, ticket} = this.state;
        const select_new_game = next.length > 0 && next[0].select_new_game !== undefined ? next[0].select_new_game : 'ft.select_new_game';
        const ft_games = '';

        return (
            <Modal isOpen={modalStartSelect} toggle={this.toggleStartSelect}>
                <ModalHeader toggle={this.toggleStartSelect}>{i18n.t('common.new_game_title')}</ModalHeader>
                <ModalBody>
                    <div dangerouslySetInnerHTML={i18ntDangerHtml(select_new_game, {ft_games})}/>
                    {this.isModeOnline()
                        ? <Button className={'btn rounded w-100 py-3 my-1 text-white'} color={'success'} href={'/aft'}>{i18n.t('common.main_page')}</Button>
                        : config.BackURI !== undefined
                            ? <Button className={'btn rounded w-100 py-3 my-1 text-white'} color={'success'}
                                      href={config.BackURI}>{i18n.t('common.back_page')}</Button>
                            : <></>}
                    {next && next.map((item, idx) =>
                        <div key={idx} className={'border rounded my-1 bg-primary'}>
                            <Button className={'btn w-100 py-0 my-1 text-white'} color={'link'} onClick={async () => await this.onStartDelayX({
                                settings: item.settings,
                                game_descriptor: item.game_descriptor,
                                ticket_ref: ticket.ref
                            })} dangerouslySetInnerHTML={i18ntDangerHtml('ft.word_select', {
                                title: getTitle(item.adjacent, false)
                                , word: item.word
                                , max: item.width * item.height
                                , letters: item.word.length
                            })}/>
                            <div className={'text-center'} dangerouslySetInnerHTML={i18ntDangerHtml('ft.prizes', {
                                prizes: item.prizes.map(p => prizeString(p, false, true)).join(', ')
                            })}/>
                        </div>
                    )}
                </ModalBody>
            </Modal>);
    };

    toggleStartSelect = async () => {
        this.setState(prevState => ({
                modalStartSelect: !prevState.modalStartSelect
            })
        );
    };

    isEnableModalStart = (game) => false;

    isDelayMode = (game) => game.publicCode === undefined;

    getInfoEndGame = () => {
        const {game, ticket} = this.state;
        const lastTurnSuccess = (game.fail + game.success) > 0 && game.last.letter !== undefined;

        return i18n.t(lastTurnSuccess ? 'ft.info_turn_win_end' : 'ft.info_turn_lost_end', {
            position: game.last.pos,
            prize: prizeString(game.fail < ticket.prizes.length ? ticket.prizes[game.fail] : ticket.noPrize, true, true)
        });
    };

    getInfoText = () => {
        const {info, ticket, game} = this.state;

        if (info !== undefined) {
            return info;
        }

        if (this.isDelayMode(game)) {
            return i18n.t('ft.info_delay');
        }

        if (game.gameOver) {
            if (game.status === 'timeout') {
                return i18n.t('ft.info_timeout');
            } else {
                return this.getInfoEndGame();
            }
        }

        return i18n.t('ft.info_common', {
            word: preProcessWord(ticket.description, true),
            letters: ticket.description.length,
            max: ticket.width * ticket.height
        });
    };

    getCombinationText = (word, ms, color, contrast, full) => {
        let result = '';

        for (let k = 0; k < word.length; k++) {
            const letter = preProcessLetterString(word[k], color);
            const vpos = (ms[k].open || full) ? (ms[k].pos + 1) : '#';

            if (color) {
                result += `<span class="t-red">${letter}</span><span class="t-blue"}>${vpos}</span>`;
            } else if (contrast) {
                result += `<b>${letter}</b>${vpos}`;
            } else {
                result += `${letter}${vpos}`;
            }
        }

        return result;
    };

    toggleTooltip = async () => {
        this.setState(prevState => ({
                tooltipOpen: !prevState.tooltipOpen
            })
        );
    };

    toggleStart = async () => {
        this.setState(prevState => ({
                modalTicketCoupon: !prevState.modalTicketCoupon
            })
        );
    };

    togglePayed = async () => {
        const {modalPay = false, game, ticket} = this.state;
        if (!modalPay) {
            const {api} = this.props;

            const response = await api.auth.requestApiJson({
                request: '/api/c/ft/pay',
                query: {
                    ticket: ticket.id
                }
            });

            const {status} = response;
            if (status !== undefined && status.status === 'pay') {
                game.payed = true;
            }
        }

        this.setState(prevState => ({
            modalPay: !prevState.modalPay,
            game
        }));
    };

    makeFooterTicket = () => {
        let divs = [];
        const {ticket, game} = this.state;

        divs.push(
            <div key={1} className={'h-100 d-flex align-items-start pt-1'}
                 onClick={() => window.open(this.getGameURI(), '_self')}>
                <QRCode width={'80'} height={'80'} renderAs='svg' level='L'
                        value={this.getGameURI()}
                />
            </div>
        );
        divs.push(
            <div key={2} style={{wordBreak: 'break-all', textAlign: 'left'}}
                 className={'d-flex flex-column justify-content-center pl-1'}>
                <div dangerouslySetInnerHTML={i18ntDangerHtml('common.paper_game_id', {ticket_id: ticket.id})}/>
                <div dangerouslySetInnerHTML={i18ntDangerHtml('common.paper_valid_to', {valid_to: Moment(game.validTo).format(dateTimeFormat)})}/>
                <div>
                    <b><span dangerouslySetInnerHTML={i18ntDangerHtml('ft.open_code_title')}/></b>
                    <span className={'px-1'}>{game.publicCode}</span>
                </div>
            </div>
        );
        return divs;
    };

    makeQrDialog = () => {
        const {gameExpire = false, ticket, game, modalTicketCoupon = false} = this.state;
        const {gameOver = false, ms, secretKey, cost} = game;

        const secretCode = i18n.t('ft.secret_code_raw', {
            secret_key: gameOver ? secretKey : i18n.t('ft.default_key'),
            mask: this.getCombinationText(ticket.description, ms, true, false, game.gameOver)
        });

        const span_cost = cost === undefined || cost.count < 0 ? dangerHtml('&nbsp;') : i18ntDangerHtml('common.paper_cost_title', {
            price: prizeString(cost, true, true)
        });

        let body, footer, gameResult;
        if (gameExpire) {
            body =
                <ModalBody className={'t-blue'}>
                    <div dangerouslySetInnerHTML={i18ntDangerHtml(getTagWordRule(ticket.word_rule, ticket.adjacent, this.isModeOnline()), {
                        word: preProcessWord(ticket.description, true),
                        timeout: (game.validTo.getTime() - game.start.getTime()) / 1000,
                        letters: ticket.description.length,
                        max: ticket.width * ticket.height,
                        max_prize: prizeString(ticket.prizes[0], true, true)
                    })}/>
                    <div className={'d-flex flex-row pb-1 paper-dashed-top mt-1'} onClick={this.toggleStart}>
                        <div className={'h-100 d-flex align-items-start pt-1'} onClick={() => window.open(this.getGameURI(), '_self')}>
                            <QRCode width={'80'} height={'80'} renderAs='svg' level='L' value={this.getGameURI()}
                            />
                        </div>
                        <div style={{wordBreak: 'break-all', textAlign: 'left'}} className={'d-flex flex-column justify-content-center pl-1'}>
                            <div>
                                <span dangerouslySetInnerHTML={i18ntDangerHtml('common.paper_game_id', {ticket_id: ticket.id})}/>&nbsp;<span
                                dangerouslySetInnerHTML={span_cost}/>
                            </div>
                            <div>
                                <b><span dangerouslySetInnerHTML={i18ntDangerHtml('ft.open_code_title')}/></b>
                                <span className={'px-1'}>{game.publicCode}</span>
                            </div>
                            <div dangerouslySetInnerHTML={i18ntDangerHtml('common.paper_valid_to', {
                                valid_to: Moment(game.validTo).format(dateTimeFormat)
                            })}/>
                            <div dangerouslySetInnerHTML={i18ntDangerHtml('common.paper_finish', {finish: Moment(game.validTo).format(dateTimeFormat)})}/>
                            <div dangerouslySetInnerHTML={i18ntDangerHtml('common.timeout_info')}/>
                        </div>
                    </div>
                </ModalBody>;
        } else if (gameOver) {
            const {ms} = game;
            const lock_zones = this.getCombinationText(ticket.description, ms, false, true, game.gameOver);
            const win = game.status === 'win';
            const prize = prizeString(win ? ticket.prizes[game.fail] : ticket.noPrize, true, true);
            const checkURI = getCheckURI(game.secretCode, game.publicCode);

            body =
                <ModalBody className={'t-blue'}>
                    <div dangerouslySetInnerHTML={i18ntDangerHtml(getTagWordRule(ticket.word_rule, ticket.adjacent, this.isModeOnline()), {
                        word: preProcessWord(ticket.description, true),
                        timeout: (game.validTo.getTime() - game.start.getTime()) / 1000,
                        letters: ticket.description.length,
                        max: ticket.width * ticket.height,
                        max_prize: prizeString(ticket.prizes[0], true, true)
                    })}/>
                    {this.isModeOnline()
                        ? <>
                            <div className={'d-flex flex-row my-1 paper-dashed-top'}>
                                <div className={'h-100 d-flex align-items-start pt-1'}>
                                    <QRCode width={'80'} height={'80'} renderAs='svg' level='L' value={game.publicCode}/>
                                </div>
                                <div className={'d-flex flex-column justify-content-center pl-1'}>
                                    <div className={`wordBreakAll`}>
                                        <b><span dangerouslySetInnerHTML={i18ntDangerHtml('ft.open_code_title')}/></b>
                                        <span className={'px-1'}>{game.publicCode}</span>
                                    </div>
                                </div>
                            </div>
                        </>
                        : <>
                            <div className={'d-flex flex-row paper-dashed-top mt-1'} onClick={this.toggleStart}>
                                <div className={'h-100 d-flex align-items-start pt-1'} onClick={() => window.open(this.getGameURI(), '_self')}>
                                    <QRCode width={'80'} height={'80'} renderAs='svg' level='L' value={this.getGameURI()}/>
                                </div>
                                <div className={'d-flex flex-column justify-content-center pl-1 wordBreakAll text-left'}>
                                    <div>
                                        <span dangerouslySetInnerHTML={i18ntDangerHtml('common.paper_game_id', {ticket_id: ticket.id})}/>&nbsp;<span
                                        dangerouslySetInnerHTML={span_cost}/>
                                    </div>
                                    <div>
                                        <b><span dangerouslySetInnerHTML={i18ntDangerHtml('ft.open_code_title')}/></b>
                                        <span className={'px-1'}>{game.publicCode}</span>
                                    </div>
                                    <div dangerouslySetInnerHTML={i18ntDangerHtml('common.paper_valid_to', {
                                        valid_to: Moment(game.validTo).format(dateTimeFormat)
                                    })}/>
                                    <div
                                        dangerouslySetInnerHTML={i18ntDangerHtml('common.paper_finish', {finish: Moment(game.finish).format(dateTimeFormat)})}/>
                                </div>
                            </div>
                            <div className={'mb-1'} dangerouslySetInnerHTML={i18ntDangerHtml('common.paper_prize_info', {
                                prize
                                , status: win ? i18n.t(game.payed ? 'common.prize_payed_info' : 'common.prize_notpayed_info') : ''
                            })}/>
                        </>
                    }
                    <div className={'d-flex flex-row paper-dashed-top pt-1'}>
                        <div style={{wordBreak: 'break-all', textAlign: 'left'}}
                             className={'d-flex flex-column justify-content-center pr-1 w-100'}>
                            <div dangerouslySetInnerHTML={i18ntDangerHtml('ft.secret_code')}/>
                            <div
                                dangerouslySetInnerHTML={i18ntDangerHtml('common.paper_secret_key', {secret_key: game.secretKey})}/>
                            <div dangerouslySetInnerHTML={i18ntDangerHtml('ft.lock_zones', {lock_zones})}/>
                        </div>
                        <div className={'h-100 d-flex justify-content-center'}
                             onClick={() => this.isModeOnline() ? '' : window.open(checkURI, '_blank')}>
                            <QRCode width={'80'} height={'80'} renderAs='svg' level='L' value={this.isModeOnline() ? game.secretCode : checkURI}/>
                        </div>
                    </div>
                    <div className={'text-left'} dangerouslySetInnerHTML={i18ntDangerHtml('common.paper_generate', {
                        date: Moment(game.start).format(dateTimeFormat)
                    })}/>
                    {
                        this.isModeOnline() ?
                            <div className={'d-flex flex-row mt-1'} onClick={this.toggleStart}>
                                <div className={'h-100 d-flex align-items-start pt-1'} onClick={() => window.open(checkURI, '_blank')}>
                                    <QRCode width={'80'} height={'80'} renderAs='svg' level='L' value={checkURI}/>
                                </div>
                                <div className={'d-flex flex-column justify-content-center pl-1 text-left'}>
                                    <div className={'text-left'}>
                                        <div className={'d-flex flex-column justify-content-center'}>
                                            <div dangerouslySetInnerHTML={i18ntDangerHtml(`common.coupon_check_online`, {
                                                uri: checkURI
                                            })}/>
                                        </div>
                                    </div>
                                    <div className={'pb-1'}>
                                        <div className={'d-flex flex-column justify-content-center'}>
                                            <div dangerouslySetInnerHTML={i18ntDangerHtml('common.coupon_check_formula')}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : <>
                                <div className={'text-left'}>
                                    <div className={'d-flex flex-column justify-content-center'}>
                                        <div dangerouslySetInnerHTML={i18ntDangerHtml(`common.coupon_check`, {
                                            uri: checkURI
                                        })}/>
                                    </div>
                                </div>
                                <div className={'pb-1'}>
                                    <div className={'d-flex flex-column justify-content-center'}>
                                        <div dangerouslySetInnerHTML={i18ntDangerHtml('common.coupon_check_formula')}/>
                                    </div>
                                </div>
                            </>
                    }
                </ModalBody>;
            if (this.isModeOnline()) {
                gameResult =
                    <Row>
                        <Col className={'d-flex flex-row mt-1 justify-content-between'}>
                                <span dangerouslySetInnerHTML={i18ntDangerHtml('common.paper_prize_info', {
                                    prize
                                    , status: ''
                                })}/>
                            <span dangerouslySetInnerHTML={i18ntDangerHtml('common.paper_finish', {
                                finish: game.status === 'timeout' ? i18n.t('common.result_timeout') : Moment(game.finish).format(dateTimeFormat)
                            })}/>
                        </Col>
                    </Row>;
            }

            if (game.invite) {
                footer = <Button className={'rounded'} color="primary" href={'/aft/referral'}>{i18n.t('common.referral_page')}</Button>
            }
        } else {
            body =
                <ModalBody className={'t-blue'}>
                    <div dangerouslySetInnerHTML={i18ntDangerHtml(getTagWordRule(ticket.word_rule, ticket.adjacent, false), {
                        word: preProcessWord(ticket.description, true),
                        timeout: (game.validTo.getTime() - game.start.getTime()) / 1000,
                        letters: ticket.description.length,
                        max: ticket.width * ticket.height,
                        max_prize: ticket.prizes && prizeString(ticket.prizes[0], true, true)
                    })}/>
                    <div className={'paper-dashed-top mt-1'} dangerouslySetInnerHTML={i18ntDangerHtml('ft.qr_dialog', {
                        game_id: ticket.id,
                        open_code: game.publicCode,
                        secret_code: secretCode,
                        word: preProcessWord(ticket.description, true),
                        letters: ticket.description.length
                    })}/>
                    {this.isModeOnline()
                        ? <>
                            <div className={'d-flex flex-row mt-1 paper-dashed-top'}>
                                <div className={'h-100 d-flex align-items-start pt-1'}>
                                    <QRCode width={'80'} height={'80'} renderAs='svg' level='L' value={game.publicCode}/>
                                </div>
                                <div className={'d-flex flex-column justify-content-center pl-1 wordBreakAll'}>
                                    <div>
                                        <b><span dangerouslySetInnerHTML={i18ntDangerHtml('ft.open_code_title')}/></b>
                                        <span className={'px-1'}>{game.publicCode}</span>
                                    </div>
                                </div>
                            </div>
                        </>
                        : <div className={'d-flex flex-row paper-dashed-top mt-1'} onClick={this.toggleStart}>
                            <div className={'h-100 d-flex align-items-start pt-1'} onClick={() => window.open(this.getGameURI(), '_self')}>
                                <QRCode width={'80'} height={'80'} renderAs='svg' level='L' value={this.getGameURI()}/>
                            </div>
                            <div style={{wordBreak: 'break-all', textAlign: 'left'}} className={'d-flex flex-column justify-content-center pl-1'}>
                                <div>
                                    <span dangerouslySetInnerHTML={i18ntDangerHtml('common.paper_game_id', {ticket_id: ticket.id})}/>&nbsp;<span
                                    dangerouslySetInnerHTML={span_cost}/>
                                </div>
                                <div dangerouslySetInnerHTML={i18ntDangerHtml('common.paper_valid_to', {
                                    valid_to: Moment(game.validTo).format(dateTimeFormat)
                                })}/>
                                <div>
                                    <b><span dangerouslySetInnerHTML={i18ntDangerHtml('ft.open_code_title')}/></b>
                                    <span className={'px-1'}>{game.publicCode}</span>
                                </div>
                            </div>
                        </div>}
                </ModalBody>;
        }

        return (
            <Modal key={'qrd'} isOpen={modalTicketCoupon} toggle={this.toggleStart} className={this.props.className}>
                <ModalBody className={'paper-bottom-h2 t-blue pb-1'}>
                    <Row>
                        <Col className={'position-relative text-center'}>
                            <h5 className={'my-0 '}>
                                <span className={'t-blue'}>{i18n.t(!gameOver ? 'common.e_ticket' : 'common.e_coupon')}</span>
                            </h5>
                            <div className={'position-absolute'} style={{top: 0, right: '1rem '}}>
                                <span className={`t-blue text-info-1 `} aria-hidden="true" onClick={this.toggleStart}><strong>X</strong></span>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className={'text-left pr-0'}>
                            <b>
                                <span className={'t-blue'}>{getTitle(ticket.adjacent, false)}:</span>
                                <span className={'t-red pl-1'}>{ticket.description}</span>
                            </b>
                        </Col>
                        <Col className={'text-right pl-0'}>
                            <b>
                                <span className={'t-blue'}>{i18n.t('ft.id')}</span>
                                <span className={'t-red pl-1'}>{ticket.id}</span>
                            </b>
                        </Col>
                    </Row>
                    {
                        this.isModeOnline() ?
                            <Row>
                                <Col className={'d-flex flex-row mt-1 justify-content-between'}>
                                    <span dangerouslySetInnerHTML={span_cost}/>
                                    <span dangerouslySetInnerHTML={i18ntDangerHtml('common.paper_valid_to', {
                                        valid_to: Moment(game.validTo).format(dateTimeFormat)
                                    })}/>
                                </Col>
                            </Row> :
                            <></>
                    }
                    {gameResult}
                </ModalBody>
                {body}
                <ModalFooter>
                    {footer}
                    <Button className={'rounded'} color="primary" onClick={this.toggleStart}>{i18n.t('common.close')}</Button>
                </ModalFooter>
            </Modal>
        );
    };

    getNextIndex = (current, length) => current === undefined || current >= length - 1 ? 0 : current + 1;

    getPaperPrizes = (gameFail, prizes, letters) => {
        let result = '';
        for (let i = 0; i < prizes.length; i++) {
            if (result.length !== 0) {
                result += `, `;
            }
            result += prizeString(prizes[i], false, true, gameFail > i, `@${letters - i}/${letters}`);
        }
        return result;
    };

    selectedCell = (position) => {
        const {game} = this.state;
        if (!this.isGameOver) {
            game.position = position;
            this.setState({
                selectPositionDescription: position,
                game
            });
        }
    };

    updateFullGameInfo = (game, result, adjacent0) => {
        const adjacent = adjacent0 !== undefined ? adjacent0 : result && result.adjacent;

        if (adjacent !== undefined) {
            for (const [idx, a] of Object.entries(adjacent)) {
                game.adjacent[idx] = a;
            }
        }

        if (result === undefined || result.cells === undefined) {
            return;
        }

        for (const [idx, c] of Object.entries(result.cells)) {
            let cell = game.cells[idx];
            if (cell === undefined) {
                continue;
            }

            cell.open = c.open;

            if (c.letter !== undefined) {
                cell.letter = c.index;

                let m = game.ms[c.index];
                m.open = c.open;
                m.letter = c.letter;
                m.pos = parseInt(idx);
            }
        }

        game.last = result.last;
        game.success = result.success;
        game.fail = result.fail;

        if (game.gameOver) {
            game.secretCode = result.secret_code;
            game.secretKey = result.secret_key;
            game.finish = new Date(result.finish);
            game.status = result.reason;
            game.payed = result.payed;
        }
    };
}

export default FindThis;

export const findGameFromSettings = (settings, game_descriptor, word) => {
    for (let idx = 0; idx < settings.games.length; ++idx) {
        const g = settings.games[idx];
        if ((game_descriptor === '' && g.description === word) || (game_descriptor !== '' && g.game_descriptor === game_descriptor)) {
            return g;
        }
    }
    return undefined;
};
