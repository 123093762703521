import React from "react";
import i18n from "../../i18n";
import classNames from 'classnames';

import {mapToCssModules, tagPropType} from "reactstrap/src/utils";
import * as PropTypes from "prop-types";

const propTypes = {
    tag: tagPropType,
    className: PropTypes.string,
    cssModule: PropTypes.object
};

const defaultProps = {
    tag: 'div'
};

class PrizeCount extends React.Component {
    render() {
        const {
            className,
            cssModule,
            count,
            tag: Tag,
            ...attributes
        } = this.props;
        const classes = mapToCssModules(classNames(
            className,
        ), cssModule);

        return (
            <Tag {...attributes} className={classes}>
                <span className={'textComponent'} size="md">
                    {i18n.t('common.prize') + ":"}
                </span>
                <span size="md" className={'numberComponent'}>
                    {count}
                </span>
                <img src={"/img/small_yellow_green_smile.png"} width={13} height={13} alt={"=)"}/>
            </Tag>)
    }
}

PrizeCount.propTypes = propTypes;
PrizeCount.defaultProps = defaultProps;

export default PrizeCount;