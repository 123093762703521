import {Config} from "../../config";

const {COMMON_API_URL} = Config;

export const reduceQuery = (uri, query) => {
    if (query !== undefined) {
        const queryParams = Object.keys(query)
            .filter(k => query[k] !== undefined)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(query[k]))
            .join('&');
        if (queryParams.length !== 0) {
            return uri + '?' + queryParams;
        }
    }
    return uri;
};

export default class CoreApi {
    constructor() {
        const dst = localStorage.getItem("dst");
        this.diffServerTimestamp = dst === null ? 0 : parseInt(dst);
    }

    requestApi = async (params) => {
        const {api_url = COMMON_API_URL, request, method = 'POST', headers, query, json, data} = params;
        let body;

        if (data !== undefined) {
            body = data;
        } else if (json !== undefined) {
            body = JSON.stringify(json);
        }

        return await fetch(reduceQuery(api_url + request, query), {
            method,
            headers,
            body,
            credentials: 'include'
        });
    };

    requestApiJson = async (params) => {
        return await this.requestApi(params)
            .then(response => response.json());
    };

    saveServerTimestamp = (serverTime) => {
        if (serverTime !== undefined) {
            const now = new Date();
            const st = serverTime instanceof Date ? serverTime : new Date(serverTime);
            this.diffServerTimestamp = st.getTime() - now.getTime();
            localStorage.setItem("dst", this.diffServerTimestamp);
        }
    };

    adopt2ServerTime = (timestamp) => {
        const ts = timestamp instanceof Date ? timestamp : new Date(timestamp);
        ts.setTime(ts.getTime() + this.diffServerTimestamp);
        return ts
    };

    serverTimeZone = () => "CET";
}
