import {useSelector} from "react-redux";
import SimpleContainer from "../../Pages/SimpleContainer";
import {Footer0} from "../Common/Utils";
import React, {useEffect, useState} from "react";
import i18n, {i18nCurrentLanguage, i18ntDangerHtml} from "../../../i18n";
import {Alert, Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Nav, Navbar, NavbarBrand, Row, UncontrolledDropdown} from "reactstrap";
import {Redirect} from "react-router-dom";
import Settings from "../../Football/Settings";
import Selector from "../../../components/GameControl/Selector";
import {formatTextMultiColor, parseRoundValuePenalty} from "../../../components/Common/GameControl/Utils";

const select_lang = [
    {
        title: 'En'
        , code: 'en'
        , flag: 'flag-icon-gb'
        , disable: false
        , active: true
    }
    , {
        title: 'De'
        , code: 'de'
        , flag: 'flag-icon-de'
        , disable: false
        , active: true
    }
    , {
        title: 'Fr'
        , code: 'fr'
        , flag: 'flag-icon-fr'
        , disable: false
        , active: false
    }
    , {
        title: 'It'
        , code: 'it'
        , flag: 'flag-icon-it'
        , disable: false
        , active: false
    }
    , {
        title: 'Ru'
        , code: 'ru'
        , flag: 'flag-icon-ru'
        , disable: false
        , active: true
    }
];

export const Dashboard = (props) => {
    const api = useSelector(state => state.api);
    const cache = useSelector(state => state.cache);

    const [state, setState] = useState({price_2: 3, price_3: 1, price_4: 2, price_5: 0, price_6: 0});

    // монтирование
    useEffect(() => {
        const check = sessionStorage.getItem("token");
        const accessToken = check !== null ? JSON.parse(check) : null;

        setState(s => ({...s, accessToken}));
    }, [props]);

    const makeLinks = (settings_id, type, priceIndex) => {
        const gameInfo = Settings.getGame(settings_id, type);

        const changePriceIndex = (type, priceIndex, diff) => {
            let npi = priceIndex + diff;
            if (npi < 0) {
                npi = gameInfo.price.length - 1;
            } else if (npi >= gameInfo.price.length) {
                npi = 0;
            }

            let p = {};
            p[`price_${type}`] = npi;

            setState(s => ({...s, ...p}));
        };

        return (
            <Row key={settings_id + type} noGutters={true}>
                <Col className={'border border-success mb-1 px-1'}>
                    <Row>
                        <Col className={'text-left'}>{i18n.t('modes.penalty.title')}-{gameInfo.code}</Col>
                        <Col className={'text-right'}>
                            <Button color={'link'} className={'p-0'}
                                    onClick={(e) => onTakeTicketPenalty(e, settings_id, gameInfo.id, gameInfo.price[priceIndex])}>{i18n.t('common.get_ticket')}</Button>
                        </Col>
                    </Row>
                    <Row className={'align-items-baseline mb-1'}>
                        <Col><Selector title={i18n.t('common.select_cost')}
                                       value={formatTextMultiColor('b', gameInfo.price[priceIndex] / 100)}
                                       valueClassName={'outline-primary'}
                                       onChange={(diff) => changePriceIndex(type, priceIndex, diff)}/></Col>
                        <Col
                            className={'text-right'}>{i18n.t('common.max_prize') + ': '}
                            <>{formatTextMultiColor('b', parseRoundValuePenalty(gameInfo.prize.win * gameInfo.price[priceIndex] / 100))}</>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    };

    const onTakeTicketPenalty = async (e, settings_id, subtype, price) => {
        e.preventDefault();

        const {accessToken} = state;
        const response = await api.auth.requestApiJson({
            request: '/api/c/fd/take',
            query: {
                token: accessToken.tag,
                settings_id,
                type: subtype,
                price: price,
                code: 'g'
            }
        });

        api.auth.saveServerTimestamp(response.server_time);

        await cache.db.setPaper(response.ticket, response.expire, response);

        setState({fpp: response.ticket});
    };

    if (state.fpp !== undefined) {
        return (
            <Redirect to={'/fpdg/' + state.fpp}/>
        );
    }

    const currentLanguage = i18nCurrentLanguage();

    return (
        <SimpleContainer BackGroundColor={'white'} Footer0={Footer0}>
            <Row>
                <Col>
                    <Navbar color="light" light expand="md">
                        <NavbarBrand>
                            <h4 className={'my-0'}>
                                <span key={'t1'} dangerouslySetInnerHTML={i18ntDangerHtml('app.title2')}/>
                            </h4>
                        </NavbarBrand>
                        <Nav className="ml-auto" navbar>
                            {/*{navLogout}*/}
                            <UncontrolledDropdown nav inNavbar>
                                {select_lang.filter(item => item.code === currentLanguage && item.active).map(item =>
                                    <DropdownToggle key={item.code} nav caret>
                                        <span className={'flag-icon ' + item.flag}/> {item.title}
                                    </DropdownToggle>
                                )}
                                <DropdownMenu right>
                                    {select_lang.filter(item => item.code !== currentLanguage && item.active).map(item =>
                                        <DropdownItem key={item.code} disabled={item.disable}
                                                      onClick={() => i18n.changeLanguage(item.code, () => setState({...state, lang: item.code}))}>
                                            <span className={'flag-icon ' + item.flag}/> {item.title}
                                        </DropdownItem>
                                    )}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                    </Navbar>
                </Col>
            </Row>
            <Row className={'text-left'}>
                <Col><h4>{i18n.t('modes.penalty.title')}</h4></Col>
            </Row>
            <Row className={'text-left'}>
                <Col><h5>{i18n.t('common.paper_version')}</h5></Col>
            </Row>
            <Row className={'text-left'}>
                <Col>
                    <Alert color="success" fade={false}>
                        <span dangerouslySetInnerHTML={i18ntDangerHtml('penalty.MainRule.title')}/>
                    </Alert>
                </Col>
            </Row>
            <Row className={'text-left'}>
                <Col className={'mb-1'}>
                    {i18n.t('common.title_select')}
                </Col>
            </Row>
            {makeLinks('dd73b83f-057f-46af-811c-88cb6e5da913', 2, state.price_2)}
            {makeLinks('dd73b83f-057f-46af-811c-88cb6e5da913', 3, state.price_3)}
            {makeLinks('dd73b83f-057f-46af-811c-88cb6e5da913', 4, state.price_4)}
            {makeLinks('dd73b83f-057f-46af-811c-88cb6e5da913', 5, state.price_5)}
            {makeLinks('dd73b83f-057f-46af-811c-88cb6e5da913', 6, state.price_6)}
        </SimpleContainer>
    );
};
