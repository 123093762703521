import React, {Fragment, Component} from "react";
import {
    Alert,
    Row,
    Col,
    Container,
    Navbar,
    NavbarBrand,
    /*
        NavItem,
        NavLink,
    */
    Nav,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem, CardGroup, Card, CardBody, InputGroup, Input, Button
} from 'reactstrap';
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";

import i18n, {i18nCurrentLanguage, i18ntDangerHtml} from '../../i18n';
import Settings from './Settings'
import {formatTextMultiColor, map2Tags, parseRoundValue} from '../../components/Common/GameControl/Utils'
import Selector from "../../components/GameControl/Selector";
import {shift_index} from '../../components/Common/GameControl/Utils'
import {checkVersionAuto} from "../../components/Common/GameControl/Service";
import * as configActions from '../../actions/ConfigAction'
import {bindActionCreators} from "redux";

const select_lang = [
    {
        title: 'En'
        , code: 'en'
        , flag: 'flag-icon-gb'
        , disable: false
        , active: true
    }
    , {
        title: 'De'
        , code: 'de'
        , flag: 'flag-icon-de'
        , disable: false
        , active: true
    }
    , {
        title: 'Fr'
        , code: 'fr'
        , flag: 'flag-icon-fr'
        , disable: false
        , active: false
    }
    , {
        title: 'It'
        , code: 'it'
        , flag: 'flag-icon-it'
        , disable: false
        , active: false
    }
    , {
        title: 'Ru'
        , code: 'ru'
        , flag: 'flag-icon-ru'
        , disable: false
        , active: true
    }
];

const all_games = [
    {
        "subtype": "football",
        "settings": [{
            settings_id: '7d10489d-53e7-45c6-8944-01dd5decff51'
            , types: [2, 4]
            , prices: [1, 2, 4, 8, 10]
        }, {
            settings_id: '125e354b-8c13-4866-bb60-684db1dd3a0d'
            , types: [2, 4]
            , price_multiplicator: [0.5, 1, 2, 3, 4, 5]
        }]
    },
    {
        "subtype": "xmt",
        "settings": [{
            settings_id: '3e0e58f1-592c-4293-a31c-3ee60f241e6c'
            , types: [1, 2, 3, 4]
            , prices: [1, 5, 10]
            , default_price: 10
        }, {
            settings_id: '962c639e-c45e-4247-8072-1b98cce79963'
            , types: [1, 2, 3]
            , price_multiplicator: [1, 5, 10]
            , default_price: 10
        }]
    }
];

class Dashboard extends Component {
    constructor(props) {
        super(props);

        const {api} = this.props;
        if (api.auth.isUserNull() || api.auth.isRefreshTokenExpired()) {
            api.auth.registerUser();
        }

        const {offline = false} = this.props;
        const check = sessionStorage.getItem("token");
        const accessToken = check !== null ? JSON.parse(check) : null;

        this.state = {
            offline,
            accessToken,
            subtype: undefined,
            tag: '',
            tokenValid: accessToken !== null && accessToken.tag !== undefined,
            prices: this.initPrices()
        };
    }

    componentDidMount() {
        this.props.configActions.setBackURI('/fp');
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {api} = this.props;
        checkVersionAuto(api);
    }

    initPrices = () => {
        let prices = {};
        all_games.forEach(g => {
            g.settings.forEach(s => {
                s.types.forEach(type => {
                    if (s.prices !== undefined) {
                        const ki = s.default_price !== undefined ? s.prices.indexOf(s.default_price) : 0;
                        prices = {[s.settings_id + '-' + type]: {price: s.prices[ki], index: ki}, ...prices};
                    } else {
                        const ki = s.default_price !== undefined ? s.price_multiplicator.indexOf(s.default_price) : 0;
                        const gameInfo = Settings.getGame(s.settings_id, type);
                        prices = {
                            [s.settings_id + '-' + type]: {
                                price: s.price_multiplicator[ki] * gameInfo.price[0] / 100.0,
                                index: ki
                            }, ...prices
                        };
                    }
                });
            });
        });
        return prices;
    };

    onTakeTicket = async (e, settings_id, type, price) => {
        const {offline, accessToken, subtype, paper} = this.state;
        if (!offline) {
            e.preventDefault();

            const {api, cache} = this.props;
            const response = await api.auth.requestApiJson({
                request: '/api/c/ticket/take',
                query: {
                    token: accessToken.tag,
                    settings_id,
                    type: Settings.getSubType(settings_id, type),
                    price,
                    code: (type === 2 || type === 4 ? 'r' : 'y')
                }
            });

            api.auth.saveServerTimestamp(response.server_time);

            await cache.db.setPaper(response.ticket, response.expire, response);

            if (paper && (type === 2 || subtype !== 'xmt')) {
                this.setState({fpga: response.ticket});
            } else {
                this.setState({fpg: response.ticket});
            }

        }
    };

    makeGameLinks = (subtype, settings_id, prices) => {
        return all_games
            .filter(g => g.subtype === subtype)
            .map(g => g.settings
                .filter(s => s.settings_id === settings_id)
                .map(item => {
                    return item.types.map((type, keyType) => {
                        const price = prices[item.settings_id + '-' + type].price;
                        return (
                            <Row key={keyType} noGutters={true}>
                                <Col className={'border border-success mb-1 px-1'}>
                                    <Row>
                                        <Col>{Settings.getGameFullTitle(settings_id, type)}</Col>
                                        <Col className={'text-right'}>
                                            <Button key={keyType} color={'link'} className={'p-0'}
                                                    onClick={(e) => this.onTakeTicket(e, item.settings_id, type, price * 100)}>{i18n.t('common.get_ticket')}</Button>
                                        </Col>
                                    </Row>
                                    <Row className={'align-items-baseline mb-1'}>
                                        <Col><Selector title={i18n.t('common.select_cost')}
                                                       value={formatTextMultiColor('b', price)}
                                            //                                                       valueClassName={'outline-primary'}
                                                       onChange={(diff) => this.onChange(subtype, item.settings_id, type, diff)}/></Col>
                                        <Col
                                            className={'text-right'}>{i18n.t('common.max_prize') + ': '}
                                            <Fragment>{formatTextMultiColor('b', parseRoundValue(Settings.getMaxPrize(settings_id, type, price)))}</Fragment></Col>
                                    </Row>
                                </Col>
                            </Row>);
                    });
                })
            );
    };

    onChange = (subtype, settings_id, type, diff) => {
        const g = all_games.filter(g => g.subtype === subtype)[0];
        const v = g.settings.filter(s => s.settings_id === settings_id)[0];

        const mt = settings_id + '-' + type;
        const {prices} = this.state;
        const pmt = prices[mt];

        if (v.prices !== undefined) {
            pmt['index'] = shift_index(diff, pmt['index'], v.prices);
            pmt['price'] = v.prices[pmt.index];
        } else {
            const gameInfo = Settings.getGame(settings_id, type);
            pmt['index'] = shift_index(diff, pmt['index'], v.price_multiplicator);
            pmt['price'] = v.price_multiplicator[pmt.index] * gameInfo.price[0] / 100.0;
        }
        this.setState({prices: prices});
    };

    isSelectMode = () => this.state.offline || this.state.tokenValid;

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    };

    handleSubmit = async (event) => {
        event.preventDefault();

        const {api} = this.props;
        const {tag} = this.state;

        const response = await api.auth.checkToken({token: tag});
        if (response.success) {
            const accessToken = {payload: response.payload, tag};
            sessionStorage.setItem('token', JSON.stringify(accessToken));
            this.setState({
                accessToken,
                tokenValid: true
            });
        }
    };

    onLogout = () => {
        sessionStorage.removeItem('token');
        this.setState({tokenValid: false})
    };

    onSubtypeSelect = (subtype, paper) => this.setState({subtype: subtype, paper});

    onPenaltySelect = (settings_id, paper) => this.setState({penalty: settings_id, paper});

    onAirplaneSelect = (id, paper) => this.setState({ap8: id, paper});

    makeSubtypeChoose = () => {
        let rows = [];
        const {accessToken} = this.state;

        rows.push(
            <Row key={'pv'}>
                <Col>{i18n.t('common.choice_subtype')}</Col>
            </Row>
        );

        if (accessToken.payload !== undefined) {
            accessToken.payload.acl
                .filter(a => a.subtype === 'ft')
                .forEach(g => {
                    rows.push(
                        <Row key={g.subtype}>
                            <Col>
                                <Button color={'link'} href={'/ftd'}>
                                    {i18n.t('modes.ft.title_full')}
                                </Button>
                            </Col>
                        </Row>
                    );
                });

            accessToken.payload.acl
                .filter(a => a.subtype === 'ap8')
                .forEach(g => {
                    rows.push(
                        <Row key={g.subtype}>
                            <Col>
                                <Button color={'link'} onClick={() => this.onAirplaneSelect(g.ids[0], g.paper)}>
                                    {i18n.t('ap8.title')}
                                </Button>
                            </Col>
                        </Row>
                    );
                });

            accessToken.payload.acl
                .filter(a => a.subtype === 'xmt')
                .forEach(g => {
                    rows.push(
                        <Row key={g.subtype}>
                            <Col>
                                <Button color={'link'} onClick={() => this.onSubtypeSelect(g.subtype, g.paper ?? true)}>
                                    {i18n.t('modes.xmt_zero.title')}
                                </Button>
                            </Col>
                        </Row>
                    );
                });

            accessToken.payload.acl
                .filter(a => a.subtype === 'football')
                .forEach(g => {
                    rows.push(
                        <Row key={g.subtype}>
                            <Col>
                                <Button color={'link'} onClick={() => this.onSubtypeSelect(g.subtype, g.paper ?? true)}>
                                    {i18n.t('modes.fbl_zero.title')}
                                </Button>
                            </Col>
                        </Row>
                    );
                });

            accessToken.payload.acl
                .filter(a => a.subtype === 'frx-d')
                .forEach(g => {
                    rows.push(
                        <Row key={g.subtype}>
                            <Col>
                                <Button color={'link'} onClick={() => this.onPenaltySelect(g.ids[0], g.paper)}>
                                    {i18n.t('modes.penalty.title')}
                                </Button>
                            </Col>
                        </Row>
                    );
                });
        }

        return rows;
    };

    getAllTitleForSubtype = (g) => {
        let title = '';
        g.settings.forEach(s => {
            if (title.length !== 0) {
                title += ' & ';
            }
            title += i18n.t('modes.' + Settings.getModeById(s.settings_id) + '.title');
        });
        return title;
    };

    makeGameChoose = () => {
        const {subtype, shortRules = true} = this.state;
        const g = all_games.filter(g => g.subtype === subtype)[0];

        let rows = [];
        rows.push(
            <Row key={'tt'}>
                <Col><h4>{this.getAllTitleForSubtype(g)}</h4></Col>
            </Row>
        );

        rows.push(
            <Row key={'pv'}>
                <Col><h5>{i18n.t('common.paper_version')}</h5></Col>
            </Row>
        );

        const prefixRule = subtype + (!shortRules ? '.FullMainRule' : '.MainRule');
        const enableSwitchRules = i18n.exists(prefixRule + '.toggle');
        const appendixRule = <>{i18n.exists(prefixRule + '.appendix') ? i18n.t(prefixRule + '.appendix') : ''}</>;
        const toggleRule = enableSwitchRules ? <><br/><Button color='link'
                                                              onClick={() => this.setState({shortRules: !shortRules})}>{i18n.t(prefixRule + '.toggle')}</Button></> : <>&nbsp;</>;

        rows.push(
            <Row key={'mr'}>
                <Col>
                    <Alert color="success" fade={false}>
                        {map2Tags(i18n.t(prefixRule + '.title'), '\n', <br/>)}
                        <ul className={'mb-0'}>
                            {i18n.t(prefixRule + '.list', {returnObjects: true}).map((item, key) => {
                                return <li key={key}>{item}</li>
                            })}
                        </ul>
                        {appendixRule}
                        {toggleRule}
                    </Alert>
                </Col>
            </Row>
        );

        rows.push(
            <Row key={'ts'}>
                <Col className={'mb-1'}>
                    {i18n.t('common.title_select')}
                </Col>
            </Row>
        );

        all_games
            .filter(g => g.subtype === subtype)
            .forEach(g => g.settings.forEach(s => {
                    rows.push(
                        <Row key={s.settings_id} className={'mb-2'}>
                            <Col>
                                {this.makeGameLinks(subtype, s.settings_id, this.state.prices)}
                            </Col>
                        </Row>
                    );
                })
            );

        rows.push(
            <Row key={'tb'}>
                <Col className={'mb-1'}>
                    <Button color={'link'}
                            onClick={() => this.onSubtypeSelect(undefined)}>{i18n.t('common.back_to_select_subtype')}</Button>
                </Col>
            </Row>
        );

        return rows;
    };

    render() {
        const {fpg, fpga, subtype, penalty, ap8, paper = true} = this.state;

        if (ap8 !== undefined) {
            return (
                <Redirect to={`/b/${ap8}`}/>
            );
        } else if (fpga !== undefined) {
            return (
                <Redirect to={'/fpga/' + fpga}/>
            );
        } else if (fpg !== undefined) {
            return (
                <Redirect to={'/fpg/' + fpg}/>
            );
        } else if (penalty !== undefined) {
            if (paper === false) {
                return <Redirect to={`/b/${penalty}?back=${encodeURIComponent('/fp')}`}/>;
            } else {
                return (
                    <Redirect to={'/fpdd/'}/>
                );
            }
        } else {
            /*
            let navLogout = [];
            if (!offline) {
                navLogout.push(
                    <NavItem key={'nil'}>
                        <NavLink href="#" onClick={() => this.onLogout()}>Logout</NavLink>
                    </NavItem>
                );
            }
            */
            const title = <span key={'t1'} dangerouslySetInnerHTML={i18ntDangerHtml('app.title2')}/>;
            const currentLanguage = i18nCurrentLanguage();

            return this.isSelectMode() ? (
                <Container fluid={true}>
                    <Row>
                        <Col>
                            <Navbar color="light" light expand="md">
                                <NavbarBrand>
                                    <h4 className={'my-0'}>
                                        {title}
                                    </h4>
                                </NavbarBrand>
                                <Nav className="ml-auto" navbar>
                                    {/*{navLogout}*/}
                                    <UncontrolledDropdown nav inNavbar>
                                        {select_lang.filter(item => item.code === currentLanguage && item.active).map(item =>
                                            <DropdownToggle key={item.code} nav caret>
                                                <span className={'flag-icon ' + item.flag}/> {item.title}
                                            </DropdownToggle>
                                        )}
                                        <DropdownMenu right>
                                            {select_lang.filter(item => item.code !== currentLanguage && item.active).map(item =>
                                                <DropdownItem key={item.code} disabled={item.disable}
                                                              onClick={() => i18n.changeLanguage(item.code, () => {
                                                                  this.setState({newLang: item.code})
                                                              })}>
                                                    <span className={'flag-icon ' + item.flag}/> {item.title}
                                                </DropdownItem>
                                            )}
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </Nav>
                            </Navbar>
                        </Col>
                    </Row>
                    {/*======================  Content ======================*/}
                    {subtype === undefined ? this.makeSubtypeChoose() : this.makeGameChoose()}
                    <Row className={'justify-content-center'}>
                        <Col xs={'auto'} className={'px-1'}>
                                    <span className="ml-auto"
                                          dangerouslySetInnerHTML={i18ntDangerHtml('app.copyright')}/>
                        </Col>
                    </Row>
                </Container>
            ) : (
                <Container fluid={true}>
                    <Row className="justify-content-center">
                        <Col md="8">
                            <CardGroup className="mb-4">
                                <Card className="p-4">
                                    <CardBody>
                                        <h1>Promo Token</h1>
                                        <p className="text-muted">Enter promo token</p>
                                        <InputGroup className="mb-4">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                  <i className="icon-lock"/>
                                                </span>
                                            </div>
                                            <Input type="password" placeholder="Token" name="tag"
                                                   value={this.state.tag} onChange={this.handleChange}/>
                                        </InputGroup>
                                        <Row>
                                            <Col xs="6">
                                                <Button color="primary" className="px-4"
                                                        onClick={this.handleSubmit}>Check</Button>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </CardGroup>
                        </Col>
                    </Row>
                </Container>
            )
        }
    }
}

const mapDispatchToProps = dispatch => ({
    configActions: bindActionCreators(configActions, dispatch)
});

const mapStateToProps = (state) => {
    return {
        api: state.api,
        cache: state.cache,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
