import React from "react";
import {Col, Container, Row} from "reactstrap";
import {i18ntDangerHtml} from "../../../i18n";
import classNames from 'classnames';
import {mapToCssModules} from "reactstrap/src/utils";

export default (props) => {
    const {
        className,
        cssModule,
        zeroCol
    } = props;
    const classes = mapToCssModules(classNames(
        'smooth-container',
        className,
    ), cssModule);

    return (
        <Container className={classes} fluid={true} style={{textAlign: 'center'}}>
            <Row className={"justify-content-center"}>
                <Col xs={12} sm={10} md={8} lg={6} xl={4} className={`${zeroCol ? 'px-0' : ''}`}
                     style={{backgroundColor: props.BackGroundColor}}>
                    {props.children}
                    {props.Partner !== undefined ?
                        <Row>
                            <Col className={'px-1 info-white'}>
                                    <span className="ml-auto"
                                          dangerouslySetInnerHTML={i18ntDangerHtml('app.licensed', {partner: props.Partner})}/>
                            </Col>
                        </Row> :
                        <></>
                    }
                    <Row>
                        <Col className={'px-1 info-white'}>
                                    <span className="ml-auto"
                                          dangerouslySetInnerHTML={i18ntDangerHtml('app.copyright')}/>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {props.Footer0}
        </Container>
    )
}
