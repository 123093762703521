import React, {Component} from 'react';
import {
    Container,
    Row,
    Col,
    CardGroup,
    Card,
    CardBody,
    Button,
    Input,
    InputGroup,
    Alert
} from 'reactstrap';
import {connect} from "react-redux";
import * as queryString from "query-string";

export class Login extends Component {
    constructor(props, context) {
        super(props, context);

        let parsed = queryString.parse(this.props.location.search);
        const {back} = parsed;

        this.state = {
            loggingIn: false,
            login: '',
            password: '',
            back
        };
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    };

    handleSubmit = async (event) => {
        event.preventDefault();

        const {api, history} = this.props;
        const {login, password, back = '/'} = this.state;

        if (login.length === 0 || password.length === 0) {
            return this.setState({
                errorCode: 'missingUsernameOrPassword'
            });
        }

        this.setState({
            loggingIn: true,
            errorCode: null
        });

        const response = await api.auth.login({login, password});
        if (response.auth) {
            history.push(back);
        }
    };

    renderLoginError() {
        if (!this.state.error) return null;

        let message = null;
        switch (this.state.error.code) {
            case 'invalidCredentials':
                message = 'Invalid credentials';
                break;
            case 'missingUsernameOrPassword':
                message = 'Please enter your username and password';
                break;
            default:
                message = 'Unknown sign-in error';
                break;
        }

        return <Alert type="danger"><strong>{message}</strong></Alert>;
    }

    render() {
        return (
            <div className="app flex-row align-items-center">
                <Container>
                    {this.renderLoginError()}
                    <Row className="justify-content-center">
                        <Col md="8">
                            <CardGroup className="mb-4">
                                <Card className="p-4">
                                    <CardBody>
                                        <h1>Login</h1>
                                        <p className="text-muted">Sign In to your account</p>
                                        <InputGroup className="mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                  <i className="icon-user"/>
                                                </span>
                                            </div>
                                            <Input type="text" placeholder="Username" name="login"
                                                   value={this.state.login} onChange={this.handleChange}/>
                                        </InputGroup>
                                        <InputGroup className="mb-4">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                  <i className="icon-lock"/>
                                                </span>
                                            </div>
                                            <Input type="password" placeholder="Password" name="password"
                                                   value={this.state.password} onChange={this.handleChange}/>
                                        </InputGroup>
                                        <Row>
                                            <Col xs="6">
                                                <Button color="primary" className="px-4"
                                                        onClick={this.handleSubmit}>Login</Button>
                                            </Col>
                                            <Col xs="6" className="text-right">
                                                {/*<Button color="link" className="px-0">Forgot password?</Button>*/}
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                {/*
                                <Card className="text-white bg-primary py-5 d-md-down-none" style={{width: 44 + '%'}}>
                                    <CardBody className="text-center">
                                        <div>
                                            <h2>Sign up</h2>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                                tempor incididunt ut
                                                labore et dolore magna aliqua.</p>
                                            <Button color="primary" className="mt-3" active>Register Now!</Button>
                                        </div>
                                    </CardBody>
                                </Card>
*/}
                            </CardGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        api: state.api,
    }
};

export default connect(mapStateToProps)(Login)