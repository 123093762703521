import React, {useCallback, useEffect, useState} from "react";
import {connect} from "react-redux";
import {Bar} from "react-chartjs-2";

const options = {
    responsive: true,
    tooltips: {
        mode: 'label'
    },
    elements: {
        line: {
            fill: false
        }
    },
    scales: {
        xAxes: [
            {
                display: true,
                gridLines: {
                    display: false
                }
            }
        ],
        yAxes: [
            {
                type: 'linear',
                display: true,
                position: 'right',
                id: 'y-axis-g',
                gridLines: {
                    display: true
                },
                labels: {
                    show: true
                },
                ticks: {
                    min: 0
                }
            }, {
                type: 'linear',
                display: true,
                position: 'left',
                id: 'y-axis-p',
                gridLines: {
                    display: false
                },
                labels: {
                    show: true
                },
                ticks: {
                    min: 0
                }
            }
        ]
    }
};

const StatsGamePlayer = (props) => {
    const [dataBar, setDataBar] = useState({});

    const onRefresh = useCallback(() => {
        const {api} = props;
        api.auth.requestTokenApiJson({
            request: '/api/c/ft/stats/gp'
            , query: {
                board_id: props.BoardId
            }
        }).then((response) => {
            const data = {
                labels: [],
                datasets: [{
                    data: [],
                    label: 'Players',
                    type: 'line',
                    fill: false,
                    borderColor: '#EC932F',
                    backgroundColor: '#EC932F',
                    pointBorderColor: '#EC932F',
                    pointBackgroundColor: '#EC932F',
                    pointHoverBackgroundColor: '#EC932F',
                    pointHoverBorderColor: '#EC932F',
                    yAxisID: 'y-axis-p'
                }, {
                    data: [],
                    type: 'bar',
                    label: 'Games',
                    fill: false,
                    backgroundColor: '#71B37C',
                    borderColor: '#71B37C',
                    hoverBackgroundColor: '#71B37C',
                    hoverBorderColor: '#71B37C',
                    yAxisID: 'y-axis-g'
                }]
            };

            response && response.forEach((item) => {
                data.labels.unshift(item.date);
                data.datasets[0].data.unshift(item.players);
                data.datasets[1].data.unshift(item.games);
            });

            setDataBar(data);
        });
    }, [props]);

    // монтирование
    useEffect(() => {
        onRefresh();
    }, [props, onRefresh]);

    return (<Bar
        data={dataBar}
        width={100}
        height={50}
        options={options}
        onElementsClick={props.onClick}
    />);
};

const mapStateToProps = (state) => {
    return {
        api: state.api,
        cache: state.cache,
    }
};

export default connect(mapStateToProps)(StatsGamePlayer)
