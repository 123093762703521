import React, {useEffect} from "react";
import ReactGA from "react-ga";
import {useSelector} from "react-redux";

export const withTracker = (WrappedComponent, pageTrack) => {
    const trackPage = (page, googleAnalytics) => {
        if (googleAnalytics !== undefined) {
            const {options} = googleAnalytics;
            ReactGA.set({
                page,
                ...options
            });
            ReactGA.pageview(page);
        }
    };

    return props => {
        const config = useSelector(state => state.config);
        const googleAnalytics = config['google-analytics'];

        useEffect(() => trackPage(pageTrack !== undefined ? pageTrack : props.location.pathname, googleAnalytics), [
            props.location.pathname, googleAnalytics
        ]);

        return <WrappedComponent {...props} />;
    };
};
