import React from 'react';
import {mapToCssModules} from "reactstrap/lib/utils";
import classNames from "classnames";

export const TouchPanel = (props) => {
    const {
        className,
        cssModule,
    } = props;

    const classes = mapToCssModules(classNames(
        'shadow'
        , props.Compact ? 'sig-panel-mini' : 'sig-panel'
        , className
    ), cssModule);

    const onClick = (e) => {
        e.preventDefault();

        const onAction = (e) => {
            e.currentTarget.removeEventListener('animationend', onAction);
            e.currentTarget.classList.remove('selected');

            props.onClick && props.onClick(e);
        };

        e.currentTarget.addEventListener('animationend', onAction, false);
        e.currentTarget.classList.add('selected');
    };

    return (
        <div className={classes} onClick={onClick}>
            {props.children}
        </div>
    );
};
