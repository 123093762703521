import React, {useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Doughnut, Pie, Polar, Radar} from "react-chartjs-2";
import {i18ntDangerHtml} from "../../../../i18n";

export const StatsGameAdjacent = (props) => {
    const api = useSelector(state => state.api);
    const [dataBar, setDataBar] = useState({});

    const onRefresh = useCallback(() => {
        api.auth.requestTokenApiJson({
            request: '/api/c/ft/stats/ga'
            , query: {
                board_id: props.BoardId
            }
        }).then((response) => {
            const data = {
                datasets: [{
                    data: [],
                    backgroundColor: [
                        '#FF6384',
                        '#4BC0C0',
                        '#FFCE56',
                        '#E7E9ED',
                        '#36A2EB',
                        '#D936EB',
                    ],
                    label: 'Games' // for legend
                }],
                labels: []
                , total: 0
            };
            response && response.forEach((item) => {
                const prepareWord = (adjacent, description) => {
                    switch (adjacent) {
                        case '2':
                            return `F+: ${description}`;
                        case '1':
                            return `F++: ${description}`;
                        default:
                            return `F: ${description}`;
                    }
                };
                data.labels.push(prepareWord(item.adjacent, item.description));
                data.datasets[0].data.push(item.games);
                data.total += item.games;
            });
            setDataBar(data);
        });
    }, [props, api.auth]);

    // монтирование
    useEffect(() => {
        onRefresh();
    }, [props, onRefresh]);

    const options = {
        legend: {
            position: 'left'
            , display: dataBar.datasets && dataBar.datasets.length > 0 && dataBar.datasets[0].data.length < 10
        }
    };

    let graph;
    switch (props.Type) {
        case 'pie':
            graph = <Pie data={dataBar} options={options}/>;
            break;
        case 'polar':
            graph = <Polar data={dataBar} options={options}/>;
            break;
        case 'doughnut':
            graph = <Doughnut data={dataBar} options={options}/>;
            break;
        default:
            graph = <Radar data={dataBar} options={options}/>;
    }

    if (props.TitleTag !== undefined) {
        return (
            <>
                <div className={'text-left t-blue text-info-1'} dangerouslySetInnerHTML={i18ntDangerHtml(props.TitleTag, {
                    count: dataBar.total
                })}/>
                {graph}
            </>
        );
    } else {
        return graph;
    }
};
