const nav = {
    items: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'icon-speedometer'
        },
        {
            title: true,
            name: 'Game labs',
            wrapper: {
                element: 'span',
                attributes: {}
            },
            class: 'text-center',
            acl: ['l']
        }, {
            name: 'Onex',
            acl: ['l'],
            children: [
                {
                    name: 'OneZero',
                    url: '/onex/zero',
                },
                {
                    name: 'OneOne',
                    url: '/onex/one',
                },
                {
                    name: 'OneMax',
                    url: '/onex/max',
                },
                {
                    name: 'OneMini',
                    url: '/onex/mini',
                }, {
                    name: 'OneZero 2',
                    url: '/onex2/zero',
                }
            ]
        }, {
            name: 'OneS',
            acl: ['l'],
            children: [
                {
                    name: 'OneS',
                    url: '/ftd',
                }
            ]
        }, {
            name: 'AdExample',
            acl: ['l'],
            children: [
                {
                    name: 'AdExample',
                    url: '/adexample',
                }
            ]
        },
        {
            name: 'Football',
            acl: ['l'],
            children: [
                {
                    name: 'Paper Online',
                    url: '/fp'
                },
                {
                    name: 'Paper Stats',
                    url: '/fp/stats'
                },
            ]
        },
        {
            title: true,
            name: 'Admin',
            wrapper: {
                element: 'span',
                attributes: {}
            },
            class: 'text-center',
            acl: ['ad']
        },
        {
            name: 'Accounts',
            url: '/admin/accounts',
            acl: ['ad']
        },

        {
            name: 'Profile',
            url: '/admin/profile',
            acl: ['ad']
        },
        {
            title: true,
            name: 'Additional',
            wrapper: {
                element: 'span',
                attributes: {}
            },
            class: 'text-center',
            acl: ['l']
        }, {
            name: 'News',
            acl: ['l'],
            children: [
                {
                    name: 'ListNews',
                    url: '/news/list',

                }

            ]
        },
        {
            title: true,
            name:
                'I18N',
            wrapper:
                {
                    element: 'span',
                    attributes:
                        {}
                }
            ,
            class:
                'text-center',
            acl: ['t']
        },
        {
            name: 'Translate',
            acl: ['t'],
            children: [
                {
                    name: 'Info',
                    url: '/translate/info'
                },
                {
                    name: 'Tags',
                    url: '/translate/tags'
                },
                {
                    name: 'Compound',
                    url: '/translate/compound'
                },
                {
                    name: 'Languages',
                    url: '/translate/lang'
                }
            ]
        }
    ]
};

export default nav;
