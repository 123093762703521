import {AuthApi} from "../modules/api/AuthApi";
import WebSocketApi from "../modules/api/WebSocketApi";

const initialState = {
    auth: new AuthApi()
    , wss: new WebSocketApi()
};

export default function api(state = initialState, action) {
    switch (action.type) {
        default:
            return state;
    }
}
