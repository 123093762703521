import React from "react";
import {connect} from "react-redux";
import FindThis, {getTitle} from "./FindThis";
import SimpleContainer from "../../Pages/SimpleContainer";
import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Tooltip} from "reactstrap";
import i18n, {dangerHtml, i18ntDangerHtml} from "../../../i18n";
import {
    dataDiff, getBalance,
    preProcessLetter,
    preProcessWord, prizeString, PrizeTag, prizeValueType, processWallet, resultValue
} from "../../../components/Common/GameControl/Utils";
import {AdvertisingList} from "./Settings";
import * as queryString from "query-string";
import {getCheckURI} from "../Common/FloorX";
import {Link, Redirect} from "react-router-dom";
import ReactGA from 'react-ga';
import {bindActionCreators} from "redux";
import * as configActions from "../../../actions/ConfigAction";

const adTime = 8;
const timeToWaitAd = adTime * 2;

class FindThisInteractive extends FindThis {
    constructor(props) {
        super(props);

        const check = sessionStorage.getItem("token");
        const accessToken = check !== null ? JSON.parse(check) : null;

        this.state = {
            all_right: true,
            accessToken,
            tokenValid: accessToken !== null && accessToken.tag !== undefined,
        };
    }

    async starting(code, altBackground) {
        const {api, cache} = this.props;
        const gameInfo = await cache.db.getPaper(code, api, '/api/c/ft/load');
        const response = await api.auth.requestTokenApiJson({
            request: '/api/c/ft/status',
            query: {
                ticket: gameInfo.ticket
            }
        });
        const tg = await this.prepareGameInfo(gameInfo, response);
        if (altBackground !== undefined) {
            tg.game.background = `#${altBackground}`;
        }
        this.setState({
            ...tg
        });
    }

    componentDidMount() {
        let altBackground;
        let {ticket: code} = this.props.match.params;
        let parsed = queryString.parse(this.props.location.search);
        const {background, game_id: code1, ticket: code2, back} = parsed;

        if (back !== undefined) {
            this.props.configActions.setBackURI(back);
        }

        altBackground = background;

        if (code === undefined) {
            code = code2 !== undefined ? code2 : code1;
        }

        this.starting(code, altBackground);
    }

    startGame = (game) => {
        if (this.isDelayMode(game)) {
            return;
        }

        this.timerId = this.startTimer(() => {
            const {api, cache} = this.props;
            const {game, ticket} = this.state;
            const serverTime = api.auth.adopt2ServerTime(new Date());
            const gameExpire = game.validTo < serverTime;

            if (gameExpire) {
                if (this.isModeOnline()) {
                    api.auth.requestTokenApiJson({
                        request: '/api/c/ft/status',
                        query: {
                            ticket: ticket.id
                        }
                    }).then(response => {
                        if (response.status.game_over) {
                            this.stopTimer(this.timerId);
                            this.timerId = undefined;

                            cache.db.getPaper(ticket.id, api, '/api/c/ft/load').then(gameInfo => {
                                this.prepareGameInfo(gameInfo, response)
                                    .then(tg => {
                                        this.setState({
                                            ...tg
                                            , modalTicketCoupon: true
                                            , info: i18n.t('ft.info_timeout')
                                        });
                                    });
                            });
                        }
                    });
                } else {
                    this.stopTimer(this.timerId);
                    this.timerId = undefined;

                    this.setState({
                        gameExpire,
                        serverTime,
                        info: i18n.t('ft.info_timeout')
                        , game
                    });
                }
            } else {
                this.setState({
                    gameExpire,
                    serverTime
                });
            }
        }, 1000);
    };

    isAdjacent = () => {
        const {ticket} = this.state;
        return ticket.adjacent === 1 || ticket.adjacent === 2;
    };

    getCellInfo = (gameOver, ticket, cells, adjacent, adjacentHint, pos, selected) => {
        const getCellValue = () => {
            const pl = cells[pos].letter === undefined ? <>&nbsp;</> : preProcessLetter(ticket.description[cells[pos].letter]);
            if (adjacentHint) {
                return {letter: adjacent[pos], index2: pl}
            } else {
                return {letter: pl}
            }
        };

        if (gameOver && cells[pos].letter !== undefined && !cells[pos].open) {
            const value = getCellValue();
            return {
                letter: <div className={`ft${ticket.height}-cell-letter`}>{value.letter}</div>,
                index: <div className={`d-flex ft${ticket.height}-cell-index ft-cell-index px-1`}>
                    <div>{pos + 1}</div>
                    <div className={'ml-auto ft-cell-index-letter'}>{value.index2}</div>
                </div>,
                className: 'ones-open-after'
            }
        }

        if (selected === pos || cells[pos].selected) {
            return {
                letter: <div>#{pos + 1}</div>,
                className: 'ones-selected'
            }
        } else if (cells[pos].open) {
            const value = getCellValue();
            return {
                letter: <div className={`ft${ticket.height}-cell-letter`}>{value.letter}</div>,
                index: <div className={`d-flex ft${ticket.height}-cell-index ft-cell-index px-1`}>
                    <div>{pos + 1}</div>
                    <div className={'ml-auto ft-cell-index-letter'}>{value.index2}</div>
                </div>,
                className: `ones-${cells[pos].letter === undefined ? 'lost' : 'open'} ${adjacentHint ? 'adjacent-hint' : ''}`
            }
        } else {
            return {
                letter: <div className={`ft${ticket.height}-cell-letter`}>{adjacent[pos]}</div>,
                index: <div className={`ft${ticket.height}-cell-index ft-cell-index pl-1`}>{pos + 1}</div>,
                className: `ones-close${gameOver ? '-after' : ''} ${adjacent[pos] !== undefined ? 'ft-adjacent' : ''}`
            }
        }
    };

    onCellClick = (cells, pos) => {
        const {gameExpire = false, ticket, selected, game = {}} = this.state;

        if (this.isDelayMode(game) || gameExpire) {
            return;
        }

        if (!cells[pos].open) {
            const {fast = false} = ticket;

            if (fast) {
                this.onBtnTurn(pos);
            } else {
                this.setState({
                    redirectTicketId: undefined
                    , info: undefined
                    , selected: selected !== pos ? pos : undefined
                });
            }
        }
    };

    makeMatrix = () => {
        let rows = [], mode = 'ftnw';
        const {ticket, game, selected, adv = {}} = this.state;

        if (game.window !== undefined) {
            if (ticket.adjacent === 1 || ticket.adjacent === 2) {
                mode = game.success > 0 ? 'ftw-pp-9' : 'ftw-pp-0';
            } else {
                mode = 'ftw';
            }
        }

        const colClassname = `${mode} frx-col-frx${ticket.width}-1 ft${ticket.height}-cell`;

        for (let r = 0; r < ticket.height; r++) {
            let cols = [];
            for (let c = 0; c < ticket.width; c++) {
                const pos = (r * ticket.width) + c;
                const cell = this.getCellInfo(game.gameOver, ticket, game.cells, game.adjacent, ticket.adjacentHint, pos, selected);

                cols.push(<div key={c} className={`${cell.className} p-0 text-monospace ${colClassname}`}
                               onClick={() => !game.gameOver && !adv.active ? this.onCellClick(game.cells, pos) : undefined}>
                        {cell.letter}{cell.index}
                    </div>
                );
            }
            rows.push(<Row key={r} noGutters={true} className={`ft${ticket.height}-row`}>{cols}</Row>);
        }

        if (game.window !== undefined) {
            return (
                <div className={`ft-window ${game.theme!==undefined?game.theme:''}`}>
                    <img className={`ft-window-img ${mode}`} src={game.window} alt={''}/>
                    <div className={'ft-window-over'}>{rows}</div>
                </div>);
        } else {
            return <div className={`ft-solid${ticket.height}`}>{rows}</div>;
        }
    };

    onBtnTurn = async (pos) => {
        if (pos === undefined) {
            return;
        }

        const {api} = this.props;
        const {wallet: currentWallet = [], serverTime, ticket, game, adv = {}} = this.state;

        if (adv.active) {
            if (Math.floor((adv.time2turn.getTime() - serverTime.getTime()) / 1000) > 0) {
                return;
            }
            adv.active = false;
        } else if (ticket.advertising !== undefined) {
            const alc = AdvertisingList[ticket.advertising];
            if (alc !== undefined) {
                const {uno = false} = alc;
                const turnCount = game.success + game.fail;

                if ((uno && turnCount === 0) || (!uno && turnCount % 2 === 0)) {
                    const nextIndex = this.getNextIndex(adv.index, alc.ad.length);

                    this.setState({
                        adv: {
                            active: true,
                            index: nextIndex,
                            time2turn: new Date(serverTime.getTime() + adTime * 1000)
                        },
                        info: undefined,
                        selected: pos
                    });
                    return;
                }
            }
        }

        const response = await api.auth.requestTokenApiJson({
            request: '/api/c/ft/step',
            query: {
                ticket: ticket.id,
                pos
            }
        });

        const {error, result, turn, status, wallet: walletServer, next: ns} = response;
        let info = '&nbsp;', {next} = this.state;
        const {lockTooltipChange = false} = game;
        let tooltipOpen = false;
        let modalPayStatus = false, promo_reset, promo = {}, modalTicketCoupon = false;
        const wallet = processWallet(currentWallet, walletServer);

        if (error === undefined) {
            const {game_over} = status;
            if (!game_over) {
                api.auth.saveServerTimestamp(turn.server_time);

                let turnSuccess;
                const cell = game.cells[turn.pos];
                cell.open = true;

                if (turn.adjacent !== undefined) {
                    for (const [idx, a] of Object.entries(turn.adjacent)) {
                        game.adjacent[idx] = a;
                    }
                }

                if (turn.last.letter !== undefined) {
                    cell.letter = turn.last.index;

                    let m = game.ms[turn.last.index];
                    m.open = true;
                    m.letter = turn.last.letter;
                    m.pos = parseInt(turn.pos);

                    turnSuccess = true;
                } else {
                    turnSuccess = false;
                }
                game.fail = turn.fail;
                game.success = turn.success;

                // infoPrepare
                info = i18n.t(turnSuccess ? 'ft.info_turn_win_noend' : 'ft.info_turn_lost_noend', {
                    position: pos + 1,
                    prize: prizeString(game.fail < ticket.prizes.length ? ticket.prizes[game.fail] : ticket.noPrize, true, true)
                });
            } else {
                if (this.timerId !== undefined) {
                    this.stopTimer(this.timerId);
                    this.timerId = undefined;
                }

                game.gameOver = true;
                this.updateFullGameInfo(game, result);

                info = this.getInfoEndGame();

                next = await this.processNext(ns, walletServer);

                const prize = game.fail < ticket.prizes.length ? ticket.prizes[game.fail] : ticket.noPrize;
                modalPayStatus = prize.type === 'b3k_w100' || prize.type === 'b3k_w10';

                if (status.status === 'promo.restart') {
                    promo_reset = true;
                    promo.best = status.best;
                    promo.current = status.current;
                }
            }

            if (!lockTooltipChange) {
                if (ticket.adjacent === 2 || ticket.adjacent === 1) {
                    tooltipOpen = game.success >= 1;
                }
                if (tooltipOpen) {
                    game.lockTooltipChange = true;
                }
            }
        }

        this.setState({
            info
            , selected: undefined
            , game
            , adv
            , wallet
            , next
            , modalPayStatus
            , modalTicketCoupon
            , errorMessage: result && result.error_pay
            , promo
            , promo_reset
            , tooltipOpen
        });
    };

    togglePayStatus = async () => {
        this.setState(prevState => ({
                modalPayStatus: !prevState.modalPayStatus
            })
        );
    };

    makePayStatusB3KDialog = (modalPayStatus, code, prize, errorMessage) => {
        return (
            <Modal key={'psb3k'} isOpen={modalPayStatus} toggle={this.togglePayStatus}>
                <ModalHeader className={'t-blue'} toggle={this.togglePayStatus}>
                    <span
                        dangerouslySetInnerHTML={i18ntDangerHtml(errorMessage === undefined ? 'common.paper_prize_title' : 'common.paper_prize_title_warning')}/>
                </ModalHeader>
                <ModalBody className={'t-blue'}>
                    <div dangerouslySetInnerHTML={i18ntDangerHtml(errorMessage !== undefined ? 'ft.NextPrzB3KgotError' : 'ft.NextPrzB3Kgot', {
                        prize,
                        code,
                        msg: errorMessage
                    })}/>
                </ModalBody>
                <ModalFooter>
                    <Button key={'tp'} color="primary" onClick={this.togglePayStatus}>{i18n.t('common.ok')}</Button>
                </ModalFooter>
            </Modal>
        );
    };

    togglePromoRestart = async () => {
        this.setState(prevState => ({
                promo_reset: !prevState.promo_reset
            })
        );
    };

    defi = () => ({
        phase: 0
        , result: {count: 0, code: ''}
        , z: {
            count: 0
            , spent: {count: 0, code: ''}
            , income: {count: 0, code: ''}
        }
        , p: {
            count: 0
            , spent: {count: 0, code: ''}
            , income: {count: 0, code: ''}
        }
        , pp: {
            count: 0
            , spent: {count: 0, code: ''}
            , income: {count: 0, code: ''}
        }
    });

    makePromoRestartDialog = () => {
        const {api} = this.props;
        const {promo_reset = false, game = {}, promo = {}} = this.state;
        const {best = this.defi(), current = this.defi()} = promo;
        const {xLimit = 0} = game;

        return (
            <Modal isOpen={promo_reset} toggle={this.togglePromoRestart} className={'t-blue'}>
                <ModalHeader toggle={this.togglePromoRestart}>
                    <span dangerouslySetInnerHTML={i18ntDangerHtml('ft.promo_reset_title', {
                        nickname: api.auth.getNickname()
                    })}/>
                </ModalHeader>
                <ModalBody>
                    <div dangerouslySetInnerHTML={i18ntDangerHtml('ft.promo_reset_body', {
                        count: xLimit
                        , current: {
                            num: current.phase
                            , result: prizeValueType(current.result, true, true)
                            , info: {
                                z: {
                                    count: current.z.count
                                    , spent: prizeValueType(current.z.spent, true, true)
                                    , income: prizeValueType(current.z.income, true, true)
                                    , result: prizeValueType(resultValue(current.z.spent, current.z.income), true, true)
                                }
                                , p: {
                                    count: current.p.count
                                    , spent: prizeValueType(current.p.spent, true, true)
                                    , income: prizeValueType(current.p.income, true, true)
                                    , result: prizeValueType(resultValue(current.p.spent, current.p.income), true, true)
                                }
                                , pp: {
                                    count: current.pp.count
                                    , spent: prizeValueType(current.pp.spent, true, true)
                                    , income: prizeValueType(current.pp.income, true, true)
                                    , result: prizeValueType(resultValue(current.pp.spent, current.pp.income), true, true)
                                }
                            }
                        }
                        , best: {
                            num: best.phase
                            , result: prizeValueType(best.result, true, true)
                            , info: {
                                z: {
                                    count: best.z.count
                                    , spent: prizeValueType(best.z.spent, true, true)
                                    , income: prizeValueType(best.z.income, true, true)
                                    , result: prizeValueType(resultValue(best.z.spent, best.z.income), true, true)
                                }
                                , p: {
                                    count: best.p.count
                                    , spent: prizeValueType(best.p.spent, true, true)
                                    , income: prizeValueType(best.p.income, true, true)
                                    , result: prizeValueType(resultValue(best.p.spent, best.p.income), true, true)
                                }
                                , pp: {
                                    count: best.pp.count
                                    , spent: prizeValueType(best.pp.spent, true, true)
                                    , income: prizeValueType(best.pp.income, true, true)
                                    , result: prizeValueType(resultValue(best.pp.spent, best.pp.income), true, true)
                                }
                            }
                        }
                    })}/>
                </ModalBody>
                <ModalFooter>
                    <Button key={'tp'} color="primary" onClick={this.togglePromoRestart}>{i18n.t('common.ok')}</Button>
                </ModalFooter>
            </Modal>);
    };

    makePayDialog = (modalPay, prize) => {
        return (
            <Modal isOpen={modalPay} toggle={this.togglePayed} className={this.props.className}>
                <ModalHeader toggle={this.togglePayed}>{i18n.t('common.paper_prize_title')}</ModalHeader>
                <ModalBody>
                    <div dangerouslySetInnerHTML={i18ntDangerHtml('common.paper_prize_body', {prize})}/>
                    <div dangerouslySetInnerHTML={i18ntDangerHtml('common.paper_prize_body2')}/>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.togglePayed}>{i18n.t('common.ok')}</Button>{' '}
                </ModalFooter>
            </Modal>);
    };

    makeButton0 = () => {
        let row = [];
        const {gameExpire = false, ticket, game, errorMessage, modalPayStatus = false, selected, adv = {}, next} = this.state;
        const {fast = false} = ticket;

        row.push(this.makeDelayDialog());
        row.push(this.makeQrDialog());

        if (this.isDelayMode(game)) {
            row.push(
                <Row key={'new'} className={'mb-1'}>
                    <Col className={'my-1'}>
                        <Button color="primary" className="btn-lg btn-block rounded btn-n-red" onClick={() => this.onStartDelayX({ticket: ticket.id})}>
                            {i18n.t('ft.start')}
                        </Button>
                    </Col>
                </Row>);
        } else if (game.gameOver || gameExpire) {
            if (game.fail < ticket.prizes.length) {
                const prize = prizeString(ticket.prizes[game.fail], true, true);
                row.push(this.makePayStatusB3KDialog(modalPayStatus, ticket.ref, prize, errorMessage));
            }

            if (ticket.ref !== undefined) {
                row.push(
                    <Row key={'new_ref'}>
                        {game.invite
                            ? <Col className={'my-1 pr-1'}>
                                <Button color="success" className="btn-lg btn-block rounded text-white" href={'/aft/referral'}>
                                    {i18n.t('common.referral_page')}
                                </Button>
                            </Col>
                            : <></>}
                        <Col className={'my-1'}>
                            <Button color="success" className="btn-lg btn-block rounded text-white" onClick={() => this.onStartNext(next, ticket.ref)}>
                                {i18n.t('common.new_game')}
                            </Button>
                        </Col>
                        {this.makeStartSelectDialog()}
                        {this.makePromoRestartDialog()}
                    </Row>);
            }
        } else if (!fast && !gameExpire) {
            row.push(
                <Row key={'turn'}>
                    <Col>
                        <Button disabled={adv.active} onClick={() => this.onBtnTurn(selected)}
                                className={`btn btn-lg btn-block rounded my-1 ${selected !== undefined ? 'btn-n-red' : 'btn-n-lred'}`}>
                            {i18n.t('ft.turn', {
                                current: game.success + game.fail + 1,
                                letters: ticket.description.length,
                                position: selected !== undefined ? selected + 1 : ''
                            })}
                        </Button>
                    </Col>
                </Row>);
        }

        return row;
    };

    onClickCheck = () => {
        const {config} = this.props;
        const {game} = this.state;

        if (config['google-analytics'] !== undefined) {
            ReactGA.event({
                category: 'Check hash',
                action: 'check hash'
            });
        }

        window.open(getCheckURI(game.secretCode, game.publicCode), '_blank');
    };

    makeButton = () => {
        let row = [];
        const {game} = this.state;

        if (game.gameOver) {
            row.push(
                <Row key={'continue'} className={'mb-1'}>
                    <Col>
                        <Button color="primary" className="btn-lg btn-block rounded mt-1" onClick={() => this.onClickCheck()}>
                            {i18n.t('common.check_game0')}
                        </Button>
                    </Col>
                </Row>);
        }
        return row;
    };

    isEnableModalStart = (game) => !game.gameOver && (game.success + game.fail) === 0 && !this.isDelayMode(game);

    makeCheckInfo = () => {
        let rows = [];
        const {ticket, game = {}} = this.state;
        const {ms, gameOver = false, publicCode = i18n.t('ft.default_mask'), secretKey} = game;
        const mask = this.getCombinationText(ticket.description, ms, true, false, game.gameOver);

        rows.push(
            <Row key={'sc1'}>
                <Col key={1} className={'px-1 wordBreakAll'}>
                    <span className={'t-blue'} dangerouslySetInnerHTML={i18ntDangerHtml('ft.secret_code_interactive')}/>
                </Col>
            </Row>
        );

        if (gameOver) {
            rows.push(
                <Row key={'sc'} className={'my-1'}>
                    <Col key={1} className={'px-1 wordBreakAll'}>
                        <span className={'t-blue'}>{i18n.t('ft.secret_key_title')}</span>
                        <span className={'t-red pl-1'}>{secretKey}</span>
                    </Col>
                </Row>
            );
        }
        rows.push(
            <Row key={'sc2'}>
                <Col key={1} className={'px-1 wordBreakAll'}>
                    <span className={'t-blue'} dangerouslySetInnerHTML={i18ntDangerHtml('ft.turn_title')}/>
                    <span className={'t-red pl-1'}
                          dangerouslySetInnerHTML={dangerHtml(mask)}/>
                </Col>
            </Row>
        );

        if (!this.isDelayMode(game)) {
            rows.push(
                <Row key={'pc'}>
                    <Col key={1} className={'px-1 wordBreakAll'}>
                        <span className={'t-blue'} dangerouslySetInnerHTML={i18ntDangerHtml('ft.open_code_title')}/>
                        <span className={'t-red pl-1'}>{publicCode}</span>
                    </Col>
                </Row>
            );
        }

        return rows;
    };

    showWallet = (wallet) => {
        if (wallet && wallet.length > 0) {
            return (
                <div dangerouslySetInnerHTML={dangerHtml(getBalance(wallet, true, 't-blue'))}/>
            );
        } else {
            return (
                <>&nbsp;</>
            );
        }
    };

    makeResults = () => {
        let rows = [];

        const {config} = this.props;
        const {wallet = [], ticket, game, serverTime, tooltipOpen} = this.state;
        const {ms, gameOver = false} = game;
        const delayMode = this.isDelayMode(game);
        const tv = delayMode ? 300 : Math.floor(dataDiff(game.validTo, gameOver ? game.finish : serverTime) / 1000);
        const qrCode = <i className={`fa fa-qrcode text-info-2 t-red ${gameOver ? 'ft-blink' : ''}`} aria-hidden="true" onClick={this.toggleStart}/>;
        const infoCode =
            <>
                <i id="TooltipAdj" className={`fa fa-info-circle text-info-1 t-green px-1`} aria-hidden="true" onClick={this.toggleTooltip}/>
                <Tooltip placement="bottom" autohide={false} isOpen={tooltipOpen} target="TooltipAdj" toggle={this.toggleTooltip}>
                    <span dangerouslySetInnerHTML={i18ntDangerHtml(`ft.tooltip_info_${ticket.adjacent === 2 ? 'p' : 'pp'}`)}/>
                </Tooltip>
            </>;
        const ticketCode = <span className={'t-red'}>{ticket.ref}</span>;

        rows.push(
            <Row key={0} className={'info-white'}>
                <Col className={'d-flex justify-content-center align-items-center position-relative px-1'}>
                    <div className={'position-absolute'} style={{left: '.5rem '}}>
                        {game.xLimit !== undefined
                            ? <Link to={`/aft`}><span className={'t-blue'}>&lt;--</span></Link>
                            : config.BackURI !== undefined
                                ? <a href={config.BackURI}><span className={'t-blue'}>&lt;--</span></a>
                                : <></>}
                    </div>
                    <h5 className={'m-0 py-1'}>
                        {this.isAdjacent() ? infoCode : <></>}
                        <span className={'t-blue'}>{getTitle(ticket.adjacent, false)}</span>
                        <img style={{height: '1.5rem'}} className={'mt-n1 px-1'} src={'/img/logo.svg'} alt='logo'/>
                        <span className={'t-red'} dangerouslySetInnerHTML={dangerHtml(preProcessWord(ticket.description, true))}/>
                    </h5>
                    <div className={'position-absolute'} style={{right: '.5rem '}}>
                        {ticket.partner === 'Bet3000' && ticket.word_rule_delay !== undefined ?
                            <i className={`fa fa-pencil-square-o t-blue text-info-1`} aria-hidden="true" onClick={this.toggleAllRight}/> :
                            <span className={'text-info-1'}>{this.showWallet(wallet)}</span>}
                    </div>
                </Col>
            </Row>
        );

        rows.push(
            <Row key={1} className={'my-1 mx-n2'}>
                <Col className={'p-0 d-flex justify-content-start align-items-center'}>
                    {delayMode ? ticketCode : qrCode}
                </Col>
                <Col className={'p-0 d-flex justify-content-center align-items-center'}>
                    {this.showMask(ms, ticket.description, gameOver)}
                </Col>
                <Col className={'p-0 d-flex justify-content-end align-items-center'}>
                    <span className={'text-info-1 t-red'}>{Math.max(tv, 0)}</span>
                    <span className={'text-info-1 t-blue'}>"</span>
                </Col>
            </Row>
        );

        const nt = game !== undefined ? (game.success + game.fail) : 0;
        rows.push(
            <Row key={2} className={'my-1 mx-n2'}>
                <Col xs={'auto'} className={'p-0 d-flex justify-content-start'}>
                    <span className={'text-info-1 t-blue'}>{i18n.t('ft.turn_title')}</span>
                    <span className={'text-info-1 t-red pl-1'}>{nt}</span>
                    <span className={'text-info-1 t-blue'}>/</span>
                    <span className={'text-info-1 t-red'}>{ticket.description.length}</span>
                </Col>
                <Col xs={'auto'} className={'p-0 d-flex justify-content-end ml-auto'}>
                    <span className={'text-info-1 t-blue'}>{i18n.t('ft.prize_title')}</span>
                    {this.showPrizes()}
                </Col>
            </Row>
        );

        return rows;
    };

    componentWillUnmount() {
        this.stopTimer(this.timerId);
        this.timerId = undefined;
    }

    startTimer = (ticker, delta = 1000) => {
        return window.setInterval(ticker, delta);
    };

    stopTimer = (timerId) => {
        if (timerId !== undefined) {
            window.clearInterval(timerId);
        }
    };

    showPrizes = () => {
        const {ticket, game} = this.state;
        const pos = game !== undefined ? game.fail : 0;

        return ticket.prizes && ticket.prizes.map((item, idx) => <
                PrizeTag
                key={idx}
                className={'pl-1 text-info-1'}
                item={item}
                color={true}
                active={pos === idx
                }
                lost={game !== undefined && game.fail > idx
                }
            />
        );
    };

    showMask = (ms, word, open) => {
        let spans = [];
        for (let k = 0; k < word.length; k++) {
            let ww, cc;
            if (ms[k].open) {
                ww = preProcessLetter(ms[k].letter);
                cc = ' ones-open-m';
            } else if (open) {
                ww = preProcessLetter(word[k]);
                cc = ' ones-open-after-m';
            } else {
                ww = <>&nbsp;</>;
                cc = ' ones-close-m';
            }

            spans.push(
                <span key={k + 1} className={'text-monospace' + cc}>{ww}</span>
            );
        }
        return spans;
    };

    advertisingSwitch = () => {
        const {adv, ticket} = this.state;
        const adList = AdvertisingList[ticket.advertising].ad;
        const adItem = adList[adv.index];
        const adStyle = adItem.bLandscape ? 'childStyle-w' : 'childStyle-h';

        switch (adItem.sType) {
            case"video":
                return (
                    <div className={'parentStyle'} key={adItem.sType}>
                        <video height={"100%"} width={"100%"} className={adStyle} playsInline
                               onLoadStart={() => {
                                   this.onAdLoadStart();
                               }}
                               onPlay={() => {
                                   this.onShowAdvertising()
                               }}
                               onAbort={() => {
                                   this.onBreakAd();
                                   console.log("video onAbort")
                               }}
                               onError={() => {
                                   this.onBreakAd();
                                   console.log("video onError")
                               }}
                               autoPlay
                               loop
                               poster='/img/loader.gif'
                        >
                            <source src={adItem.sURL} type='video/mp4'/>
                        </video>
                    </div>);
            case "text":
                return (
                    <div key={'text'} className={'parentStyle my-1 text-info-1'}>
                        <span style={{width: "100%"}} className={adStyle + ' t-blue'}
                              dangerouslySetInnerHTML={dangerHtml(adItem.sBody === undefined ? i18n.t('advertising.banner') : adItem.sBody)}/>
                    </div>
                );
            case "gif":
            case "png":
                return (
                    <div className={'parentStyle'}
                         key={adItem.sType}>
                        <img className={adStyle} src={adItem.sURL} alt="loading..."
                             onLoad={() =>
                                 this.onShowAdvertising()}
                             onError={() => this.onBreakAd()}/>
                    </div>
                );
            default:
                return (
                    <Row key={adItem.sType} className={'my-1'}>
                        <span className={'t-blue'}>{i18n.t('advertising.defaultBanner')}</span>
                    </Row>
                )

        }
    };

    onBreakAd() {
        const {adv} = this.state;
        adv.active = false;
        this.setState({adv});
    }

    onAdLoadStart() {
        this.setState({
            // timeToEndAd: undefined,
            timeToBreakAd: (new Date().getTime() + timeToWaitAd * 1000)
        })
    }

    onShowAdvertising() {
        this.setState({
            isShowAd: true,
            timeToEndAd: (new Date().getTime() + adTime * 1000),
        })
    }

    makeAdvertising = () => {
        const {adv = {}, ticket, selected, serverTime} = this.state;

        if (!adv.active) {
            return (<div/>);
        }

        const remainingTime = Math.floor(dataDiff(adv.time2turn, serverTime) / 1000);
        const tv = remainingTime >= 0 ? remainingTime : 0;
        const endOfAdv = tv <= 0;
        const adFullScreen = ticket.adFullScreen;

        return (
            <div style={{backgroundColor: ticket.backgroundColor !== undefined ? ticket.backgroundColor : "white"}}
                 className={"pace-progress advertisingStyle d-flex flex-column border shadow px-2 rounded "}>
                <div key={'title'} className={'d-flex justify-content-between mt-1 text-info-0'}>
                    <div>
                        <span className={'t-red'}>{i18n.t('advertising.title')}</span>
                    </div>
                    {!endOfAdv ?
                        <div className={'d-flex justify-content-end'}>
                            <span className={'t-red'}>{tv}</span>
                            <span className={'t-blue'}>"</span>
                        </div>
                        : <div className={'d-flex justify-content-end t-blue'}>
                            <span className={"fa fa-window-close"} onClick={() => this.onBtnTurn(selected)}/>
                        </div>}
                </div>

                {adFullScreen ? <div className={"fullScreen text-info-1"}>
                        {this.advertisingSwitch()}
                    </div> :
                    <div className={"fragmentStyle text-info-1"}>
                        {this.advertisingSwitch()}
                    </div>
                }
                {endOfAdv ?
                    <Button style={{position: "absolute", right: "0.5rem", bottom: "0.5rem"}}
                            onClick={() => this.onBtnTurn(selected)}>{i18n.t('advertising.close')}</Button>
                    : <></>}

            </div>
        );
    };

    render() {
        const {ftg, redirectTicketId, tokenValid = false, ticket, adv = {}, game} = this.state;

        if (ftg !== undefined) {
            return <Redirect exact to={`/ftg/${ftg}`}/>;
        } else if (redirectTicketId !== undefined) {
            let {ticket: zt} = this.props.match.params;
            if (redirectTicketId !== zt) {
                return <Redirect exact to={`/fti/${redirectTicketId}`}/>;
            }
        }

        if (ticket === undefined) {
            return <SimpleContainer BackGroundColor={'white'} Footer0={footer0(adv, tokenValid)}>{i18n.t('common.loading')}</SimpleContainer>;
        }

        const b3kMode = ticket.partner !== undefined && ticket.partner === 'Bet3000';
        return (
            <SimpleContainer BackGroundColor={game.background} Footer0={footer0(adv, tokenValid)} Partner={b3kMode ? 'Bet3000' : undefined}>
                {this.makeResults()}
                <Row className={'my-1'}>
                    <Col className={'text-info-1 info-white px-1 t-blue'}>
                        <span dangerouslySetInnerHTML={dangerHtml(this.getInfoText())}/>
                    </Col>
                </Row>
                <Row className={'position-relative'}>
                    <Col>
                        {this.makeMatrix()}
                        {this.makeButton0()}
                        {this.makeCheckInfo()}
                        {this.makeButton()}
                        {this.makeAdvertising()}
                    </Col>
                </Row>
            </SimpleContainer>
        )
    }

    toggleAllRight = async () => {
        this.setState(prevState => ({
                all_right: !prevState.all_right
            })
        );
    };

    makeDelayDialog = () => {
        const {all_right = false, ticket} = this.state;

        return (
            <Modal key={'dd'} isOpen={!all_right} toggle={this.toggleAllRight} className={this.props.className}>
                <ModalBody className={'paper-bottom-h2 t-blue pb-1'}>
                    <Row>
                        <Col className={'text-center'}>
                            <h5 className={'my-0'}>
                                <span className={'t-blue'}>{i18n.t('common.delay_title')}</span>
                            </h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col className={'text-left pr-0'}>
                            <b>
                                <span className={'t-blue'}>{getTitle(ticket.adjacent, false)}:</span>
                                <span className={'t-red pl-1'}>{ticket.description}</span>
                            </b>
                        </Col>
                        <Col className={'text-right pl-0'}>
                            <b>
                                <span className={'t-blue'}>{i18n.t('ft.user_id')}</span>
                                <span className={'t-red pl-1'}>{ticket.ref !== undefined ? ticket.ref : ticket.id}</span>
                            </b>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalBody className={'t-blue pb-1'}>
                    <div dangerouslySetInnerHTML={i18ntDangerHtml(ticket.word_rule_delay, {
                        word: preProcessWord(ticket.description, true),
                        timeout: /*(game.validTo.getTime() - game.start.getTime()) / 1000*/300,
                        letters: ticket.description.length,
                        max: ticket.width * ticket.height,
                        max_prize: ticket.prizes && prizeString(ticket.prizes[0], true, true)
                    })}/>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={this.toggleAllRight} color="primary"
                            className="btn-lg btn-block rounded">{i18n.t('common.all_right')}</Button>
                </ModalFooter>
            </Modal>
        );
    };
}

const footer0 = (adv, tokenValid) => {
    let rows = [];
    if (tokenValid) {
        rows.push(
            <Row key={'tb'}>
                <Col className={'mb-1'}>
                    <Button disabled={adv.active} color={'link'}
                            href={'/fp'}>{i18n.t('common.back_to_select_subtype')}</Button>
                </Col>
            </Row>);
    }
    return rows;
};

const mapStateToProps = (state) => {
    return {
        api: state.api,
        cache: state.cache,
        config: state.config
    }
};

const mapDispatchToProps = dispatch => ({
    configActions: bindActionCreators(configActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(FindThisInteractive)
