import React from "react";
import {Col, Row} from "reactstrap";
import classNames from 'classnames';

import {mapToCssModules} from "reactstrap/src/utils";
import * as PropTypes from "prop-types";

const propTypes = {
    className: PropTypes.string,
    cssModule: PropTypes.object
};


class SmilesCount extends React.Component {

    render() {
        const {
            className,
            cssModule,
            gameCodeId,
            description,
            ...attributes
        } = this.props;
        const classes = mapToCssModules(classNames(
            className,
        ), cssModule);
        //let accounts = this.props.accounts;
        return (
            <div {...attributes} className={classes}>
                <Row>
                    <Col className={'w-100 pl-1 pr-1'}>
                        <div className={'text-left textComponent'}>
                            {description}
                        </div>
                    </Col>
                    {/*<PictureWithCount count={200} smile={"r"} textAlign={"text-left"}/>*/}
                    <PictureWithCount count={200} smile={"y"} textAlign={"text-center"}/>
                    <PictureWithCount count={150} smile={"g"} textAlign={"text-right"}/>
                </Row>
            </div>
        )
    }
}

SmilesCount.propTypes = propTypes;
export default SmilesCount;

export class PictureWithCount extends React.Component {
    render() {
        const count = this.props.count;
        const smile = this.props.smile;
        const textAlign = this.props.textAlign;
        let path;
        switch (smile) {
            case "g":
                path = "/img/small_green_smile.png";
                break;
            case "y":
                path = "/img/small_yellow_smile.png";
                break;
            case "r":
                path = "/img/small_red_smile.png";
                break;
            default:
                break;
        }
        return (
            <div style={{padding: 1}}>
                <span size="md" className={textAlign + ' textComponent'}>
                    {count}
                </span>
                <img src={path} width={13} height={13} alt={"=)"}/>
            </div>)
    }
}