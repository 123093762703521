import React from "react";
import classNames from 'classnames';

import {mapToCssModules} from "reactstrap/src/utils";
import * as PropTypes from "prop-types";

const propTypes = {
    className: PropTypes.string,
    cssModule: PropTypes.object,
};

export const CostComponent = (props) => {
    const {
        className,
        cssModule,
        cost,
        enable,
        onChangeCost,
        ...attributes
    } = props;
    const classes = mapToCssModules(classNames(
        className,
    ), cssModule);

    let yellowSmile, greenSmile, redSmile, disableSmile;

    const {value = 0, type: smile} = props.cost;

    switch (smile) {
        case 'r':
            disableSmile = '/img/small_red_smile.png';
            redSmile = '/img/red_smile_circle_pressed.png';
            yellowSmile = '/img/yellow_smile_circle.png';
            greenSmile = '/img/green_smile_circle.png';
            break;
        case 'y':
            disableSmile = '/img/small_yellow_smile.png';
            redSmile = '/img/red_smile_circle.png';
            yellowSmile = '/img/yellow_smile_circle_pressed.png';
            greenSmile = '/img/green_smile_circle.png';
            break;
        case 'g':
            disableSmile = '/img/small_green_smile.png';
            redSmile = '/img/red_smile_circle.png';
            yellowSmile = '/img/yellow_smile_circle.png';
            greenSmile = '/img/green_smile_circle_pressed.png';
            break;
        default:
            break;
    }

    return (
        <div {...attributes} className={'d-flex align-items-center ' + classes}>
            <span className={'textComponent'}>{props.title}</span>
            <span className={'numberComponent'}>{value / 100}</span>
            {
                props.enable
                    ? <>
                        <img src={redSmile} onClick={() => props.onChangeCost({type: 'r', value})} width={17} height={17} alt={''}/>
                        <img src={yellowSmile} onClick={() => props.onChangeCost({type: 'y', value})} width={17} height={17} alt={''}/>
                        <img src={greenSmile} onClick={() => props.onChangeCost({type: 'g', value})} width={17} height={17} alt={''}/>
                    </>
                    : <img className={'smile-coin align-middle'} src={disableSmile} alt={''}/>
            }
        </div>)
}

CostComponent.propTypes = propTypes;
