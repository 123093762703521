import React, {useEffect} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {useSelector} from "react-redux";
import {Container} from 'reactstrap';

import {Header, Dashboard, Footer, Aside} from '../components';
import Sidebar from '../components/Sidebar';
import Breadcrumb from '../components/Breadcrumb';
import Accounts from '../views/Accounts/Accounts';
import Profiles from '../views/Pages/Page404';
import TranslateLanguage from '../views/Translate/Language';
import TranslateCompound from '../views/Translate/Compound';
import TranslateTags from '../views/Translate/Tags';
import GameOneView from "../views/OneX/GameOneView";
import requireAuthentication from '../views/Pages/AuthenticatedComponent'
import Statistics from "../views/Football/Stats/Statistics";
import List from "../views/Football/Stats/List";
import ListNews from "../views/News/ListNews";
import NewsItem from "../views/News/NewsItem";
import {withTracker} from "../withTracker";
import {DashboardFindThis} from "../views/Games/FindThis";
import {DashboardFootball} from "../views/Games/Football";

export const Full = (props) => {
    const config = useSelector(state => state.config);
    // монтирование
    useEffect(() => {
        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        let vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);

        if (config.MobileDetect.phone()) {
            document.body.classList.remove('breadcrumb-fixed');
            document.body.classList.add('mobile');
        } else {
            document.body.classList.remove('mobile');
        }
    }, [props, config.MobileDetect]);

    let breadcrumb;
    if (config && config.MobileDetect.phone()) {
    } else {
        breadcrumb = <Breadcrumb/>;
    }

    return (
        <div className={`app`}>
            <Header {...props}/>
            <div className={`app-body`}>
                <Sidebar {...props}/>
                <main className="main">
                    {breadcrumb}
                    <Container fluid>
                        <Switch>
                            <Route path="/dashboard" name="Dashboard" component={withTracker(Dashboard, '/dashboard')}/>
                            <Route path="/d" name="Dashboard" component={withTracker(Dashboard, '/dashboard')}/>

                            <Route path="/admin/accounts" name="Accounts" component={Accounts}/>
                            <Route path="/admin/profile" name="Profiles" component={Profiles}/>

                            <Route exact path="/onex2/:mode" name="OneX" component={requireAuthentication(GameOneView)}/>

                            <Route path="/translate/lang" name="Language" component={TranslateLanguage}/>
                            <Route path="/translate/compound" name="Compound" component={TranslateCompound}/>
                            <Route path="/translate/tags" name="Tags" component={TranslateTags}/>

                            <Route path="/fp/stats/list" name="Stats Football Paper" component={List}/>
                            <Route path="/fp/stats" name="Stats Football Paper" component={Statistics}/>
                            <Route path="/news/list" name="List News" component={ListNews}/>
                            <Route path="/news/item/:lang/:id?" name="News Item" component={NewsItem}/>

                            <Route path="/g/a/ft" component={withTracker(DashboardFindThis, '/g/a/ft')}/>

                            <Route path="/g/a/football" component={withTracker(DashboardFootball, '/g/a/football')}/>

                            <Redirect to={'/'}/>
                        </Switch>
                    </Container>
                </main>
                <Aside/>
            </div>
            <Footer/>
        </div>
    );
}
