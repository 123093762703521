import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {Alert, Button, Col, Row} from 'reactstrap';
import SmilesCount from "../../components/GameControl/SmilesCount"
import {CostComponent} from "../../components/GameControl/CostComponent";
import MarqueeString from "../../components/GameControl/MarqueeString";
import TextWithNumber from "../../components/GameControl/TextWithNumber";
import PrizeCount from "../../components/GameControl/PrizeCount";
import i18n, {dangerHtml, i18ntDangerHtml} from "../../i18n";
import Selector from "../../components/GameControl/Selector";
import SimpleContainer from "../Pages/SimpleContainer";
import {useTranslation} from "react-i18next";
import {Board} from "./Board";
import {shift_index} from "../../components/Common/GameControl/Utils";

//const ANIMATION_TIME = 3000;
const DIMENSIONALITY_ONE = "one";

const getBoardId = (mode) => {
    switch (mode) {
        case 'zero':
            return '376b401c-29ea-40d0-96b7-d96e3ceb9fc5';
        case 'one':
            return 'd11f8a7c-df13-4eb2-9437-c779614b4973';
        case 'max':
            return 'a4108792-c91f-4fbb-8877-f3593296c800';
        case 'mini':
            return 'b2a48160-e5a2-4168-b66a-a56246dc46da';
        default:
            return '376b401c-29ea-40d0-96b7-d96e3ceb9fc5';
    }
};

const getSettingsId = (mode) => {
    switch (mode) {
        case 'zero':
            return 'de111545-5f16-4d3d-bb70-ca0f42e332de';
        case 'one':
            return '05713161-a888-4b4e-a4ee-bc5b10e78713';
        case 'max':
            return '8283321f-5e78-48e9-96f3-21546f8f0155';
        case 'mini':
            return '8bc41203-1fed-4732-a008-2aa5117e52cb';
        default:
            return 'de111545-5f16-4d3d-bb70-ca0f42e332de';

    }
};

//тип игры по умолчанию
const getDefaultGameTypeIndex = (settings) => {
    if (settings.games === undefined || settings.games.length === 1) {
        return 0;
    } else {
        for (let i = 0; i < settings.games.length; i++) {
            if (settings.games[i].default === true) {
                return i;
            }
        }
    }

    return -1;
};

//инициализация нового игрового поля
const initialCells = (gameInfo) => {
    let a = [];
    for (let i = gameInfo.height * gameInfo.width; i > 0; i--) {
        a.push({count: 0, ownCount: 0});
    }
    return a;
};

export const getColumnDescription = (settings, column, rows) => {
    let options = settings.options;
    let axisStep = 1/*options != null ? options.axisStep : 1*/;
    let axisStart = 0 /*options != null ? options.axisStart : 0*/;
    let axisRotate = options != null && options.button0;

    if (options && options.axis_x_0 === "A") {
        return String.fromCharCode('A'.charCodeAt(0) + column);
    } else if (options && options.axis_x_0 === ("0")) {
        if (axisRotate) {
            return column * axisStep * rows + axisStart;
        } else {
            return column * axisStep;
        }
    } else {
        return column + 1;
    }
};

export const getRowDescription = (settings, row, columns) => {
    const {options} = settings;
    let axisStep = 1/*options != null ? options.axisStep : 1*/;
    let axisStart = 0 /*options != null ? options.axisStart : 0*/;
    let axisRotate = options != null && options.button0;

    if (options) {
        if (options.axis_y_0 === ("A")) {
            //Описание строк буквами
            return String.fromCharCode('A'.charCodeAt(0) + row);
        } else {
            //Описание строк цифрами
            if (options.axis_y_0 === "0") {
                if (axisRotate) {
                    return options.dimensions !== DIMENSIONALITY_ONE ? row * axisStep : row * axisStep;
                } else {
                    return options.dimensions !== DIMENSIONALITY_ONE ? row * axisStep + axisStart : row * axisStep * columns + axisStart;
                }
            } else {
                return options.dimensions === DIMENSIONALITY_ONE ? row + 1 : (row + 1) * columns;
            }
        }
    }
    return '';
};

const getCellDescription = (settings, position, type) => {
    let game = settings.games[type];
    let column = position % game.width;
    let row = position / game.height;

    let description = "";
    let options = settings.options;

    let axisStep = 1/*options != null ? options.axisStep : 1*/;
    let axisStart = 0 /*options != null ? options.axisStart : 0*/;
    let axisRotate = options != null && options.button0;
    if (column !== -1 && row !== -1) {
        if (options !== null) {
            if (options.dimensions === DIMENSIONALITY_ONE) {
                if (axisRotate) {
                    description = row * axisStep + axisStart + column * axisStep * game.height;//TODO * axisStep * axisStep Для тестирования OneMax
                } else {
                    description = (row * game.width * axisStep + axisStart + column * axisStep);
                }
            } else {
                if (options.axis_y_0 === (0))
                    description = getRowDescription(settings, row, game.width) + column;
                else
                    description = getRowDescription(settings, row, game.width) + (column + 1);
            }
        }
    }
    return description;
};

const initialStatistic = (gameInfo) => {
    return {
        countOwnStep: 0,
        countZero: gameInfo === undefined ? 0 : gameInfo.height * gameInfo.width,
        countOne: 0,
        countEnemyOne: 0,
        countSteps: 0,
        isGameStarted: false,
        isGameOver: false
    };
};

const updateIncrementStatistics = (prevStatistics, prevField, position, nextCount, nextOwnCount) => {
    let {consolationPosition = 0, isGameStarted = false, countSteps = 0, countOwnStep = 0, countOne = 0, countZero = 0, countEnemyOne = 0, ...more} = prevStatistics;

    if (prevField.count === 0) {
        countZero--;
        if (nextCount === 1) {
            countOne++;
            if (nextOwnCount === 0) {
                countEnemyOne++;
            }
        }
    } else if (prevField.count === 1) {
        if ((countOne === 2 || countZero === 1) && nextOwnCount !== 0) {//???
            consolationPosition = position;
        }

        countOne--;
        if (prevField.ownCount === 0) {
            countEnemyOne--;
        }
    }

    countSteps += nextCount;
    countOwnStep += nextOwnCount;

    if (!isGameStarted) {
        isGameStarted = true;
    }

    return {
        ...more
        , countZero
        , countEnemyOne
        , countOne
        , countOwnStep
        , countSteps
        , isGameStarted
        , consolationPosition
    };
};

const restoreCells = (settings, type, turns) => {
    const gameInfo = settings.games[type];
    const cells = initialCells(gameInfo);
    let statistics = initialStatistic(gameInfo);
    let timeLastStep, gameRound, prize, lastPosition, lastStepDescription;

    turns && turns.forEach(t => {
        const cell = t.numbers;

        if (cell.position === -1) {
            timeLastStep = cell.time;
            gameRound = cell.round;

            if (cell.next_prizes !== null) {
                prize = cell.next_prizes[0][0].value / 100;
            }

            /*
                        lastPosition = cell.position;
                        lastStepDescription = getCellDescription(settings, cell.position, type);
            */
            statistics.isGameOver = cell.is_game_over
        } else {
            const field = cells[cell.position];
            statistics = updateIncrementStatistics(statistics, field, cell.position, cell.count, cell.ownCount);
            field.count = cell.count;
            field.ownCount = cell.ownCount;
        }
    });

    return {
        cells
        , timeLastStep
        , gameRound
        , prize
        , lastPosition
        , lastStepDescription
        , statistics
    };
};

const prepareGame = (settings, gameId, turns, gameTypeIndex, mode) => {
    const restore = restoreCells(settings, gameTypeIndex, turns);
    /*
            let consolationPosition = -1;
            let prizePosition = -1;
            if (this.isGameOver) {
                consolationPosition = this.lastPosition;
                prizePosition = mode === "zero" ? this.lastPosition : this.getPositionLastOne(cells)
            }

            return {
                lastOne: this.getPositionLastOne(cells),
                consolationPosition: consolationPosition,
                prizePosition: prizePosition
            };
    */
    return {
        lastPosition: restore.lastPosition
        , statistics: restore.statistics
        , cells: restore.cells
        , gameTypeIndex
        , gameId
    };
};

const GameOneView = (props) => {
    const {t} = useTranslation();
    const [state, setState] = useState({});
    const [game, updateGame] = useState({});
    const [settings, setSettings] = useState({});
    const [cost, setCost] = useState({value: 1, type: 'y'});

    // монтирование
    useEffect(() => {
        const {api, cache} = props;
        const {mode, boardId = getBoardId(mode)} = props.match.params;

        cache.db.getSettings(getSettingsId(mode), api)
            .then(settings => {
                setSettings(settings);

                cache.db.getLastGameOneX(boardId, mode)
                    .then(lastGame => {
                        const defaultGameTypeIndex = getDefaultGameTypeIndex(settings);

                        if (lastGame !== undefined) {
                            cache.db.getTurnsOneX(boardId, lastGame.gameId)
                                .then(turns => {
                                    console.log(turns);
                                    updateGame(prepareGame(settings, lastGame.gameId, turns, lastGame.type, mode));
                                });
                        } else {
                            updateGame(prepareGame(settings, undefined, undefined, defaultGameTypeIndex, mode));
                        }
                    });
            });

        /***
         this.prize = this.onGetPrizeValue(mode, settings[mode].games[0]);
         this.isGameOver = false;
         this.animationTimer = 0;
         this.gameRound = "001";
         this.currentType = 0;
         this.isUserAuthenticated = api.auth.isUserAuthenticated();

         this.state = {
            value: 0,
            selectPositionDescription: '',
            count: 0,
            gameCodeId: mode,
            currentGame: settings[mode].games[0],
            game: this.initialGame(this.currentType, mode, boardId),
            selectedCell: 0,
            isAnimate: false,
        };
         */
    }, [props]);

    if (game.cells === undefined) {
        return <SimpleContainer BackGroundColor={'white'}>{t('common.loading')}</SimpleContainer>;
    }

    const {mode: gameCodeId, boardId = getBoardId(gameCodeId)} = props.match.params;
    const {selectedPosition = -1, selectPositionDescription = ''} = state;
    const {gameRound = '001', timeLastStep} = state;
    const {statistics = {}, prize = '', gameId, gameTypeIndex} = game;

    /*   const onGetPrizeValue = (mode, currentGame) => {
           if (prize !== undefined) {
               return prize;
           } else {
               let prize = currentGame.width * currentGame.height;
               if (mode === "zero") {
                   prize = (Math.round(prize / 2 - 1));
               } else if (mode === "one") {
                   prize = (Math.round(2 * (prize - 1) - 1));
               } else if (mode === "min") {
                   prize = (Math.round((prize * 0.5 - 1)));
               } else if (mode === "max") {
                   prize = (Math.round((prize * 0.5 - 1)));
               }
               return prize !== 0 ? prize : "?";
           }
       };

       const updateDescription = () => {
           /!*
                   this.setState({
                       selectedPosition: -1,
                       selectPositionDescription: ""
                   })
           *!/
       };
   */
    const onChangeable = (mode) => (mode !== "max" && mode !== "mini") && settings.games.length > 1;

    const onChangeType = (diff) => {
        const {cache} = props;
        let newGameTypeIndex = shift_index(diff, gameTypeIndex, settings.games);

        setState({
            ...state
            , selectedPosition: -1
            , selectPositionDescription: ''
        });

        cache.db.getCommonGames(boardId, newGameTypeIndex)
            .then(onexGame => {
                if (onexGame !== undefined) {
                    cache.db.getTurnsOneX(boardId, onexGame.gameId)
                        .then(turns => {
                            updateGame(prepareGame(settings, onexGame.gameId, turns, onexGame.gameType, gameCodeId));
                        });
                } else {
                    updateGame(prepareGame(settings, undefined, undefined, newGameTypeIndex, gameCodeId));
                }
            });

        /*********

         this.initialData();
         this.setState({
            game: this.initialGame(currentType, mode, boardId),
            count: 0,
            gameCodeId: mode,
            selectedPosition: -1,
            selectPositionDescription: "",
            gameId: gameId
        });
         cache.db.getCommonGames(boardId, currentType)
         .then(commonGames => {
                if (commonGames !== undefined) {
                    gameId = commonGames.gameId;
                    console.log("boardId, gameId = ", boardId, gameId);
                    cache.db.getTurnsOneX(boardId, gameId)
                        .then(turns => {
                            this.turns = turns;

                            this.setState({
                                game: this.updateGame(turns, currentType, mode, boardId),
                                count: 0,
                                gameCodeId: mode,
                                selectedPosition: -1,
                                selectPositionDescription: "",
                                gameId: gameId,
                                isGameOver: this.isGameOver
                            });
                        });

                } else {
                    this.setState({
                        game: this.initialGame(currentType, mode, boardId),
                        count: 0,
                        gameCodeId: mode,
                        selectedPosition: -1,
                        selectPositionDescription: "",
                        gameId: gameId
                    });
                }
            });
         */
    };

    const getMarqueeString = (mode) => {
        /*********
         let statistic = game.statistics;
         switch (mode) {
                    case "zero":
                        if (statistic.isGameStarted) {
                            let cell = this.state.game.cells[this.lastPosition];

                            if (cell !== null /!*&& cell.count > 0*!/) {
                                let stepsOwn = statistic.countOwnStep;

                                if (this.isGameOver) {
                                    return i18ntDangerHtml("onex:types." + this.state.gameCodeId + ".onex_zero_win",
                                        {
                                            lastStep: this.lastStepDescription,
                                            round: this.gameRound,
                                            countSteps: statistic.countSteps,
                                            prize: this.prize,
                                            // iconYellow: '<img src="/img/small_yellow_smile.png" width={1} height={1} alt="=)" align="middle"/>',
                                            ownSteps: stepsOwn,
                                            ownSteps2: stepsOwn - 1
                                        });
                                } else {
                                    return i18ntDangerHtml("onex:types." + this.state.gameCodeId + ".onex_zero_step_result",
                                        {
                                            lastStep: this.lastStepDescription,
                                            round: this.gameRound,
                                            countSteps: statistic.countSteps,
                                            ownSteps: stepsOwn
                                        });
                                }
                            } else {
                                return i18ntDangerHtml("onex:common.onex_choose_cell_step");
                            }
                        }
                        return i18ntDangerHtml("onex:common.onex_choose_cell_step");
                    case "one":
                        if (statistic.isGameStarted) {
                            let cell = this.state.game.cells[this.lastPosition];
                            let stepsOwn = statistic.countOwnStep;
                            let stepsLostOwn = stepsOwn - (statistic.countOne - statistic.countEnemyOne);
                            if (cell !== undefined && cell.count > 0) {
                                if (this.isGameOver) {
                                    if (this.state.game.consolationPosition === this.lastPosition && !this.isLastOneOwn()) {
                                        return i18ntDangerHtml("onex:types." + this.state.gameCodeId + ".onex_one_consolation_prize", {
                                            lastStep: this.lastStepDescription,
                                            round: this.gameRound,
                                            countSteps: statistic.countSteps,
                                            consolationPrize: this.consolationPosition,
                                            ownSteps: stepsOwn,
                                            ownLostSteps: stepsLostOwn - 1
                                        });
                                    } else {
                                        return i18ntDangerHtml("onex:types." + this.state.gameCodeId + ".onex_one_win",
                                            {
                                                lastStep: this.lastStepDescription,
                                                round: this.gameRound,
                                                countSteps: statistic.countSteps,
                                                consolationPrize: this.consolationPosition,
                                                ownSteps: stepsOwn,
                                                ownLostSteps: stepsLostOwn - 1
                                            });
                                    }
                                } else {
                                    if (stepsLostOwn === stepsOwn) {
                                        return i18ntDangerHtml("onex:types." + this.state.gameCodeId + ".onex_one_step_result_lost_all",
                                            {
                                                lastStep: this.lastStepDescription,
                                                round: this.gameRound,
                                                countSteps: statistic.countSteps,
                                                ownSteps: stepsOwn,
                                            });
                                    } else {
                                        if (cell.ownCount !== 0 && cell.count === 1) {
                                            return i18ntDangerHtml("onex:types." + this.state.gameCodeId + ".onex_one_step_result_not_lost",
                                                {
                                                    lastStep: this.lastStepDescription,
                                                    round: this.gameRound,
                                                    countSteps: statistic.countSteps,
                                                    ownSteps: stepsOwn,
                                                    ownLostSteps: stepsLostOwn
                                                });
                                        } else {
                                            return i18ntDangerHtml("onex:types." + this.state.gameCodeId + ".onex_one_step_result_lost",
                                                {
                                                    lastStep: this.lastStepDescription,
                                                    round: this.gameRound,
                                                    countSteps: statistic.countSteps,
                                                    ownSteps: stepsOwn,
                                                    ownLostSteps: stepsLostOwn
                                                });
                                        }
                                    }
                                }
                            } else {
                                return i18ntDangerHtml("onex:common.onex_choose_cell_step");
                            }
                        }
                        return i18ntDangerHtml("onex:common.onex_choose_cell_step");
                    default:
                        break;
                }
         */
        return dangerHtml('');
    };

    const doTurn = async (position) => {
        if (position === -1 || statistics.isGameOver) {
            return;
        }

        const {cache, api} = props;
        /*
                    if (this.isGameOver) {
                        const {mode, boardId = GameOneView.getBoardId(mode)} = this.props.match.params;
                        this.setState({
                            game: this.initialGame(this.currentType, mode, boardId)
                        });
                        gameId = null;
                        this.isGameOver = false;
                    }
        */
        const gameInfo = settings.games[gameTypeIndex];

        const response = await api.auth.requestTokenApiData({
            request: '/api/v2/do_step',
            query: {
                lang: 'en'
                , board_id: boardId
                , settings_id: settings.id
                , sub_type_id: gameInfo.id
                , game_id: gameId
                , value: cost.value * 100
                , type: cost.type
                , num_step: statistics.countSteps
                , player_step: position
            }
        });

        console.log(response);

        const {numbers} = response;
        if (response.game_id !== undefined && gameId !== response.game_id) {
            game.gameId = response.game_id;

            await cache.db.setLastGameIdOneX(boardId, gameCodeId, response.game_id, gameTypeIndex);
            await cache.db.setCommonGames(boardId, response.game_id, gameTypeIndex, "", settings.id, gameInfo.id);
        }

        if (numbers !== undefined && numbers !== null) {
            let gameInfo = [];
            numbers.forEach((num) => {
                const {own = 0} = num;
                const field = game.cells[num.pos];

                game.statistics = updateIncrementStatistics(game.statistics, field, num.pos, num.all, own);

                field.count += num.all;
                field.ownCount += own;

                gameInfo.push({
                    count: field.count,
                    ownCount: field.ownCount,
                    position: num.pos,
                    cost: cost
                });
            });
            //сохранение дополнительной информации
            gameInfo.push({
                position: -1,
                time: response.timestamp,
                next_prizes: response.next_prizes,
//                    position: lastPosition,
                cost: cost,
                round: response.code_round,
                is_game_over: response.game_over
            });

            await cache.db.setTurnsOneX(boardId, game.gameId, gameInfo);
            /*
                        if (this.isGameOver) {
                            let game = this.state.game;
                            game.lastPosition = this.lastPosition;
                            game.lastOne = this.getPositionLastOne(this.state.game.cells);
                            game.consolationPosition = this.lastPosition;
                            game.prizePosition = mode === "zero" ? this.lastPosition : this.getPositionLastOne(this.state.game.cells);
                            console.log(" game.lastOne = ", game.lastOne);
                            this.setState({
                                game: game,
                            });
                        }
            */
            setState({
                ...state
                , selectedPosition: -1
                , selectPositionDescription: ''
            });

            updateGame({...game});
        }

        /*
                this.gameRound = turnData.code_round;
                this.isGameOver = turnData.game_over === true;

                lastPosition = state.selectedPosition;
                lastStepDescription = getCellDescription(settings, lastPosition, gameTypeIndex);
                        if (turnData.next_prizes !== undefined) {
                            this.prize = turnData.next_prizes[0][0].value / 100;//TODO Рассчет приза
                        }


                        //this.onSaveGameInfo();
                        this.updateDescription();
                        if (!this.state.isAnimate) {
                            this.setState({isAnimate: true});
                            this.animationTimer = setTimeout(this.stopAnimation, ANIMATION_TIME);
                        } else {
                            clearTimeout(this.animationTimer);
                            this.animationTimer = setTimeout(this.stopAnimation, ANIMATION_TIME);
                        }
         */
    };

    const newGame = () => {
        /*********
         const {mode, boardId = GameOneView.getBoardId(mode)} = this.props.match.params;
         this.initialData();
         this.setState({
            game: this.initialGame(this.currentType, mode, boardId)
        })
         */
    };

    const makeButtons = () => {
        let row = [];

        if (statistics.isGameOver) {
            row.push(
                <Row key={'continue'}>
                    <Col>
                        <Button onClick={() => newGame()} className="btn-danger btn-lg btn-block rounded">
                            {i18n.t("onex:common.continue")}
                        </Button>
                    </Col>
                </Row>);
        } else {
            row.push(
                <Row key={'turn'}>
                    <Col className={'px-1'}>
                        <Button onClick={() => doTurn(selectedPosition)} className="btn-danger btn-lg btn-block rounded" disabled={selectedPosition === -1}>
                            {i18n.t(`onex:types.${gameCodeId}.turn`, {position: selectPositionDescription})}
                        </Button>
                    </Col>
                </Row>);
        }
        return row;
    };

    const getPositionLastOne = (cells) => {
        for (let i = 0; i < cells.length; i++) {
            if (cells[i].count === 1) {
                return i;
            }
        }
        return -1;
    };

    const isLastOneOwn = () => {
        let cell = game.cells[getPositionLastOne(game.cells)];
        return cell !== null && cell.ownCount === 1;
    };

    const getLegendString = (mode) => {
        switch (mode) {
            case "zero":
                return i18ntDangerHtml(`onex:types.${mode}.onex_legend_info_zero`);
            case "one":
                if (statistics.isGameOver) {
                    if (isLastOneOwn()) {
                        return i18ntDangerHtml(`onex:types.${mode}.onex_legend_info_one_combo_win`);
                    } else {
                        return i18ntDangerHtml(`onex:types.${mode}.onex_legend_info_one_consolation_win`);
                    }
                } else {
                    return i18ntDangerHtml(`onex:types.${mode}.onex_legend_info_one`);
                }
            default:
                return i18ntDangerHtml("onex:common.onex_legend_info");
        }
    };

    /*
        stopAnimation = () => {
            this.setState({
                    isAnimate: false
                }
            )
        };


        handleButtonPress = () => {
            this.buttonPressTimer = setTimeout(() => {
                // console.log('long press activated');
                this.setLongPress(true);
            }, 1500);
        };

        handleButtonRelease = () => {
            clearTimeout(this.buttonPressTimer);
            this.setLongPress(false);
        };

        setLongPress(isLongPress) {
            this.setState({
                isLongPress: isLongPress
            })
        }
    */

    const getCurrentGameInfoCode = (settings, gameTypeIndex) => {
        const gameInfo = settings.games !== undefined ? settings.games[gameTypeIndex] : undefined;
        return gameInfo !== undefined ? gameInfo.code : '';
    };

    const onSelectedPosition = (position) => {
        if (!statistics.isGameOver) {
            setState(
                {
                    ...state
                    , selectedPosition: position
                    , selectPositionDescription: getCellDescription(settings, position, gameTypeIndex)
                }
            );
        }
    };

    return (
        <SimpleContainer BackGroundColor={'#ceebe4'}>
            <Row>
                <Col style={{backgroundColor: 'white'}}>
                    <SmilesCount className={'w-100 pl-1 pr-1'} gameCodeId={gameCodeId} accounts={100} description={i18n.t(`onex:types.${gameCodeId}.title`)}/>
                </Col>
            </Row>
            <Row>
                <Col className={"pl-1 pr-1 "}>
                    <Alert className={'runningLine pl-1 pr-1 pt-1 pb-0  m-1'}>
                        <MarqueeString className={"runningLine"} dangerouslySetInnerHTML={i18ntDangerHtml(`onex:types.${gameCodeId}.onex_middle_top_text`)}/>
                    </Alert>
                </Col>
            </Row>
            <Row>
                <Col className={'d-flex w-100 pl-1 pr-1'}>
                    <Selector title={i18n.t('common.select_type')}
                              value={getCurrentGameInfoCode(settings, gameTypeIndex)}
                              valueSizeClass={"tmcWidth-2"}
                              titleColor={"blue"}
                              isChangeable={onChangeable(gameCodeId)}
                              onChange={onChangeType}/>
                </Col>
                <Col className={"d-flex justify-content-center pl-1 pr-1 w-100"}>
                    <TextWithNumber title={i18n.t('onex:common.round')} number={gameRound}/>
                </Col>
                <Col className={"d-flex justify-content-end w-100 pl-1 pr-1"}>
                    <CostComponent onChangeCost={setCost} cost={cost}/>
                </Col>
            </Row>
            <Row>
                <Col className={"d-flex justify-content-center w-100"}>
                    <TimeComponent time={timeLastStep}/>
                </Col>
            </Row>
            <StatisticComponent gameCodeId={gameCodeId}
                                steps={statistics.countSteps !== undefined ? statistics.countSteps : '?'}
                                zeroCount={statistics.countZero !== undefined ? statistics.countZero : '?'}
                                oneCount={statistics.countOne !== undefined ? statistics.countOne : '?'}
                                prize={prize}/>
            <Row>
                <Col className={'px-1'}>
                    <Alert className={'runningLine px-1 pt-1 pb-0 m-1'}>
                        <MarqueeString dangerouslySetInnerHTML={getMarqueeString(gameCodeId)}/>
                    </Alert>
                </Col>
            </Row>
            <Row>
                <Col className={'p-1'}>
                    <Board settings={settings} mode={gameCodeId} game={game} gameTypeIndex={gameTypeIndex}
                           onSelected={onSelectedPosition} selectedPosition={selectedPosition}/>
                </Col>
            </Row>
            {makeButtons()}
            <Row>
                <Col className={"pl-1 pr-1 "}>
                    <Alert className={'runningLine pl-1 pr-1 pt-1 pb-0 m-1'}>
                        <MarqueeString dangerouslySetInnerHTML={getLegendString(gameCodeId)}/>
                    </Alert>
                </Col>
            </Row>
        </SimpleContainer>
    );
};

const TimeComponent = (props) => {
    let currentTime = props.time === undefined ? new Date() : props.time;
    let moment = require('moment');
    moment.locale('en');

    return (
        <div>
            <span className={'numberComponent'}>{moment(currentTime).format('DD')}</span>
            <span className={'textComponent'}>{moment(currentTime).format('MMMM') + moment(currentTime).format('YYYY') + '@'}</span>
            <span className={'textComponent'}>{moment(currentTime).format('hh:mm:ss')}</span>
        </div>
    );
};

const StatisticComponent = (props) => {
    const makeElements = () => {
        let columns = [];

        columns.push(
            <Col key={1} className={"d-flex justify-content-start w-100 px-1"}>
                <TextWithNumber title={i18n.t(`onex:types.${props.gameCodeId}.steps`)} number={props.steps}/>
            </Col>);

        columns.push(
            <Col key={2} className={"d-flex justify-content-center px-1 w-100"}>
                <TextWithNumber title={i18n.t("onex:common.zero")} number={props.zeroCount}
                />
            </Col>);

        if (props.gameCodeId !== "zero") {
            columns.push(
                <Col key={4} className={"d-flex justify-content-center px-1 w-100"}>
                    <TextWithNumber title={"One:"} number={props.oneCount}/>
                </Col>);
        }

        columns.push(
            <Col key={3} className={"d-flex justify-content-end w-100 px-1"}>
                <PrizeCount title={"Prize:"} count={props.prize}/>
            </Col>);

        return columns;
    };

    return <Row>{makeElements()}</Row>;
};

const mapStateToProps = (state) => {
    return {
        api: state.api,
        cache: state.cache,
    }
};

export default connect(mapStateToProps)(GameOneView)
