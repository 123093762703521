import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {Bar} from 'react-chartjs-2';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

const dataTemplate = {
//    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [{
        label: 'Tickets',
        type: 'bar',
//        data: [51, 65, 40, 49, 60, 37, 40],
        fill: false,
        borderColor: '#EC932F',
        backgroundColor: '#EC932F',
        pointBorderColor: '#EC932F',
        pointBackgroundColor: '#EC932F',
        pointHoverBackgroundColor: '#EC932F',
        pointHoverBorderColor: '#EC932F',
        yAxisID: 'y-axis-1'
    }, {
        type: 'bar',
        label: 'Games',
//        data: [200, 185, 590, 621, 250, 400, 95],
        fill: false,
        backgroundColor: '#71B37C',
        borderColor: '#71B37C',
        hoverBackgroundColor: '#71B37C',
        hoverBorderColor: '#71B37C',
        yAxisID: 'y-axis-1'
    }]
};

const options = {
    responsive: true,
    tooltips: {
        mode: 'label'
    },
    elements: {
        line: {
            fill: false
        }
    },
    scales: {
        xAxes: [
            {
                display: true,
                gridLines: {
                    display: false
                }
            }
        ],
        yAxes: [
            {
                type: 'linear',
                display: true,
                position: 'left',
                id: 'y-axis-1',
                gridLines: {
                    display: true
                },
                labels: {
                    show: true
                },
                ticks: {
                    min: 0
                }
            }
        ]
    }
};

const columns = [{
    dataField: 'id',
    text: '#'
}, {
    dataField: 'token',
    text: 'Token',
    formatter: (cell, row) => {
        return (
            <Link to={{pathname: '/fp/stats/list', search: '?token=' + row.token}}>{row.token}</Link>
        );
    }
}, {
    dataField: 'date',
    text: 'Date',
    align: 'center',
    formatter: (cell, row) => {
        return (
            <Link to={{pathname: '/fp/stats/list', search: '?date=' + row.date}}>{row.date}</Link>
        );
    }
}, {
    dataField: 'tickets',
    text: 'Tickets',
    formatter: (cell, row) => {
        return (
            <Link to={{
                pathname: '/fp/stats/list',
                search: '?ticket=true&token=' + row.token + '&date=' + row.date
            }}>{row.tickets}</Link>
        );
    }
}, {
    dataField: 'games',
    text: 'Games',
    formatter: (cell, row) => {
        return (
            <Link to={{
                pathname: '/fp/stats/list',
                search: '?game=true&token=' + row.token + '&date=' + row.date
            }}>{row.games}</Link>
        );
    }
}];

class Statistics extends Component {
    state = {compact: [], dataBar: {}};

    render() {
        const {page = 1, sizePerPage = 10, totalSize = 0, compact = null, dataBar} = this.state;

        return (
            <div>
                <Bar
                    data={dataBar}
                    width={100}
                    height={50}
                    options={options}
                />
                <BootstrapTable
                    remote
                    striped
                    hover
                    keyField="id"
                    data={compact}
                    columns={columns}
                    pagination={paginationFactory({page, sizePerPage, totalSize, showTotal: false})}
                    onTableChange={(type, {page, sizePerPage}) => this.refreshCompact(page, sizePerPage)}
                />
            </div>
        );
    }

    componentDidMount() {
        const {page = 1, sizePerPage = 10} = this.state;
        this.refreshCompact(page, sizePerPage);
    }

    refreshCompact = async (page, sizePerPage) => {
        const from = (page - 1) * sizePerPage;
        const {api} = this.props;
        const response = await api.auth.requestApiJson({
            request: '/api/c/ticket/stats',
            query: {from, count: sizePerPage}
        });

        this.setState({
            page, sizePerPage,
            totalSize: response.all_count,
            compact: response.stats !== undefined ? response.stats.map((item, idx) => {
                item.id = from + idx + 1;
                return item;
            }) : [],
            dataBar: this.prepareBar(response.stats)
        })
    };

    prepareBar = (data) => {
        const dataBar = dataTemplate;
        let labels = [], tickets = [], games = [];
        let checkDate = '', dg = 0, dt = 0;

        if (data !== undefined) {
            data.forEach((item) => {
                if (item.date === undefined) {
                    return;
                }
                if (checkDate !== item.date) {
                    if (checkDate !== '') {
                        labels.unshift(checkDate);
                        tickets.unshift(dt);
                        games.unshift(dg);
                    }
                    checkDate = item.date;
                    dt = dg = 0;
                }
                if (item.games !== undefined) {
                    dg += item.games;
                }
                dt += item.tickets;
            });

            if (checkDate !== '') {
                labels.unshift(checkDate);
                tickets.unshift(dt);
                games.unshift(dg);
            }
        }

        dataBar.labels = labels;
        dataBar.datasets[0].data = tickets;
        dataBar.datasets[1].data = games;
        return dataBar;
    };
}

const mapStateToProps = (state) => {
    return {
        api: state.api,
        cache: state.cache,
    }
};

export default connect(mapStateToProps)(Statistics)
