import React, {useEffect, useState} from "react";
import SimpleContainer from "../../Pages/SimpleContainer";
import {useSelector} from "react-redux";
import * as queryString from "query-string";
import {useTranslation} from "react-i18next";

export const PayLanding = (props) => {
    const {t} = useTranslation();
    const api = useSelector(state => state.api);
    const [payed, setPayed] = useState(false);

    // монтирование
    useEffect(() => {
        let parsed = queryString.parse(props.location.search);
        const {game_id: code1, ticket: code2} = parsed;
        const code = code2 !== undefined ? code2 : code1;

        const doPay = async (code) => {
            const response = await api.auth.requestApiJson({
                request: '/api/c/ft/pay',
                query: {
                    ticket: code
                }
            });

            const {status} = response;
            if (status !== undefined && status.status === 'pay') {
                setPayed(true);
            }
        };

        doPay(code).then(value => {
        });
    }, [props.location.search, api.auth]);

    return (
        <SimpleContainer BackGroundColor={'white'}>
            {payed ? t('common.prize_payed') : ''}
        </SimpleContainer>
    );
}
