export default class WebSocketApi {
    connect = () => {
        const url = (process.env.REACT_APP_WEBSOCKET_URL !== undefined && process.env.REACT_APP_WEBSOCKET_URL.length > 0) ? process.env.REACT_APP_WEBSOCKET_URL : ('wss://' + window.location.hostname + '/ws/');
        this.ws = new WebSocket(url);

        this.ws.onopen = () => {
            console.log(new Date() + ' connected to ' + url);
            this.ws.send(JSON.stringify({t: 'cn', tw: '', ta: 'asd', a: {a: 1, b: [1, 2, 3]}}));
        };

        this.ws.onmessage = (message) => {
            console.log(message.data);
            /*
                        const message = JSON.parse(evt.data)
                        this.addMessage(message)
            */
        };

        this.ws.onclose = () => {
            console.log(new Date() + 'disconnected');
            // automatically try to reconnect on connection loss
            this.ws = undefined;
        }
    };

    send = (message) => {
        if (this.ws !== undefined) {
            this.ws.send(JSON.stringify(message));
        }
    };
}
