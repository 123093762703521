import React, {Component} from 'react';

class Language extends Component {

    render() {
        return (
            <div>
                Language
            </div>
        );
    }
}

export default Language;