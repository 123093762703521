import "core-js/stable";
import "regenerator-runtime/runtime";
import 'isomorphic-fetch';
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux'

// Styles
import './index.scss'
import './i18n';
import {App} from './containers/'
import configureStore from './store/configureStore'

const store = configureStore();
ReactDOM.render((
    <Provider store={store}>
        <App/>
    </Provider>
), document.getElementById('root'));
