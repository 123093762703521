import React, {Component} from 'react';
import {Button, Col, Container, Row} from "reactstrap";
import i18n, {dangerHtml, i18ntDangerHtml} from "../../../i18n";


/*
const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red"
};
*/

const Comp = (props) => {
    return (
        <Container className={"smooth-container"} fluid={true} style={{textAlign: 'center'}}>
            <Row>
                <Col xs={12} sm={12} md={{size: 4, offset: 4}} lg={{size: 3, offset: 5}}
                     style={{backgroundColor: '#ceebe4'}}>
                    {props.children}
                    <Row>
                        <Col className={'px-1 info-white'}>
                                    <span className="ml-auto"
                                          dangerouslySetInnerHTML={i18ntDangerHtml('app.copyright')}/>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>);
};

class AdExample extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowAd: false
        };

    }


    onChange = () => {
        const {adType = -1, isShowAd = false} = this.state;
        // console.log("isShowAd = ", isShowAd);
        this.setState({
            isShowAd: !isShowAd,
            adType: this.getAdType(adType),
        })
    };

    getAdType = (adType) => adType >= AdvertisingList.length - 1 ? 0 : adType + 1;
    /*

        advertising = () => {
            return (<div style={{height: "12rem"}}
                         className={"d-flex flex-column border shadow mx-auto my-2 px-2 bg-white rounded w-100 parent"}>
                <span className={"child-l-t"}>Advertising</span>
                {this.advertisingSwitch()}
            </div>)
        };
    */

    checkInfo = () => {
        let rows = [];
        rows.push(
            <Row key={'sc'} className={'my-1'}>
                <Col key={1} className={'px-0'}>
                    <span className={'t-blue'} dangerouslySetInnerHTML={i18ntDangerHtml('ft.secret_code_title')}/>
                    <span className={'t-red px-1'} dangerouslySetInnerHTML={dangerHtml("asdasdasdasdsadasd")}/>
                </Col>
            </Row>);
        return rows;
    };
    makeMatrix = () => {
        let rows = [];
        rows.push(
            <Row key={1}>
                <Col xs={'auto'} className={'bg-success'}>1111</Col>
                <Col className={'bg-alert'}>2222</Col>
            </Row>
        );
        rows.push(
            <Row key={2}>
                <Col xs={'auto'}>1111<br/>1111<br/>1111<br/>1111<br/></Col>
                <Col>2222</Col>
            </Row>
        );
        rows.push(
            <Row key={3}>
                <Col xs={'auto'}>1111</Col>
                <Col>2222</Col>
            </Row>
        );
        return rows;
    };

    makeAdvertising = () => {
        return (
            <div style={{position: "absolute", zIndex: '1000', height: "90%", width: "90%", top: '5%', left: '5%'}}
                 className={"d-flex flex-column border shadow px-2 bg-white rounded "}>
                <div key={'title'} className={'d-flex justify-content-between my-1'}>
                    <div>
                        <span className={'t-red'}>{i18n.t('advertising.title')}</span>
                    </div>
                    {
                        <div className={'d-flex justify-content-end'}>
                            <span className={'t-red'}>{5}</span>
                            <span className={'t-blue'}>"</span>
                        </div>
                    }
                </div>
                <div style={{position: "relative", height: "100%", width: "100%"}}>
                    {this.advertisingSwitch()}
                </div>


                <div className={' child-r-b ml-auto mt-auto my-1 '}>
                    <Button onClick={() => this.onChange()}>{i18n.t('advertising.close')}</Button>
                </div>
            </div>)
    };

    advertisingSwitch = () => {
        const {adType/*, isLodaing = false, isLoadingFailed = false*/} = this.state;
        const adItem = AdvertisingList[adType];
        const parentStyle = {position: "relative", height: "90%", width: "90%", top: '5%', left: '5%'};
        const childStyle = {position: "absolute", height: "100%", left: '50%', transform: 'translate(-50%,0)'};
        switch (adItem.sType) {
            case"video":
                console.log(adItem.sURL);
                return (
                    <div style={parentStyle} className={'bg-success'} key={adItem.sType}>
                        <video height={"100%"} width={"100%"} style={childStyle}
                               onAbort={() => console.log("video onAbort")}
                               onCanPlay={() => console.log("video onCanPlay")}
                               onCanPlayThrough={() => console.log("video onCanPlayThrough")}
                               onDurationChange={() => console.log("video onDurationChange")}
                               onEmptied={() => console.log("video onEmptied")}
                               onEncrypted={() => console.log("video onEncrypted")}
                               onEnded={() => console.log("video onEnded")}
                               onLoadedData={() => console.log("video onLoadedData")}
                               onLoadedMetadata={() => console.log("video onLoadedMetadata")}
                               onLoadStart={() => console.log("video onLoadStart")}
                               onPause={() => console.log("video onPause")}
                               onPlay={() => console.log("video onPlay")}
                               onPlaying={() => console.log("video onPlaying")}
                               onProgress={() => console.log("video onProgress")}
                               onRateChange={() => console.log("video onRateChange")}
                               onSeeked={() => console.log("video onSeeked")}
                               onSeeking={() => console.log("video onSeeking")}
                               onStalled={() => console.log("video onStalled")}
                               onSuspend={() => console.log("video onSuspend")}
                               onTimeUpdate={() => console.log("video onTimeUpdate")}
                               onVolumeChange={() => console.log("video onVolumeChange")}
                               onWaiting={() => console.log("video onWaiting")}
                               onLoad={() => this.onLoaded()}
                               onError={() => this.onLoadingFailed()}
                               autoPlay
                               poster="/img/loader.gif"
                        >
                            <source src={adItem.sURL} type='video/mp4'/>
                        </video>
                    </div>);
            case "text":
                return (
                    <div key={adItem.sType} className={'my-1 child'}>
                        <span dangerouslySetInnerHTML={i18ntDangerHtml(adItem.sBody)}/>
                    </div>
                );
            case "gif":
                return (
                    <div style={parentStyle}
                         className={'bg-success'} key={adItem.sType}>
                        <img style={childStyle} src={adItem.sURL}
                             onLoad={() => this.onLoaded()}
                             onError={() => this.onLoadingFailed()}
                             alt="loading..."/>
                        {/*  <img   height={"60%"} width={"60%"} src={adItem.sURL} alt="loading..."/>*/}
                    </div>
                );
            case "png":
                return (
                    <div style={parentStyle}
                         className={'bg-success'} key={adItem.sType}>
                        <img style={childStyle} src={adItem.sURL} alt="loading..."/></div>
                );
            default:
                return (
                    <Row key={adItem.sType} className={'my-1'}>
                        <span className={'t-blue'}>Реклама</span>
                    </Row>
                )

        }
    };

    onLoading = () => {
        console.log("loading onLoadStart");
        this.setState({
            isOnLoading: true
        })
    };

    onLoadingFailed = () => {
        console.log("loading onError");
        this.setState({
            isOnLoadingFailed: true,
            isOnLoading: false
        })
    };

    onLoaded = () => {
        console.log("loading finished");
    };

    render() {
        const {isShowAd = false} = this.state;

        return (
            <Comp>
                <Row className={'my-1'}>
                    <Col className={'text-info-1 info-white px-1'}>
                        <span dangerouslySetInnerHTML={dangerHtml("dskmfw[efmvj dine")}/>
                    </Col>
                </Row>
                <Row style={{position: "relative"}} className={'bg-danger'}>
                    <Col>
                        {this.makeMatrix()}
                        {this.checkInfo()}

                        {isShowAd ? this.makeAdvertising() : <></>}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button className={'btn-lg btn-block'}
                                onClick={() => this.onChange()}>{"Переход " + (isShowAd ? "к игре" : "к рекламе")}
                        </Button>
                    </Col>
                </Row>

                {/* <div style={{height: "12rem"}} className={"d-flex flex-column bd-highlight"}>
                    {isShowAd ?
                        this.advertising() : <>
                            <div key={"matrix"} style={{background: "yellow", height: "10rem"}}>
                            </div>
                            {this.checkInfo()}
                        </>
                    }
                    <Button onClick={() => this.onChange()}>{"Переход " + (isShowAd ? "к игре" : "к рекламе")}</Button>
                </div>*/}
            </Comp>
        )
    }
}

export default AdExample

const AdvertisingList = [
        {
            sType: "gif"
            , sBody: null
            , sURL: "http://khorost.net/img.gif"
            , bLandscape: false
        }
        , {
            sType: "png"
            , sBody: null
            , sURL: "http://khorost.net/unnamed.png"
            , bLandscape: true
        }
        , {
            sType: "video"
            , sBody: null
            , sURL: "http://khorost.net/telecast_360p.mp4"

            , bLandscape: true
        }
        , {
            sType: "video"
            , sBody: null
            , sURL: "http://khorost.net/vp360.mp4"

            , bLandscape: true
        }
        , {
            sType: "video"
            , sBody: null
            , sURL: "http://khorost.net/vp.webm"

            , bLandscape: true
        }
    ]
;
