import React, {Component} from "react";
import {connect} from "react-redux";
import * as queryString from 'query-string';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {Link} from "react-router-dom";
import Moment from "moment";

const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';

const columns = [{
    dataField: 'id',
    text: '#'
}, {
    dataField: 'token',
    text: 'Token'
}, {
    dataField: 'token_create',
    text: 'Create',
    align: 'center',
    formatter: (cell, row) => {
        return (
            <>{Moment(row.token_create).format(dateTimeFormat)}</>
        );
    }
}, {
    dataField: 'ticket',
    text: 'GameID',
    formatter: (cell, row) => {
        return (
            <><Link
                to={`/${row.game_prefix === 'Ft' ? 'fti' : 'fig'}?game_id=${row.ticket}`}>{row.ticket}</Link><br/>{getGameCode(row)}</>
        );
    }
}, {
    dataField: 'game_finish',
    text: 'Finish',
    formatter: (cell, row) => {
        return (
            <>{row.game_finish !== undefined ? Moment(row.game_finish).format(dateTimeFormat) : <>&nbsp;</>}</>
        );
    }
}, {
    dataField: 'game_reason',
    text: 'Reason'
}, {
    dataField: 'game_prize',
    text: 'Prize',
    formatter: (cell, row) => {
        return (
            <>{row.game_prize !== undefined ? row.game_prize / 100 : <>&nbsp;</>}</>
        );
    }
}, {
    dataField: 'game_payed',
    text: 'Payed',
    formatter: (cell, row) => {
        return (
            <>{row.game_payed ? <i className="fa fa-check" aria-hidden="true"/> : <>&nbsp;</>}</>
        );
    }
}];

const getGameCode = (item) => item.game_prefix === undefined ? '' : item.game_prefix + '_' + item.game_code + '@' + item.game_price / 100 + 'b';

class List extends Component {
    constructor(props) {
        super(props);

        let parsed = queryString.parse(this.props.location.search);
        const {token, date, game, ticket} = parsed;

        this.state = {
            list: []
            , query: {
                token, date, game, ticket
            }
        };
    }

    render() {
        const {list = null, page = 1, sizePerPage = 10, totalSize = 0} = this.state;

        return (
            <div>
                <BootstrapTable
                    remote
                    striped
                    hover
                    keyField="id"
                    data={list}
                    columns={columns}
                    pagination={paginationFactory({page, sizePerPage, totalSize, showTotal: false})}
                    onTableChange={(type, {page, sizePerPage}) => this.refreshList(page, sizePerPage)}
                />
            </div>
        )
    }

    componentDidMount() {
        const {page = 1, sizePerPage = 10} = this.state;
        this.refreshList(page, sizePerPage);
    }

    refreshList = async (page, sizePerPage) => {
        const from = (page - 1) * sizePerPage;
        const {query} = this.state;
        const {api} = this.props;
        const response = await api.auth.requestApiJson({
            request: '/api/c/ticket/stats/list',
            query: {...query, from, count: sizePerPage}
        });

        this.setState({
            page, sizePerPage,
            totalSize: response.all_count,
            list: response.list !== undefined ? response.list.map((item, idx) => {
                item.id = from + idx + 1;
                return item;
            }) : [],
        })
    };
}

const mapStateToProps = (state) => {
    return {
        api: state.api,
        cache: state.cache,
    }
};

export default connect(mapStateToProps)(List)
