import React, {Component} from 'react';

class Accounts extends Component {

    render() {
        return (
            <div>

                1123
            </div>
        );
    }
}

export default Accounts;