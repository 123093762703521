import React from "react";
import Game from "./Game";
import {connect} from "react-redux";
import * as queryString from 'query-string';
import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import i18n, {i18ntDangerHtml} from "../../../i18n";
import {fillCellsFromResponse, getCheckURI, prepareGameInfo} from "../../Games/Common/FloorX";
import {getCharByIndex} from "../../../components/Common/GameControl/Utils";
import Settings from "../Settings";

class PaperInteractive extends Game {
    constructor(props) {
        super(props);

        let parsed = queryString.parse(this.props.location.search);
        const {game_id: code1, ticket: code2, color = 'false'} = parsed;
        const code = code2 !== undefined ? code2 : code1;

        this.state = {
            zonesActive: true
            , color: color === 'true'
        };

        this.startGame(code).then(r => {
        });
    }

    startGame = async (code) => {
        const {api, cache} = this.props;
        const gameInfo = await cache.db.getPaper(code, api);
        const response = await api.auth.requestApiJson({
            request: '/api/c/ticket/status',
            query: {
                ticket: gameInfo.ticket
            }
        });

        const {result, status} = response;
        const game = prepareGameInfo(gameInfo);
        fillCellsFromResponse(game, result);

        if (!status.game_over) {
            this.timerId = this.startTimer(() => {
                const serverTime = api.auth.adopt2ServerTime(new Date());
                this.setState({
                    gameExpire: game.validTo < serverTime,
                    serverTime: serverTime
                });
            }, 1000);
        }

        this.setState({
            isShowNewGameDialog: false,
            eog: status.game_over,
            zonesActive: !status.game_over,
            prize: result.prize / 100,
            prizeNumber: result.prize_number,
            game
        });
    };

    quadColor = () => 'blue';

    onBtnNewGame = () => {
        this.setState({
            isShowNewGameDialog: true
        });
    };

    toggleStartSelect = () => {
        this.setState(prevState => ({
            isShowNewGameDialog: !prevState.isShowNewGameDialog
        }));
    };

    onBtnStartNewGame = async (id) => {
        const {api, cache} = this.props;
        const {game} = this.state;

        const response = await api.auth.requestApiJson({
            request: '/api/c/ticket/take',
            query: {
                settings_id: game.settings_id,
                type: id,
                price: game.cost * 100, // TODO
                code: 'g'
            }
        });

        api.auth.saveServerTimestamp(response.server_time);
        await cache.db.setPaper(response.ticket, response.expire, response);

        this.startGame(response.ticket);
    };

    makeNewGameSelectDialog = () => {
        const {isShowNewGameDialog = false, game} = this.state;
        const superGame = Settings.getSuperGame(game.settings_id);

        return (
            <Modal isOpen={isShowNewGameDialog} toggle={this.toggleStartSelect}>
                <ModalHeader toggle={this.toggleStartSelect}>{i18n.t('common.new_game_title')}</ModalHeader>
                <ModalBody>
                    {superGame.games && superGame.games.filter(g => g.id === 'f2' || g.id === 'f4').map((g, key) => (
                        <div key={key} className={'my-2'}>
                            <Button color="primary" className={'btn-block rounded'} onClick={() => this.onBtnStartNewGame(g.id)}>
                                {i18n.t(`modes.${superGame.restart ? 'fbl_plus' : 'fbl_zero'}.title`)}_{g.code}
                            </Button>
                        </div>))
                    }
                </ModalBody>
            </Modal>);
    };

    makeButtons = () => {
        const {eog, game} = this.state;
        let rows = [];
        if (eog) {
            rows.push(
                <Row key={1} className={'py-1'}>
                    {this.makeNewGameSelectDialog()}
                    <Col xs={6}>
                        <Button color="primary" className={'btn-block'} onClick={() => this.onBtnNewGame()}>
                            {i18n.t('common.new_game')}
                        </Button>
                    </Col>
                    <Col xs={6}>
                        <Button color="primary" className={'btn-block'}
                                href={getCheckURI(game.secretCode + '_' + game.serverSteps, game.publicCode)}
                                target='_blank'>
                            {i18n.t('common.check_game')}
                        </Button>
                    </Col>
                </Row>
            );
        }

        return rows;
    };

    makePreFooter = (subtype) => {
        const {ecoupon = true, prize = 0, prizeNumber = 0, game, eog, gameExpire = false} = this.state;
        const {step = -1, stepsServer = [], payed = false} = game;
        let rows = [];
        let cols = [];
        let className = 'py-1';

        if (!eog) {
            let tt, styleMarquee = {background: 'cyan', color: 'black', textAlign: 'center'};

            if (gameExpire) {
                tt = i18n.t(subtype + '.interactive_info_expire');
            } else if (stepsServer.length === 0) {
                tt = i18n.t(subtype + '.interactive_info_play');
            } else {
                if (stepsServer.filter(i => i === step).length > 0) {
                    tt = i18n.t(subtype + '.interactive_info_not_goal');
                } else {
                    tt = i18n.t(subtype + '.interactive_info_goal');
                }
            }

            rows.push(
                <Row key={'f2'}>
                    <Col xs={12} key={'fl'} className={'p-1 paper-top-h1'} style={{textAlign: 'left'}}>
                        <div className={'d-flex flex-column justify-content-center'}>
                            <div key={3} className={'px-2 py-1'} style={styleMarquee}>{tt}</div>
                        </div>
                    </Col>
                </Row>
            );
        } else {
            className = 'py-1 paper-top-h1';
        }

        if (gameExpire) {
            cols.push(
                <Col key={3} xs={12}>
                    <Button color="danger" className={'btn-block'} onClick={() => this.onBtnTakePrize(false)}>
                        {i18n.t('common.check_game0')}
                    </Button>
                </Col>
            );
        } else if (eog) {
            if (ecoupon && prize > 0) {
                cols.push(
                    <Col key={2} xs={12}>
                        <Button color="success" className={'btn-block'} disabled={payed}
                                onClick={this.toggle} dangerouslySetInnerHTML={i18ntDangerHtml(payed ? 'common.prize_payed' : 'common.prize_notpayed', {
                            prize: prize,
                            kick: prizeNumber
                        })}/>
                        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                            <ModalHeader toggle={this.toggle}>{i18n.t('common.paper_prize_title')}</ModalHeader>
                            <ModalBody>
                                <div>{i18n.t('common.paper_prize_body', {prize, kick: prizeNumber})}</div>
                                <div dangerouslySetInnerHTML={i18ntDangerHtml('common.paper_prize_body2')}/>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onClick={this.toggle}>{i18n.t('common.ok')}</Button>{' '}
                            </ModalFooter>
                        </Modal>
                    </Col>);
            }
        } else if (prize === 0 && prizeNumber === 0) {
            cols.push(
                <Col key={4} xs={12}>
                    <Button color="danger" className={'btn-block'} disabled={step === -1}
                            onClick={() => this.onBtnPlay()}>
                        {i18n.t('common.play0', {step: step === -1 ? '' : getCharByIndex('A', step)})}
                    </Button>
                </Col>
            );
        } else {
            cols.push(
                <Col key={3} xs={6} className={'px-1'}>
                    <Button color="danger" className={'btn-block'}
                            onClick={() => this.onBtnTakePrize(false)}>
                        {i18n.t(prize !== 0 ? 'common.take_prize' : 'common.check_game0')}
                    </Button>
                </Col>
            );
            cols.push(
                <Col key={4} xs={6} className={'px-1'}>
                    <Button color="danger" className={'btn-block'} disabled={step === -1 || stepsServer.length > 0}
                            onClick={() => this.onBtnPlay()}>
                        {i18n.t('common.play0', {step: step === -1 || stepsServer.length > 0 ? '' : getCharByIndex('A', step)})}
                    </Button>
                </Col>
            );
        }

        if (cols.length > 0) {
            rows.push(<Row key={1} className={className}>{cols}</Row>);
        }
        return rows;
    };

    onBtnPlay = async () => {
        const {game} = this.state;
        const {ticket, step, position} = game;
        const {api} = this.props;

        const response = await api.auth.requestApiJson({
            request: '/api/c/ticket/step',
            query: {
                ticket,
                step: getCharByIndex('A', step),
                pos: position
            }
        });

        if (response.error === undefined) {
            const result = response.result;

            api.auth.saveServerTimestamp(result.server_time);

            game.stepsServer = fillCellsFromResponse(game, result);
            game.position = position + 1;

            if (response.status.game_over) {
                game.stepsServer = [];

                this.stopTimer(this.timerId);
                this.timerId = undefined;
            }

            this.setState({
                eog: response.status.game_over,
                zonesActive: !response.status.game_over,
                error: undefined,
                prize: result.prize / 100,
                prizeNumber: result.prize_number,
                game
            });
        } else {
            this.setState({
                error: response.error
            });
        }
    };
}

const mapStateToProps = (state) => {
    return {
        api: state.api,
        cache: state.cache,
    }
};

export default connect(mapStateToProps)(PaperInteractive)
