import React, {useCallback, useEffect, useState} from "react";
import * as queryString from "query-string";
import {useDispatch, useSelector} from "react-redux";
import {
    dateTimeFormat, fillCellsFromResponse, getCheckURI, getGameURI, makeCellXmt2, makeErrorText, makeMultiplicator2, prepareGameInfo
} from "../Common/FloorX";
import {Redirect} from "react-router-dom";
import {Alert, Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import i18n, {dangerHtml, i18ntDangerHtml} from "../../../i18n";
import SimpleContainer from "../../Pages/SimpleContainer";
import Settings from "../../Football/Settings";
import {
    dataDiff, getCharByIndex, map2Tags, parseRoundValue, useInterval
} from "../../../components/Common/GameControl/Utils";
import {useTranslation} from "react-i18next";
import QRCode from "qrcode.react";
import Moment from "moment";
import {Footer0} from "../Common/Utils";
import {setBackURI} from "../../../actions/ConfigAction";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const delay = 1000;
const star_count = 10, star_top = 5, star_bottom = 40;
const snow_count = 25, snow_top = 25, snow_bottom = 70;

export const InteractiveFloorX = (props) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const api = useSelector(state => state.api);
    const cache = useSelector(state => state.cache);
    const config = useSelector(state => state.config);

    const [state, setState] = useState({});
    const [game, updateGame] = useState({});
    const [isShowLegend, toggleShowLegend] = useState(false);
    const [isRunning, setIsRunning] = useState(false);
    const [serverTime, setServerTime] = useState(undefined);
    const [qrDialog, openQrDialog] = useState(false);
    const [modalPay, setModalPay] = useState(false);
    const [enabledCheckDialog, openCheckDialog] = useState(false);
    const [stars, setStars] = useState([]);
    const [snows, setSnows] = useState([]);
    const [isNewGameDialog, showNewGameDialog] = useState(false);

    const startGame = useCallback((code, color) => {
        let skip = false;

        cache.db.getPaper(code, api)
            .then(async gameInfo => {
                const response = await api.auth.requestApiJson({
                    request: '/api/c/ticket/status',
                    query: {
                        ticket: gameInfo.ticket
                    }
                });

                const {result, status} = response;
                const newGame = prepareGameInfo(gameInfo);
                fillCellsFromResponse(newGame, result);

                updateGame(newGame);

                if (!skip) {
                    setState({
                        color: color === 'true',
                        eog: status.game_over,
                        zonesActive: !status.game_over,
                        prize: result.prize / 100,
                        prizeNumber: result.prize_number
                    });

                    if (!status.game_over) {
                        setIsRunning(true);
                        if (newGame.cells[0].status === 'future') {
                            openQrDialog(true);
                        }
                    }
                }
            });

        const randomAnimation = (count, top, bottom) => {
            let v = [];
            const delta = bottom - top;
            for (let k = 0; k < count; k++) {
                v.push({
                    x: Math.floor(Math.random() * 100),
                    y: top + Math.floor(Math.random() * delta),
                    delay: -Math.floor(Math.random() * 10)
                });
            }

            return v;
        };

        setStars(randomAnimation(star_count, star_top, star_bottom));
        setSnows(randomAnimation(snow_count, snow_top, snow_bottom));
        showNewGameDialog(false);

        return () => {
            skip = true;
        };
    }, [api, cache.db]);

    // монтирование
    useEffect(() => {
        let parsed = queryString.parse(props.location.search);
        const {game_id: code1, ticket: code2, color = 'true', back} = parsed;
        const code = code2 !== undefined ? code2 : code1;

        if (back !== undefined && config.BackURI !== back) {
            dispatch(
                setBackURI(back)
            );
        } else {
            startGame(code, color);
        }
    }, [config.BackURI, dispatch, props.location.search, startGame]);

    useInterval(() => {
        const now = api.auth.adopt2ServerTime(new Date());
        const gameExpire = game.validTo < now;

        if (gameExpire) {
            setIsRunning(false);

            onBtnTakePrize();
        }
        setServerTime(now);
    }, isRunning ? delay : null);

    if (game.subtype === undefined) {
        return <SimpleContainer BackGroundColor={'white'} Footer0={Footer0}>{t('common.loading')}</SimpleContainer>;
    } else if (game.subtype !== 'xmt' || (state.color !== undefined && !state.color)) {
        // поддержка старого
        return <Redirect to={{pathname: '/fiz', search: props.location.search}}/>;
    }

    const onBtnClick = (row, col) => {
        const {stepsServer = [], position = 0} = game;
        let nextGame = {...game};

        if (stepsServer.length > 0) {
            nextGame.stepsServer = [];
            nextGame.step = -1;
        }

        if (row - 1 === position) {
            if (nextGame.step === col - 1) {
                nextGame.step = -1;
            } else {
                nextGame.step = col - 1;
            }
            updateGame(nextGame);
        }
    };

    const onBtnPay = async () => {
        if (modalPay) {
            const response = await api.auth.requestApiJson({
                request: '/api/c/ticket/pay',
                query: {
                    ticket: game.ticket
                }
            });

            const {status} = response;
            if (status !== undefined && status.status === 'pay') {
                game.payed = true;
            }
        }

        setModalPay(false);
        updateGame({...game});
    };

    const onBtnTakePrize = async () => {
        const {ticket} = game;

        const response = await api.auth.requestApiJson({
            request: '/api/c/ticket/check',
            json: {
                ticket
            }
        });

        setIsRunning(false);

        const {error, result} = response;

        if (result !== undefined) {
            fillCellsFromResponse(game, result);

            game.stepsServer = [];

            setState({
                ...state,
                eog: true,
                zonesActive: false,
                ecoupon: true,
                error: undefined,
                gameExpire: undefined,
                finish: new Date(result.finish),
                prize: result.prize / 100,
                prizeNumber: result.prize_number
            });
        } else {
            setState({
                ...state
                , error: error
            });
        }
    };

    const getServerStepsFormat = () => {
        const {serverParts = [], prizes = [], cells = []} = game;
        let result = '';

        const qm = (c) => {
            let res = '';
            for (let k = 0; k < c; ++k) {
                res += 'X';
            }
            return res;
        };

        for (let k = 0; k < prizes.length; k++) {
            result += `<span class='t-blue'>${k + 1}</span><span class='t-red'>${k < serverParts.length ? serverParts[k] : qm(cells[k].multiplicator)}</span>`;
        }

        return result;
    };

    const onBtnNewGame = () => {
        showNewGameDialog(true);
    };

    const toggleStartSelect = () => {
        showNewGameDialog(prevState => !prevState);
    };

    const onBtnStartNewGame = async (id, price) => {
        const response = await api.auth.requestApiJson({
            request: '/api/c/ticket/take',
            query: {
                settings_id: game.settings_id,
                type: id,
                price,
                code: 'g'
            }
        });

        api.auth.saveServerTimestamp(response.server_time);
        await cache.db.setPaper(response.ticket, response.expire, response);

        startGame(response.ticket, 'true');
    };

    const makeNewGameSelectDialog = () => {
        const superGame = Settings.getSuperGame(game.settings_id);

        return (
            <Modal isOpen={isNewGameDialog} toggle={toggleStartSelect}>
                <ModalHeader toggle={toggleStartSelect}>{i18n.t('common.new_game_title')}</ModalHeader>
                <ModalBody>
                    {config.BackURI !== undefined
                        ? <Button className={'btn rounded w-100 py-2 my-1 text-white'} color={'success'}
                                  href={config.BackURI}>{i18n.t('common.back_page')}</Button>
                        : <></>}
                    {superGame.games && superGame.games.map((g, key) => (
                        <div key={key} className={'my-2'}>
                            <Button color="primary" className={'btn-block rounded'} onClick={() => onBtnStartNewGame(g.id, 1000)}>
                                <span dangerouslySetInnerHTML={i18ntDangerHtml('xmt.item_new_game', {
                                    title: i18n.t(`modes.${superGame.restart ? 'xmt_plus' : 'xmt_zero'}.title`)
                                    , code: g.code
                                    , max_prize: parseRoundValue(Settings.getMaxPrize(settings_id, parseInt(g.code), 10))
                                    , cost: 10
                                })}/>
                            </Button>
                        </div>))
                    }
                </ModalBody>
            </Modal>);
    };

    const makeFooter = (subtype, state, game) => {
        const {ecoupon = true, prize = 0, prizeNumber = 0, eog, gameExpire = false} = state;
        const {step = -1, stepsServer = [], payed = false} = game;

        let rows = [];
        let cols = [];

        if (gameExpire) {
            cols.push(
                <Col key={3} xs={12}>
                    <Button color="danger" className={'btn-block rounded'} onClick={() => onBtnTakePrize()}>
                        {i18n.t('common.check_game0')}
                    </Button>
                </Col>
            );
        } else if (eog) {
            if (ecoupon && prize > 0) {
                cols.push(
                    <Col key={2} xs={12}>
                        <Button color="success" className={'btn-block rounded text-white'} disabled={payed}
                                onClick={() => setModalPay(true)}
                                dangerouslySetInnerHTML={i18ntDangerHtml(payed ? 'common.prize_payed' : 'common.prize_notpayed', {
                                    prize: `<span class='t-red'>${prize}</span>b`,
                                    kick: prizeNumber
                                })}/>
                        <Modal isOpen={modalPay} toggle={() => setModalPay(!modalPay)} className={props.className}>
                            <ModalHeader
                                toggle={() => setModalPay(!modalPay)}>{i18n.t('common.paper_prize_title')}</ModalHeader>
                            <ModalBody>
                                <div>{i18n.t('common.paper_prize_body', {prize, kick: prizeNumber})}</div>
                                <div dangerouslySetInnerHTML={i18ntDangerHtml('common.paper_prize_body2')}/>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onClick={onBtnPay}>{i18n.t('common.ok')}</Button>{' '}
                            </ModalFooter>
                        </Modal>
                    </Col>);
            }
        } else if (prize === 0 && prizeNumber === 0) {
            cols.push(
                <Col key={4} xs={12}>
                    <Button color="danger" className={'btn-block rounded'} disabled={step === -1}
                            onClick={() => onBtnPlay()}>
                        {i18n.t('common.play0', {step: step === -1 ? '' : getCharByIndex('A', step)})}
                    </Button>
                </Col>
            );
        } else {
            cols.push(
                <Col key={3} xs={6} className={'px-1'}>
                    <Button color="danger" className={'btn-block rounded'}
                            onClick={() => prize !== 0 ? onBtnTakePrize() : openCheckDialog(!enabledCheckDialog)}>{i18n.t(prize !== 0 ? 'common.take_prize' : 'common.check_game0')}</Button>
                </Col>
            );
            cols.push(
                <Col key={4} xs={6} className={'px-1'}>
                    <Button color="danger" className={'btn-block rounded'} disabled={step === -1 || stepsServer.length > 0}
                            onClick={() => onBtnPlay()}>
                        {i18n.t('common.play0', {step: step === -1 || stepsServer.length > 0 ? '' : getCharByIndex('A', step)})}
                    </Button>
                </Col>
            );
        }

        if (cols.length > 0) {
            rows.push(<Row key={'buttons'} className={'my-2'}>{cols}</Row>);
        }

        if (eog) {
            rows.push(
                <Row key={'check'} className={cols.length > 0 ? 'mb-2' : 'my-2'}>
                    <Col className={'px-2'}>
                        <Button color="primary" className={'btn-block rounded'} onClick={onBtnNewGame}>
                            {i18n.t('common.new_game')}
                        </Button>
                    </Col>
                    <Col className={'px-2'}>
                        <Button color="primary" className={'btn-block rounded'} target='_blank'
                                href={getCheckURI(`${game.secretCode}_${game.serverSteps}`, game.publicCode)}>
                            {i18n.t('common.check_game0')}
                        </Button>
                    </Col>
                    {makeNewGameSelectDialog()}
                </Row>
            );

            rows.push(
                <Row key={'sc'}>
                    <Col key={1} className={'px-1 wordBreakAll text-left'}>
                        <div dangerouslySetInnerHTML={i18ntDangerHtml(`${subtype}.paper_secret_code`)}/>
                        <div dangerouslySetInnerHTML={i18ntDangerHtml('common.paper_secret_key', {secret_key: game.secretCode})}/>
                        <div dangerouslySetInnerHTML={i18ntDangerHtml(`${subtype}.paper_lock_zones`, {lock_zones: getServerStepsFormat()})}/>
                    </Col>
                </Row>
            );
            rows.push(
                <Row key={'pc'}>
                    <Col key={1} className={'px-0 wordBreakAll'}>
                        <span className={'t-blue'} dangerouslySetInnerHTML={i18ntDangerHtml('xmt.open_code_title')}/>
                        <span className={'t-red px-1'}>{game.publicCode}</span>
                    </Col>
                </Row>
            );
        }

        return rows;
    };

    const onBtnPlay = async () => {
        const {ticket, step, position} = game;

        const response = await api.auth.requestApiJson({
            request: '/api/c/ticket/step',
            query: {
                ticket,
                step: getCharByIndex('A', step),
                pos: position
            }
        });

        if (response.error === undefined) {
            const result = response.result;

            api.auth.saveServerTimestamp(result.server_time);

            game.stepsServer = fillCellsFromResponse(game, result);

            if (response.status.game_over) {
                game.stepsServer = [];
                setIsRunning(false);
            } else {
                game.position = position + 1;
            }

            updateGame(game);

            setState({
                ...state,
                eog: response.status.game_over,
                zonesActive: !response.status.game_over,
                error: undefined,
                prize: result.prize / 100,
                prizeNumber: result.prize_number
            });
        } else {
            setState({
                ...state,
                error: response.error
            });
        }
    };

    const makeSecondHeader = () => {
        const result = getWinLost(game.cells);

        return (
            <Row>
                <Col xs={2} className={'paper-right-h2-white'}>
                    <Row>
                        <Col className={'p-0 font-weight-bold'}>{i18n.t(`${subtype}.step_title`)}</Col>
                    </Row>
                    <Row>
                        <Col>
                            <span>#</span>
                            <span className={'t-red'}>{prizeNumber}</span>
                        </Col>
                    </Row>
                </Col>
                <Col xs={8} className={'p-0 d-flex flex-column'}>
                    <div>{i18n.t(`xmt.${!superGame.restart || result.lost === 0 ? 'info_top0' : 'info_top'}`)}</div>
                    <div>
                        <span
                            className={`t-red ${eog && !superGame.restart && game.reason === 'lost' ? 'text-through' : ''}`}>{eog && superGame.restart && game.reason === 'lost' && prizeNumber === game.cells.length ? 0 : parseRoundValue(game.cells[game.cells.length - 1].prize / 100.0)}</span>
                        <span>b</span>
                    </div>
                </Col>
                <Col xs={2} className={'paper-left-h2-white'}>
                    <Row>
                        <Col className={'p-0 font-weight-bold'}>{i18n.t('common.prize')}</Col>
                    </Row>
                    <Row>
                        <Col>
                            <span className={`t-red ${gameExpire ? 'text-through' : ''}`}>{prize}</span>
                            <span>b</span>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    };

    const getWinLost = (cells) => cells
        .reduce((v, item) => item.status === 'lost' ? {lost: v.lost + 1, win: v.win} : item.status === 'win' ? {
            lost: v.lost,
            win: v.win + 1
        } : v, {lost: 0, win: 0});

    const getInfoTagXmt = (state, game, stepsServer, step, prize) => {
        const {eog = false, prizeNumber} = state;
        const {reason = ''} = game;

        if (eog) {
            const result = getWinLost(game.cells);

            if (reason === 'win') {
                return {
                    infoHit: i18n.t(prizeNumber === game.prizes.length ? 'xmt.interactive_info_hit_take_max_prize' : 'xmt.interactive_info_hit_take_prize', {prize: prize}),
                    infoStatus: i18n.t(result.win === game.prizes.length ? 'xmt.interactive_info_hit_take_main_prize' : `xmt.interactive_info_status_take_prize_${superGame.restart ? 'plus' : 'zero'}`, {
                        fail: result.lost,
                        prize: prize
                    }),
                    classname: 'xmt-info-turn-none',
                    moon: '/img/moon_fun.png'
                };
            } else if (reason === 'lost' && prizeNumber !== game.prizes.length && superGame.restart) {
                return {
                    infoHit: i18n.t('xmt.interactive_info_hit_lost2'),
                    infoStatus: i18n.t(`xmt.interactive_info_status_take_prize_${superGame.restart ? 'plus' : 'zero'}`, {
                        fail: result.lost,
                        prize: 0
                    }),
                    classname: 'xmt-info-turn-failure2',
                    moon: '/img/moon_lost.png'
                };
            } else {
                return {
                    infoHit: i18n.t(reason === 'timeout' ? 'xmt.info_bottom_result.timeout2' : 'xmt.interactive_info_hit_lost'),
                    infoStatus: i18n.t(reason === 'timeout' ? `xmt.interactive_info_play2_timeout` : `xmt.interactive_info_status_take_prize_${superGame.restart ? 'plus' : 'zero'}`, {
                        fail: result.lost,
                        prize: 0
                    }),
                    classname: 'xmt-info-turn-failure',
                    moon: '/img/moon_lost.png'
                };
            }
        } else {
            if (stepsServer.length > 0) {
                if (stepsServer.filter(i => i === step).length > 0) {
                    return {
                        infoHit: i18n.t('xmt.status_hit'),
                        infoStatus: i18n.t(`xmt.interactive_info_not_goal`),
                        classname: 'xmt-info-turn-failure',
                        moon: '/img/moon_lost.png'
                    };
                } else {
                    return {
                        infoHit: i18n.t('xmt.status_not_hit', {prize: prize}),
                        infoStatus: i18n.t(`xmt.interactive_info_goal`),
                        classname: 'xmt-info-turn-success',
                        moon: '/img/moon_fun.png'
                    };
                }
            } else {
                return {
                    infoHit: i18n.t(`xmt.${superGame.restart ? 'interactive_info_play2_plus' : 'interactive_info_play2_zero'}`),
                    infoStatus: i18n.t(`${subtype}.info_bottom2`),
                    classname: 'xmt-info-turn-none',
                    moon: step < 0 ? '/img/moon_none.png' : '/img/moon_surprised.png'
                };
            }
        }

        /*
                if (info !== undefined) {
                    return info;
                } else if (eog) {
                    if (reason === 'timeout') {
                        return i18n.t('xmt.info_bottom_result.timeout2');
                    } else if (reason === 'lost') {
                        return i18n.t('xmt.info_bottom_result.lost');
                    }

                    switch (count) {
                        case 0:
                            return i18n.t(prizeNumber === game.cells.length ? 'xmt.info_bottom_result.zero_max' : 'xmt.info_bottom_result.zero');
                        case 1:
                            return i18n.t('xmt.info_bottom_result.one');
                        default:
                            return i18n.t('xmt.info_bottom_result.more', {count});
                    }
                }
        */


    };

    const makeRows = () => {
        const {eog, prize = 0} = state;
        const {step = -1, position = 0, stepsServer = [], prizes, zones, cells, code} = game;
        let rows = [], superRows = [];

        const infoTagXmt = getInfoTagXmt(state, game, stepsServer, step, prize);
        const cn = `frx-col-frx${zones}-1 xmt-cell d-flex justify-content-center align-items-center`;

        let moon;

        if (eog) {
            moon = <img className={'xmt-moon'}
                        src={game.reason === 'win' ? '/img/moon_fun.png' : game.reason === 'lost' ? '/img/moon_lost.png' : '/img/moon_none.png'} alt={''}/>;
        } else {
            moon = <img className={'xmt-moon'} src={infoTagXmt.moon} alt={''}/>;
        }

        for (let idx = prizes.length; idx > 0; idx--) {
            let columns = prizes[idx - 1].column;
            let cols = [];

            const row = cells[idx - 1];
            const ps = parseRoundValue(row.prize / 100.0);

            let cn_info = '';
            if (position === (idx - 1)) {
                cn_info = 'xmt-info-active';
            }

            let ball_img = undefined, block_img;
            if (row.status === "future") {
                const awc = position === (idx - 1) ? 'active' : 'waiting';
                ball_img =
                    <img className={`${superGame.restart || position === (idx - 1) ? 'xmt-ball-right' : 'xmt-ball-left'} ${awc}`}
                         src={`/img/tree_ball_${code === 'r' ? 'red' : 'yellow'}.png`} alt={''}/>;
                if (eog && !superGame.restart) {
                    block_img = <img className={`xmt-block`} src={'/img/blocked.png'} alt={''}/>;
                }
            }

            cols.push(
                <Col key={'info-left'} xs={2} className={`d-flex justify-content-center align-items-center xmt-info-left ${cn_info}`}>
                    {idx}
                    {ball_img}
                    {block_img}
                </Col>
            );

            let active_cols = [];
            for (let c = 1; c <= columns; c++) {
                const cc = row.cols[c - 1];

                const cell = makeCellXmt2(c, cc, position === (idx - 1), (c - 1) === step && stepsServer.length === 0, row.status === 'win' || row.status === 'timeoutWin' || (eog && row.status === 'future' && (superGame.restart || game.reason !== 'lost')), code);
                active_cols.push(<div key={c} className={`${cn} ${cell.classname}`}
                                      onClick={() => eog ? undefined : onBtnClick(idx, c)}>{cell.content}</div>)
            }

            if (columns !== zones) {
                const cnh = "frx-col-frx" + zones + "-h" + columns;
                active_cols.push(<div key={columns + 1} className={cnh}>&nbsp;</div>);
                active_cols.unshift(<div key={0} className={cnh}>&nbsp;</div>);
            }

            cols.push(
                <Col key={'info-center'} xs={8} className={`${idx === prizes.length ? 'paper-top-h2-white' : ''}`}>
                    <Row noGutters={true}>
                        {active_cols}
                    </Row>
                </Col>);

            const mq = !eog && row.status === 'future' ? makeMultiplicator2(row.multiplicator > 1 ? row.multiplicator : 1, 'red') : [];
            cols.push(
                <Col key={'info-right'} xs={2}
                     className={`d-flex justify-content-center align-items-center xmt-info-right ${cn_info} ${row.status === 'lost' || row.status === 'lostWin' || (eog && row.status === 'future' && game.reason === 'lost' && !superGame.restart) ? 'text-through' : ''}`}>
                    {(row.status === 'win' || row.status === 'lostWin' || row.status === 'timeoutWin') ? `[${ps}]` : ps}
                    {mq}
                </Col>);

            rows.push(<Row key={`r-${idx}`} noGutters={true}>{cols}</Row>)
        }

        superRows.push(
            <Row key={'sr'}>
                <Col xs={12} className={'px-0'}>
                    <div style={{position: 'relative'}}>
                        <div className={'xmt-field'}>
                            <img className={'xmt-tree'} src={'/img/tree_white.png'} alt={''}/>
                            {moon}
                            <FontAwesomeIcon icon={['fal', 'file-alt']} onClick={() => toggleShowLegend(prevState => !prevState)}
                                             className={`xmt-legend t-yellow`}/>
                        </div>
                        {rows}
                    </div>
                </Col>
            </Row>);

        superRows.push(
            <Row key={'info-hit'}>
                <Col xs={12} className={'p-0 paper-top-h2-white'}>
                    <div className={`px-2 py-1 ${infoTagXmt.classname}`}
                         dangerouslySetInnerHTML={dangerHtml(infoTagXmt.infoHit)}/>
                </Col>
            </Row>);

        superRows.push(
            <Row key={'info-status'}>
                <Col xs={12} className={'p-0 paper-top-h2-white'}>
                    <div className={'d-flex flex-column justify-content-center'}>
                        <div className={'px-2 py-1 xmt-info-status'} dangerouslySetInnerHTML={dangerHtml(infoTagXmt.infoStatus)}/>
                    </div>
                </Col>
            </Row>
        );

        return superRows;
    };

    const makeQrDialog = () => {
        const {settings_id, type, prizes} = game;
        const secret_code = `<span class='t-red'>${eog ? game.secretCode : i18n.t('xmt.default_key')}</span><span class='t-blue'>_</span>${getServerStepsFormat()}`;

        let body;

        if (eog) {
            body = <ModalBody className={'t-blue'}>
                <div className={'d-flex flex-row p-1 paper-h1-top mt-1'}>
                    <div className={'h-100 d-flex justify-content-center align-items-center'}
                         onClick={() => window.open(getGameURI(game), '_self')}>
                        <QRCode width={'80'} height={'80'} renderAs='svg' level='L'
                                value={getGameURI(game)}
                        />
                    </div>
                    <div style={{wordBreak: 'break-all', textAlign: 'left'}}
                         className={'d-flex flex-column justify-content-center pl-1'}>
                        <div dangerouslySetInnerHTML={i18ntDangerHtml('common.paper_game_id', {ticket_id: game.ticket})}/>
                        <div dangerouslySetInnerHTML={i18ntDangerHtml('common.paper_public_code', {public_code: game.publicCode})}/>
                        <div dangerouslySetInnerHTML={i18ntDangerHtml('common.paper_valid_to', {valid_to: Moment(game.validTo).format(dateTimeFormat)})}/>
                    </div>
                </div>
                <div className={'d-flex flex-row p-1 paper-dashed-top mt-1'}>
                    <div style={{wordBreak: 'break-all', textAlign: 'left'}}
                         className={'d-flex flex-column justify-content-center pr-1 w-100'}>
                        <div dangerouslySetInnerHTML={i18ntDangerHtml(`${subtype}.paper_secret_code`)}/>
                        <div dangerouslySetInnerHTML={i18ntDangerHtml('common.paper_secret_key', {secret_key: game.secretCode})}/>
                        <div dangerouslySetInnerHTML={i18ntDangerHtml(`${subtype}.paper_lock_zones`, {lock_zones: getServerStepsFormat()})}/>
                        <div dangerouslySetInnerHTML={i18ntDangerHtml('common.paper_generate', {date: Moment(game.validFrom).format(dateTimeFormat)})}/>
                    </div>
                    <div className={'h-100 d-flex justify-content-center'}
                         onClick={() => window.open(getCheckURI(`${game.secretCode}_${game.serverSteps}`, game.publicCode), '_blank')}>
                        <QRCode width={'80'} height={'80'} renderAs='svg' level='L'
                                value={getCheckURI(`${game.secretCode}_${game.serverSteps}`, game.publicCode)}
                        />
                    </div>
                </div>

                <div className={'d-flex flex-column justify-content-center'}>
                    <div dangerouslySetInnerHTML={i18ntDangerHtml('common.coupon_check')}/>
                </div>
                <div className={'d-flex flex-column justify-content-center text-center'}>
                    <div dangerouslySetInnerHTML={i18ntDangerHtml('common.coupon_check_formula')}/>
                </div>
            </ModalBody>;
        } else {
            body = <ModalBody className={'t-blue'}>
                <div className={'paper-h1-top'}
                     dangerouslySetInnerHTML={i18ntDangerHtml(superGame.restart ? 'xmt.qr_dialog_plus' : 'xmt.qr_dialog_zero', {
                         game_type: type
                         , timeout: (game.validTo.getTime() - game.validFrom.getTime()) / 1000
                         , max_prize: parseRoundValue(game.cells[prizes.length - 1].prize / 100.0)
                     })}/>
                <div className={'paper-dashed-top mt-1'}
                     dangerouslySetInnerHTML={i18ntDangerHtml('xmt.qr_dialog_info', {
                         game_id: game.ticket
                         , public_code: game.publicCode
                         , secret_code
                     })}/>
                <div className={'d-flex flex-row p-1 paper-dashed-top mt-1'}>
                    <div className={'h-100 d-flex justify-content-center align-items-center'}
                         onClick={() => window.open(getGameURI(game), '_self')}>
                        <QRCode width={'80'} height={'80'} renderAs='svg' level='L'
                                value={getGameURI(game)}
                        />
                    </div>
                    <div style={{wordBreak: 'break-all', textAlign: 'left'}}
                         className={'d-flex flex-column justify-content-center pl-1'}>
                        <div className={'d-flex'}>
                            <div dangerouslySetInnerHTML={i18ntDangerHtml('common.paper_game_id', {ticket_id: game.ticket})}/>
                            <div className={'ml-2'} dangerouslySetInnerHTML={i18ntDangerHtml('xmt.paper_cost', {cost: game.cost})}/>
                        </div>
                        <div dangerouslySetInnerHTML={i18ntDangerHtml('common.paper_public_code', {public_code: game.publicCode})}/>
                        <div dangerouslySetInnerHTML={i18ntDangerHtml('common.paper_valid_to', {valid_to: Moment(game.validTo).format(dateTimeFormat)})}/>
                    </div>
                </div>
            </ModalBody>;
        }

        return (
            <Modal isOpen={qrDialog} toggle={() => openQrDialog(!qrDialog)} className={props.className}>
                <ModalBody className={'paper-bottom-h2 t-blue pb-1'}>
                    <Row>
                        <Col className={'text-center'}>
                            <h5 className={'my-0'}>
                                <span className={'t-blue'}>{i18n.t(!eog ? 'common.e_ticket' : 'common.e_coupon')}</span>
                            </h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={'auto'}>
                            <h5>
                                <span className={'t-blue'}>{Settings.getGameFullTitle(settings_id, type)}</span>
                            </h5>
                        </Col>
                        <Col xs={'auto'} className={'ml-auto'}>
                            <h5>
                                <span className={'t-blue'}>{i18n.t('common.game_id')}</span>
                                <span className={'t-red pl-1'}>{game.ticket}</span>
                            </h5>
                        </Col>
                    </Row>
                </ModalBody>
                {body}
                <ModalFooter>
                    <Button color="primary" onClick={() => openQrDialog(!qrDialog)}>{i18n.t('common.ok')}</Button>{' '}
                </ModalFooter>
            </Modal>
        );
    };

    const {eog, prize, prizeNumber, gameExpire = false} = state;
    const {subtype, settings_id, type} = game;
    const superGame = Settings.getSuperGame(settings_id);

    const tv = Math.floor(dataDiff(game.validTo, eog ? (game.finish !== undefined ? game.finish : game.validTo) : serverTime) / 1000);

    const makeAnimation = (anim, classname, image) => anim && anim.map((item, idx) => <img key={`${classname}-${idx}`} className={classname}
                                                                                           style={{
                                                                                               left: `${item.x}%`,
                                                                                               top: `${item.y}%`,
                                                                                               animationDelay: `${item.delay}s`
                                                                                           }}
                                                                                           src={image} alt={''}/>);

    const makeCheckDialog = () => (
        <Modal isOpen={enabledCheckDialog} toggle={() => openCheckDialog(!enabledCheckDialog)} className={props.className}>
            <ModalHeader
                toggle={() => openCheckDialog(!enabledCheckDialog)}>{i18n.t('common.check_title')}</ModalHeader>
            <ModalBody>
                <div dangerouslySetInnerHTML={i18ntDangerHtml('xmt.check_body')}/>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => {
                    openCheckDialog(false);
                    onBtnTakePrize();
                }}>{i18n.t('common.check_game0')}</Button>
                <Button color="secondary" onClick={() => openCheckDialog(false)}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );

    const makeLegend = () => {
        return (
            <Row>
                <Col className={`px-1 xmt-help-text`}>
                    <div dangerouslySetInnerHTML={i18ntDangerHtml('xmt.legend')}/>
                    <div className={'d-flex justify-content-end mb-2'}>
                        <Button className={'rounded mb-1'} onClick={() => toggleShowLegend(false)}>{i18n.t('common.close')}</Button>
                    </div>
                </Col>
            </Row>
        );
    }

    return (
        <SimpleContainer BackGroundColor={'white'} Footer0={Footer0}>
            <Row>
                <Col className={'paper-h1-blue t-blue'}>
                    {/*======================  Header ======================*/}
                    <Row className={'my-1 mx-n2'}>
                        <Col className={'p-0 d-flex justify-content-start align-items-center'}>
                            <i className={`fa fa-qrcode text-info-2 t-red pl-1 ${eog ? 'ft-blink' : ''}`} aria-hidden="true"
                               onClick={() => openQrDialog(!qrDialog)}/>
                        </Col>
                        <Col className={'p-0 d-flex justify-content-center align-items-center'}>
        <span dangerouslySetInnerHTML={i18ntDangerHtml('xmt.title_cost', {
            title: Settings.getGameFullTitle(settings_id, type)
            , cost: game.cost
        })}/>
                        </Col>
                        <Col className={'p-0 d-flex justify-content-end align-items-center'}>
                            <span className={'text-info-1 t-red'}>{Math.max(tv, 0)}</span>
                            <span className={'text-info-1 t-blue'}>"</span>
                        </Col>
                    </Row>
                    {
                        isShowLegend
                            ? makeLegend()
                            : <>
                                <Row>
                                    <Col className={'xmt-field-outer t-white'}>
                                        {makeSecondHeader()}
                                        {makeRows()}
                                        {makeAnimation(stars, 'xmt-star', '/img/star-24.png')}
                                        {makeAnimation(snows, 'xmt-snow', '/img/snow.png')}
                                    </Col>
                                </Row>
                                {makeFooter(subtype, state, game)}
                                {makeQrDialog()}
                                {makeCheckDialog()}
                            </>
                    }
                </Col>
            </Row>
            <Row>
                <Col>
                    <Alert color={'danger'} isOpen={state.error !== undefined}>
                        {map2Tags(makeErrorText(subtype, state.error), '\n', <br/>)}
                    </Alert>
                </Col>
            </Row>
        </SimpleContainer>
    );
};
