import React, {Component} from "react";
import {Redirect} from 'react-router-dom';
import {connect} from "react-redux";
import * as PropTypes from 'prop-types'

class Logout extends Component {
    static propTypes = {
        redirect: PropTypes.bool
    };

    static defaultProps = {
        redirect: false
    };

    state = {
        redirect: this.props.redirect
    };

    componentDidMount() {
        const {auth} = this.props.api;
        auth.logout()
            .then((response) => this.setState({redirect: true}));
    }

    render() {
        const {redirect} = this.state;
        return redirect ? (
            <Redirect to={'/'}/>
        ) : (
            <div>Logout...</div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        api: state.api,
    }
};

export default connect(mapStateToProps)(Logout)