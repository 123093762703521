import React from 'react';
import {mapToCssModules} from "reactstrap/lib/utils";
import classNames from "classnames";

const getImage = (type, circle) => {
    switch (type) {
        case 'g':
            return circle ? '/img/green_smile_circle.png' : '/img/small_green_smile.png';
        case 'y':
            return circle ?'/img/yellow_smile_circle.png' : '/img/small_yellow_smile.png';
        case 'r':
            return circle ?'/img/red_smile_circle.png' : '/img/small_red_smile.png';
        default:
            return '';
    }
};

const Smile = (props) => {
    const {
        className,
        cssModule,
        wallet,
        type,
        ...attributes
    } = props;

    const classes = mapToCssModules(classNames(
        className,
    ), cssModule);

    return (
        <div {...attributes} className={classes}>
                <span className={'t-blue align-middle'}>
                    {wallet === undefined || wallet[type] === undefined ? 0 : wallet[type] / 100}
                </span>
            <SmileIcon type={type}/>
        </div>
    );
};

export const SmileIcon = (props) =>
    <img className={'smile-coin align-middle'} src={getImage(props.type)} alt={''}/>;

export const SmileIconString = (type, circle) =>
    `<img class="smile-coin align-middle" src="${getImage(type, circle)}" alt=""/>`;

export default Smile;