import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from "react-redux";

const UserInfo = (props) => {
    const {api} = props;

    return api.auth.isUserAuthenticated() ? (
        <div>
            {api.auth.getNickname()}&nbsp;
            <Link to='/logout'>logout</Link>
        </div>
    ) : (
        <Link to='/login'>login</Link>
    );
};

const mapStateToProps = (state) => {
    return {
        api: state.api,
    }
};

export default connect(mapStateToProps)(UserInfo)