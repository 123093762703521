import React from "react";
import classNames from 'classnames';
import {mapToCssModules, tagPropType} from "reactstrap/src/utils";
import * as PropTypes from "prop-types";

/*eslint no-useless-rename: ["error", { ignoreDestructuring: true }]*/

const propTypes = {
    tag: tagPropType,
    className: PropTypes.string,
    cssModule: PropTypes.object,
};

const defaultProps = {
    tag: 'div'
};

const TextWithNumber = (props) => {
    const {
        className,
        cssModule,
        title,
        number,
        tag: Tag,
        ...attributes
    } = props;

    const classes = mapToCssModules(classNames(
        className,
    ), cssModule);

    return (
        <Tag {...attributes} className={classes}>
                <span className={'textComponent'}>
                    {title}
                </span>
            <span className={'numberComponent'}>
                    {number}
                </span>
        </Tag>);
};

TextWithNumber.propTypes = propTypes;
TextWithNumber.defaultProps = defaultProps;

export default TextWithNumber;