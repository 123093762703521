import Settings from "../../Football/Settings";
import i18n, {i18nCurrentLanguage, i18ntDangerHtml} from "../../../i18n";
import React from "react";
import {getCharByIndex, getLocationOrigin, parseRoundValue} from "../../../components/Common/GameControl/Utils";
import {Col, Row} from "reactstrap";
import QRCode from "qrcode.react";
import Moment from "moment";
import {Config} from "../../../config";

export const dateTimeFormat = 'DD-MM-YYYY HH:mm:ss';

export const initCells = (prizes, div) => {
    let rows = [], useMultiplicator = false;

    for (let idx = 1; idx <= prizes.length; idx++) {
        let cols = [];
        const row = prizes[idx - 1];
        for (let c = 1; c <= row.column; c++) {
            cols.push({player: false});
        }
        rows.push({
            cols: cols,
            status: 'future',
            prize: div * row.prize,
            edge: row.edge,
            multiplicator: row.multiplicator !== undefined ? row.multiplicator : 1
        });

        if (row.multiplicator > 1) {
            useMultiplicator = true;
        }
    }

    return {rows, useMultiplicator};
};

export const prepareGameInfo = (gameInfo) => {
    const {ticket, settings_id, subgame_code, public_code: publicCode, prizes, code = 'y'} = gameInfo;
    const superGame = Settings.getSuperGame(settings_id);
    const type = parseInt(subgame_code);
    const prepareCells = initCells(prizes, gameInfo.price_mult);

    return {
        prizes: prizes
        , ticket: ticket
        , publicCode: publicCode
        , validFrom: new Date(gameInfo.create)
        , validTo: new Date(gameInfo.expire)
        , cells: prepareCells.rows
        , useMultiplicator: prepareCells.useMultiplicator
        , type: type
        , subtype: superGame.subtype
        , settings_id: settings_id
        , cost: gameInfo.price / 100
        , zones: prizes[0].column
        , position: 0
        , step: -1
        , code
    }
};

export const fillCellsFromResponse = (game, result) => {
    const charCodeAlpha = 'A'.charCodeAt(0);
    let lastServerResult = [];

    game.serverParts = [];
    game.serverSteps = '';
    game.position = 0;

    for (let r = 0; r < game.cells.length; r++) {
        const responseRow = result.rows[r];
        const gameRow = game.cells[r];

        gameRow.status = responseRow.status;
        gameRow.prize = responseRow.prize;

        if (responseRow.server && responseRow.server.length !== 0) {
            game.serverParts.push(responseRow.server);

            if (game.useMultiplicator) {
                game.serverSteps += r + 1;
            }

            lastServerResult = [];
            for (let idx = 0; idx < responseRow.server.length; ++idx) {
                const serverResult = responseRow.server.charCodeAt(idx) - charCodeAlpha;
                gameRow.cols[serverResult].server = true;
                lastServerResult.push(serverResult);
            }
            game.serverSteps += responseRow.server;
        }
        if (responseRow.player && responseRow.player !== '') {
            gameRow.cols[responseRow.player.charCodeAt(0) - charCodeAlpha].player = true;
            game.position = r + 1;
        }
    }
    game.payed = result.payed;
    game.finish = result.finish !== undefined ? new Date(result.finish) : undefined;
    game.reason = result.reason;

    game.secretCode = result.secret_key;

    if (game.reason !== undefined) {
        game.position = -1;
    }

    return lastServerResult;
};


export const getCornerTagXmt = (zonesActive, stepsServer, step, prize) => {
    if (zonesActive) {
        if (stepsServer.length > 0) {
            if (stepsServer.filter(i => i === step).length > 0) {
                return {
                    corner: i18n.t('xmt.short_hit'),
                    info: i18n.t('xmt.status_hit'),
                    style: {background: 'red', color: 'yellow'},
                    classname: 'xmt-info-turn-failure',
                    moon: '/img/moon_lost.png'
                };
            } else {
                return {
                    corner: i18n.t('xmt.short_not_hit'),
                    info: i18n.t('xmt.status_not_hit', {prize: prize}),
                    style: {background: 'green', color: 'yellow'},
                    classname: 'xmt-info-turn-success',
                    moon: '/img/moon_fun.png'
                };
            }
        } else {
            return {
                classname: 'xmt-info-turn-none',
                corner: i18n.t('common.online'),
                moon: '/img/moon_none.png'
            };
        }
    } else {
        return {
            corner: '',
            classname: 'xmt-info-turn-none',
        };
    }
};

export const makeCellXmt2 = (pos, cell, activeRow, activeCell, winStatus, code) => {
    if (cell.player && cell.server) {
        return {
            classname: ''
            , content: <img className={'xmt-ball'} src={'/img/tree_boom_3.png'} alt={''}/>
        };
    } else if (cell.player) {
        return {
            classname: ''
            , content: <img className={'xmt-ball'} src={`/img/tree_ball_${code === 'r' ? 'red' : 'yellow'}.png`} alt={''}/>
        };
    } else if (cell.server) {
        return {
            classname: ''
            , content: <img className={'xmt-ball'}
                            src={winStatus ? '/img/tree_gift_white.png' : '/img/tree_gift_disable.png'}
                            alt={''}/>
        };
    } else {
        return {
            classname: ''
            , content: <span className={activeRow ? (activeCell ? 't-red' : 't-yellow') : 't-white'}>{getCharByIndex('A', pos - 1)}</span>
        };
    }
};

export const makeMultiplicator2 = (count, color) => {
    let qq = [];
    if (count > 0) {
        qq.push(<img key={1} className={'xmt-gift-1'} src={'/img/tree_gift_white.png'} alt={''}/>);
    }
    if (count > 1) {
        qq.push(<img key={2} className={'xmt-gift-2'} src={'/img/tree_gift_white.png'} alt={''}/>);
    }
    if (count > 2) {
        qq.push(<img key={3} className={'xmt-gift-3'} src={'/img/tree_gift_white.png'} alt={''}/>);
    }
    if (count > 3) {
        qq.push(<img key={4} className={'xmt-gift-4'} src={'/img/tree_gift_white.png'} alt={''}/>);
    }
    return qq;
};

export const prepareInfoBottomXmt = (state, game, info) => {
    const {eog, prizeNumber} = state;
    const {reason} = game;

    if (info !== undefined) {
        return info;
    } else if (eog) {
        if (reason === 'timeout') {
            return i18n.t('xmt.info_bottom_result.timeout');
        }

        const count = game.cells.reduce((v, item) => item.status === 'lost' ? v + 1 : v, 0);
        switch (count) {
            case 0:
                return i18n.t(prizeNumber === game.cells.length ? 'xmt.info_bottom_result.zero_max' : 'xmt.info_bottom_result.zero');
            case 1:
                return i18n.t('xmt.info_bottom_result.one');
            default:
                return i18n.t('xmt.info_bottom_result.more', {count});
        }
    } else {
        return i18n.t('xmt.info_bottom');
    }
};

export const getCheckURI = (secretCode, publicCode, qr_mode) => {
    let index = '', currentLanguage = i18nCurrentLanguage();
    if (currentLanguage === 'ru' || currentLanguage === 'de') {
        index = `index.${currentLanguage}.html`;
    }
    return `https://hash${qr_mode ? '-qr' : ''}.cryptochainlab.com/${index}?secret_code=${encodeURIComponent(secretCode)}&open_code=${encodeURIComponent(publicCode)}`;
};

export const getGameURI = (game, BackURI) => {
    const {ticket, publicCode} = game;
    const origin = Config.COMMON_URL !== '' ? Config.COMMON_URL : getLocationOrigin();
    return `${origin}/fig?game_id=${ticket}&open_code=${publicCode}${BackURI !== undefined ? '&back=' + encodeURIComponent(BackURI) : ''}`;
};

export const getGameURI2 = (game) => {
    const {ticket, publicCode} = game;
    const origin = Config.COMMON_URL !== '' ? Config.COMMON_URL : getLocationOrigin();
    return `${origin}/g/fb?game_id=${ticket}&open_code=${publicCode}`;
};

export const makeXmtTitle = (game, prize, prizeNumber, zonesActive) => {
    const pv = prizeNumber === game.cells.length ? prize : parseRoundValue(game.cells[game.cells.length - 1].prize / 100.0);
    const cnValue = zonesActive ? 't-red' : '';
    const cnTitle = zonesActive ? 't-blue' : '';
    return (
        <Col xs={8} className={'p-0 paper-top-h1 d-flex flex-column'}>
            <div>{i18n.t('xmt.info_top0')}</div>
            <div>
                <span className={cnValue}>{pv}</span>
                <span className={cnTitle}>b</span>
            </div>
        </Col>
    );
};

export const makeErrorText = (subtype, error) => {
    let result = i18n.t('error.title');
    if (error !== undefined) {
        const {status = '', over = [], missing = []} = error;
        switch (status) {
            case 'out_of_position':
                result += '\n' + i18n.t('error.out_of_position');
                break;
            case 'interactive':
                result += '\n' + i18n.t('error.interactive');
                break;
            case 'archive':
                result += '\n' + i18n.t('error.archive');
                break;
            case 'timeout':
                result += '\n' + i18n.t('error.timeout');
                break;
            case 'no_move':
                result += '\n' + i18n.t('error.' + subtype + '.no_move');
                break;
            default:
                if (over.length > 0) {
                    result += '\n' + i18n.t('error.' + subtype + '.over_ball', {numbers: over.toString()});
                }
                if (missing.length > 0) {
                    result += '\n' + i18n.t('error.' + subtype + '.missing_ball', {numbers: missing.toString()});
                }
        }
    }

    return result;
};

export const makeFooter = (subtype, state, game) => {
    const {eog, zonesActive = false, prize, prizeNumber} = state;
    const {reason} = game;

    let rows = [];

    rows.push(
        <Row key={'f0'}>
            <Col xs={12} className={'d-flex justify-content-center align-items-center p-1 paper-h1-top'}>
                <span dangerouslySetInnerHTML={i18ntDangerHtml(`penalty.price`, {
                    price: game.cost
                })}/>
                {eog ? <>
                    <span className={'mx-auto'} dangerouslySetInnerHTML={i18ntDangerHtml(`penalty.played`, {
                        played: Moment(reason === 'timeout' ? new Date() : game.finish).format(dateTimeFormat)
                    })}/>
                    <span dangerouslySetInnerHTML={i18ntDangerHtml(`penalty.prize`, {
                        prize
                    })}/>
                </> : <></>}
            </Col>
        </Row>
    );

    rows.push(
        <Row key={'f1'}>
            <Col xs={12} key={'fl'} className={'d-flex flex-row p-1 paper-dashed-top'}>
                <div className={'h-100 d-flex justify-content-center align-items-center'}
                     onClick={() => window.open(getGameURI(game), '_self')}>
                    <QRCode width={'80'} height={'80'} renderAs='svg' level='L'
                            value={getGameURI(game)}
                    />
                </div>
                <div style={{wordBreak: 'break-all', textAlign: 'left'}}
                     className={'d-flex flex-column justify-content-center pl-1'}>
                    <div dangerouslySetInnerHTML={i18ntDangerHtml('common.paper_valid_to', {valid_to: Moment(game.validTo).format(dateTimeFormat)})}/>
                    <div dangerouslySetInnerHTML={i18ntDangerHtml('common.paper_game_id', {ticket_id: game.ticket})}/>
                    <div dangerouslySetInnerHTML={i18ntDangerHtml('common.paper_public_code', {public_code: game.publicCode})}/>
                </div>
            </Col>
        </Row>
    );

    let todoInfo = [];
    if (eog) {
        if (prize !== 0) {
            todoInfo.push(<div key={1} dangerouslySetInnerHTML={i18ntDangerHtml(subtype + '.paper_prize', {
                prize: prize
                , kick: prizeNumber
            })}/>);
        } else if (reason === 'timeout') {
            todoInfo.push(<div key={1} dangerouslySetInnerHTML={i18ntDangerHtml(subtype + '.paper_no_prize_timeout', {
                prize: prize
                , kick: prizeNumber === 0 ? '' : prizeNumber
            })}/>);
        } else {
//            todoInfo.push(<div key={1}>{i18n.t(subtype + '.paper_no_prize', {prize: prize, kick: prizeNumber})}</div>);
        }
    } else {
        if (!zonesActive) {
            todoInfo.push(<div key={3} dangerouslySetInnerHTML={i18ntDangerHtml(subtype + '.paper_todo')}/>);
        }
    }
    rows.push(
        <Row key={'f2'}>
            <Col xs={12} key={'fl'} className={'px-1'} style={{textAlign: 'left'}}>
                <div className={'d-flex flex-column justify-content-center'}>
                    {todoInfo}
                </div>
            </Col>
        </Row>
    );

    if (eog) {
        rows.push(
            <Row key={'f3'}>
                <Col xs={12} key={'fl'} className={'d-flex flex-row px-1 pt-1 paper-dashed-top'}>
                    <div style={{wordBreak: 'break-all', textAlign: 'left'}}
                         className={'d-flex flex-column justify-content-center pr-1 w-100'}>
                        <div dangerouslySetInnerHTML={i18ntDangerHtml(`${subtype}.paper_secret_code`)}/>
                        <div dangerouslySetInnerHTML={i18ntDangerHtml('common.paper_secret_key', {secret_key: game.secretCode})}/>
                        <div dangerouslySetInnerHTML={i18ntDangerHtml(`${subtype}.paper_lock_zones`, {lock_zones: game.serverSteps})}/>
                        <div dangerouslySetInnerHTML={i18ntDangerHtml('common.paper_generate', {date: Moment(game.validFrom).format(dateTimeFormat)})}/>
                    </div>
                    <div className={'h-100 d-flex justify-content-center'}
                         onClick={() => window.open(getCheckURI(`${game.secretCode}_${game.serverSteps}`, game.publicCode), '_blank')}>
                        <QRCode width={'80'} height={'80'} renderAs='svg' level='L'
                                value={getCheckURI(`${game.secretCode}_${game.serverSteps}`, game.publicCode)}
                        />
                    </div>
                </Col>
            </Row>
        );
        rows.push(
            <Row key={'f4'}>
                <Col xs={12} key={'fl'} className={'px-1'} style={{textAlign: 'left'}}>
                    <div className={'d-flex flex-column justify-content-center'}>
                        <div dangerouslySetInnerHTML={i18ntDangerHtml('common.coupon_check')}/>
                    </div>
                </Col>
            </Row>
        );
        rows.push(
            <Row key={'f5'}>
                <Col xs={12} key={'fl'} className={'px-1 pb-1'} style={{textAlign: 'center'}}>
                    <div className={'d-flex flex-column justify-content-center'}>
                        <div dangerouslySetInnerHTML={i18ntDangerHtml('common.coupon_check_formula')}/>
                    </div>
                </Col>
            </Row>
        );
    }

    return rows;
};
